@import "./src/assets/scss/variables";

$side-menu-width: 300px;
.main-layout {
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  background: $color-white;

  .side-menu {
    overflow: auto;
    height: 100vh;
    background: $color-gray-light;

    .pe-brand {
      width: 100px;
      padding: 1.5rem 0;
      margin-left: 1rem;

      img {
        width: 100%;
        height: auto;
      }
    }
    .menu-ul{
      background-color: transparent;
      .menu-item{
        background-color: transparent;
        transition: 0.2s;
        width: 95%;
        margin: 0 auto;
        border-radius: 5px;
        color: #646464;
        padding: 1rem 0;

        .icon-menu{
          color: #646466;
          font-size: 1.5rem;
        }
      }
      .menu-item:hover{
        color: #000000;
      }
      .ant-menu-item-selected{
        background-color: rgba($color-primary, 0.09) !important;
        font-weight: 500;
        border-right: 5px solid $color-primary;
        .icon-menu{
          color: $color-primary;
        }
      }
      .ant-menu-item-selected:after{
        border: none;
      }
      .ant-menu-item-selected:hover{
      }
    }
  }

  .content-layout {
    //margin-left: $side-menu-width;
    overflow: hidden;

    .content {
      background: $color-white;
      .inner-content{

        .scrollable{
          padding: 2rem;
          height: 100vh;
          overflow: scroll;
        }
      }
    }

    .header {
      background: #FAF0E4;
      display: flex;
      align-items: center;
      .trigger-menu{
        font-size: 1.5rem;
        transition: 0.2s;
      }
      .trigger-menu:hover{
        color: $color-primary;
        transform: scale(1.15);
        cursor: pointer;
      }
      .notification-bell{
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .is-notify:after{
        content: '';
        height: 0.5rem;
        width: 0.5rem;
        background: $color-primary;
        display: inline-block;
        position: absolute;
        border-radius: 0.5rem;
      }
      .is-notify:hover{
        transform: scale(1.25);
        cursor: pointer;
      }
    }
    .footer{
      background: $color-gray-light;
      z-index: 10000;
    }
  }
}
@media (max-width: 768px) {
  .content-layout{
    .content{
      .inner-content{
        .scrollable{
          padding: 1rem!important;
        }
      }
    }
    .header{
      padding: 0 1rem!important;
    }
  }
}
