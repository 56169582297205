//Global Variables
$font-primary: 'Roboto', sans-serif;

$color-primary: #FB8605;
$color-secondary: #AC0404;
$color-black: #000000;
$color-white: #ffffff;
$color-gray-light: #F4F4F4;
$placeholder-color: #ABABAB;

$color-success: #52C41A;
$color-danger: #FF4D4F;
$color-warning: #FAAD14;
