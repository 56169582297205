//Global css
@import "assets/bt-utils/bootstrap-grid.min.css";
@import "assets/bt-utils/bootstrap-utilities.min.css";
@import "assets/scss/variables";

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  box-sizing: border-box;
}
*:focus {
  outline: 0;
}
html, body {
  height: 100%;
  background-color: #F2F2F2;
  font-size: 14px;
  font-weight: 300;
}

h1, h2, h3, h4 {
  font-weight: 700;
  color: #4B4B4B;
}
h1{
  font-size: 3rem;
}
h2{
  font-size: 2.4rem;
}
h3{
  font-size: 1.9rem;
}
h4{
  font-size: 1.5rem;
}

h5, h6 {
  font-weight: 500;
}
h5{
  font-size: 1.25rem;
}
h6{
  font-size: 0.9rem;
}

p {
  line-height: 1rem;
  font-weight: 300;
}
input::placeholder {
  color: $placeholder-color !important;
  font-weight: 300;
}
a{
  text-decoration: underline;
  color: $color-secondary;
}
a:hover{
  text-decoration: none;
}
.small-text{
  font-size: 0.8rem;
  font-weight: 300;
}
.extra-small-text{
  font-size: 0.6rem;
  font-weight: 300;
}
.cursor-on{
  cursor: pointer;
}

.ant-form-item-explain-error{
  font-size: 0.8rem;
  margin-left: 0.25rem;
  margin-top: 0.15rem;
  text-align: left;
}
.ant-form-item{
  margin-bottom: 0.5rem;
}

.text-primary{
  color: $color-primary;
}

.ant-message-notice-content {
  margin-top: 100px !important;
}
@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  border: 0;
  margin: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-primary, #161616);
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 100%;
}

body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}

strong {
  font-weight: 600;
}

@media screen and (-ms-high-contrast: active) {
  svg {
    fill: ButtonText;
  }
}
h1 {
  font-size: var(--cds-heading-06-font-size, 2.625rem);
  font-weight: var(--cds-heading-06-font-weight, 300);
  line-height: var(--cds-heading-06-line-height, 1.199);
  letter-spacing: var(--cds-heading-06-letter-spacing, 0);
}

h2 {
  font-size: var(--cds-heading-05-font-size, 2rem);
  font-weight: var(--cds-heading-05-font-weight, 400);
  line-height: var(--cds-heading-05-line-height, 1.25);
  letter-spacing: var(--cds-heading-05-letter-spacing, 0);
}

h3 {
  font-size: var(--cds-heading-04-font-size, 1.75rem);
  font-weight: var(--cds-heading-04-font-weight, 400);
  line-height: var(--cds-heading-04-line-height, 1.28572);
  letter-spacing: var(--cds-heading-04-letter-spacing, 0);
}

h4 {
  font-size: var(--cds-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-heading-03-font-weight, 400);
  line-height: var(--cds-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-heading-03-letter-spacing, 0);
}

h5 {
  font-size: var(--cds-heading-02-font-size, 1rem);
  font-weight: var(--cds-heading-02-font-weight, 600);
  line-height: var(--cds-heading-02-line-height, 1.5);
  letter-spacing: var(--cds-heading-02-letter-spacing, 0);
}

h6 {
  font-size: var(--cds-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-01-font-weight, 600);
  line-height: var(--cds-heading-01-line-height, 1.42857);
  letter-spacing: var(--cds-heading-01-letter-spacing, 0.16px);
}

p {
  font-size: var(--cds-body-02-font-size, 1rem);
  font-weight: var(--cds-body-02-font-weight, 400);
  line-height: var(--cds-body-02-line-height, 1.5);
  letter-spacing: var(--cds-body-02-letter-spacing, 0);
}

a {
  color: var(--cds-link-primary, #0062fe);
}

em {
  font-style: italic;
}

@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Light-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Light-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Light-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Light-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Light-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-LightItalic-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-LightItalic-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-LightItalic-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-LightItalic-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-LightItalic-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Regular-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Regular-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Regular-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Regular-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Regular-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Italic-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Italic-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Italic-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Italic-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-Italic-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBold-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBold-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBold-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBold-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBold-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBoldItalic-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBoldItalic-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBoldItalic-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBoldItalic-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Mono/fonts/split/woff/IBMPlexMono-SemiBoldItalic-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Arabic";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Arabic/fonts/complete/woff2/IBMPlexSansArabic-Light.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Arabic/fonts/complete/woff/IBMPlexSansArabic-Light.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Arabic";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Arabic/fonts/complete/woff2/IBMPlexSansArabic-Regular.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Arabic/fonts/complete/woff/IBMPlexSansArabic-Regular.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Arabic";
  font-style: normal;
  font-weight: 500;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Arabic/fonts/complete/woff2/IBMPlexSansArabic-SemiBold.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Arabic/fonts/complete/woff/IBMPlexSansArabic-SemiBold.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Devanagari";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Devanagari/fonts/complete/woff2/IBMPlexSansDevanagari-Light.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Devanagari/fonts/complete/woff/IBMPlexSansDevanagari-Light.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Devanagari";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Devanagari/fonts/complete/woff2/IBMPlexSansDevanagari-Regular.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Devanagari/fonts/complete/woff/IBMPlexSansDevanagari-Regular.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Devanagari";
  font-style: normal;
  font-weight: 500;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Devanagari/fonts/complete/woff2/IBMPlexSansDevanagari-SemiBold.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Devanagari/fonts/complete/woff/IBMPlexSansDevanagari-SemiBold.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Hebrew";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Light.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Light.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Hebrew";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Regular.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Regular.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Hebrew";
  font-style: normal;
  font-weight: 500;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-SemiBold.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-SemiBold.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Thai Looped";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-Light.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-Light.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Thai Looped";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-Regular.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-Regular.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Thai Looped";
  font-style: normal;
  font-weight: 500;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-SemiBold.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-SemiBold.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Thai";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai/fonts/complete/woff2/IBMPlexSansThai-Light.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai/fonts/complete/woff/IBMPlexSansThai-Light.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Thai";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai/fonts/complete/woff2/IBMPlexSansThai-Regular.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai/fonts/complete/woff/IBMPlexSansThai-Regular.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans Thai";
  font-style: normal;
  font-weight: 500;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai/fonts/complete/woff2/IBMPlexSansThai-SemiBold.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans-Thai/fonts/complete/woff/IBMPlexSansThai-SemiBold.woff") format("woff");
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Light-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Light-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Light-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Light-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Light-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-LightItalic-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-LightItalic-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-LightItalic-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-LightItalic-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-LightItalic-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Regular-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Regular-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Regular-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Regular-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Regular-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Italic-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Italic-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Italic-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Italic-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-Italic-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBold-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBold-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBold-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBold-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBold-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBoldItalic-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBoldItalic-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBoldItalic-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBoldItalic-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Sans/fonts/split/woff/IBMPlexSans-SemiBoldItalic-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Light-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Light-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Light-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Light-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Light-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-LightItalic-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-LightItalic-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-LightItalic-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-LightItalic-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 300;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-LightItalic-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Regular-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Regular-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Regular-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Regular-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Regular-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Italic-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Italic-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Italic-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Italic-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 400;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-Italic-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBold-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBold-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBold-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBold-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: normal;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBold-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Cyrillic.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBoldItalic-Cyrillic.woff") format("woff");
  unicode-range: "U+0400-045F", "U+0472-0473", "U+0490-049D", "U+04A0-04A5", "U+04AA-04AB", "U+04AE-04B3", "U+04B6-04BB", "U+04C0-04C2", "U+04CF-04D9", "U+04DC-04DF", "U+04E2-04E9", "U+04EE-04F5", "U+04F8-04F9";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Pi.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBoldItalic-Pi.woff") format("woff");
  unicode-range: "U+0E3F", "U+2032-2033", "U+2070", "U+2075-2079", "U+2080-2081", "U+2083", "U+2085-2089", "U+2113", "U+2116", "U+2126", "U+212E", "U+2150-2151", "U+2153-215E", "U+2190-2199", "U+21A9-21AA", "U+21B0-21B3", "U+21B6-21B7", "U+21BA-21BB", "U+21C4", "U+21C6", "U+2202", "U+2206", "U+220F", "U+2211", "U+221A", "U+221E", "U+222B", "U+2248", "U+2260", "U+2264-2265", "U+25CA", "U+2713", "U+274C", "U+2B0E-2B11", "U+EBE1-EBE7", "U+ECE0", "U+EFCC";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Latin3.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBoldItalic-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103", "U+1EA0-1EF9", "U+20AB";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Latin2.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBoldItalic-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F", "U+0259", "U+1E00-1EFF", "U+20A0-20AB", "U+20AD-20CF", "U+2C60-2C7F", "U+A720-A7FF", "U+FB01-FB02";
}
@font-face {
  font-display: "swap";
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: 600;
  src: url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Latin1.woff2") format("woff2"), url("https://1.www.s81c.com/common/carbon/plex/fonts/IBM-Plex-Serif/fonts/split/woff/IBMPlexSerif-SemiBoldItalic-Latin1.woff") format("woff");
  unicode-range: "U+0000", "U+000D", "U+0020-007E", "U+00A0-00A3", "U+00A4-00FF", "U+0131", "U+0152-0153", "U+02C6", "U+02DA", "U+02DC", "U+2013-2014", "U+2018-201A", "U+201C-201E", "U+2020-2022", "U+2026", "U+2030", "U+2039-203A", "U+2044", "U+2074", "U+20AC", "U+2122", "U+2212", "U+FB01-FB02";
}
:root {
  --cds-grid-gutter: 2rem;
  --cds-grid-columns: 4;
  --cds-grid-margin: 0;
}
@media (min-width: 42rem) {
  :root {
    --cds-grid-columns: 8;
    --cds-grid-margin: 1rem;
  }
}
@media (min-width: 66rem) {
  :root {
    --cds-grid-columns: 16;
  }
}
@media (min-width: 99rem) {
  :root {
    --cds-grid-margin: 1.5rem;
  }
}

.cds--css-grid {
  --cds-grid-gutter-start: calc(var(--cds-grid-gutter) / 2);
  --cds-grid-gutter-end: calc(var(--cds-grid-gutter) / 2);
  --cds-grid-column-hang: calc(var(--cds-grid-gutter) / 2);
  display: grid;
  width: 100%;
  max-width: 99rem;
  padding-right: var(--cds-grid-margin);
  padding-left: var(--cds-grid-margin);
  margin-right: auto;
  margin-left: auto;
  grid-template-columns: repeat(var(--cds-grid-columns), minmax(0, 1fr));
}

.cds--css-grid--full-width {
  max-width: 100%;
}

.cds--css-grid-column {
  --cds-grid-mode-start: var(--cds-grid-gutter-start);
  --cds-grid-mode-end: var(--cds-grid-gutter-end);
  margin-right: var(--cds-grid-gutter-end);
  margin-left: var(--cds-grid-gutter-start);
}
[dir=rtl] .cds--css-grid-column {
  margin-right: var(--cds-grid-gutter-start);
  margin-left: var(--cds-grid-gutter-end);
}

.cds--css-grid--narrow {
  --cds-grid-gutter-start: 0;
}

.cds--css-grid--condensed {
  --cds-grid-gutter: 0.0625rem;
  --cds-grid-column-hang: 0.96875rem;
}

.cds--subgrid {
  display: grid;
  margin-right: calc(var(--cds-grid-mode-end) * -1);
  margin-left: calc(var(--cds-grid-mode-start) * -1);
  grid-template-columns: repeat(var(--cds-grid-columns), minmax(0, 1fr));
}
[dir=rtl] .cds--subgrid {
  margin-right: calc(var(--cds-grid-mode-start) * -1);
  margin-left: calc(var(--cds-grid-mode-end) * -1);
}

.cds--subgrid--wide {
  --cds-grid-gutter-start: 1rem;
  --cds-grid-gutter-end: 1rem;
  --cds-grid-column-hang: 0;
}

.cds--subgrid--narrow {
  --cds-grid-gutter-start: 0;
  --cds-grid-gutter-end: 1rem;
  --cds-grid-column-hang: 1rem;
}

.cds--subgrid--condensed {
  --cds-grid-gutter-start: 0.03125rem;
  --cds-grid-gutter-end: 0.03125rem;
  --cds-grid-column-hang: 0.96875rem;
}

.cds--grid-column-hang {
  margin-left: var(--cds-grid-column-hang);
}
[dir=rtl] .cds--grid-column-hang {
  margin-right: var(--cds-grid-column-hang);
  margin-left: initial;
}

.cds--col-span-0 {
  display: none;
}

.cds--col-span-1 {
  --cds-grid-columns: 1;
  display: block;
  grid-column: span 1/span 1;
}

.cds--col-span-2 {
  --cds-grid-columns: 2;
  display: block;
  grid-column: span 2/span 2;
}

.cds--col-span-3 {
  --cds-grid-columns: 3;
  display: block;
  grid-column: span 3/span 3;
}

.cds--col-span-4 {
  --cds-grid-columns: 4;
  display: block;
  grid-column: span 4/span 4;
}

.cds--col-span-5 {
  --cds-grid-columns: 5;
  display: block;
  grid-column: span 5/span 5;
}

.cds--col-span-6 {
  --cds-grid-columns: 6;
  display: block;
  grid-column: span 6/span 6;
}

.cds--col-span-7 {
  --cds-grid-columns: 7;
  display: block;
  grid-column: span 7/span 7;
}

.cds--col-span-8 {
  --cds-grid-columns: 8;
  display: block;
  grid-column: span 8/span 8;
}

.cds--col-span-9 {
  --cds-grid-columns: 9;
  display: block;
  grid-column: span 9/span 9;
}

.cds--col-span-10 {
  --cds-grid-columns: 10;
  display: block;
  grid-column: span 10/span 10;
}

.cds--col-span-11 {
  --cds-grid-columns: 11;
  display: block;
  grid-column: span 11/span 11;
}

.cds--col-span-12 {
  --cds-grid-columns: 12;
  display: block;
  grid-column: span 12/span 12;
}

.cds--col-span-13 {
  --cds-grid-columns: 13;
  display: block;
  grid-column: span 13/span 13;
}

.cds--col-span-14 {
  --cds-grid-columns: 14;
  display: block;
  grid-column: span 14/span 14;
}

.cds--col-span-15 {
  --cds-grid-columns: 15;
  display: block;
  grid-column: span 15/span 15;
}

.cds--col-span-16 {
  --cds-grid-columns: 16;
  display: block;
  grid-column: span 16/span 16;
}

.cds--sm\:col-span-0 {
  display: none;
}

.cds--sm\:col-span-1 {
  --cds-grid-columns: 1;
  display: block;
  grid-column: span 1/span 1;
}

.cds--sm\:col-span-2 {
  --cds-grid-columns: 2;
  display: block;
  grid-column: span 2/span 2;
}

.cds--sm\:col-span-3 {
  --cds-grid-columns: 3;
  display: block;
  grid-column: span 3/span 3;
}

.cds--sm\:col-span-4 {
  --cds-grid-columns: 4;
  display: block;
  grid-column: span 4/span 4;
}

.cds--sm\:col-span-auto {
  grid-column: auto;
}

.cds--sm\:col-span-100 {
  grid-column: 1/-1;
}

.cds--sm\:col-span-75 {
  --cds-grid-columns: 3;
  grid-column: span 3 / span 3;
}

.cds--sm\:col-span-50 {
  --cds-grid-columns: 2;
  grid-column: span 2 / span 2;
}

.cds--sm\:col-span-25 {
  --cds-grid-columns: 1;
  grid-column: span 1 / span 1;
}

@media (min-width: 42rem) {
  .cds--md\:col-span-0 {
    display: none;
  }
}
@media (min-width: 42rem) {
  .cds--md\:col-span-1 {
    --cds-grid-columns: 1;
    display: block;
    grid-column: span 1/span 1;
  }
}
@media (min-width: 42rem) {
  .cds--md\:col-span-2 {
    --cds-grid-columns: 2;
    display: block;
    grid-column: span 2/span 2;
  }
}
@media (min-width: 42rem) {
  .cds--md\:col-span-3 {
    --cds-grid-columns: 3;
    display: block;
    grid-column: span 3/span 3;
  }
}
@media (min-width: 42rem) {
  .cds--md\:col-span-4 {
    --cds-grid-columns: 4;
    display: block;
    grid-column: span 4/span 4;
  }
}
@media (min-width: 42rem) {
  .cds--md\:col-span-5 {
    --cds-grid-columns: 5;
    display: block;
    grid-column: span 5/span 5;
  }
}
@media (min-width: 42rem) {
  .cds--md\:col-span-6 {
    --cds-grid-columns: 6;
    display: block;
    grid-column: span 6/span 6;
  }
}
@media (min-width: 42rem) {
  .cds--md\:col-span-7 {
    --cds-grid-columns: 7;
    display: block;
    grid-column: span 7/span 7;
  }
}
@media (min-width: 42rem) {
  .cds--md\:col-span-8 {
    --cds-grid-columns: 8;
    display: block;
    grid-column: span 8/span 8;
  }
}
@media (min-width: 42rem) {
  .cds--md\:col-span-auto {
    grid-column: auto;
  }

  .cds--md\:col-span-100 {
    grid-column: 1/-1;
  }

  .cds--md\:col-span-75 {
    --cds-grid-columns: 6;
    grid-column: span 6 / span 6;
  }

  .cds--md\:col-span-50 {
    --cds-grid-columns: 4;
    grid-column: span 4 / span 4;
  }

  .cds--md\:col-span-25 {
    --cds-grid-columns: 2;
    grid-column: span 2 / span 2;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-0 {
    display: none;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-1 {
    --cds-grid-columns: 1;
    display: block;
    grid-column: span 1/span 1;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-2 {
    --cds-grid-columns: 2;
    display: block;
    grid-column: span 2/span 2;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-3 {
    --cds-grid-columns: 3;
    display: block;
    grid-column: span 3/span 3;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-4 {
    --cds-grid-columns: 4;
    display: block;
    grid-column: span 4/span 4;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-5 {
    --cds-grid-columns: 5;
    display: block;
    grid-column: span 5/span 5;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-6 {
    --cds-grid-columns: 6;
    display: block;
    grid-column: span 6/span 6;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-7 {
    --cds-grid-columns: 7;
    display: block;
    grid-column: span 7/span 7;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-8 {
    --cds-grid-columns: 8;
    display: block;
    grid-column: span 8/span 8;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-9 {
    --cds-grid-columns: 9;
    display: block;
    grid-column: span 9/span 9;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-10 {
    --cds-grid-columns: 10;
    display: block;
    grid-column: span 10/span 10;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-11 {
    --cds-grid-columns: 11;
    display: block;
    grid-column: span 11/span 11;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-12 {
    --cds-grid-columns: 12;
    display: block;
    grid-column: span 12/span 12;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-13 {
    --cds-grid-columns: 13;
    display: block;
    grid-column: span 13/span 13;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-14 {
    --cds-grid-columns: 14;
    display: block;
    grid-column: span 14/span 14;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-15 {
    --cds-grid-columns: 15;
    display: block;
    grid-column: span 15/span 15;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-16 {
    --cds-grid-columns: 16;
    display: block;
    grid-column: span 16/span 16;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-span-auto {
    grid-column: auto;
  }

  .cds--lg\:col-span-100 {
    grid-column: 1/-1;
  }

  .cds--lg\:col-span-75 {
    --cds-grid-columns: 12;
    grid-column: span 12 / span 12;
  }

  .cds--lg\:col-span-50 {
    --cds-grid-columns: 8;
    grid-column: span 8 / span 8;
  }

  .cds--lg\:col-span-25 {
    --cds-grid-columns: 4;
    grid-column: span 4 / span 4;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-0 {
    display: none;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-1 {
    --cds-grid-columns: 1;
    display: block;
    grid-column: span 1/span 1;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-2 {
    --cds-grid-columns: 2;
    display: block;
    grid-column: span 2/span 2;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-3 {
    --cds-grid-columns: 3;
    display: block;
    grid-column: span 3/span 3;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-4 {
    --cds-grid-columns: 4;
    display: block;
    grid-column: span 4/span 4;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-5 {
    --cds-grid-columns: 5;
    display: block;
    grid-column: span 5/span 5;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-6 {
    --cds-grid-columns: 6;
    display: block;
    grid-column: span 6/span 6;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-7 {
    --cds-grid-columns: 7;
    display: block;
    grid-column: span 7/span 7;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-8 {
    --cds-grid-columns: 8;
    display: block;
    grid-column: span 8/span 8;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-9 {
    --cds-grid-columns: 9;
    display: block;
    grid-column: span 9/span 9;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-10 {
    --cds-grid-columns: 10;
    display: block;
    grid-column: span 10/span 10;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-11 {
    --cds-grid-columns: 11;
    display: block;
    grid-column: span 11/span 11;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-12 {
    --cds-grid-columns: 12;
    display: block;
    grid-column: span 12/span 12;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-13 {
    --cds-grid-columns: 13;
    display: block;
    grid-column: span 13/span 13;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-14 {
    --cds-grid-columns: 14;
    display: block;
    grid-column: span 14/span 14;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-15 {
    --cds-grid-columns: 15;
    display: block;
    grid-column: span 15/span 15;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-16 {
    --cds-grid-columns: 16;
    display: block;
    grid-column: span 16/span 16;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-span-auto {
    grid-column: auto;
  }

  .cds--xlg\:col-span-100 {
    grid-column: 1/-1;
  }

  .cds--xlg\:col-span-75 {
    --cds-grid-columns: 12;
    grid-column: span 12 / span 12;
  }

  .cds--xlg\:col-span-50 {
    --cds-grid-columns: 8;
    grid-column: span 8 / span 8;
  }

  .cds--xlg\:col-span-25 {
    --cds-grid-columns: 4;
    grid-column: span 4 / span 4;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-0 {
    display: none;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-1 {
    --cds-grid-columns: 1;
    display: block;
    grid-column: span 1/span 1;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-2 {
    --cds-grid-columns: 2;
    display: block;
    grid-column: span 2/span 2;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-3 {
    --cds-grid-columns: 3;
    display: block;
    grid-column: span 3/span 3;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-4 {
    --cds-grid-columns: 4;
    display: block;
    grid-column: span 4/span 4;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-5 {
    --cds-grid-columns: 5;
    display: block;
    grid-column: span 5/span 5;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-6 {
    --cds-grid-columns: 6;
    display: block;
    grid-column: span 6/span 6;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-7 {
    --cds-grid-columns: 7;
    display: block;
    grid-column: span 7/span 7;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-8 {
    --cds-grid-columns: 8;
    display: block;
    grid-column: span 8/span 8;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-9 {
    --cds-grid-columns: 9;
    display: block;
    grid-column: span 9/span 9;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-10 {
    --cds-grid-columns: 10;
    display: block;
    grid-column: span 10/span 10;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-11 {
    --cds-grid-columns: 11;
    display: block;
    grid-column: span 11/span 11;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-12 {
    --cds-grid-columns: 12;
    display: block;
    grid-column: span 12/span 12;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-13 {
    --cds-grid-columns: 13;
    display: block;
    grid-column: span 13/span 13;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-14 {
    --cds-grid-columns: 14;
    display: block;
    grid-column: span 14/span 14;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-15 {
    --cds-grid-columns: 15;
    display: block;
    grid-column: span 15/span 15;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-16 {
    --cds-grid-columns: 16;
    display: block;
    grid-column: span 16/span 16;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-span-auto {
    grid-column: auto;
  }

  .cds--max\:col-span-100 {
    grid-column: 1/-1;
  }

  .cds--max\:col-span-75 {
    --cds-grid-columns: 12;
    grid-column: span 12 / span 12;
  }

  .cds--max\:col-span-50 {
    --cds-grid-columns: 8;
    grid-column: span 8 / span 8;
  }

  .cds--max\:col-span-25 {
    --cds-grid-columns: 4;
    grid-column: span 4 / span 4;
  }
}
.cds--col-span-auto {
  grid-column: auto;
}

.cds--col-span-100 {
  grid-column: 1/-1;
}

.cds--col-span-75 {
  --cds-grid-columns: 3;
  grid-column: span 3 / span 3;
}
@media (min-width: 42rem) {
  .cds--col-span-75 {
    --cds-grid-columns: 6;
    grid-column: span 6 / span 6;
  }
}
@media (min-width: 66rem) {
  .cds--col-span-75 {
    --cds-grid-columns: 12;
    grid-column: span 12 / span 12;
  }
}

.cds--col-span-50 {
  --cds-grid-columns: 2;
  grid-column: span 2 / span 2;
}
@media (min-width: 42rem) {
  .cds--col-span-50 {
    --cds-grid-columns: 4;
    grid-column: span 4 / span 4;
  }
}
@media (min-width: 66rem) {
  .cds--col-span-50 {
    --cds-grid-columns: 8;
    grid-column: span 8 / span 8;
  }
}

.cds--col-span-25 {
  --cds-grid-columns: 1;
  grid-column: span 1 / span 1;
}
@media (min-width: 42rem) {
  .cds--col-span-25 {
    --cds-grid-columns: 2;
    grid-column: span 2 / span 2;
  }
}
@media (min-width: 66rem) {
  .cds--col-span-25 {
    --cds-grid-columns: 4;
    grid-column: span 4 / span 4;
  }
}

.cds--col-start-1 {
  grid-column-start: 1;
}

.cds--col-start-2 {
  grid-column-start: 2;
}

.cds--col-start-3 {
  grid-column-start: 3;
}

.cds--col-start-4 {
  grid-column-start: 4;
}

.cds--col-start-5 {
  grid-column-start: 5;
}

.cds--col-start-6 {
  grid-column-start: 6;
}

.cds--col-start-7 {
  grid-column-start: 7;
}

.cds--col-start-8 {
  grid-column-start: 8;
}

.cds--col-start-9 {
  grid-column-start: 9;
}

.cds--col-start-10 {
  grid-column-start: 10;
}

.cds--col-start-11 {
  grid-column-start: 11;
}

.cds--col-start-12 {
  grid-column-start: 12;
}

.cds--col-start-13 {
  grid-column-start: 13;
}

.cds--col-start-14 {
  grid-column-start: 14;
}

.cds--col-start-15 {
  grid-column-start: 15;
}

.cds--col-start-16 {
  grid-column-start: 16;
}

.cds--col-end-2 {
  grid-column-end: 2;
}

.cds--col-end-3 {
  grid-column-end: 3;
}

.cds--col-end-4 {
  grid-column-end: 4;
}

.cds--col-end-5 {
  grid-column-end: 5;
}

.cds--col-end-6 {
  grid-column-end: 6;
}

.cds--col-end-7 {
  grid-column-end: 7;
}

.cds--col-end-8 {
  grid-column-end: 8;
}

.cds--col-end-9 {
  grid-column-end: 9;
}

.cds--col-end-10 {
  grid-column-end: 10;
}

.cds--col-end-11 {
  grid-column-end: 11;
}

.cds--col-end-12 {
  grid-column-end: 12;
}

.cds--col-end-13 {
  grid-column-end: 13;
}

.cds--col-end-14 {
  grid-column-end: 14;
}

.cds--col-end-15 {
  grid-column-end: 15;
}

.cds--col-end-16 {
  grid-column-end: 16;
}

.cds--col-end-17 {
  grid-column-end: 17;
}

.cds--col-start-auto {
  grid-column-start: auto;
}

.cds--col-end-auto {
  grid-column-end: auto;
}

.cds--sm\:col-start-1 {
  grid-column-start: 1;
}

.cds--sm\:col-start-2 {
  grid-column-start: 2;
}

.cds--sm\:col-start-3 {
  grid-column-start: 3;
}

.cds--sm\:col-start-4 {
  grid-column-start: 4;
}

.cds--sm\:col-start-5 {
  grid-column-start: 5;
}

.cds--sm\:col-start-6 {
  grid-column-start: 6;
}

.cds--sm\:col-start-7 {
  grid-column-start: 7;
}

.cds--sm\:col-start-8 {
  grid-column-start: 8;
}

.cds--sm\:col-start-9 {
  grid-column-start: 9;
}

.cds--sm\:col-start-10 {
  grid-column-start: 10;
}

.cds--sm\:col-start-11 {
  grid-column-start: 11;
}

.cds--sm\:col-start-12 {
  grid-column-start: 12;
}

.cds--sm\:col-start-13 {
  grid-column-start: 13;
}

.cds--sm\:col-start-14 {
  grid-column-start: 14;
}

.cds--sm\:col-start-15 {
  grid-column-start: 15;
}

.cds--sm\:col-start-16 {
  grid-column-start: 16;
}

.cds--sm\:col-end-2 {
  grid-column-end: 2;
}

.cds--sm\:col-end-3 {
  grid-column-end: 3;
}

.cds--sm\:col-end-4 {
  grid-column-end: 4;
}

.cds--sm\:col-end-5 {
  grid-column-end: 5;
}

.cds--sm\:col-end-6 {
  grid-column-end: 6;
}

.cds--sm\:col-end-7 {
  grid-column-end: 7;
}

.cds--sm\:col-end-8 {
  grid-column-end: 8;
}

.cds--sm\:col-end-9 {
  grid-column-end: 9;
}

.cds--sm\:col-end-10 {
  grid-column-end: 10;
}

.cds--sm\:col-end-11 {
  grid-column-end: 11;
}

.cds--sm\:col-end-12 {
  grid-column-end: 12;
}

.cds--sm\:col-end-13 {
  grid-column-end: 13;
}

.cds--sm\:col-end-14 {
  grid-column-end: 14;
}

.cds--sm\:col-end-15 {
  grid-column-end: 15;
}

.cds--sm\:col-end-16 {
  grid-column-end: 16;
}

.cds--sm\:col-end-17 {
  grid-column-end: 17;
}

.cds--sm\:col-start-auto {
  grid-column-start: auto;
}

.cds--sm\:col-end-auto {
  grid-column-end: auto;
}

@media (min-width: 42rem) {
  .cds--md\:col-start-1 {
    grid-column-start: 1;
  }

  .cds--md\:col-start-2 {
    grid-column-start: 2;
  }

  .cds--md\:col-start-3 {
    grid-column-start: 3;
  }

  .cds--md\:col-start-4 {
    grid-column-start: 4;
  }

  .cds--md\:col-start-5 {
    grid-column-start: 5;
  }

  .cds--md\:col-start-6 {
    grid-column-start: 6;
  }

  .cds--md\:col-start-7 {
    grid-column-start: 7;
  }

  .cds--md\:col-start-8 {
    grid-column-start: 8;
  }

  .cds--md\:col-start-9 {
    grid-column-start: 9;
  }

  .cds--md\:col-start-10 {
    grid-column-start: 10;
  }

  .cds--md\:col-start-11 {
    grid-column-start: 11;
  }

  .cds--md\:col-start-12 {
    grid-column-start: 12;
  }

  .cds--md\:col-start-13 {
    grid-column-start: 13;
  }

  .cds--md\:col-start-14 {
    grid-column-start: 14;
  }

  .cds--md\:col-start-15 {
    grid-column-start: 15;
  }

  .cds--md\:col-start-16 {
    grid-column-start: 16;
  }

  .cds--md\:col-end-2 {
    grid-column-end: 2;
  }

  .cds--md\:col-end-3 {
    grid-column-end: 3;
  }

  .cds--md\:col-end-4 {
    grid-column-end: 4;
  }

  .cds--md\:col-end-5 {
    grid-column-end: 5;
  }

  .cds--md\:col-end-6 {
    grid-column-end: 6;
  }

  .cds--md\:col-end-7 {
    grid-column-end: 7;
  }

  .cds--md\:col-end-8 {
    grid-column-end: 8;
  }

  .cds--md\:col-end-9 {
    grid-column-end: 9;
  }

  .cds--md\:col-end-10 {
    grid-column-end: 10;
  }

  .cds--md\:col-end-11 {
    grid-column-end: 11;
  }

  .cds--md\:col-end-12 {
    grid-column-end: 12;
  }

  .cds--md\:col-end-13 {
    grid-column-end: 13;
  }

  .cds--md\:col-end-14 {
    grid-column-end: 14;
  }

  .cds--md\:col-end-15 {
    grid-column-end: 15;
  }

  .cds--md\:col-end-16 {
    grid-column-end: 16;
  }

  .cds--md\:col-end-17 {
    grid-column-end: 17;
  }

  .cds--md\:col-start-auto {
    grid-column-start: auto;
  }

  .cds--md\:col-end-auto {
    grid-column-end: auto;
  }
}
@media (min-width: 66rem) {
  .cds--lg\:col-start-1 {
    grid-column-start: 1;
  }

  .cds--lg\:col-start-2 {
    grid-column-start: 2;
  }

  .cds--lg\:col-start-3 {
    grid-column-start: 3;
  }

  .cds--lg\:col-start-4 {
    grid-column-start: 4;
  }

  .cds--lg\:col-start-5 {
    grid-column-start: 5;
  }

  .cds--lg\:col-start-6 {
    grid-column-start: 6;
  }

  .cds--lg\:col-start-7 {
    grid-column-start: 7;
  }

  .cds--lg\:col-start-8 {
    grid-column-start: 8;
  }

  .cds--lg\:col-start-9 {
    grid-column-start: 9;
  }

  .cds--lg\:col-start-10 {
    grid-column-start: 10;
  }

  .cds--lg\:col-start-11 {
    grid-column-start: 11;
  }

  .cds--lg\:col-start-12 {
    grid-column-start: 12;
  }

  .cds--lg\:col-start-13 {
    grid-column-start: 13;
  }

  .cds--lg\:col-start-14 {
    grid-column-start: 14;
  }

  .cds--lg\:col-start-15 {
    grid-column-start: 15;
  }

  .cds--lg\:col-start-16 {
    grid-column-start: 16;
  }

  .cds--lg\:col-end-2 {
    grid-column-end: 2;
  }

  .cds--lg\:col-end-3 {
    grid-column-end: 3;
  }

  .cds--lg\:col-end-4 {
    grid-column-end: 4;
  }

  .cds--lg\:col-end-5 {
    grid-column-end: 5;
  }

  .cds--lg\:col-end-6 {
    grid-column-end: 6;
  }

  .cds--lg\:col-end-7 {
    grid-column-end: 7;
  }

  .cds--lg\:col-end-8 {
    grid-column-end: 8;
  }

  .cds--lg\:col-end-9 {
    grid-column-end: 9;
  }

  .cds--lg\:col-end-10 {
    grid-column-end: 10;
  }

  .cds--lg\:col-end-11 {
    grid-column-end: 11;
  }

  .cds--lg\:col-end-12 {
    grid-column-end: 12;
  }

  .cds--lg\:col-end-13 {
    grid-column-end: 13;
  }

  .cds--lg\:col-end-14 {
    grid-column-end: 14;
  }

  .cds--lg\:col-end-15 {
    grid-column-end: 15;
  }

  .cds--lg\:col-end-16 {
    grid-column-end: 16;
  }

  .cds--lg\:col-end-17 {
    grid-column-end: 17;
  }

  .cds--lg\:col-start-auto {
    grid-column-start: auto;
  }

  .cds--lg\:col-end-auto {
    grid-column-end: auto;
  }
}
@media (min-width: 82rem) {
  .cds--xlg\:col-start-1 {
    grid-column-start: 1;
  }

  .cds--xlg\:col-start-2 {
    grid-column-start: 2;
  }

  .cds--xlg\:col-start-3 {
    grid-column-start: 3;
  }

  .cds--xlg\:col-start-4 {
    grid-column-start: 4;
  }

  .cds--xlg\:col-start-5 {
    grid-column-start: 5;
  }

  .cds--xlg\:col-start-6 {
    grid-column-start: 6;
  }

  .cds--xlg\:col-start-7 {
    grid-column-start: 7;
  }

  .cds--xlg\:col-start-8 {
    grid-column-start: 8;
  }

  .cds--xlg\:col-start-9 {
    grid-column-start: 9;
  }

  .cds--xlg\:col-start-10 {
    grid-column-start: 10;
  }

  .cds--xlg\:col-start-11 {
    grid-column-start: 11;
  }

  .cds--xlg\:col-start-12 {
    grid-column-start: 12;
  }

  .cds--xlg\:col-start-13 {
    grid-column-start: 13;
  }

  .cds--xlg\:col-start-14 {
    grid-column-start: 14;
  }

  .cds--xlg\:col-start-15 {
    grid-column-start: 15;
  }

  .cds--xlg\:col-start-16 {
    grid-column-start: 16;
  }

  .cds--xlg\:col-end-2 {
    grid-column-end: 2;
  }

  .cds--xlg\:col-end-3 {
    grid-column-end: 3;
  }

  .cds--xlg\:col-end-4 {
    grid-column-end: 4;
  }

  .cds--xlg\:col-end-5 {
    grid-column-end: 5;
  }

  .cds--xlg\:col-end-6 {
    grid-column-end: 6;
  }

  .cds--xlg\:col-end-7 {
    grid-column-end: 7;
  }

  .cds--xlg\:col-end-8 {
    grid-column-end: 8;
  }

  .cds--xlg\:col-end-9 {
    grid-column-end: 9;
  }

  .cds--xlg\:col-end-10 {
    grid-column-end: 10;
  }

  .cds--xlg\:col-end-11 {
    grid-column-end: 11;
  }

  .cds--xlg\:col-end-12 {
    grid-column-end: 12;
  }

  .cds--xlg\:col-end-13 {
    grid-column-end: 13;
  }

  .cds--xlg\:col-end-14 {
    grid-column-end: 14;
  }

  .cds--xlg\:col-end-15 {
    grid-column-end: 15;
  }

  .cds--xlg\:col-end-16 {
    grid-column-end: 16;
  }

  .cds--xlg\:col-end-17 {
    grid-column-end: 17;
  }

  .cds--xlg\:col-start-auto {
    grid-column-start: auto;
  }

  .cds--xlg\:col-end-auto {
    grid-column-end: auto;
  }
}
@media (min-width: 99rem) {
  .cds--max\:col-start-1 {
    grid-column-start: 1;
  }

  .cds--max\:col-start-2 {
    grid-column-start: 2;
  }

  .cds--max\:col-start-3 {
    grid-column-start: 3;
  }

  .cds--max\:col-start-4 {
    grid-column-start: 4;
  }

  .cds--max\:col-start-5 {
    grid-column-start: 5;
  }

  .cds--max\:col-start-6 {
    grid-column-start: 6;
  }

  .cds--max\:col-start-7 {
    grid-column-start: 7;
  }

  .cds--max\:col-start-8 {
    grid-column-start: 8;
  }

  .cds--max\:col-start-9 {
    grid-column-start: 9;
  }

  .cds--max\:col-start-10 {
    grid-column-start: 10;
  }

  .cds--max\:col-start-11 {
    grid-column-start: 11;
  }

  .cds--max\:col-start-12 {
    grid-column-start: 12;
  }

  .cds--max\:col-start-13 {
    grid-column-start: 13;
  }

  .cds--max\:col-start-14 {
    grid-column-start: 14;
  }

  .cds--max\:col-start-15 {
    grid-column-start: 15;
  }

  .cds--max\:col-start-16 {
    grid-column-start: 16;
  }

  .cds--max\:col-end-2 {
    grid-column-end: 2;
  }

  .cds--max\:col-end-3 {
    grid-column-end: 3;
  }

  .cds--max\:col-end-4 {
    grid-column-end: 4;
  }

  .cds--max\:col-end-5 {
    grid-column-end: 5;
  }

  .cds--max\:col-end-6 {
    grid-column-end: 6;
  }

  .cds--max\:col-end-7 {
    grid-column-end: 7;
  }

  .cds--max\:col-end-8 {
    grid-column-end: 8;
  }

  .cds--max\:col-end-9 {
    grid-column-end: 9;
  }

  .cds--max\:col-end-10 {
    grid-column-end: 10;
  }

  .cds--max\:col-end-11 {
    grid-column-end: 11;
  }

  .cds--max\:col-end-12 {
    grid-column-end: 12;
  }

  .cds--max\:col-end-13 {
    grid-column-end: 13;
  }

  .cds--max\:col-end-14 {
    grid-column-end: 14;
  }

  .cds--max\:col-end-15 {
    grid-column-end: 15;
  }

  .cds--max\:col-end-16 {
    grid-column-end: 16;
  }

  .cds--max\:col-end-17 {
    grid-column-end: 17;
  }

  .cds--max\:col-start-auto {
    grid-column-start: auto;
  }

  .cds--max\:col-end-auto {
    grid-column-end: auto;
  }
}
:root {
  --cds-layer: var(--cds-layer-01, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-01, #c6c6c6);
}

.cds--layer-one {
  --cds-layer: var(--cds-layer-01, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-01, #c6c6c6);
}

.cds--layer-two {
  --cds-layer: var(--cds-layer-02, #ffffff);
  --cds-layer-active: var(--cds-layer-active-02, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-02, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-02, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-02, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-02, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-02, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-02, #a8a8a8);
  --cds-field: var(--cds-field-02, #ffffff);
  --cds-field-hover: var(--cds-field-hover-02, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-02, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-02, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-02, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-02, #a8a8a8);
}

.cds--layer-three {
  --cds-layer: var(--cds-layer-03, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-03, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-03, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-03, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-03, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-03, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-03, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-03, #a8a8a8);
  --cds-field: var(--cds-field-03, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-03, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-03, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-03, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-03, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-03, #c6c6c6);
}

.cds--white {
  background: var(--cds-background);
  color: var(--cds-text-primary);
  --cds-background: #ffffff;
  --cds-background-active: rgba(141, 141, 141, 0.5);
  --cds-background-brand: #620b5b;
  --cds-background-hover: rgba(141, 141, 141, 0.12);
  --cds-background-inverse: #393939;
  --cds-background-inverse-hover: #474747;
  --cds-background-selected: rgba(141, 141, 141, 0.2);
  --cds-background-selected-hover: rgba(141, 141, 141, 0.32);
  --cds-border-disabled: #c6c6c6;
  --cds-border-interactive: #620b5b;
  --cds-border-inverse: #161616;
  --cds-border-strong-01: #8d8d8d;
  --cds-border-strong-02: #8d8d8d;
  --cds-border-strong-03: #8d8d8d;
  --cds-border-subtle-00: #e0e0e0;
  --cds-border-subtle-01: #e0e0e0;
  --cds-border-subtle-02: #e0e0e0;
  --cds-border-subtle-03: #e0e0e0;
  --cds-border-subtle-selected-01: #c6c6c6;
  --cds-border-subtle-selected-02: #c6c6c6;
  --cds-border-subtle-selected-03: #c6c6c6;
  --cds-border-tile-01: #c6c6c6;
  --cds-border-tile-02: #a8a8a8;
  --cds-border-tile-03: #c6c6c6;
  --cds-field-01: #f4f4f4;
  --cds-field-02: #ffffff;
  --cds-field-03: #f4f4f4;
  --cds-field-hover-01: #e8e8e8;
  --cds-field-hover-02: #e8e8e8;
  --cds-field-hover-03: #e8e8e8;
  --cds-focus: #620b5b;
  --cds-focus-inset: #ffffff;
  --cds-focus-inverse: #ffffff;
  --cds-highlight: #d0e2ff;
  --cds-icon-disabled: rgba(22, 22, 22, 0.25);
  --cds-icon-inverse: #ffffff;
  --cds-icon-on-color: #ffffff;
  --cds-icon-on-color-disabled: #8d8d8d;
  --cds-icon-primary: #161616;
  --cds-icon-secondary: #525252;
  --cds-interactive: #620b5b;
  --cds-layer-01: #f4f4f4;
  --cds-layer-02: #ffffff;
  --cds-layer-03: #f4f4f4;
  --cds-layer-accent-01: #e0e0e0;
  --cds-layer-accent-02: #e0e0e0;
  --cds-layer-accent-03: #e0e0e0;
  --cds-layer-accent-active-01: #a8a8a8;
  --cds-layer-accent-active-02: #a8a8a8;
  --cds-layer-accent-active-03: #a8a8a8;
  --cds-layer-accent-hover-01: #d1d1d1;
  --cds-layer-accent-hover-02: #d1d1d1;
  --cds-layer-accent-hover-03: #d1d1d1;
  --cds-layer-active-01: #c6c6c6;
  --cds-layer-active-02: #c6c6c6;
  --cds-layer-active-03: #c6c6c6;
  --cds-layer-hover-01: #e8e8e8;
  --cds-layer-hover-02: #e8e8e8;
  --cds-layer-hover-03: #e8e8e8;
  --cds-layer-selected-01: #e0e0e0;
  --cds-layer-selected-02: #e0e0e0;
  --cds-layer-selected-03: #e0e0e0;
  --cds-layer-selected-disabled: #8d8d8d;
  --cds-layer-selected-hover-01: #d1d1d1;
  --cds-layer-selected-hover-02: #d1d1d1;
  --cds-layer-selected-hover-03: #d1d1d1;
  --cds-layer-selected-inverse: #161616;
  --cds-link-inverse: #78a9ff;
  --cds-link-inverse-active: #f4f4f4;
  --cds-link-inverse-hover: #a6c8ff;
  --cds-link-primary: #620b5b;
  --cds-link-primary-hover: #0043ce;
  --cds-link-secondary: #0043ce;
  --cds-link-visited: #8a3ffc;
  --cds-overlay: rgba(22, 22, 22, 0.5);
  --cds-shadow: rgba(0, 0, 0, 0.3);
  --cds-skeleton-background: #e8e8e8;
  --cds-skeleton-element: #c6c6c6;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #8a3ffc;
  --cds-support-error: #da1e28;
  --cds-support-error-inverse: #fa4d56;
  --cds-support-info: #0043ce;
  --cds-support-info-inverse: #4589ff;
  --cds-support-success: #198038;
  --cds-support-success-inverse: #42be65;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: rgba(22, 22, 22, 0.25);
  --cds-text-error: #da1e28;
  --cds-text-helper: #6f6f6f;
  --cds-text-inverse: #ffffff;
  --cds-text-on-color: #ffffff;
  --cds-text-on-color-disabled: #8d8d8d;
  --cds-text-placeholder: rgba(22, 22, 22, 0.4);
  --cds-text-primary: #161616;
  --cds-text-secondary: #525252;
  --cds-toggle-off: #8d8d8d;
  --cds-layer: var(--cds-layer-01, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-01, #c6c6c6);
  --cds-button-separator: #e0e0e0;
  --cds-button-primary: #620b5b;
  --cds-button-secondary: #393939;
  --cds-button-tertiary: #620b5b;
  --cds-button-danger-primary: #da1e28;
  --cds-button-danger-secondary: #da1e28;
  --cds-button-danger-active: #750e13;
  --cds-button-primary-active: #620b5b;
  --cds-button-secondary-active: #6f6f6f;
  --cds-button-tertiary-active: #620b5b;
  --cds-button-danger-hover: #b81921;
  --cds-button-primary-hover: #620b5b;
  --cds-button-secondary-hover: #474747;
  --cds-button-tertiary-hover: #620b5b;
  --cds-button-disabled: #c6c6c6;
  --cds-notification-background-error: #fff1f1;
  --cds-notification-background-success: #defbe6;
  --cds-notification-background-info: #edf5ff;
  --cds-notification-background-warning: #fdf6dd;
  --cds-notification-action-hover: #edf5ff;
  --cds-notification-action-tertiary-inverse: #ffffff;
  --cds-notification-action-tertiary-inverse-active: #c6c6c6;
  --cds-notification-action-tertiary-inverse-hover: #f4f4f4;
  --cds-notification-action-tertiary-inverse-text: #161616;
  --cds-notification-action-tertiary-inverse-text-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-tag-background-red: #ffd7d9;
  --cds-tag-color-red: #750e13;
  --cds-tag-hover-red: #ffb3b8;
  --cds-tag-background-magenta: #ffd6e8;
  --cds-tag-color-magenta: #740937;
  --cds-tag-hover-magenta: #ffafd2;
  --cds-tag-background-purple: #e8daff;
  --cds-tag-color-purple: #491d8b;
  --cds-tag-hover-purple: #d4bbff;
  --cds-tag-background-blue: #d0e2ff;
  --cds-tag-color-blue: #620b5b;
  --cds-tag-hover-blue: #a6c8ff;
  --cds-tag-background-cyan: #bae6ff;
  --cds-tag-color-cyan: #003a6d;
  --cds-tag-hover-cyan: #82cfff;
  --cds-tag-background-teal: #9ef0f0;
  --cds-tag-color-teal: #004144;
  --cds-tag-hover-teal: #3ddbd9;
  --cds-tag-background-green: #a7f0ba;
  --cds-tag-color-green: #044317;
  --cds-tag-hover-green: #6fdc8c;
  --cds-tag-background-gray: #e0e0e0;
  --cds-tag-color-gray: #393939;
  --cds-tag-hover-gray: #c6c6c6;
  --cds-tag-background-cool-gray: #dde1e6;
  --cds-tag-color-cool-gray: #343a3f;
  --cds-tag-hover-cool-gray: #c1c7cd;
  --cds-tag-background-warm-gray: #e5e0df;
  --cds-tag-color-warm-gray: #3c3838;
  --cds-tag-hover-warm-gray: #cac5c4;
}

.cds--g10 {
  background: var(--cds-background);
  color: var(--cds-text-primary);
  --cds-background: #f4f4f4;
  --cds-background-active: rgba(141, 141, 141, 0.5);
  --cds-background-brand: #620b5b;
  --cds-background-hover: rgba(141, 141, 141, 0.12);
  --cds-background-inverse: #393939;
  --cds-background-inverse-hover: #474747;
  --cds-background-selected: rgba(141, 141, 141, 0.2);
  --cds-background-selected-hover: rgba(141, 141, 141, 0.32);
  --cds-border-disabled: #c6c6c6;
  --cds-border-interactive: #620b5b;
  --cds-border-inverse: #161616;
  --cds-border-strong-01: #8d8d8d;
  --cds-border-strong-02: #8d8d8d;
  --cds-border-strong-03: #8d8d8d;
  --cds-border-subtle-00: #e0e0e0;
  --cds-border-subtle-01: #e0e0e0;
  --cds-border-subtle-02: #e0e0e0;
  --cds-border-subtle-03: #e0e0e0;
  --cds-border-subtle-selected-01: #c6c6c6;
  --cds-border-subtle-selected-02: #c6c6c6;
  --cds-border-subtle-selected-03: #c6c6c6;
  --cds-border-tile-01: #a8a8a8;
  --cds-border-tile-02: #c6c6c6;
  --cds-border-tile-03: #a8a8a8;
  --cds-field-01: #ffffff;
  --cds-field-02: #f4f4f4;
  --cds-field-03: #ffffff;
  --cds-field-hover-01: #e8e8e8;
  --cds-field-hover-02: #e8e8e8;
  --cds-field-hover-03: #e8e8e8;
  --cds-focus: #620b5b;
  --cds-focus-inset: #ffffff;
  --cds-focus-inverse: #ffffff;
  --cds-highlight: #d0e2ff;
  --cds-icon-disabled: rgba(22, 22, 22, 0.25);
  --cds-icon-inverse: #ffffff;
  --cds-icon-on-color: #ffffff;
  --cds-icon-on-color-disabled: #8d8d8d;
  --cds-icon-primary: #161616;
  --cds-icon-secondary: #525252;
  --cds-interactive: #620b5b;
  --cds-layer-01: #ffffff;
  --cds-layer-02: #f4f4f4;
  --cds-layer-03: #ffffff;
  --cds-layer-accent-01: #e0e0e0;
  --cds-layer-accent-02: #e0e0e0;
  --cds-layer-accent-03: #e0e0e0;
  --cds-layer-accent-active-01: #a8a8a8;
  --cds-layer-accent-active-02: #a8a8a8;
  --cds-layer-accent-active-03: #a8a8a8;
  --cds-layer-accent-hover-01: #d1d1d1;
  --cds-layer-accent-hover-02: #d1d1d1;
  --cds-layer-accent-hover-03: #d1d1d1;
  --cds-layer-active-01: #c6c6c6;
  --cds-layer-active-02: #c6c6c6;
  --cds-layer-active-03: #c6c6c6;
  --cds-layer-hover-01: #e8e8e8;
  --cds-layer-hover-02: #e8e8e8;
  --cds-layer-hover-03: #e8e8e8;
  --cds-layer-selected-01: #e0e0e0;
  --cds-layer-selected-02: #e0e0e0;
  --cds-layer-selected-03: #e0e0e0;
  --cds-layer-selected-disabled: #8d8d8d;
  --cds-layer-selected-hover-01: #d1d1d1;
  --cds-layer-selected-hover-02: #d1d1d1;
  --cds-layer-selected-hover-03: #d1d1d1;
  --cds-layer-selected-inverse: #161616;
  --cds-link-inverse: #78a9ff;
  --cds-link-inverse-active: #f4f4f4;
  --cds-link-inverse-hover: #a6c8ff;
  --cds-link-primary: #620b5b;
  --cds-link-primary-hover: #0043ce;
  --cds-link-secondary: #0043ce;
  --cds-link-visited: #8a3ffc;
  --cds-overlay: rgba(22, 22, 22, 0.5);
  --cds-shadow: rgba(0, 0, 0, 0.3);
  --cds-skeleton-background: #e8e8e8;
  --cds-skeleton-element: #c6c6c6;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #8a3ffc;
  --cds-support-error: #da1e28;
  --cds-support-error-inverse: #fa4d56;
  --cds-support-info: #0043ce;
  --cds-support-info-inverse: #4589ff;
  --cds-support-success: #198038;
  --cds-support-success-inverse: #42be65;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: rgba(22, 22, 22, 0.25);
  --cds-text-error: #da1e28;
  --cds-text-helper: #6f6f6f;
  --cds-text-inverse: #ffffff;
  --cds-text-on-color: #ffffff;
  --cds-text-on-color-disabled: #8d8d8d;
  --cds-text-placeholder: rgba(22, 22, 22, 0.4);
  --cds-text-primary: #161616;
  --cds-text-secondary: #525252;
  --cds-toggle-off: #8d8d8d;
  --cds-layer: var(--cds-layer-01, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-01, #c6c6c6);
  --cds-button-separator: #e0e0e0;
  --cds-button-primary: #620b5b;
  --cds-button-secondary: #393939;
  --cds-button-tertiary: #620b5b;
  --cds-button-danger-primary: #da1e28;
  --cds-button-danger-secondary: #da1e28;
  --cds-button-danger-active: #750e13;
  --cds-button-primary-active: #620b5b;
  --cds-button-secondary-active: #6f6f6f;
  --cds-button-tertiary-active: #620b5b;
  --cds-button-danger-hover: #b81921;
  --cds-button-primary-hover: #620b5b;
  --cds-button-secondary-hover: #474747;
  --cds-button-tertiary-hover: #620b5b;
  --cds-button-disabled: #c6c6c6;
  --cds-notification-background-error: #fff1f1;
  --cds-notification-background-success: #defbe6;
  --cds-notification-background-info: #edf5ff;
  --cds-notification-background-warning: #fdf6dd;
  --cds-notification-action-hover: #edf5ff;
  --cds-notification-action-tertiary-inverse: #ffffff;
  --cds-notification-action-tertiary-inverse-active: #c6c6c6;
  --cds-notification-action-tertiary-inverse-hover: #f4f4f4;
  --cds-notification-action-tertiary-inverse-text: #161616;
  --cds-notification-action-tertiary-inverse-text-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-tag-background-red: #ffd7d9;
  --cds-tag-color-red: #750e13;
  --cds-tag-hover-red: #ffb3b8;
  --cds-tag-background-magenta: #ffd6e8;
  --cds-tag-color-magenta: #740937;
  --cds-tag-hover-magenta: #ffafd2;
  --cds-tag-background-purple: #e8daff;
  --cds-tag-color-purple: #491d8b;
  --cds-tag-hover-purple: #d4bbff;
  --cds-tag-background-blue: #d0e2ff;
  --cds-tag-color-blue: #620b5b;
  --cds-tag-hover-blue: #a6c8ff;
  --cds-tag-background-cyan: #bae6ff;
  --cds-tag-color-cyan: #003a6d;
  --cds-tag-hover-cyan: #82cfff;
  --cds-tag-background-teal: #9ef0f0;
  --cds-tag-color-teal: #004144;
  --cds-tag-hover-teal: #3ddbd9;
  --cds-tag-background-green: #a7f0ba;
  --cds-tag-color-green: #044317;
  --cds-tag-hover-green: #6fdc8c;
  --cds-tag-background-gray: #e0e0e0;
  --cds-tag-color-gray: #393939;
  --cds-tag-hover-gray: #c6c6c6;
  --cds-tag-background-cool-gray: #dde1e6;
  --cds-tag-color-cool-gray: #343a3f;
  --cds-tag-hover-cool-gray: #c1c7cd;
  --cds-tag-background-warm-gray: #e5e0df;
  --cds-tag-color-warm-gray: #3c3838;
  --cds-tag-hover-warm-gray: #cac5c4;
}

.cds--g90 {
  background: var(--cds-background);
  color: var(--cds-text-primary);
  --cds-background: #262626;
  --cds-background-active: rgba(141, 141, 141, 0.4);
  --cds-background-brand: #620b5b;
  --cds-background-hover: rgba(141, 141, 141, 0.16);
  --cds-background-inverse: #f4f4f4;
  --cds-background-inverse-hover: #e8e8e8;
  --cds-background-selected: rgba(141, 141, 141, 0.24);
  --cds-background-selected-hover: rgba(141, 141, 141, 0.32);
  --cds-border-disabled: rgba(141, 141, 141, 0.5);
  --cds-border-interactive: #4589ff;
  --cds-border-inverse: #f4f4f4;
  --cds-border-strong-01: #8d8d8d;
  --cds-border-strong-02: #a8a8a8;
  --cds-border-strong-03: #c6c6c6;
  --cds-border-subtle-00: #525252;
  --cds-border-subtle-01: #525252;
  --cds-border-subtle-02: #6f6f6f;
  --cds-border-subtle-03: #8d8d8d;
  --cds-border-subtle-selected-01: #6f6f6f;
  --cds-border-subtle-selected-02: #8d8d8d;
  --cds-border-subtle-selected-03: #a8a8a8;
  --cds-border-tile-01: #6f6f6f;
  --cds-border-tile-02: #8d8d8d;
  --cds-border-tile-03: #a8a8a8;
  --cds-field-01: #393939;
  --cds-field-02: #525252;
  --cds-field-03: #6f6f6f;
  --cds-field-hover-01: #474747;
  --cds-field-hover-02: #636363;
  --cds-field-hover-03: #5e5e5e;
  --cds-focus: #ffffff;
  --cds-focus-inset: #161616;
  --cds-focus-inverse: #620b5b;
  --cds-highlight: #0043ce;
  --cds-icon-disabled: rgba(244, 244, 244, 0.25);
  --cds-icon-inverse: #161616;
  --cds-icon-on-color: #ffffff;
  --cds-icon-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-icon-primary: #f4f4f4;
  --cds-icon-secondary: #c6c6c6;
  --cds-interactive: #4589ff;
  --cds-layer-01: #393939;
  --cds-layer-02: #525252;
  --cds-layer-03: #6f6f6f;
  --cds-layer-accent-01: #525252;
  --cds-layer-accent-02: #6f6f6f;
  --cds-layer-accent-03: #8d8d8d;
  --cds-layer-accent-active-01: #8d8d8d;
  --cds-layer-accent-active-02: #393939;
  --cds-layer-accent-active-03: #525252;
  --cds-layer-accent-hover-01: #636363;
  --cds-layer-accent-hover-02: #5e5e5e;
  --cds-layer-accent-hover-03: #7a7a7a;
  --cds-layer-active-01: #6f6f6f;
  --cds-layer-active-02: #8d8d8d;
  --cds-layer-active-03: #393939;
  --cds-layer-hover-01: #474747;
  --cds-layer-hover-02: #636363;
  --cds-layer-hover-03: #5e5e5e;
  --cds-layer-selected-01: #525252;
  --cds-layer-selected-02: #6f6f6f;
  --cds-layer-selected-03: #525252;
  --cds-layer-selected-disabled: #a8a8a8;
  --cds-layer-selected-hover-01: #636363;
  --cds-layer-selected-hover-02: #5e5e5e;
  --cds-layer-selected-hover-03: #636363;
  --cds-layer-selected-inverse: #f4f4f4;
  --cds-link-inverse: #620b5b;
  --cds-link-inverse-active: #161616;
  --cds-link-inverse-hover: #0043ce;
  --cds-link-primary: #78a9ff;
  --cds-link-primary-hover: #a6c8ff;
  --cds-link-secondary: #a6c8ff;
  --cds-link-visited: #be95ff;
  --cds-overlay: rgba(0, 0, 0, 0.65);
  --cds-shadow: rgba(0, 0, 0, 0.8);
  --cds-skeleton-background: #333333;
  --cds-skeleton-element: #525252;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #a56eff;
  --cds-support-error: #ff8389;
  --cds-support-error-inverse: #da1e28;
  --cds-support-info: #4589ff;
  --cds-support-info-inverse: #0043ce;
  --cds-support-success: #42be65;
  --cds-support-success-inverse: #24a148;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: rgba(244, 244, 244, 0.25);
  --cds-text-error: #ffb3b8;
  --cds-text-helper: #c6c6c6;
  --cds-text-inverse: #161616;
  --cds-text-on-color: #ffffff;
  --cds-text-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-text-placeholder: rgba(244, 244, 244, 0.4);
  --cds-text-primary: #f4f4f4;
  --cds-text-secondary: #c6c6c6;
  --cds-toggle-off: #8d8d8d;
  --cds-layer: var(--cds-layer-01, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-01, #c6c6c6);
  --cds-button-separator: #161616;
  --cds-button-primary: #620b5b;
  --cds-button-secondary: #6f6f6f;
  --cds-button-tertiary: #ffffff;
  --cds-button-danger-primary: #da1e28;
  --cds-button-danger-secondary: #ff8389;
  --cds-button-danger-active: #750e13;
  --cds-button-primary-active: #620b5b;
  --cds-button-secondary-active: #393939;
  --cds-button-tertiary-active: #c6c6c6;
  --cds-button-danger-hover: #b81921;
  --cds-button-primary-hover: #620b5b;
  --cds-button-secondary-hover: #5e5e5e;
  --cds-button-tertiary-hover: #f4f4f4;
  --cds-button-disabled: rgba(141, 141, 141, 0.3);
  --cds-notification-background-error: #393939;
  --cds-notification-background-success: #393939;
  --cds-notification-background-info: #393939;
  --cds-notification-background-warning: #393939;
  --cds-notification-action-tertiary-inverse: #620b5b;
  --cds-notification-action-tertiary-inverse-active: #620b5b;
  --cds-notification-action-tertiary-inverse-hover: #620b5b;
  --cds-notification-action-tertiary-inverse-text: #ffffff;
  --cds-notification-action-tertiary-inverse-text-on-color-disabled: #8d8d8d;
  --cds-tag-background-red: #a2191f;
  --cds-tag-color-red: #ffd7d9;
  --cds-tag-hover-red: #c21e25;
  --cds-tag-background-magenta: #9f1853;
  --cds-tag-color-magenta: #ffd6e8;
  --cds-tag-hover-magenta: #bf1d63;
  --cds-tag-background-purple: #6929c4;
  --cds-tag-color-purple: #e8daff;
  --cds-tag-hover-purple: #7c3dd6;
  --cds-tag-background-blue: #0043ce;
  --cds-tag-color-blue: #d0e2ff;
  --cds-tag-hover-blue: #0053ff;
  --cds-tag-background-cyan: #00539a;
  --cds-tag-color-cyan: #bae6ff;
  --cds-tag-hover-cyan: #0066bd;
  --cds-tag-background-teal: #005d5d;
  --cds-tag-color-teal: #9ef0f0;
  --cds-tag-hover-teal: #007070;
  --cds-tag-background-green: #0e6027;
  --cds-tag-color-green: #a7f0ba;
  --cds-tag-hover-green: #11742f;
  --cds-tag-background-gray: #525252;
  --cds-tag-color-gray: #e0e0e0;
  --cds-tag-hover-gray: #636363;
  --cds-tag-background-cool-gray: #4d5358;
  --cds-tag-color-cool-gray: #dde1e6;
  --cds-tag-hover-cool-gray: #5d646a;
  --cds-tag-background-warm-gray: #565151;
  --cds-tag-color-warm-gray: #e5e0df;
  --cds-tag-hover-warm-gray: #696363;
}

.cds--g100 {
  background: var(--cds-background);
  color: var(--cds-text-primary);
  --cds-background: #161616;
  --cds-background-active: rgba(141, 141, 141, 0.4);
  --cds-background-brand: #620b5b;
  --cds-background-hover: rgba(141, 141, 141, 0.16);
  --cds-background-inverse: #f4f4f4;
  --cds-background-inverse-hover: #e8e8e8;
  --cds-background-selected: rgba(141, 141, 141, 0.24);
  --cds-background-selected-hover: rgba(141, 141, 141, 0.32);
  --cds-border-disabled: rgba(141, 141, 141, 0.5);
  --cds-border-interactive: #4589ff;
  --cds-border-inverse: #f4f4f4;
  --cds-border-strong-01: #6f6f6f;
  --cds-border-strong-02: #8d8d8d;
  --cds-border-strong-03: #a8a8a8;
  --cds-border-subtle-00: #393939;
  --cds-border-subtle-01: #393939;
  --cds-border-subtle-02: #525252;
  --cds-border-subtle-03: #6f6f6f;
  --cds-border-subtle-selected-01: #525252;
  --cds-border-subtle-selected-02: #6f6f6f;
  --cds-border-subtle-selected-03: #8d8d8d;
  --cds-border-tile-01: #525252;
  --cds-border-tile-02: #6f6f6f;
  --cds-border-tile-03: #8d8d8d;
  --cds-field-01: #262626;
  --cds-field-02: #393939;
  --cds-field-03: #525252;
  --cds-field-hover-01: #333333;
  --cds-field-hover-02: #474747;
  --cds-field-hover-03: #636363;
  --cds-focus: #ffffff;
  --cds-focus-inset: #161616;
  --cds-focus-inverse: #620b5b;
  --cds-highlight: #620b5b;
  --cds-icon-disabled: rgba(244, 244, 244, 0.25);
  --cds-icon-inverse: #161616;
  --cds-icon-on-color: #ffffff;
  --cds-icon-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-icon-primary: #f4f4f4;
  --cds-icon-secondary: #c6c6c6;
  --cds-interactive: #4589ff;
  --cds-layer-01: #262626;
  --cds-layer-02: #393939;
  --cds-layer-03: #525252;
  --cds-layer-accent-01: #393939;
  --cds-layer-accent-02: #525252;
  --cds-layer-accent-03: #6f6f6f;
  --cds-layer-accent-active-01: #6f6f6f;
  --cds-layer-accent-active-02: #8d8d8d;
  --cds-layer-accent-active-03: #393939;
  --cds-layer-accent-hover-01: #474747;
  --cds-layer-accent-hover-02: #636363;
  --cds-layer-accent-hover-03: #5e5e5e;
  --cds-layer-active-01: #525252;
  --cds-layer-active-02: #6f6f6f;
  --cds-layer-active-03: #8d8d8d;
  --cds-layer-hover-01: #333333;
  --cds-layer-hover-02: #474747;
  --cds-layer-hover-03: #636363;
  --cds-layer-selected-01: #393939;
  --cds-layer-selected-02: #525252;
  --cds-layer-selected-03: #6f6f6f;
  --cds-layer-selected-disabled: #a8a8a8;
  --cds-layer-selected-hover-01: #474747;
  --cds-layer-selected-hover-02: #636363;
  --cds-layer-selected-hover-03: #5e5e5e;
  --cds-layer-selected-inverse: #f4f4f4;
  --cds-link-inverse: #620b5b;
  --cds-link-inverse-active: #161616;
  --cds-link-inverse-hover: #0043ce;
  --cds-link-primary: #78a9ff;
  --cds-link-primary-hover: #a6c8ff;
  --cds-link-secondary: #a6c8ff;
  --cds-link-visited: #be95ff;
  --cds-overlay: rgba(0, 0, 0, 0.65);
  --cds-shadow: rgba(0, 0, 0, 0.8);
  --cds-skeleton-background: #292929;
  --cds-skeleton-element: #393939;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #a56eff;
  --cds-support-error: #fa4d56;
  --cds-support-error-inverse: #da1e28;
  --cds-support-info: #4589ff;
  --cds-support-info-inverse: #0043ce;
  --cds-support-success: #42be65;
  --cds-support-success-inverse: #24a148;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: rgba(244, 244, 244, 0.25);
  --cds-text-error: #ff8389;
  --cds-text-helper: #a8a8a8;
  --cds-text-inverse: #161616;
  --cds-text-on-color: #ffffff;
  --cds-text-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-text-placeholder: rgba(244, 244, 244, 0.4);
  --cds-text-primary: #f4f4f4;
  --cds-text-secondary: #c6c6c6;
  --cds-toggle-off: #6f6f6f;
  --cds-layer: var(--cds-layer-01, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-01, #c6c6c6);
  --cds-button-separator: #161616;
  --cds-button-primary: #620b5b;
  --cds-button-secondary: #6f6f6f;
  --cds-button-tertiary: #ffffff;
  --cds-button-danger-primary: #da1e28;
  --cds-button-danger-secondary: #fa4d56;
  --cds-button-danger-active: #750e13;
  --cds-button-primary-active: #620b5b;
  --cds-button-secondary-active: #393939;
  --cds-button-tertiary-active: #c6c6c6;
  --cds-button-danger-hover: #b81921;
  --cds-button-primary-hover: #620b5b;
  --cds-button-secondary-hover: #5e5e5e;
  --cds-button-tertiary-hover: #f4f4f4;
  --cds-button-disabled: rgba(141, 141, 141, 0.3);
  --cds-notification-background-error: #262626;
  --cds-notification-background-success: #262626;
  --cds-notification-background-info: #262626;
  --cds-notification-background-warning: #262626;
  --cds-notification-action-tertiary-inverse: #620b5b;
  --cds-notification-action-tertiary-inverse-active: #620b5b;
  --cds-notification-action-tertiary-inverse-hover: #620b5b;
  --cds-notification-action-tertiary-inverse-text: #ffffff;
  --cds-notification-action-tertiary-inverse-text-on-color-disabled: #8d8d8d;
  --cds-tag-background-red: #a2191f;
  --cds-tag-color-red: #ffd7d9;
  --cds-tag-hover-red: #c21e25;
  --cds-tag-background-magenta: #9f1853;
  --cds-tag-color-magenta: #ffd6e8;
  --cds-tag-hover-magenta: #bf1d63;
  --cds-tag-background-purple: #6929c4;
  --cds-tag-color-purple: #e8daff;
  --cds-tag-hover-purple: #7c3dd6;
  --cds-tag-background-blue: #0043ce;
  --cds-tag-color-blue: #d0e2ff;
  --cds-tag-hover-blue: #0053ff;
  --cds-tag-background-cyan: #00539a;
  --cds-tag-color-cyan: #bae6ff;
  --cds-tag-hover-cyan: #0066bd;
  --cds-tag-background-teal: #005d5d;
  --cds-tag-color-teal: #9ef0f0;
  --cds-tag-hover-teal: #007070;
  --cds-tag-background-green: #0e6027;
  --cds-tag-color-green: #a7f0ba;
  --cds-tag-hover-green: #11742f;
  --cds-tag-background-gray: #525252;
  --cds-tag-color-gray: #e0e0e0;
  --cds-tag-hover-gray: #636363;
  --cds-tag-background-cool-gray: #4d5358;
  --cds-tag-color-cool-gray: #dde1e6;
  --cds-tag-hover-cool-gray: #5d646a;
  --cds-tag-background-warm-gray: #565151;
  --cds-tag-color-warm-gray: #e5e0df;
  --cds-tag-hover-warm-gray: #696363;
}

.cds--accordion--flush .cds--accordion__title {
  margin-left: 0;
}

.cds--accordion--flush .cds--accordion__arrow {
  margin-right: 0;
}

.cds--accordion--flush .cds--accordion__content {
  padding-left: 0;
}

.cds--accordion--flush:not(.cds--skeleton) .cds--accordion__heading:hover::before,
.cds--accordion--flush:not(.cds--skeleton) .cds--accordion__heading:focus::before {
  left: -1rem;
  width: calc(100% + 32px);
}

.cds--accordion {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  width: 100%;
  list-style: none;
}
.cds--accordion *,
.cds--accordion *::before,
.cds--accordion *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--accordion__item {
  display: list-item;
  overflow: visible;
  border-top: 1px solid var(--cds-border-subtle);
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--accordion__item:last-child {
  border-bottom: 1px solid var(--cds-border-subtle);
}

.cds--accordion__heading {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0.625rem 0;
  margin: 0;
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
  -webkit-transition: background-color cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
  transition: background-color cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
}
.cds--accordion__heading *,
.cds--accordion__heading *::before,
.cds--accordion__heading *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--accordion__heading::-moz-focus-inner {
  border: 0;
}
.cds--accordion__heading:hover::before, .cds--accordion__heading:focus::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: calc(100% + 2px);
  content: "";
}
.cds--accordion__heading:hover::before {
  background-color: var(--cds-layer-hover);
}
.cds--accordion__heading:focus {
  outline: none;
}
.cds--accordion__heading:focus::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--accordion__heading:focus::before {
    border-style: dotted;
  }
}

.cds--accordion--lg .cds--accordion__heading {
  min-height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--accordion--sm .cds--accordion__heading {
  min-height: 2rem;
  padding: 0.3125rem 0;
}

.cds--accordion__heading[disabled] {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--accordion__heading[disabled] .cds--accordion__arrow {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--accordion__heading[disabled]:hover::before {
  background-color: transparent;
}

.cds--accordion__item--disabled,
.cds--accordion__item--disabled + .cds--accordion__item {
  border-top: 1px solid var(--cds-border-subtle);
}

li.cds--accordion__item--disabled:last-of-type {
  border-bottom: 1px solid var(--cds-border-subtle);
}

.cds--accordion__arrow {
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 1rem;
  height: 1rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
  margin: 2px 1rem 0 0;
  fill: var(--cds-icon-primary, #161616);
  -webkit-transform: rotate(-270deg) /*rtl:ignore*/;
  transform: rotate(-270deg) /*rtl:ignore*/;
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--accordion__title {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  z-index: 1;
  width: 100%;
  padding-right: 1rem;
  margin: 0 0 0 1rem;
  text-align: left;
}

.cds--accordion__content {
  display: none;
  padding-right: 1rem;
  padding-left: 1rem;
  -webkit-transition: padding cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
  transition: padding cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
}
@media (min-width: 480px) {
  .cds--accordion__content {
    padding-right: 3rem;
  }
}
@media (min-width: 640px) {
  .cds--accordion__content {
    padding-right: 25%;
  }
}
.cds--accordion__content > p {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
}

.cds--accordion--start .cds--accordion__heading {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.cds--accordion--start .cds--accordion__arrow {
  margin: 2px 0 0 1rem;
}

.cds--accordion--start .cds--accordion__title {
  margin-right: 1rem;
}

.cds--accordion--start .cds--accordion__content {
  margin-left: 2rem;
}

.cds--accordion__item--collapsing .cds--accordion__content,
.cds--accordion__item--expanding .cds--accordion__content {
  display: block;
}

@-webkit-keyframes collapse-accordion {
  0% {
    height: 100%;
    opacity: 1;
    visibility: inherit;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes collapse-accordion {
  0% {
    height: 100%;
    opacity: 1;
    visibility: inherit;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes expand-accordion {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    height: 100%;
    opacity: 1;
    visibility: inherit;
  }
}
@keyframes expand-accordion {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    height: 100%;
    opacity: 1;
    visibility: inherit;
  }
}
.cds--accordion__item--collapsing .cds--accordion__content {
  -webkit-animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) collapse-accordion;
  animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) collapse-accordion;
}

.cds--accordion__item--expanding .cds--accordion__content {
  -webkit-animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) expand-accordion;
  animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) expand-accordion;
}

.cds--accordion__item--active {
  overflow: visible;
}
.cds--accordion__item--active .cds--accordion__content {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  -webkit-transition: padding-top cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-bottom cubic-bezier(0, 0, 0.38, 0.9) 110ms;
  transition: padding-top cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-bottom cubic-bezier(0, 0, 0.38, 0.9) 110ms;
}
.cds--accordion__item--active .cds--accordion__arrow {
  fill: var(--cds-icon-primary, #161616);
  -webkit-transform: rotate(-90deg) /*rtl:ignore*/;
  transform: rotate(-90deg) /*rtl:ignore*/;
}

.cds--accordion.cds--skeleton .cds--accordion__heading,
.cds--accordion.cds--skeleton .cds--accordion__button {
  cursor: default;
}

.cds--accordion.cds--skeleton .cds--accordion__arrow {
  cursor: default;
  fill: var(--cds-icon-primary, #161616);
  pointer-events: none;
}
.cds--accordion.cds--skeleton .cds--accordion__arrow:hover, .cds--accordion.cds--skeleton .cds--accordion__arrow:focus, .cds--accordion.cds--skeleton .cds--accordion__arrow:active {
  border: none;
  cursor: default;
  outline: none;
}

.cds--accordion.cds--skeleton .cds--accordion__heading:hover::before {
  background-color: transparent;
}

.cds--accordion--end.cds--skeleton .cds--accordion__arrow {
  margin-left: 1rem;
}

.cds--skeleton .cds--accordion__heading:focus .cds--accordion__arrow {
  border: none;
  cursor: default;
  outline: none;
}

.cds--accordion__title.cds--skeleton__text {
  margin-bottom: 0;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--accordion__arrow,
  .cds--accordion__item--active .cds--accordion__arrow {
    fill: ButtonText;
  }
}

.cds--aspect-ratio {
  position: relative;
}

.cds--aspect-ratio::before {
  width: 1px;
  height: 0;
  margin-left: -1px;
  content: "";
  float: left;
}

.cds--aspect-ratio::after {
  display: table;
  clear: both;
  content: "";
}

.cds--aspect-ratio--16x9::before {
  padding-top: 56.25%;
}

.cds--aspect-ratio--9x16::before {
  padding-top: 177.7777777778%;
}

.cds--aspect-ratio--2x1::before {
  padding-top: 50%;
}

.cds--aspect-ratio--1x2::before {
  padding-top: 200%;
}

.cds--aspect-ratio--4x3::before {
  padding-top: 75%;
}

.cds--aspect-ratio--3x4::before {
  padding-top: 133.3333333333%;
}

.cds--aspect-ratio--3x2::before {
  padding-top: 66.6666666667%;
}

.cds--aspect-ratio--2x3::before {
  padding-top: 150%;
}

.cds--aspect-ratio--1x1::before {
  padding-top: 100%;
}

@-webkit-keyframes hide-feedback {
  0% {
    opacity: 1;
    visibility: inherit;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes hide-feedback {
  0% {
    opacity: 1;
    visibility: inherit;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes show-feedback {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: inherit;
  }
}
@keyframes show-feedback {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: inherit;
  }
}
@-webkit-keyframes skeleton {
  0% {
    opacity: 0.3;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  20% {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  28% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  51% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  58% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  82% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  83% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  96% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  100% {
    opacity: 0.3;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
}
@keyframes skeleton {
  0% {
    opacity: 0.3;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  20% {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  28% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  51% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  58% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  82% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  83% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  96% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  100% {
    opacity: 0.3;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
}
.cds--breadcrumb {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: inline;
}
.cds--breadcrumb html {
  font-size: 100%;
}
.cds--breadcrumb body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--breadcrumb code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--breadcrumb strong {
  font-weight: 600;
}
@media (min-width: 42rem) {
  .cds--breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.cds--breadcrumb-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0.5rem;
}

.cds--breadcrumb-item .cds--link:visited {
  color: var(--cds-link-primary, #620b5b);
}
.cds--breadcrumb-item .cds--link:visited:hover {
  color: var(--cds-link-primary-hover, #0043ce);
}

.cds--breadcrumb-item::after {
  margin-left: 0.5rem;
  color: var(--cds-text-primary, #161616);
  content: "/";
}

.cds--breadcrumb--no-trailing-slash .cds--breadcrumb-item:last-child::after {
  content: "";
}

.cds--breadcrumb-item:last-child,
.cds--breadcrumb-item:last-child::after {
  margin-right: 0;
}

.cds--breadcrumb .cds--link {
  white-space: nowrap;
}

.cds--breadcrumb-item [aria-current=page],
.cds--breadcrumb-item.cds--breadcrumb-item--current .cds--link {
  color: var(--cds-text-primary, #161616);
  cursor: auto;
}
.cds--breadcrumb-item [aria-current=page]:hover,
.cds--breadcrumb-item.cds--breadcrumb-item--current .cds--link:hover {
  text-decoration: none;
}

.cds--breadcrumb-item .cds--overflow-menu {
  position: relative;
  width: 1.25rem;
  height: 1.125rem;
}
.cds--breadcrumb-item .cds--overflow-menu:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
.cds--breadcrumb-item .cds--overflow-menu:hover {
  background: transparent;
}
.cds--breadcrumb-item .cds--overflow-menu::after {
  position: absolute;
  bottom: 2px;
  width: 0.75rem;
  height: 1px;
  background: var(--cds-link-primary-hover, #0043ce);
  content: "";
  opacity: 0;
  -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--breadcrumb-item .cds--overflow-menu::after {
    -webkit-transition: none;
    transition: none;
  }
}

.cds--breadcrumb-item .cds--overflow-menu:hover::after {
  opacity: 1;
}

.cds--breadcrumb-item .cds--overflow-menu.cds--overflow-menu--open {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cds--breadcrumb-item .cds--overflow-menu__icon {
  position: relative;
  fill: var(--cds-link-primary, #620b5b);
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}

.cds--breadcrumb-item .cds--overflow-menu:hover .cds--overflow-menu__icon {
  fill: var(--cds-link-primary-hover, #0043ce);
}

.cds--breadcrumb-menu-options:focus {
  outline: none;
}

.cds--breadcrumb-menu-options.cds--overflow-menu-options::after {
  top: -0.4375rem;
  left: 0.875rem;
  width: 0;
  height: 0;
  border-right: 0.4375rem solid transparent;
  border-bottom: 0.4375rem solid var(--cds-field);
  border-left: 0.4375rem solid transparent;
  margin: 0 auto;
  background: transparent;
}

.cds--breadcrumb.cds--skeleton .cds--link {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem;
  height: 1rem;
}
.cds--breadcrumb.cds--skeleton .cds--link:hover, .cds--breadcrumb.cds--skeleton .cds--link:focus, .cds--breadcrumb.cds--skeleton .cds--link:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--breadcrumb.cds--skeleton .cds--link::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--breadcrumb.cds--skeleton .cds--link::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--link {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: var(--cds-link-text-color, var(--cds-link-primary, #620b5b));
  outline: none;
  text-decoration: none;
  -webkit-transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--link *,
.cds--link *::before,
.cds--link *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--link:hover {
  color: var(--cds-link-hover-text-color, var(--cds-link-primary-hover, #0043ce));
  text-decoration: underline;
}
.cds--link:active, .cds--link:active:visited, .cds--link:active:visited:hover {
  color: var(--cds-text-primary, #161616);
  text-decoration: underline;
}
.cds--link:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
  outline-color: var(--cds-link-focus-text-color, var(--cds-focus, #620b5b));
}
@media screen and (prefers-contrast) {
  .cds--link:focus {
    outline-style: dotted;
  }
}
.cds--link:visited {
  color: var(--cds-link-primary, #620b5b);
}
.cds--link:visited:hover {
  color: var(--cds-link-primary-hover, #0043ce);
}

.cds--link--disabled,
.cds--link--disabled:hover {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
  font-weight: 400;
  text-decoration: none;
}
.cds--link--disabled *,
.cds--link--disabled *::before,
.cds--link--disabled *::after,
.cds--link--disabled:hover *,
.cds--link--disabled:hover *::before,
.cds--link--disabled:hover *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--link.cds--link--visited:visited {
  color: var(--cds-link-visited, #8a3ffc);
}

.cds--link.cds--link--visited:visited:hover {
  color: var(--cds-link-primary-hover, #0043ce);
}

.cds--link.cds--link--inline {
  text-decoration: underline;
}
.cds--link.cds--link--inline:focus, .cds--link.cds--link--inline:visited {
  text-decoration: none;
}

.cds--link--disabled.cds--link--inline {
  text-decoration: underline;
}

.cds--link--sm,
.cds--link--sm.cds--link--disabled:hover {
  font-size: var(--cds-helper-text-01-font-size, 0.75rem);
  line-height: var(--cds-helper-text-01-line-height, 1.33333);
  letter-spacing: var(--cds-helper-text-01-letter-spacing, 0.32px);
}

.cds--link--lg,
.cds--link--lg.cds--link--disabled:hover {
  font-size: var(--cds-body-compact-02-font-size, 1rem);
  font-weight: var(--cds-body-compact-02-font-weight, 400);
  line-height: var(--cds-body-compact-02-line-height, 1.375);
  letter-spacing: var(--cds-body-compact-02-letter-spacing, 0);
}

.cds--link__icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 0.5rem;
}

.cds--assistive-text,
.cds--visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}

.cds--btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 20rem;
  min-height: 3rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: calc(0.875rem - 3px) 63px calc(0.875rem - 3px) 15px;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  text-align: left;
  text-decoration: none;
  -webkit-transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9), -webkit-box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9), -webkit-box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9), -webkit-box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9);
  vertical-align: top;
}
.cds--btn *,
.cds--btn *::before,
.cds--btn *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--btn:disabled, .cds--btn:hover:disabled, .cds--btn:focus:disabled, .cds--btn.cds--btn--disabled, .cds--btn.cds--btn--disabled:hover, .cds--btn.cds--btn--disabled:focus {
  border-color: var(--cds-button-disabled, #c6c6c6);
  background: var(--cds-button-disabled, #c6c6c6);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--cds-text-on-color-disabled, #8d8d8d);
  cursor: not-allowed;
}
.cds--btn .cds--btn__icon {
  position: absolute;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.cds--btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.cds--btn--primary {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--cds-button-primary, #620b5b);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--primary:hover {
  background-color: var(--cds-button-primary-hover, #620b5b);
}
.cds--btn--primary:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #620b5b));
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--primary:active {
  background-color: var(--cds-button-primary-active, #620b5b);
}
.cds--btn--primary .cds--btn__icon,
.cds--btn--primary .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--primary:hover {
  color: var(--cds-text-on-color, #ffffff);
}

.cds--btn--secondary {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--cds-button-secondary, #393939);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--secondary:hover {
  background-color: var(--cds-button-secondary-hover, #474747);
}
.cds--btn--secondary:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #620b5b));
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--secondary:active {
  background-color: var(--cds-button-secondary-active, #6f6f6f);
}
.cds--btn--secondary .cds--btn__icon,
.cds--btn--secondary .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--secondary:hover, .cds--btn--secondary:focus {
  color: var(--cds-text-on-color, #ffffff);
}

.cds--btn--tertiary {
  border-width: 1px;
  border-style: solid;
  border-color: var(--cds-button-tertiary, #620b5b);
  background-color: transparent;
  color: var(--cds-button-tertiary, #620b5b);
}
.cds--btn--tertiary:hover {
  background-color: var(--cds-button-tertiary-hover, #620b5b);
}
.cds--btn--tertiary:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #620b5b));
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--tertiary:active {
  background-color: var(--cds-button-tertiary-active, #620b5b);
}
.cds--btn--tertiary .cds--btn__icon,
.cds--btn--tertiary .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--tertiary:hover {
  color: var(--cds-text-inverse, #ffffff);
}
.cds--btn--tertiary:focus {
  background-color: var(--cds-button-tertiary, #620b5b);
  color: var(--cds-text-inverse, #ffffff);
}
.cds--btn--tertiary:active {
  border-color: transparent;
  background-color: var(--cds-button-tertiary-active, #620b5b);
  color: var(--cds-text-inverse, #ffffff);
}
.cds--btn--tertiary:disabled, .cds--btn--tertiary:hover:disabled, .cds--btn--tertiary:focus:disabled, .cds--btn--tertiary.cds--btn--disabled, .cds--btn--tertiary.cds--btn--disabled:hover, .cds--btn--tertiary.cds--btn--disabled:focus {
  background: transparent;
  color: var(--cds-text-on-color-disabled, #8d8d8d);
  outline: none;
}

.cds--btn--ghost {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  color: var(--cds-link-primary, #620b5b);
  padding: calc(0.875rem - 3px) 16px;
}
.cds--btn--ghost:hover {
  background-color: var(--cds-layer-hover);
}
.cds--btn--ghost:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #620b5b));
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--ghost:active {
  background-color: var(--cds-layer-active);
}
.cds--btn--ghost .cds--btn__icon,
.cds--btn--ghost .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--ghost .cds--btn__icon {
  position: static;
  margin-left: 0.5rem;
}
.cds--btn--ghost:hover, .cds--btn--ghost:active {
  color: var(--cds-link-primary-hover, #0043ce);
}
.cds--btn--ghost:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
}
.cds--btn--ghost:disabled, .cds--btn--ghost:hover:disabled, .cds--btn--ghost:focus:disabled, .cds--btn--ghost.cds--btn--disabled, .cds--btn--ghost.cds--btn--disabled:hover, .cds--btn--ghost.cds--btn--disabled:focus {
  border-color: transparent;
  background: transparent;
  color: var(--cds-text-on-color-disabled, #8d8d8d);
  outline: none;
}
.cds--btn--ghost.cds--btn--sm {
  padding: calc(0.375rem - 3px) 16px;
}
.cds--btn--ghost.cds--btn--field, .cds--btn--ghost.cds--btn--md {
  padding: calc(0.675rem - 3px) 16px;
}
.cds--btn--ghost:not([disabled]) svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--btn--icon-only {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.cds--btn--icon-only .cds--btn__icon {
  position: static;
}
.cds--btn--icon-only.cds--btn--ghost .cds--btn__icon, .cds--btn--icon-only.cds--btn--danger--ghost .cds--btn__icon {
  margin: 0;
}

.cds--btn--icon-only.cds--btn--selected {
  background: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
}

.cds--btn path[data-icon-path=inner-path] {
  fill: none;
}

.cds--btn--ghost.cds--btn--icon-only .cds--btn__icon path:not([data-icon-path]):not([fill=none]),
.cds--btn--ghost.cds--btn--icon-only .cds--btn__icon {
  fill: var(--cds-icon-primary, #161616);
}

.cds--btn--ghost.cds--btn--icon-only[disabled] .cds--btn__icon path:not([data-icon-path]):not([fill=none]),
.cds--btn--ghost.cds--btn--icon-only[disabled] .cds--btn__icon,
.cds--btn.cds--btn--icon-only.cds--btn--ghost[disabled]:hover .cds--btn__icon {
  fill: var(--cds-icon-on-color-disabled, #8d8d8d);
}

.cds--btn--ghost.cds--btn--icon-only[disabled] {
  cursor: not-allowed;
}

.cds--btn--field.cds--btn--icon-only,
.cds--btn--md.cds--btn--icon-only {
  padding-right: 0.6875rem;
  padding-left: 0.6875rem;
}

.cds--btn--sm.cds--btn--icon-only {
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
}

.cds--btn--danger {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--cds-button-danger-primary, #da1e28);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger:hover {
  background-color: var(--cds-button-danger-hover, #b81921);
}
.cds--btn--danger:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #620b5b));
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--danger:active {
  background-color: var(--cds-button-danger-active, #750e13);
}
.cds--btn--danger .cds--btn__icon,
.cds--btn--danger .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--danger:hover {
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--tertiary {
  border-width: 1px;
  border-style: solid;
  border-color: var(--cds-button-danger-secondary, #da1e28);
  background-color: transparent;
  color: var(--cds-button-danger-secondary, #da1e28);
}
.cds--btn--danger--tertiary:hover {
  background-color: var(--cds-button-danger-hover, #b81921);
}
.cds--btn--danger--tertiary:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #620b5b));
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--danger--tertiary:active {
  background-color: var(--cds-button-danger-active, #750e13);
}
.cds--btn--danger--tertiary .cds--btn__icon,
.cds--btn--danger--tertiary .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--danger--tertiary:hover {
  border-color: var(--cds-button-danger-hover, #b81921);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--tertiary:focus {
  background-color: var(--cds-button-danger-primary, #da1e28);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--tertiary:active {
  border-color: var(--cds-button-danger-active, #750e13);
  background-color: var(--cds-button-danger-active, #750e13);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--tertiary:disabled, .cds--btn--danger--tertiary:hover:disabled, .cds--btn--danger--tertiary:focus:disabled, .cds--btn--danger--tertiary.cds--btn--disabled, .cds--btn--danger--tertiary.cds--btn--disabled:hover, .cds--btn--danger--tertiary.cds--btn--disabled:focus {
  background: transparent;
  color: var(--cds-text-on-color-disabled, #8d8d8d);
  outline: none;
}
.cds--btn--danger--ghost {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  color: var(--cds-button-danger-secondary, #da1e28);
  padding: calc(0.875rem - 3px) 16px;
}
.cds--btn--danger--ghost:hover {
  background-color: var(--cds-button-danger-hover, #b81921);
}
.cds--btn--danger--ghost:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #620b5b));
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--danger--ghost:active {
  background-color: var(--cds-button-danger-active, #750e13);
}
.cds--btn--danger--ghost .cds--btn__icon,
.cds--btn--danger--ghost .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--danger--ghost .cds--btn__icon {
  position: static;
  margin-left: 0.5rem;
}
.cds--btn--danger--ghost:hover, .cds--btn--danger--ghost:active {
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--ghost:disabled, .cds--btn--danger--ghost:hover:disabled, .cds--btn--danger--ghost:focus:disabled, .cds--btn--danger--ghost.cds--btn--disabled, .cds--btn--danger--ghost.cds--btn--disabled:hover, .cds--btn--danger--ghost.cds--btn--disabled:focus {
  border-color: transparent;
  background: transparent;
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  outline: none;
}
.cds--btn--danger--ghost.cds--btn--sm {
  padding: calc(0.375rem - 3px) 16px;
}
.cds--btn--danger--ghost.cds--btn--field, .cds--btn--danger--ghost.cds--btn--md {
  padding: calc(0.675rem - 3px) 16px;
}

.cds--btn--sm {
  min-height: 2rem;
  padding: calc(0.375rem - 3px) 60px calc(0.375rem - 3px) 12px;
}

.cds--btn--2xl:not(.cds--btn--icon-only) {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 1rem;
  padding-right: 4rem;
  padding-left: 1rem;
  min-height: 5rem;
}

.cds--btn--xl:not(.cds--btn--icon-only) {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 1rem;
  padding-right: 4rem;
  padding-left: 1rem;
  min-height: 4rem;
}

.cds--btn--field,
.cds--btn--md {
  min-height: 2.5rem;
  padding: calc(0.675rem - 3px) 60px calc(0.675rem - 3px) 12px;
}

.cds--btn--expressive {
  font-size: var(--cds-body-compact-02-font-size, 1rem);
  font-weight: var(--cds-body-compact-02-font-weight, 400);
  line-height: var(--cds-body-compact-02-line-height, 1.375);
  letter-spacing: var(--cds-body-compact-02-letter-spacing, 0);
  min-height: 3rem;
}

.cds--btn--icon-only.cds--btn--expressive {
  padding: 12px 13px;
}

.cds--btn.cds--btn--expressive .cds--btn__icon {
  width: 1.25rem;
  height: 1.25rem;
}

.cds--btn-set .cds--btn.cds--btn--expressive {
  max-width: 20rem;
}

.cds--btn.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 9.375rem;
}
.cds--btn.cds--skeleton:hover, .cds--btn.cds--skeleton:focus, .cds--btn.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--btn.cds--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--btn.cds--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--btn-set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cds--btn-set--stacked {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cds--btn-set .cds--btn {
  width: 100%;
  max-width: 12.25rem;
}
.cds--btn-set .cds--btn:not(:focus) {
  -webkit-box-shadow: -0.0625rem 0 0 0 var(--cds-button-separator, #e0e0e0);
  box-shadow: -0.0625rem 0 0 0 var(--cds-button-separator, #e0e0e0);
}
.cds--btn-set .cds--btn:first-of-type:not(:focus) {
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

.cds--btn-set .cds--btn:focus + .cds--btn {
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

.cds--btn-set--stacked .cds--btn:not(:focus) {
  -webkit-box-shadow: 0 -0.0625rem 0 0 var(--cds-button-separator, #e0e0e0);
  box-shadow: 0 -0.0625rem 0 0 var(--cds-button-separator, #e0e0e0);
}

.cds--btn-set--stacked .cds--btn:first-of-type:not(:focus) {
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

.cds--btn-set .cds--btn.cds--btn--disabled {
  -webkit-box-shadow: -0.0625rem 0 0 0 var(--cds-icon-on-color-disabled, #8d8d8d);
  box-shadow: -0.0625rem 0 0 0 var(--cds-icon-on-color-disabled, #8d8d8d);
}
.cds--btn-set .cds--btn.cds--btn--disabled:first-of-type {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cds--btn-set--stacked .cds--btn.cds--btn--disabled {
  -webkit-box-shadow: 0 -0.0625rem 0 0 var(--cds-layer-selected-disabled, #8d8d8d);
  box-shadow: 0 -0.0625rem 0 0 var(--cds-layer-selected-disabled, #8d8d8d);
}
.cds--btn-set--stacked .cds--btn.cds--btn--disabled:first-of-type {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--btn:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--btn--ghost.cds--btn--icon-only .cds--btn__icon path:not([data-icon-path]):not([fill=none]),
  .cds--btn--ghost.cds--btn--icon-only .cds--btn__icon {
    fill: ButtonText;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px var(--cds-field) inset;
  box-shadow: 0 0 0 1000px var(--cds-field) inset;
  -webkit-text-fill-color: var(--cds-text-primary, #161616);
}

.cds--fieldset html {
  font-size: 100%;
}
.cds--fieldset body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--fieldset code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--fieldset strong {
  font-weight: 600;
}

.cds--form-item {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cds--label {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  display: inline-block;
  margin-bottom: 0.5rem;
  color: var(--cds-text-secondary, #525252);
  font-weight: 400;
  line-height: 1rem;
  vertical-align: baseline;
}
.cds--label html {
  font-size: 100%;
}
.cds--label body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--label code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--label strong {
  font-weight: 600;
}

.cds--label--no-margin {
  margin-bottom: 0;
}

.cds--label + .cds--tooltip {
  position: relative;
  top: 0.2rem;
  left: 0.5rem;
}

.cds--label + .cds--tooltip .cds--tooltip__trigger {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
}
.cds--label + .cds--tooltip .cds--tooltip__trigger *,
.cds--label + .cds--tooltip .cds--tooltip__trigger *::before,
.cds--label + .cds--tooltip .cds--tooltip__trigger *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--label + .cds--tooltip .cds--tooltip__trigger::-moz-focus-inner {
  border: 0;
}

.cds--label + .cds--tooltip .cds--tooltip__trigger:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}

.cds--label + .cds--tooltip .cds--tooltip__trigger svg {
  fill: var(--cds-icon-secondary, #525252);
}

.cds--label + .cds--tooltip .cds--tooltip__trigger svg :hover {
  fill: var(--cds-icon-primary, #161616);
}

.cds--label + .cds--toggletip {
  top: 0.2rem;
  left: 0.5rem;
}

.cds--label.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 4.6875rem;
  height: 0.875rem;
}
.cds--label.cds--skeleton:hover, .cds--label.cds--skeleton:focus, .cds--label.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--label.cds--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--label.cds--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}

input[type=number] {
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
}

input[data-invalid]:not(:focus),
.cds--number[data-invalid] input[type=number]:not(:focus),
.cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid:not(:focus),
.cds--text-area__wrapper[data-invalid] > .cds--text-area--invalid:not(:focus),
.cds--select-input__wrapper[data-invalid] .cds--select-input:not(:focus),
.cds--list-box[data-invalid]:not(:focus),
.cds--combo-box[data-invalid]:not(.cds--multi-select--selected) .cds--text-input:not(:focus) {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  input[data-invalid]:not(:focus),
  .cds--number[data-invalid] input[type=number]:not(:focus),
  .cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid:not(:focus),
  .cds--text-area__wrapper[data-invalid] > .cds--text-area--invalid:not(:focus),
  .cds--select-input__wrapper[data-invalid] .cds--select-input:not(:focus),
  .cds--list-box[data-invalid]:not(:focus),
  .cds--combo-box[data-invalid]:not(.cds--multi-select--selected) .cds--text-input:not(:focus) {
    outline-style: dotted;
  }
}

input[data-invalid] ~ .cds--form-requirement,
.cds--number[data-invalid] .cds--number__input-wrapper ~ .cds--form-requirement,
.cds--number__input-wrapper--warning ~ .cds--form-requirement,
.cds--date-picker-input__wrapper ~ .cds--form-requirement,
.cds--date-picker-input__wrapper--warn ~ .cds--form-requirement,
.cds--date-picker-input__wrapper--invalid ~ .cds--form-requirement,
.cds--time-picker--invalid ~ .cds--form-requirement,
.cds--text-input__field-wrapper[data-invalid] ~ .cds--form-requirement,
.cds--text-input__field-wrapper--warning ~ .cds--form-requirement,
.cds--text-input__field-wrapper--warning > .cds--text-input ~ .cds--form-requirement,
.cds--text-area__wrapper[data-invalid] ~ .cds--form-requirement,
.cds--select-input__wrapper[data-invalid] ~ .cds--form-requirement,
.cds--select--warning .cds--select-input__wrapper ~ .cds--form-requirement,
.cds--time-picker[data-invalid] ~ .cds--form-requirement,
.cds--list-box[data-invalid] ~ .cds--form-requirement,
.cds--list-box--warning ~ .cds--form-requirement {
  display: block;
  overflow: visible;
  max-height: 12.5rem;
  font-weight: 400;
}

input[data-invalid] ~ .cds--form-requirement,
.cds--number[data-invalid] .cds--number__input-wrapper ~ .cds--form-requirement,
.cds--date-picker-input__wrapper ~ .cds--form-requirement,
.cds--date-picker-input__wrapper--invalid ~ .cds--form-requirement,
.cds--time-picker--invalid ~ .cds--form-requirement,
.cds--text-input__field-wrapper[data-invalid] ~ .cds--form-requirement,
.cds--text-area__wrapper[data-invalid] ~ .cds--form-requirement,
.cds--select-input__wrapper[data-invalid] ~ .cds--form-requirement,
.cds--time-picker[data-invalid] ~ .cds--form-requirement,
.cds--list-box[data-invalid] ~ .cds--form-requirement {
  color: var(--cds-text-error, #da1e28);
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid],
.cds--form--fluid .cds--text-input__field-wrapper--warning {
  display: block;
}

.cds--form--fluid input[data-invalid] {
  outline: none;
}

.cds--form--fluid .cds--form-requirement {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0;
}

input:not(output):not([data-invalid]):-moz-ui-invalid {
  box-shadow: none;
}

.cds--form-requirement {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  display: none;
  overflow: hidden;
  max-height: 0;
  margin: 0.25rem 0 0;
}
.cds--form-requirement html {
  font-size: 100%;
}
.cds--form-requirement body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--form-requirement code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--form-requirement strong {
  font-weight: 600;
}

.cds--select--inline .cds--form__helper-text {
  margin-top: 0;
}

.cds--form__helper-text {
  font-size: var(--cds-helper-text-01-font-size, 0.75rem);
  line-height: var(--cds-helper-text-01-line-height, 1.33333);
  letter-spacing: var(--cds-helper-text-01-letter-spacing, 0.32px);
  z-index: 0;
  width: 100%;
  margin-top: 0.25rem;
  color: var(--cds-text-secondary, #525252);
  opacity: 1;
}

.cds--label--disabled,
.cds--form__helper-text--disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

fieldset[disabled] .cds--label,
fieldset[disabled] .cds--form__helper-text {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--form-item.cds--checkbox-wrapper {
  position: relative;
  margin-bottom: 0.25rem;
}

.cds--form-item.cds--checkbox-wrapper:first-of-type {
  margin-top: 0.1875rem;
}

.cds--label + .cds--form-item.cds--checkbox-wrapper {
  margin-top: -0.125rem;
}

.cds--form-item.cds--checkbox-wrapper:last-of-type {
  margin-bottom: 0.1875rem;
}

.cds--checkbox {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
  top: 1.25rem;
  left: 0.7rem;
}

.cds--checkbox-label {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 1.5rem;
  padding-top: 0.1875rem;
  padding-left: 1.25rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cds--checkbox-label html {
  font-size: 100%;
}
.cds--checkbox-label body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--checkbox-label code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--checkbox-label strong {
  font-weight: 600;
}

.cds--checkbox-label-text {
  padding-left: 0.375rem;
}

.cds--checkbox-label::before,
.cds--checkbox-label::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cds--checkbox-label::before {
  position: absolute;
  top: 0.125rem;
  left: 0;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--cds-icon-primary, #161616);
  margin: 0.125rem 0.125rem 0.125rem 0.1875rem;
  background-color: transparent;
  border-radius: 1px;
  content: "";
}

.cds--checkbox-label::after {
  position: absolute;
  top: 0.5rem;
  left: 0.4375rem;
  width: 0.5625rem;
  height: 0.3125rem;
  border-bottom: 2px solid var(--cds-icon-inverse, #ffffff);
  border-left: 2px solid var(--cds-icon-inverse, #ffffff);
  margin-top: -0.1875rem /*rtl:0rem*/;
  background: none;
  content: "";
  -webkit-transform: scale(0) rotate(-45deg);
  transform: scale(0) rotate(-45deg);
  -webkit-transform-origin: bottom right /*rtl:center*/;
  transform-origin: bottom right /*rtl:center*/;
}

.cds--checkbox:checked + .cds--checkbox-label::before,
.cds--checkbox:indeterminate + .cds--checkbox-label::before,
.cds--checkbox-label[data-contained-checkbox-state=true]::before {
  border: none;
  border-width: 1px;
  background-color: var(--cds-icon-primary, #161616);
}

.cds--checkbox:checked + .cds--checkbox-label::after,
.cds--checkbox-label[data-contained-checkbox-state=true]::after {
  -webkit-transform: scale(1) rotate(-45deg) /*rtl:scale(1.2) rotate3d(.5, 1, 0, 158deg)*/;
  transform: scale(1) rotate(-45deg) /*rtl:scale(1.2) rotate3d(.5, 1, 0, 158deg)*/;
}

.cds--checkbox:indeterminate + .cds--checkbox-label::after {
  top: 0.6875rem;
  width: 0.5rem;
  border-bottom: 2px solid var(--cds-icon-inverse, #ffffff);
  border-left: 0 solid var(--cds-icon-inverse, #ffffff);
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

.cds--checkbox:focus + .cds--checkbox-label::before,
.cds--checkbox-label__focus::before,
.cds--checkbox:checked:focus + .cds--checkbox-label::before,
.cds--checkbox-label[data-contained-checkbox-state=true].cds--checkbox-label__focus::before,
.cds--checkbox:indeterminate:focus + .cds--checkbox-label::before {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: 1px;
}

.cds--checkbox:disabled + .cds--checkbox-label,
.cds--checkbox-label[data-contained-checkbox-disabled=true] {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--checkbox:disabled + .cds--checkbox-label::before,
.cds--checkbox-label[data-contained-checkbox-disabled=true]::before {
  border-color: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--checkbox:checked:disabled + .cds--checkbox-label::before,
.cds--checkbox:indeterminate:disabled + .cds--checkbox-label::before,
.cds--checkbox-label[data-contained-checkbox-state=true][data-contained-checkbox-disabled=true]::before {
  background-color: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--checkbox-wrapper--readonly .cds--checkbox-label {
  cursor: default;
}

.cds--checkbox-wrapper--readonly .cds--checkbox-label-text {
  cursor: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.cds--checkbox-wrapper--readonly .cds--checkbox + .cds--checkbox-label::before {
  border-color: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--checkbox-wrapper--readonly .cds--checkbox:checked + .cds--checkbox-label::before {
  border: 1px solid var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
  background: transparent;
}

.cds--checkbox-wrapper--readonly .cds--checkbox:checked + .cds--checkbox-label::after {
  border-color: var(--cds-text-primary, #161616);
}

.cds--checkbox-skeleton .cds--checkbox-label {
  cursor: default;
}

.cds--checkbox-label-text.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem;
  height: 1rem;
  margin: 0.0625rem 0 0 0.375rem;
}
.cds--checkbox-label-text.cds--skeleton:hover, .cds--checkbox-label-text.cds--skeleton:focus, .cds--checkbox-label-text.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--checkbox-label-text.cds--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--checkbox-label-text.cds--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--checkbox--inline {
  position: relative;
}

.cds--copy-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  border: none;
  background-color: var(--cds-layer);
  cursor: pointer;
}
.cds--copy-btn html {
  font-size: 100%;
}
.cds--copy-btn body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--copy-btn code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--copy-btn strong {
  font-weight: 600;
}
.cds--copy-btn:hover {
  background-color: var(--cds-layer-hover);
}
.cds--copy-btn:active {
  background-color: var(--cds-layer-active);
}
.cds--copy-btn::before {
  position: absolute;
  z-index: 6000;
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  display: none;
}
.cds--copy-btn .cds--copy-btn__feedback {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  z-index: 6000;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 1.5rem;
  max-width: 13rem;
  height: auto;
  padding: 0.1875rem 1rem;
  background-color: var(--cds-background-inverse, #393939);
  border-radius: 0.125rem;
  color: var(--cds-text-inverse, #ffffff);
  font-weight: 400;
  text-align: left;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: none;
  overflow: visible;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin: auto;
  clip: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cds--copy-btn .cds--copy-btn__feedback {
    width: auto;
  }
}
@supports (-ms-accelerator: true) {
  .cds--copy-btn .cds--copy-btn__feedback {
    width: auto;
  }
}
@supports (-ms-ime-align: auto) {
  .cds--copy-btn .cds--copy-btn__feedback {
    width: auto;
  }
}
@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
  .cds--copy-btn .cds--copy-btn__feedback {
    border: 1px solid transparent;
  }
}
.cds--copy-btn:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  outline-color: var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--copy-btn:focus {
    outline-style: dotted;
  }
}
.cds--copy-btn.cds--copy-btn--animating::before, .cds--copy-btn.cds--copy-btn--animating .cds--copy-btn__feedback {
  display: block;
}
.cds--copy-btn.cds--copy-btn--animating::before {
  border: none;
}
.cds--copy-btn.cds--copy-btn--animating.cds--copy-btn--fade-out::before, .cds--copy-btn.cds--copy-btn--animating.cds--copy-btn--fade-out .cds--copy-btn__feedback {
  -webkit-animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) hide-feedback;
  animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) hide-feedback;
}
.cds--copy-btn.cds--copy-btn--animating.cds--copy-btn--fade-in::before, .cds--copy-btn.cds--copy-btn--animating.cds--copy-btn--fade-in .cds--copy-btn__feedback {
  -webkit-animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) show-feedback;
  animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) show-feedback;
}

.cds--copy {
  font-size: 0;
}

.cds--snippet html {
  font-size: 100%;
}
.cds--snippet body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--snippet code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--snippet strong {
  font-weight: 600;
}

.cds--snippet--disabled,
.cds--snippet--disabled .cds--btn.cds--snippet-btn--expand {
  background-color: var(--cds-layer);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--snippet--disabled .cds--snippet-btn--expand:hover,
.cds--snippet--disabled .cds--copy-btn:hover {
  background-color: var(--cds-layer);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--snippet--disabled .cds--snippet__icon,
.cds--snippet--disabled .cds--snippet-btn--expand .cds--icon-chevron--down {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--snippet code {
  font-family: var(--cds-code-01-font-family, 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace);
  font-size: var(--cds-code-01-font-size, 0.75rem);
  font-weight: var(--cds-code-01-font-weight, 400);
  line-height: var(--cds-code-01-line-height, 1.33333);
  letter-spacing: var(--cds-code-01-letter-spacing, 0.32px);
}

.cds--snippet--inline {
  position: relative;
  display: inline;
  padding: 0;
  border: 1px solid transparent;
  background-color: var(--cds-layer);
  border-radius: 4px;
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
}
.cds--snippet--inline html {
  font-size: 100%;
}
.cds--snippet--inline body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--snippet--inline code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--snippet--inline strong {
  font-weight: 600;
}
.cds--snippet--inline:hover {
  background-color: var(--cds-layer-hover);
}
.cds--snippet--inline:active {
  background-color: var(--cds-layer-active);
}
.cds--snippet--inline:focus {
  border: 1px solid var(--cds-focus, #620b5b);
  outline: none;
}
.cds--snippet--inline::before {
  position: absolute;
  z-index: 6000;
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  display: none;
  border: none;
}
.cds--snippet--inline .cds--copy-btn__feedback {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  z-index: 6000;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 1.5rem;
  max-width: 13rem;
  height: auto;
  padding: 0.1875rem 1rem;
  background-color: var(--cds-background-inverse, #393939);
  border-radius: 0.125rem;
  color: var(--cds-text-inverse, #ffffff);
  font-weight: 400;
  text-align: left;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: none;
  overflow: visible;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin: auto;
  clip: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cds--snippet--inline .cds--copy-btn__feedback {
    width: auto;
  }
}
@supports (-ms-accelerator: true) {
  .cds--snippet--inline .cds--copy-btn__feedback {
    width: auto;
  }
}
@supports (-ms-ime-align: auto) {
  .cds--snippet--inline .cds--copy-btn__feedback {
    width: auto;
  }
}
@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
  .cds--snippet--inline .cds--copy-btn__feedback {
    border: 1px solid transparent;
  }
}

.cds--snippet--inline.cds--copy-btn--animating::before,
.cds--snippet--inline.cds--copy-btn--animating .cds--copy-btn__feedback {
  display: block;
}

.cds--snippet--inline.cds--copy-btn--animating.cds--copy-btn--fade-out::before,
.cds--snippet--inline.cds--copy-btn--animating.cds--copy-btn--fade-out .cds--copy-btn__feedback {
  -webkit-animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) hide-feedback;
  animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) hide-feedback;
}

.cds--snippet--inline.cds--copy-btn--animating.cds--copy-btn--fade-in::before,
.cds--snippet--inline.cds--copy-btn--animating.cds--copy-btn--fade-in .cds--copy-btn__feedback {
  -webkit-animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) show-feedback;
  animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) show-feedback;
}

.cds--snippet--inline code {
  padding: 0 0.5rem;
}

.cds--snippet--inline.cds--snippet--no-copy {
  display: inline-block;
}
.cds--snippet--inline.cds--snippet--no-copy:hover {
  background-color: var(--cds-layer);
  cursor: auto;
}

.cds--snippet--light.cds--snippet--inline.cds--snippet--no-copy:hover {
  background-color: var(--cds-layer-hover);
  cursor: auto;
}

.cds--snippet--single {
  font-family: var(--cds-code-01-font-family, 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace);
  font-size: var(--cds-code-01-font-size, 0.75rem);
  font-weight: var(--cds-code-01-font-weight, 400);
  line-height: var(--cds-code-01-line-height, 1.33333);
  letter-spacing: var(--cds-code-01-letter-spacing, 0.32px);
  position: relative;
  width: 100%;
  max-width: 48rem;
  background-color: var(--cds-layer);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 2.5rem;
}

.cds--snippet--single.cds--snippet--no-copy {
  padding: 0;
}
.cds--snippet--single.cds--snippet--no-copy::after {
  right: 1rem;
}

.cds--snippet--single .cds--snippet-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 1rem;
  overflow-x: auto;
}
.cds--snippet--single .cds--snippet-container:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--snippet--single .cds--snippet-container:focus {
    outline-style: dotted;
  }
}

.cds--snippet--single pre {
  font-family: var(--cds-code-01-font-family, 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace);
  font-size: var(--cds-code-01-font-size, 0.75rem);
  font-weight: var(--cds-code-01-font-weight, 400);
  line-height: var(--cds-code-01-line-height, 1.33333);
  letter-spacing: var(--cds-code-01-letter-spacing, 0.32px);
  padding-right: 0.5rem;
}

.cds--snippet--single pre,
.cds--snippet--inline code {
  white-space: pre;
}

.cds--snippet--multi {
  font-family: var(--cds-code-01-font-family, 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace);
  font-size: var(--cds-code-01-font-size, 0.75rem);
  font-weight: var(--cds-code-01-font-weight, 400);
  line-height: var(--cds-code-01-line-height, 1.33333);
  letter-spacing: var(--cds-code-01-letter-spacing, 0.32px);
  position: relative;
  width: 100%;
  max-width: 48rem;
  background-color: var(--cds-layer);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
}

.cds--snippet--multi .cds--snippet-container {
  position: relative;
  min-height: 100%;
  max-height: 100%;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  overflow-y: auto;
  -webkit-transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--snippet--multi.cds--snippet--expand .cds--snippet-container {
  padding-bottom: 1rem;
  -webkit-transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--snippet--multi.cds--snippet--wraptext pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.cds--snippet--multi .cds--snippet-container pre {
  padding-right: 2.5rem;
  padding-bottom: 1.5rem;
  overflow-x: auto;
}

.cds--snippet--multi.cds--snippet--no-copy .cds--snippet-container pre {
  padding-right: 0;
}

.cds--snippet--multi.cds--snippet--expand .cds--snippet-container pre {
  overflow-x: auto;
}

.cds--snippet--multi.cds--snippet--has-right-overflow .cds--snippet-container pre::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(var(--cds-layer)));
  background-image: linear-gradient(to right, transparent, var(--cds-layer));
  content: "";
}

.cds--snippet--multi .cds--snippet-container pre code {
  overflow: hidden;
}

.cds--snippet__icon {
  width: 1rem;
  height: 1rem;
  fill: var(--cds-icon-primary, #161616);
  -webkit-transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--copy-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  border: none;
  background-color: var(--cds-layer);
  cursor: pointer;
  outline: none;
}
.cds--copy-btn html {
  font-size: 100%;
}
.cds--copy-btn body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--copy-btn code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--copy-btn strong {
  font-weight: 600;
}
.cds--copy-btn:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  outline-color: var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--copy-btn:focus {
    outline-style: dotted;
  }
}

.cds--snippet .cds--popover-container {
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  position: absolute;
  top: 0;
  right: 0;
}

.cds--snippet--inline.cds--btn--md.cds--btn--icon-only {
  padding-right: 0;
  padding-left: 0;
}

.cds--snippet--inline.cds--btn--md {
  min-height: 1.25rem;
}

.cds--snippet--inline.cds--btn--primary:hover {
  color: var(--cds-text-primary, #161616);
}

.cds--snippet.cds--snippet--multi .cds--popover-container {
  top: 0.5rem;
  right: 0.5rem;
}

.cds--snippet--multi .cds--copy-btn.cds--btn--md {
  z-index: 10;
  width: 2rem;
  height: 2rem;
  min-height: 2rem;
  padding: 0;
}

.cds--snippet-btn--expand {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 0;
  background-color: var(--cds-layer);
  color: var(--cds-text-primary, #161616);
}

.cds--snippet-btn--expand .cds--snippet-btn--text {
  position: relative;
  top: -0.0625rem;
}

.cds--snippet-btn--expand--hide.cds--snippet-btn--expand {
  display: none;
}

.cds--snippet-btn--expand .cds--icon-chevron--down {
  margin-left: 0.5rem;
  fill: var(--cds-icon-primary, #161616);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--snippet-btn--expand:hover {
  background: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}

.cds--snippet-btn--expand:active {
  background-color: var(--cds-layer-active);
}

.cds--snippet-btn--expand:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  border-color: transparent;
}
@media screen and (prefers-contrast) {
  .cds--snippet-btn--expand:focus {
    outline-style: dotted;
  }
}

.cds--snippet--expand .cds--snippet-btn--expand .cds--icon-chevron--down {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}

.cds--snippet--light,
.cds--snippet--light .cds--snippet-button,
.cds--snippet--light .cds--btn.cds--snippet-btn--expand,
.cds--snippet--light .cds--copy-btn {
  background-color: var(--cds-layer);
}

.cds--snippet--light.cds--snippet--inline:hover,
.cds--snippet--light .cds--snippet-button:hover,
.cds--snippet--light .cds--btn.cds--snippet-btn--expand:hover,
.cds--snippet--light .cds--copy-btn:hover {
  background-color: var(--cds-layer-hover);
}

.cds--snippet--light.cds--snippet--inline:active,
.cds--snippet--light .cds--snippet-button:active,
.cds--snippet--light .cds--btn.cds--snippet-btn--expand:active,
.cds--snippet--light .cds--copy-btn:active {
  background-color: var(--cds-layer-active);
}

.cds--snippet--light.cds--snippet--single::after,
.cds--snippet--light.cds--snippet--multi .cds--snippet-container pre::after {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(var(--cds-layer), 0)), to(var(--cds-layer)));
  background-image: linear-gradient(to right, rgba(var(--cds-layer), 0), var(--cds-layer));
}

.cds--snippet.cds--skeleton .cds--snippet-container {
  width: 100%;
  height: 100%;
}

.cds--snippet-button .cds--btn--copy__feedback {
  top: 3.175rem;
  right: auto;
  left: 50%;
}
.cds--snippet-button .cds--btn--copy__feedback::before {
  top: 0;
}
.cds--snippet-button .cds--btn--copy__feedback::after {
  top: -0.25rem;
}

.cds--snippet--multi .cds--snippet-button .cds--btn--copy__feedback {
  top: 2.675rem;
}

.cds--snippet--inline .cds--btn--copy__feedback {
  top: calc(100% - 0.25rem);
  right: auto;
  left: 50%;
}

.cds--snippet__overflow-indicator--left,
.cds--snippet__overflow-indicator--right {
  z-index: 1;
  width: 1rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.cds--snippet__overflow-indicator--left {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  margin-right: -1rem;
  background-image: -webkit-gradient(linear, right top, left top, from(transparent), to(var(--cds-layer)));
  background-image: linear-gradient(to left, transparent, var(--cds-layer));
}

.cds--snippet__overflow-indicator--right {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  margin-left: -1rem;
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(var(--cds-layer)));
  background-image: linear-gradient(to right, transparent, var(--cds-layer));
}

.cds--snippet--single .cds--snippet__overflow-indicator--right,
.cds--snippet--single .cds--snippet__overflow-indicator--left {
  position: absolute;
  width: 2rem;
  height: calc(100% - 0.25rem);
}

.cds--snippet--single .cds--snippet__overflow-indicator--right {
  right: 2.5rem;
}

.cds--snippet--single.cds--snippet--no-copy .cds--snippet__overflow-indicator--right {
  right: 0;
}

.cds--snippet--single .cds--snippet-container:focus ~ .cds--snippet__overflow-indicator--right {
  right: calc(2.5rem + 0.125rem);
}

.cds--snippet--single .cds--snippet-container:focus + .cds--snippet__overflow-indicator--left {
  left: 0.125rem;
}

.cds--snippet--light .cds--snippet__overflow-indicator--left {
  background-image: -webkit-gradient(linear, right top, left top, from(transparent), to(var(--cds-layer)));
  background-image: linear-gradient(to left, transparent, var(--cds-layer));
}

.cds--snippet--light .cds--snippet__overflow-indicator--right {
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(var(--cds-layer)));
  background-image: linear-gradient(to right, transparent, var(--cds-layer));
}

@media not all and (-webkit-min-device-pixel-ratio: 0), not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .cds--snippet__overflow-indicator--left {
      background-image: -webkit-gradient(linear, right top, left top, from(rgba(var(--cds-layer), 0)), to(var(--cds-layer)));
      background-image: linear-gradient(to left, rgba(var(--cds-layer), 0), var(--cds-layer));
    }

    .cds--snippet__overflow-indicator--right {
      background-image: -webkit-gradient(linear, left top, right top, from(rgba(var(--cds-layer), 0)), to(var(--cds-layer)));
      background-image: linear-gradient(to right, rgba(var(--cds-layer), 0), var(--cds-layer));
    }
  }
}
.cds--snippet--multi.cds--skeleton {
  height: 6.125rem;
}

.cds--snippet--single.cds--skeleton {
  height: 3.5rem;
}

.cds--snippet.cds--skeleton span {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  display: block;
  width: 100%;
  height: 1rem;
  margin-top: 0.5rem;
}
.cds--snippet.cds--skeleton span:hover, .cds--snippet.cds--skeleton span:focus, .cds--snippet.cds--skeleton span:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--snippet.cds--skeleton span::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--snippet.cds--skeleton span::before {
    -webkit-animation: none;
    animation: none;
  }
}
.cds--snippet.cds--skeleton span:first-child {
  margin: 0;
}
.cds--snippet.cds--skeleton span:nth-child(2) {
  width: 85%;
}
.cds--snippet.cds--skeleton span:nth-child(3) {
  width: 95%;
}

.cds--snippet--single.cds--skeleton .cds--snippet-container {
  padding-bottom: 0;
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--snippet__icon {
    fill: ButtonText;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--snippet--inline:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--snippet--single,
  .cds--snippet--multi {
    outline: 1px solid transparent;
  }
}

/* stylelint-enable */
.cds--text-input {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong);
  background-color: var(--cds-field);
  color: var(--cds-text-primary, #161616);
  font-family: inherit;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--text-input html {
  font-size: 100%;
}
.cds--text-input body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--text-input code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--text-input strong {
  font-weight: 600;
}
.cds--text-input:focus, .cds--text-input:active {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--text-input:focus, .cds--text-input:active {
    outline-style: dotted;
  }
}
.cds--text-input-wrapper svg[hidden] {
  display: none;
}

.cds--text-input--lg {
  height: 3rem;
}

.cds--text-input--sm {
  height: 2rem;
}

.cds--password-input {
  padding-right: 2.5rem;
}

.cds--text-input--sm.cds--password-input {
  padding-right: 2rem;
}

.cds--text-input--lg.cds--password-input {
  padding-right: 3rem;
}

.cds--text-input::-webkit-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}

.cds--text-input::-moz-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}

.cds--text-input::-ms-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}

.cds--text-input::placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}

.cds--text-input--light {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--text-input__field-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.cds--text-input__invalid-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  fill: var(--cds-support-error, #da1e28);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cds--text-input__invalid-icon--warning {
  fill: var(--cds-support-warning, #f1c21b);
}

.cds--text-input__invalid-icon--warning path:first-of-type {
  fill: #000000;
  opacity: 1;
}

.cds--text-input--password__visibility {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: visible;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.cds--text-input--password__visibility:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--text-input--password__visibility:focus {
    outline-style: dotted;
  }
}
.cds--text-input--password__visibility:focus {
  outline: 1px solid transparent;
}
.cds--text-input--password__visibility:focus svg {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--text-input--password__visibility:focus svg {
    outline-style: dotted;
  }
}
.cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after,
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  position: absolute;
  z-index: 6000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    display: inline-block;
  }
}
.cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after {
  -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after {
    -webkit-transition: none;
    transition: none;
  }
}
.cds--text-input--password__visibility.cds--tooltip--a11y::before, .cds--text-input--password__visibility.cds--tooltip--a11y::after {
  -webkit-transition: none;
  transition: none;
}
.cds--text-input--password__visibility::before {
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
}
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  color: inherit;
  opacity: 1;
  white-space: normal;
  word-break: break-word;
}
.cds--text-input--password__visibility::after,
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  z-index: 6000;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 1.5rem;
  max-width: 13rem;
  height: auto;
  padding: 0.1875rem 1rem;
  background-color: var(--cds-background-inverse, #393939);
  border-radius: 0.125rem;
  color: var(--cds-text-inverse, #ffffff);
  font-weight: 400;
  text-align: left;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    width: auto;
  }
}
@supports (-ms-accelerator: true) {
  .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    width: auto;
  }
}
@supports (-ms-ime-align: auto) {
  .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    width: auto;
  }
}
@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
  .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    border: 1px solid transparent;
  }
}
.cds--text-input--password__visibility::after {
  content: attr(aria-label);
}
.cds--text-input--password__visibility.cds--tooltip--a11y::after {
  content: none;
}
.cds--text-input--password__visibility.cds--tooltip--visible::before, .cds--text-input--password__visibility.cds--tooltip--visible::after, .cds--text-input--password__visibility:hover::before, .cds--text-input--password__visibility:hover::after, .cds--text-input--password__visibility:focus::before, .cds--text-input--password__visibility:focus::after {
  opacity: 1;
}
@-webkit-keyframes tooltip-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tooltip-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.cds--text-input--password__visibility.cds--tooltip--visible .cds--assistive-text,
.cds--text-input--password__visibility.cds--tooltip--visible + .cds--assistive-text, .cds--text-input--password__visibility:hover .cds--assistive-text,
.cds--text-input--password__visibility:hover + .cds--assistive-text, .cds--text-input--password__visibility:focus .cds--assistive-text,
.cds--text-input--password__visibility:focus + .cds--assistive-text {
  overflow: visible;
  margin: auto;
  clip: auto;
}
.cds--text-input--password__visibility.cds--tooltip--visible .cds--assistive-text,
.cds--text-input--password__visibility.cds--tooltip--visible + .cds--assistive-text, .cds--text-input--password__visibility.cds--tooltip--visible.cds--tooltip--a11y::before, .cds--text-input--password__visibility:hover .cds--assistive-text,
.cds--text-input--password__visibility:hover + .cds--assistive-text, .cds--text-input--password__visibility:hover.cds--tooltip--a11y::before, .cds--text-input--password__visibility:focus .cds--assistive-text,
.cds--text-input--password__visibility:focus + .cds--assistive-text, .cds--text-input--password__visibility:focus.cds--tooltip--a11y::before {
  -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--text-input--password__visibility.cds--tooltip--hidden .cds--assistive-text, .cds--text-input--password__visibility.cds--tooltip--hidden + .cds--assistive-text {
  overflow: hidden;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
}
.cds--text-input--password__visibility.cds--tooltip--hidden.cds--tooltip--a11y::before {
  -webkit-animation: none;
  animation: none;
  opacity: 0;
}
.cds--text-input--password__visibility .cds--assistive-text::after {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  width: 100%;
  height: 0.75rem;
  top: -0.75rem;
}
.cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after,
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  bottom: 0;
  left: 50%;
}
.cds--text-input--password__visibility::before {
  bottom: -0.5rem;
  border-width: 0 0.25rem 0.3125rem 0.25rem;
  border-color: transparent transparent var(--cds-background-inverse, #393939) transparent;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}
.cds--text-input--password__visibility::after,
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  bottom: -0.8125rem;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}

.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.5rem;
  height: 100%;
  min-height: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  -webkit-transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--text-input--sm + .cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger {
  width: 2rem;
}

.cds--text-input--lg + .cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger {
  width: 3rem;
}

.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg {
  fill: var(--cds-icon-secondary, #525252);
  -webkit-transition: fill 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: fill 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
  .cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg {
    fill: ButtonText;
  }
}

.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:focus {
    outline-style: dotted;
  }
}

.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:hover svg,
.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:focus svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--text-input--invalid,
.cds--text-input--warning {
  padding-right: 2.5rem;
}

.cds--text-input--invalid.cds--password-input {
  padding-right: 4rem;
}

.cds--text-input--invalid + .cds--text-input--password__visibility__toggle {
  right: 1rem;
}

.cds--password-input-wrapper .cds--text-input__invalid-icon {
  right: 2.5rem;
}

.cds--text-input:disabled ~ .cds--text-input--password__visibility__toggle.cds--tooltip__trigger {
  cursor: not-allowed;
}

.cds--text-input:disabled ~ .cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}
.cds--text-input:disabled ~ .cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg:hover {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-input__counter-alert {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
}

.cds--text-input:disabled {
  outline: 2px solid transparent;
  outline-offset: -2px;
  border-bottom: 1px solid transparent;
  background-color: var(--cds-field);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
  -webkit-text-fill-color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-input--light:disabled {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--text-input:disabled::-webkit-input-placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  opacity: 1;
}

.cds--text-input:disabled::-moz-placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  opacity: 1;
}

.cds--text-input:disabled::-ms-input-placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  opacity: 1;
}

.cds--text-input:disabled::placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  opacity: 1;
}

.cds--text-input--invalid {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media screen and (prefers-contrast) {
  .cds--text-input--invalid {
    outline-style: dotted;
  }
}
.cds--text-input--invalid .cds--text-input--password__visibility__toggle {
  right: 2.5rem;
}

.cds--skeleton.cds--text-input {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
}
.cds--skeleton.cds--text-input:hover, .cds--skeleton.cds--text-input:focus, .cds--skeleton.cds--text-input:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--skeleton.cds--text-input::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--skeleton.cds--text-input::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--form--fluid .cds--text-input-wrapper {
  position: relative;
  background: var(--cds-field);
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--form--fluid .cds--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.cds--form--fluid .cds--form__helper-text {
  display: none;
}

.cds--form--fluid .cds--text-input {
  min-height: 4rem;
  padding: 2rem 1rem 0.8125rem;
}

.cds--text-input__divider,
.cds--form--fluid .cds--text-input__divider {
  display: none;
}

.cds--form--fluid .cds--text-input--invalid,
.cds--form--fluid .cds--text-input--warning {
  border-bottom: none;
}

.cds--form--fluid .cds--text-input--invalid + .cds--text-input__divider,
.cds--form--fluid .cds--text-input--warning + .cds--text-input__divider {
  display: block;
  border-style: solid;
  border-color: var(--cds-border-subtle);
  border-bottom: none;
  margin: 0 1rem;
}

.cds--form--fluid .cds--text-input__invalid-icon {
  top: 5rem;
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid,
.cds--form--fluid .cds--text-input__field-wrapper--warning > .cds--text-input--warning {
  outline: none;
}

.cds--form--fluid .cds--text-input__field-wrapper--warning {
  border-bottom: 1px solid var(--cds-border-strong);
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid]:not(:focus) {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--form--fluid .cds--text-input__field-wrapper[data-invalid]:not(:focus) {
    outline-style: dotted;
  }
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid]:focus-within,
.cds--form--fluid .cds--text-input__field-wrapper--warning:focus-within {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--form--fluid .cds--text-input__field-wrapper[data-invalid]:focus-within,
  .cds--form--fluid .cds--text-input__field-wrapper--warning:focus-within {
    outline-style: dotted;
  }
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid:focus,
.cds--form--fluid .cds--text-input__field-wrapper--warning > .cds--text-input--warning:focus {
  outline: none;
}

.cds--text-input-wrapper.cds--text-input-wrapper--inline {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.cds--text-input-wrapper .cds--label--inline {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  margin: 0.8125rem 0 0 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.cds--text-input-wrapper .cds--label--inline--sm {
  margin-top: 0.5625rem;
}

.cds--text-input-wrapper .cds--label--inline--lg {
  margin-top: 1.0625rem;
}

.cds--text-input__label-helper-wrapper {
  max-width: 8rem;
  -webkit-box-flex: 2;
  -ms-flex: 2 1;
  flex: 2 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 1.5rem;
  overflow-wrap: break-word;
}

.cds--text-input-wrapper .cds--form__helper-text--inline {
  margin-top: 0.125rem;
}

.cds--text-input__field-outer-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cds--text-input__field-outer-wrapper--inline {
  -webkit-box-flex: 8;
  -ms-flex: 8 1;
  flex: 8 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cds--text-input-wrapper--inline .cds--form-requirement {
  display: block;
  overflow: visible;
  max-height: 12.5rem;
  font-weight: 400;
}

.cds--text-input-wrapper--inline--invalid .cds--form-requirement {
  color: var(--cds-text-error, #da1e28);
}

.cds--form--fluid .cds--text-input-wrapper--readonly,
.cds--text-input-wrapper--readonly .cds--text-input {
  background: transparent;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--btn.cds--btn--icon-only.cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg,
  .cds--btn.cds--btn--icon-only.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:hover svg {
    fill: ButtonText;
  }
}

.cds--text-input__label-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.cds--text-input__label-wrapper .cds--text-input__label-counter {
  -ms-flex-item-align: end;
  align-self: end;
}

.cds--tag {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  background-color: var(--cds-tag-background-gray, #e0e0e0);
  color: var(--cds-tag-color-gray, #393939);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-width: 2rem;
  max-width: 100%;
  min-height: 1.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  border-radius: 0.9375rem;
  cursor: default;
  vertical-align: middle;
  word-break: break-word;
}
.cds--tag.cds--tag--interactive:hover,
.cds--tag .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-gray, #c6c6c6);
}
.cds--tag:not(:first-child) {
  margin-left: 0;
}

.cds--tag--red {
  background-color: var(--cds-tag-background-red, #ffd7d9);
  color: var(--cds-tag-color-red, #750e13);
}
.cds--tag--red.cds--tag--interactive:hover,
.cds--tag--red .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-red, #ffb3b8);
}

.cds--tag--magenta {
  background-color: var(--cds-tag-background-magenta, #ffd6e8);
  color: var(--cds-tag-color-magenta, #740937);
}
.cds--tag--magenta.cds--tag--interactive:hover,
.cds--tag--magenta .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-magenta, #ffafd2);
}

.cds--tag--purple {
  background-color: var(--cds-tag-background-purple, #e8daff);
  color: var(--cds-tag-color-purple, #491d8b);
}
.cds--tag--purple.cds--tag--interactive:hover,
.cds--tag--purple .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-purple, #d4bbff);
}

.cds--tag--blue {
  background-color: var(--cds-tag-background-blue, #d0e2ff);
  color: var(--cds-tag-color-blue, #620b5b);
}
.cds--tag--blue.cds--tag--interactive:hover,
.cds--tag--blue .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-blue, #a6c8ff);
}

.cds--tag--cyan {
  background-color: var(--cds-tag-background-cyan, #bae6ff);
  color: var(--cds-tag-color-cyan, #003a6d);
}
.cds--tag--cyan.cds--tag--interactive:hover,
.cds--tag--cyan .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-cyan, #82cfff);
}

.cds--tag--teal {
  background-color: var(--cds-tag-background-teal, #9ef0f0);
  color: var(--cds-tag-color-teal, #004144);
}
.cds--tag--teal.cds--tag--interactive:hover,
.cds--tag--teal .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-teal, #3ddbd9);
}

.cds--tag--green {
  background-color: var(--cds-tag-background-green, #a7f0ba);
  color: var(--cds-tag-color-green, #044317);
}
.cds--tag--green.cds--tag--interactive:hover,
.cds--tag--green .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-green, #6fdc8c);
}

.cds--tag--gray {
  background-color: var(--cds-tag-background-gray, #e0e0e0);
  color: var(--cds-tag-color-gray, #393939);
}
.cds--tag--gray.cds--tag--interactive:hover,
.cds--tag--gray .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-gray, #c6c6c6);
}

.cds--tag--cool-gray {
  background-color: var(--cds-tag-background-cool-gray, #dde1e6);
  color: var(--cds-tag-color-cool-gray, #343a3f);
}
.cds--tag--cool-gray.cds--tag--interactive:hover,
.cds--tag--cool-gray .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-cool-gray, #c1c7cd);
}

.cds--tag--warm-gray {
  background-color: var(--cds-tag-background-warm-gray, #e5e0df);
  color: var(--cds-tag-color-warm-gray, #3c3838);
}
.cds--tag--warm-gray.cds--tag--interactive:hover,
.cds--tag--warm-gray .cds--tag__close-icon:hover {
  background-color: var(--cds-tag-hover-warm-gray, #cac5c4);
}

.cds--tag--high-contrast {
  background-color: var(--cds-background-inverse, #393939);
  color: var(--cds-text-inverse, #ffffff);
}
.cds--tag--high-contrast.cds--tag--interactive:hover,
.cds--tag--high-contrast .cds--tag__close-icon:hover {
  background-color: var(--cds-background-inverse-hover, #474747);
}

.cds--tag--outline {
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-primary, #161616);
  -webkit-box-shadow: 0 0 0 1px var(--cds-background-inverse, #393939);
  box-shadow: 0 0 0 1px var(--cds-background-inverse, #393939);
}
.cds--tag--outline.cds--tag--interactive:hover,
.cds--tag--outline .cds--tag__close-icon:hover {
  background-color: var(--cds-layer-hover);
}

.cds--tag--disabled,
.cds--tag--filter.cds--tag--disabled,
.cds--tag--interactive.cds--tag--disabled {
  background-color: var(--cds-layer);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cds--tag--disabled.cds--tag--interactive:hover,
.cds--tag--disabled .cds--tag__close-icon:hover,
.cds--tag--filter.cds--tag--disabled.cds--tag--interactive:hover,
.cds--tag--filter.cds--tag--disabled .cds--tag__close-icon:hover,
.cds--tag--interactive.cds--tag--disabled.cds--tag--interactive:hover,
.cds--tag--interactive.cds--tag--disabled .cds--tag__close-icon:hover {
  background-color: var(--cds-layer);
}
.cds--tag--disabled:hover,
.cds--tag--filter.cds--tag--disabled:hover,
.cds--tag--interactive.cds--tag--disabled:hover {
  cursor: not-allowed;
}

.cds--tag__label {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--tag--interactive:focus {
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-focus, #620b5b);
  box-shadow: inset 0 0 0 1px var(--cds-focus, #620b5b);
  outline: none;
}

.cds--tag--interactive:hover {
  cursor: pointer;
}

.cds--tag--filter {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  cursor: pointer;
}
.cds--tag--filter:hover {
  outline: none;
}

.cds--tag--interactive {
  -webkit-transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9);
}

.cds--tag__close-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  border: 0;
  margin: 0 0 0 0.125rem;
  background-color: transparent;
  border-radius: 50%;
  color: currentColor;
  cursor: pointer;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-box-shadow 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-box-shadow 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-box-shadow 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--tag__close-icon svg {
  fill: currentColor;
}

.cds--tag__custom-icon {
  width: 1rem;
  height: 1rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 0;
  border: 0;
  margin-right: 0.25rem;
  background-color: transparent;
  color: currentColor;
  outline: none;
}
.cds--tag__custom-icon svg {
  fill: currentColor;
}

.cds--tag--disabled .cds--tag__close-icon {
  cursor: not-allowed;
}

.cds--tag__close-icon:focus {
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-focus, #620b5b);
  box-shadow: inset 0 0 0 1px var(--cds-focus, #620b5b);
  outline: none;
}

.cds--tag--high-contrast .cds--tag__close-icon:focus {
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-focus-inverse, #ffffff);
  box-shadow: inset 0 0 0 1px var(--cds-focus-inverse, #ffffff);
}

.cds--tag--filter.cds--tag--disabled .cds--tag__close-icon:hover {
  background-color: transparent;
}

.cds--tag--filter.cds--tag--disabled svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--tag--sm {
  min-height: 1.125rem;
  padding: 0 0.5rem;
}

.cds--tag--sm.cds--tag--filter {
  padding-right: 0;
}

.cds--tag--sm .cds--tag__close-icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.3125rem;
}

.cds--tag.cds--skeleton {
  background-color: var(--cds-skeleton-background, #e8e8e8);
  color: var(--cds-text-primary, #161616);
  overflow: hidden;
  width: 3.75rem;
}
.cds--tag.cds--skeleton.cds--tag--interactive:hover,
.cds--tag.cds--skeleton .cds--tag__close-icon:hover {
  background-color: var(--cds-skeleton-background, #e8e8e8);
}
@media not all and (-webkit-min-device-pixel-ratio: 0), not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .cds--tag.cds--skeleton {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--tag {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--tag__close-icon svg,
  .cds--tag__custom-icon svg {
    fill: ButtonText;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--tag__close-icon:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

/* stylelint-enable */
.cds--list-box__wrapper--inline {
  display: inline-grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 0.25rem;
  grid-template: auto auto/auto auto;
}
.cds--list-box__wrapper--inline .cds--label {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}
.cds--list-box__wrapper--inline .cds--label,
.cds--list-box__wrapper--inline .cds--form__helper-text,
.cds--list-box__wrapper--inline .cds--form-requirement {
  margin: 0;
}
.cds--list-box__wrapper--inline .cds--form__helper-text {
  max-width: none;
}
.cds--list-box__wrapper--inline .cds--form-requirement {
  grid-column: 2;
}

.cds--list-box {
  position: relative;
  width: 100%;
  height: 2.5rem;
  max-height: 2.5rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong);
  background-color: var(--cds-field);
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
  -webkit-transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--list-box html {
  font-size: 100%;
}
.cds--list-box body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--list-box code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--list-box strong {
  font-weight: 600;
}
.cds--list-box:hover {
  background-color: var(--cds-field-hover);
}

.cds--list-box--lg {
  height: 3rem;
  max-height: 3rem;
}

.cds--list-box--sm {
  height: 2rem;
  max-height: 2rem;
}

.cds--list-box--expanded {
  border-bottom-color: var(--cds-border-subtle);
}

.cds--list-box--expanded:hover {
  background-color: var(--cds-field);
}

.cds--list-box--expanded:hover.cds--list-box--light:hover {
  background-color: var(--cds-field);
}

.cds--list-box .cds--text-input {
  min-width: 0;
  height: 100%;
}

.cds--list-box__invalid-icon {
  position: absolute;
  top: 50%;
  right: 2.5rem;
  fill: var(--cds-support-error, #da1e28);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cds--list-box__invalid-icon--warning {
  fill: var(--cds-support-warning, #f1c21b);
}

.cds--list-box__invalid-icon--warning path[fill] {
  fill: #000000;
  opacity: 1;
}

.cds--list-box[data-invalid] .cds--list-box__field,
.cds--list-box.cds--list-box--warning .cds--list-box__field {
  padding-right: 4rem;
  border-bottom: 0;
}

.cds--list-box[data-invalid].cds--list-box--inline .cds--list-box__field,
.cds--list-box.cds--list-box--warning.cds--list-box--inline .cds--list-box__field {
  padding-right: 3.5rem;
}

.cds--list-box--light {
  background-color: var(--cds-field);
}
.cds--list-box--light:hover {
  background-color: var(--cds-field-hover);
}

.cds--list-box--light .cds--list-box__menu {
  background: var(--cds-layer);
}

.cds--list-box--light .cds--list-box__menu-item__option {
  border-top-color: var(--cds-border-subtle);
}

.cds--list-box--light.cds--list-box--expanded {
  border-bottom-color: transparent;
}

.cds--list-box--disabled:hover {
  background-color: var(--cds-field);
}

.cds--list-box--light.cds--list-box--disabled {
  background-color: var(--cds-field);
}

.cds--list-box--disabled,
.cds--list-box--disabled .cds--list-box__field,
.cds--list-box--disabled .cds--list-box__field:focus {
  border-bottom-color: transparent;
  outline: none;
}

.cds--list-box--disabled .cds--list-box__label,
.cds--list-box--disabled.cds--list-box--inline .cds--list-box__label {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--list-box--disabled .cds--list-box__menu-icon > svg,
.cds--list-box--disabled .cds--list-box__selection > svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--list-box--disabled,
.cds--list-box--disabled .cds--list-box__field,
.cds--list-box--disabled .cds--list-box__menu-icon {
  cursor: not-allowed;
}

.cds--list-box--disabled .cds--list-box__menu-item,
.cds--list-box--disabled .cds--list-box__menu-item:hover,
.cds--list-box--disabled .cds--list-box__menu-item--highlighted {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  text-decoration: none;
}

.cds--list-box--disabled .cds--list-box__selection:hover {
  cursor: not-allowed;
}

.cds--list-box--disabled.cds--list-box[data-invalid] .cds--list-box__field {
  padding-right: 3rem;
}

.cds--list-box--disabled.cds--list-box[data-invalid].cds--list-box--inline .cds--list-box__field {
  padding-right: 2rem;
}

.cds--list-box.cds--list-box--inline {
  border-width: 0;
  background-color: transparent;
}
.cds--list-box.cds--list-box--inline:hover {
  background-color: var(--cds-layer-hover);
}

.cds--list-box.cds--list-box--inline.cds--list-box--expanded {
  border-bottom-width: 0;
}

.cds--list-box.cds--list-box--inline.cds--list-box--expanded .cds--list-box__field[aria-expanded=true] {
  border-width: 0;
}

.cds--list-box.cds--list-box--inline.cds--list-box--expanded:hover,
.cds--list-box.cds--list-box--inline.cds--list-box--disabled:hover {
  background-color: transparent;
}

.cds--list-box.cds--list-box--inline .cds--list-box__field {
  padding: 0 2rem 0 0.5rem;
}

.cds--list-box.cds--list-box--inline .cds--list-box__menu-icon {
  right: 0.5rem;
}

.cds--list-box.cds--list-box--inline .cds--list-box__invalid-icon {
  right: 2rem;
}

.cds--list-box--inline .cds--list-box__label {
  color: var(--cds-text-primary, #161616);
}

.cds--list-box--inline .cds--list-box__field {
  height: 100%;
}

.cds--dropdown--inline .cds--list-box__field {
  max-width: 30rem;
}

.cds--dropdown--inline .cds--list-box__menu {
  min-width: 18rem;
  max-width: 30rem;
}

.cds--list-box__field {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
  height: calc(100% + 1px);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 3rem 0 1rem;
  cursor: pointer;
  outline: none;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
}
.cds--list-box__field *,
.cds--list-box__field *::before,
.cds--list-box__field *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--list-box__field::-moz-focus-inner {
  border: 0;
}

.cds--list-box__field:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--list-box__field:focus {
    outline-style: dotted;
  }
}

.cds--list-box__field[disabled] {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  outline: none;
}

.cds--list-box__field .cds--text-input {
  padding-right: 4.5rem;
}

.cds--list-box[data-invalid] .cds--list-box__field .cds--text-input,
.cds--list-box--warning .cds--list-box__field .cds--text-input {
  padding-right: 6.125rem;
}

.cds--list-box[data-invalid] .cds--list-box__field .cds--text-input + .cds--list-box__invalid-icon,
.cds--list-box--warning .cds--list-box__field .cds--text-input + .cds--list-box__invalid-icon {
  right: 4.125rem;
}

.cds--list-box__field .cds--text-input--empty {
  padding-right: 3rem;
}

.cds--list-box[data-invalid] .cds--list-box__field .cds--text-input--empty,
.cds--list-box--warning .cds--list-box__field .cds--text-input--empty {
  padding-right: carbon--mini-units(9);
}

.cds--list-box[data-invalid] .cds--list-box__field .cds--text-input--empty + .cds--list-box__invalid-icon,
.cds--list-box--warning .cds--list-box__field .cds--text-input--empty + .cds--list-box__invalid-icon {
  right: 2.5rem;
}

.cds--list-box__label {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  overflow: hidden;
  color: var(--cds-text-primary, #161616);
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.cds--list-box__menu-icon {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  position: absolute;
  right: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  -webkit-transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--list-box__menu-icon *,
.cds--list-box__menu-icon *::before,
.cds--list-box__menu-icon *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--list-box__menu-icon::-moz-focus-inner {
  border: 0;
}

.cds--list-box__menu-icon > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--list-box__menu-icon--open {
  width: 1.5rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cds--list-box__selection {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  position: absolute;
  top: 50%;
  /* to preserve .5rem space between icons according to spec top/transform used to center the combobox clear selection icon in IE11 */
  right: 2.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cds--list-box__selection *,
.cds--list-box__selection *::before,
.cds--list-box__selection *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--list-box__selection::-moz-focus-inner {
  border: 0;
}
.cds--list-box__selection:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--list-box__selection:focus {
    outline-style: dotted;
  }
}
.cds--list-box__selection:focus:hover {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--list-box__selection:focus:hover {
    outline-style: dotted;
  }
}

.cds--list-box__selection > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--list-box--disabled .cds--list-box__selection:focus {
  outline: none;
}

.cds--list-box__selection--multi {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  position: static;
  top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 1.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem;
  padding-right: 0.125rem;
  margin-right: 0.625rem;
  background-color: var(--cds-background-inverse, #393939);
  border-radius: 0.75rem;
  color: var(--cds-text-inverse, #ffffff);
  line-height: 0;
  -webkit-transform: none;
  transform: none;
}

.cds--list-box__selection--multi > svg {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.125rem;
  margin-left: 0.25rem;
  fill: var(--cds-icon-inverse, #ffffff);
}
.cds--list-box__selection--multi > svg:hover {
  background-color: var(--cds-button-secondary-hover, #474747);
  border-radius: 50%;
}

.cds--list-box--disabled .cds--list-box__selection--multi {
  background-color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  color: var(--cds-layer);
}
.cds--list-box--disabled .cds--list-box__selection--multi.cds--tag--interactive:hover,
.cds--list-box--disabled .cds--list-box__selection--multi .cds--tag__close-icon:hover {
  background-color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}
.cds--list-box--disabled .cds--list-box__selection--multi > svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}
.cds--list-box--disabled .cds--list-box__selection--multi > svg:hover {
  background-color: initial;
}

.cds--list-box__selection--multi:hover {
  outline: none;
}

.cds--list-box__menu {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  position: absolute;
  z-index: 9100;
  right: 0;
  left: 0;
  width: 100%;
  background-color: var(--cds-layer);
  overflow-y: auto;
  -webkit-transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--list-box__menu:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--list-box__menu:focus {
    outline-style: dotted;
  }
}

.cds--list-box .cds--list-box__field[aria-expanded=false] + .cds--list-box__menu {
  max-height: 0;
}

.cds--list-box--expanded .cds--list-box__menu {
  max-height: 13.75rem;
}

.cds--list-box--expanded.cds--list-box--lg .cds--list-box__menu {
  max-height: 16.5rem;
}

.cds--list-box--expanded.cds--list-box--sm .cds--list-box__menu {
  max-height: 11rem;
}

.cds--list-box__menu-item {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  position: relative;
  height: 2.5rem;
  color: var(--cds-text-secondary, #525252);
  cursor: pointer;
  -webkit-transition: background 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cds--list-box__menu-item:hover {
  background-color: var(--cds-layer-hover);
}
.cds--list-box__menu-item:active {
  background-color: var(--cds-layer-selected);
}

.cds--list-box--light .cds--list-box__menu-item:hover {
  background-color: var(--cds-layer-hover);
}

.cds--list-box--sm .cds--list-box__menu-item {
  height: 2rem;
}

.cds--list-box--lg .cds--list-box__menu-item {
  height: 3rem;
}

.cds--list-box--disabled .cds--list-box__menu-item:hover {
  background-color: transparent;
}

.cds--list-box--light .cds--list-box__menu-item:active {
  background-color: var(--cds-layer-selected);
}

.cds--list-box--disabled .cds--list-box__menu-item__option:hover {
  border-top-color: var(--cds-border-subtle);
}

.cds--list-box__menu-item:first-of-type .cds--list-box__menu-item__option {
  border-top-color: transparent;
}

.cds--list-box__menu-item:hover .cds--list-box__menu-item__option {
  color: var(--cds-text-primary, #161616);
}

.cds--list-box__menu-item:hover + .cds--list-box__menu-item .cds--list-box__menu-item__option {
  border-top-color: transparent;
}

.cds--list-box--disabled .cds--list-box__menu-item:hover + .cds--list-box__menu-item .cds--list-box__menu-item__option {
  border-top-color: var(--cds-border-subtle);
}

.cds--list-box__menu-item__option {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  overflow: hidden;
  height: 2.5rem;
  padding: 0.6875rem 0;
  padding-right: 1.5rem;
  border-top: 1px solid transparent;
  border-top-color: var(--cds-border-subtle);
  border-bottom: 1px solid transparent;
  margin: 0 1rem;
  color: var(--cds-text-secondary, #525252);
  font-weight: normal;
  line-height: 1rem;
  text-decoration: none;
  text-overflow: ellipsis;
  -webkit-transition: border-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: border-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  white-space: nowrap;
}
.cds--list-box__menu-item__option:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  padding: 0.6875rem 1rem;
  border-color: transparent;
  margin: 0;
}
@media screen and (prefers-contrast) {
  .cds--list-box__menu-item__option:focus {
    outline-style: dotted;
  }
}
.cds--list-box__menu-item__option:hover {
  border-color: transparent;
  color: var(--cds-text-primary, #161616);
}

.cds--list-box--sm .cds--list-box__menu-item__option {
  height: 2rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}

.cds--list-box--lg .cds--list-box__menu-item__option {
  height: 3rem;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.cds--list-box--disabled .cds--list-box__menu-item:hover .cds--list-box__menu-item__option,
.cds--list-box--disabled .cds--list-box__menu-item__option {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--list-box__menu-item[disabled],
.cds--list-box__menu-item[disabled] *,
.cds--list-box__menu-item[disabled] .cds--list-box__menu-item__option,
.cds--list-box__menu-item[disabled]:hover {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
  outline: none;
}

.cds--list-box__menu-item[disabled]:hover {
  background-color: revert;
}

.cds--list-box__menu-item[disabled] .cds--checkbox-label::before {
  border-color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--list-box__menu-item[disabled] .cds--list-box__menu-item__option {
  border-top-color: var(--cds-border-subtle);
}

.cds--list-box__menu-item[disabled]:hover + .cds--list-box__menu-item .cds--list-box__menu-item__option {
  border-top-color: var(--cds-border-subtle);
}

.cds--list-box.cds--list-box--inline .cds--list-box__menu-item__option {
  margin: 0 0.5rem;
}
.cds--list-box.cds--list-box--inline .cds--list-box__menu-item__option:focus {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin: 0;
}

.cds--list-box__menu-item--highlighted {
  border-color: transparent;
  background-color: var(--cds-layer-selected);
  color: var(--cds-text-primary, #161616);
}

.cds--list-box__menu-item--highlighted .cds--list-box__menu-item__option,
.cds--list-box__menu-item--highlighted + .cds--list-box__menu-item .cds--list-box__menu-item__option {
  border-top-color: transparent;
}

.cds--list-box__menu-item--highlighted .cds--list-box__menu-item__option {
  color: var(--cds-text-primary, #161616);
}

.cds--list-box__menu-item--active {
  border-bottom-color: var(--cds-layer-selected);
  background-color: var(--cds-layer-selected);
  color: var(--cds-text-primary, #161616);
}

.cds--list-box--light .cds--list-box__menu-item--active {
  border-bottom-color: var(--cds-layer-selected);
  background-color: var(--cds-layer-selected);
}

.cds--list-box__menu-item--active:hover {
  border-bottom-color: var(--cds-layer-selected-hover);
  background-color: var(--cds-layer-selected-hover);
}

.cds--list-box__menu-item--active .cds--list-box__menu-item__option {
  color: var(--cds-text-primary, #161616);
}

.cds--list-box__menu-item--active + .cds--list-box__menu-item > .cds--list-box__menu-item__option {
  border-top-color: transparent;
}

.cds--list-box__menu-item__selected-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  display: none;
  fill: var(--cds-icon-primary, #161616);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cds--list-box--inline .cds--list-box__menu-item__selected-icon {
  right: 0.5rem;
}

.cds--list-box__menu-item--active .cds--list-box__menu-item__selected-icon {
  display: block;
}

.cds--list-box__menu-item .cds--checkbox-label {
  width: 100%;
}

.cds--list-box__menu-item .cds--checkbox-label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--list-box--up .cds--list-box__menu {
  bottom: 2.5rem;
}

.cds--list-box--up.cds--dropdown--sm .cds--list-box__menu,
.cds--list-box--up.cds--list-box--sm .cds--list-box__menu,
.cds--list-box--up .cds--list-box--sm .cds--list-box__menu {
  bottom: 2rem;
}

.cds--list-box--up.cds--dropdown--lg .cds--list-box__menu,
.cds--list-box--up.cds--list-box--lg .cds--list-box__menu,
.cds--list-box--up .cds--list-box--lg .cds--list-box__menu {
  bottom: 3rem;
}

.cds--list-box input[role=combobox],
.cds--list-box input[type=text] {
  min-width: 0;
  background-color: inherit;
  text-overflow: ellipsis;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--list-box__field,
  .cds--list-box__menu,
  .cds--multi-select .cds--tag--filter {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--list-box__field:focus,
  .cds--multi-select .cds--tag__close-icon:focus,
  .cds--list-box__menu-item--highlighted .cds--list-box__menu-item__option {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--list-box__menu-icon > svg,
  .cds--list-box__selection > svg,
  .cds--list-box__selection--multi > svg {
    fill: ButtonText;
  }
}

.cds--combo-box:hover {
  background-color: var(--cds-field);
}

.cds--combo-box.cds--list-box--light:hover {
  background-color: var(--cds-field);
}

.cds--combo-box .cds--text-input::-ms-clear {
  display: none;
}

.cds--combo-box.cds--list-box--expanded .cds--text-input {
  border-bottom-color: var(--cds-border-subtle);
}

.cds--combo-box .cds--list-box__field,
.cds--combo-box.cds--list-box[data-invalid] .cds--list-box__field,
.cds--combo-box.cds--list-box--warning .cds--list-box__field,
.cds--combo-box.cds--list-box--disabled.cds--list-box[data-invalid] .cds--list-box__field,
.cds--combo-box.cds--list-box--disabled.cds--list-box--warning .cds--list-box__field {
  padding: 0;
}

.cds--combo-box--readonly,
.cds--combo-box--readonly:hover {
  background-color: transparent;
}

.cds--combo-box--readonly .cds--list-box__menu-icon,
.cds--combo-box--readonly .cds--list-box__selection {
  cursor: default;
}

.cds--combo-box--readonly .cds--list-box__menu-icon svg,
.cds--combo-box--readonly .cds--list-box__selection svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--contained-list__header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-inline: 1rem;
}

.cds--contained-list__label {
  width: 100%;
}

.cds--contained-list--on-page.cds--contained-list--sm .cds--contained-list__header {
  height: 2rem;
}

.cds--contained-list--sm .cds--contained-list-item__content,
.cds--contained-list--sm .cds--contained-list-item--clickable .cds--contained-list-item__content {
  min-height: 2rem;
  padding: calc(0.5rem - 0.125rem) 1rem;
}

.cds--contained-list--on-page.cds--contained-list--md .cds--contained-list__header {
  height: 2.5rem;
}

.cds--contained-list--md .cds--contained-list-item__content,
.cds--contained-list--md .cds--contained-list-item--clickable .cds--contained-list-item__content {
  min-height: 2.5rem;
  padding: calc(0.75rem - 0.125rem) 1rem;
}

.cds--contained-list--on-page.cds--contained-list--lg .cds--contained-list__header {
  height: 3rem;
}

.cds--contained-list--lg .cds--contained-list-item__content,
.cds--contained-list--lg .cds--contained-list-item--clickable .cds--contained-list-item__content {
  min-height: 3rem;
  padding: calc(1rem - 0.125rem) 1rem;
}

.cds--contained-list--on-page.cds--contained-list--xl .cds--contained-list__header {
  height: 4rem;
}

.cds--contained-list--xl .cds--contained-list-item__content,
.cds--contained-list--xl .cds--contained-list-item--clickable .cds--contained-list-item__content {
  min-height: 4rem;
  padding: calc(1.5rem - 0.125rem) 1rem;
}

.cds--contained-list--on-page + .cds--contained-list--on-page {
  -webkit-margin-before: 1rem;
  margin-block-start: 1rem;
}

.cds--contained-list--on-page .cds--contained-list__header {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  border-bottom: 1px solid var(--cds-border-subtle);
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-primary, #161616);
}

.cds--layer-two .cds--contained-list--on-page .cds--contained-list__header {
  background-color: var(--cds-layer-01, #f4f4f4);
}

.cds--layer-three .cds--contained-list--on-page .cds--contained-list__header {
  background-color: var(--cds-layer-02, #ffffff);
}

.cds--contained-list--disclosed .cds--contained-list__header {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  height: 2rem;
  background-color: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
}

.cds--contained-list-item {
  position: relative;
}

.cds--contained-list-item:not(:first-of-type) {
  margin-top: -1px;
}

.cds--contained-list-item--clickable .cds--contained-list-item__content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  text-align: start;
  -webkit-transition: background-color 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--contained-list-item--clickable .cds--contained-list-item__content *,
.cds--contained-list-item--clickable .cds--contained-list-item__content *::before,
.cds--contained-list-item--clickable .cds--contained-list-item__content *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--contained-list-item--clickable .cds--contained-list-item__content::-moz-focus-inner {
  border: 0;
}

.cds--contained-list-item__content,
.cds--contained-list-item--clickable .cds--contained-list-item__content {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  color: var(--cds-text-primary, #161616);
}

.cds--contained-list-item:not(:last-of-type)::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: var(--cds-border-subtle);
  content: "";
}

.cds--contained-list--inset-rulers .cds--contained-list-item:not(:last-of-type)::before {
  right: 1rem;
  left: 1rem;
}

.cds--contained-list-item--clickable .cds--contained-list-item__content:not(:disabled):hover {
  background-color: var(--cds-layer-hover);
}

.cds--contained-list-item--clickable .cds--contained-list-item__content:not(:disabled):active {
  background-color: var(--cds-layer-active);
}

.cds--contained-list-item--clickable .cds--contained-list-item__content:disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--contained-list-item--clickable .cds--contained-list-item__content:focus {
  outline: none;
}

.cds--contained-list-item--clickable .cds--contained-list-item__content:focus::after {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
@media screen and (prefers-contrast) {
  .cds--contained-list-item--clickable .cds--contained-list-item__content:focus::after {
    outline-style: dotted;
  }
}

.cds--contained-list-item--with-action .cds--contained-list-item__content {
  -webkit-padding-end: 4rem;
  padding-inline-end: 4rem;
}

.cds--contained-list__action,
.cds--contained-list-item__action {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  pointer-events: none;
}

.cds--contained-list__action > *,
.cds--contained-list-item__action > * {
  pointer-events: all;
}

.cds--contained-list-item--with-icon .cds--contained-list-item__content {
  display: grid;
  -webkit-column-gap: 0.75rem;
  -moz-column-gap: 0.75rem;
  column-gap: 0.75rem;
  grid-template-columns: 1rem 1fr;
}

.cds--contained-list-item__icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-top: 0.125rem;
}

.cds--content-switcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 2.5rem;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.cds--content-switcher--sm {
  height: 2rem;
}

.cds--content-switcher--lg {
  height: 3rem;
}

.cds--content-switcher-btn {
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  border-top: 0.0625rem solid var(--cds-border-inverse, #161616);
  border-bottom: 0.0625rem solid var(--cds-border-inverse, #161616);
  margin: 0;
  background-color: transparent;
  color: var(--cds-text-secondary, #525252);
  text-align: left;
  text-decoration: none;
  -webkit-transition: all 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  white-space: nowrap;
}
.cds--content-switcher-btn html {
  font-size: 100%;
}
.cds--content-switcher-btn body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--content-switcher-btn code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--content-switcher-btn strong {
  font-weight: 600;
}
.cds--content-switcher-btn::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--cds-layer-selected-inverse, #161616);
  content: "";
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition: all 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media (prefers-reduced-motion: reduce) {
  .cds--content-switcher-btn::after {
    -webkit-transition: none;
    transition: none;
  }
}
.cds--content-switcher-btn:disabled::after {
  display: none;
}
.cds--content-switcher-btn:focus {
  z-index: 3;
  border-color: var(--cds-focus, #620b5b);
  -webkit-box-shadow: inset 0 0 0 2px var(--cds-focus, #620b5b), inset 0 0 0 3px var(--cds-focus-inset, #ffffff);
  box-shadow: inset 0 0 0 2px var(--cds-focus, #620b5b), inset 0 0 0 3px var(--cds-focus-inset, #ffffff);
}
.cds--content-switcher-btn:focus::after {
  -webkit-clip-path: inset(3px 3px 3px 3px);
  clip-path: inset(3px 3px 3px 3px);
}
.cds--content-switcher-btn:hover {
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
}
.cds--content-switcher-btn:hover, .cds--content-switcher-btn:active {
  z-index: 3;
  background-color: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}
.cds--content-switcher-btn:disabled {
  border-color: var(--cds-border-disabled, #c6c6c6);
  background-color: transparent;
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}
.cds--content-switcher-btn:disabled:hover {
  cursor: not-allowed;
}
.cds--content-switcher-btn:disabled:first-child, .cds--content-switcher-btn:disabled:last-child {
  border-color: var(--cds-border-disabled, #c6c6c6);
}

.cds--content-switcher-btn:first-child {
  border-left: 0.0625rem solid var(--cds-border-inverse, #161616);
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.cds--content-switcher-btn:last-child {
  border-right: 0.0625rem solid var(--cds-border-inverse, #161616);
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.cds--content-switcher--selected,
.cds--content-switcher--selected:first-child,
.cds--content-switcher--selected:last-child {
  border: 0;
}

.cds--content-switcher-btn::before {
  position: absolute;
  z-index: 2;
  left: 0;
  display: block;
  width: 0.0625rem;
  height: 1rem;
  background-color: var(--cds-border-subtle);
  content: "";
}

.cds--content-switcher-btn:first-of-type::before {
  display: none;
}

.cds--content-switcher-btn:focus::before,
.cds--content-switcher-btn:focus + .cds--content-switcher-btn::before,
.cds--content-switcher-btn:hover::before,
.cds--content-switcher-btn:hover + .cds--content-switcher-btn::before,
.cds--content-switcher--selected::before,
.cds--content-switcher--selected + .cds--content-switcher-btn::before {
  background-color: transparent;
}

.cds--content-switcher-btn:disabled::before,
.cds--content-switcher-btn:disabled:hover + .cds--content-switcher-btn:disabled::before {
  background-color: var(--cds-border-disabled, #c6c6c6);
}

.cds--content-switcher-btn.cds--content-switcher--selected:disabled + .cds--content-switcher-btn::before,
.cds--content-switcher-btn.cds--content-switcher--selected:disabled:hover + .cds--content-switcher-btn::before {
  background-color: transparent;
}

.cds--content-switcher__icon {
  fill: var(--cds-icon-secondary, #525252);
  -webkit-transition: fill 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: fill 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--content-switcher__icon + span {
  margin-left: 0.5rem;
}

.cds--content-switcher__label {
  z-index: 1;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--content-switcher-btn:hover .cds--content-switcher__icon,
.cds--content-switcher-btn:focus .cds--content-switcher__icon {
  fill: var(--cds-icon-primary, #161616);
}

.cds--content-switcher-btn.cds--content-switcher--selected {
  z-index: 3;
  background-color: var(--cds-layer-selected-inverse, #161616);
  color: var(--cds-text-inverse, #ffffff);
}
.cds--content-switcher-btn.cds--content-switcher--selected:disabled {
  background-color: var(--cds-layer-selected-disabled, #8d8d8d);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}
.cds--content-switcher-btn.cds--content-switcher--selected::after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.cds--content-switcher-btn.cds--content-switcher--selected .cds--content-switcher__icon {
  fill: var(--cds-icon-inverse, #ffffff);
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--content-switcher-btn:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

.cds--data-table-container {
  position: relative;
  padding-top: 0.125rem;
}

.cds--data-table-content {
  display: block;
  overflow-x: auto;
}

.cds--data-table-header {
  padding: 1rem 0 1.5rem 1rem;
  background: var(--cds-layer);
}

.cds--data-table-header__title {
  font-size: var(--cds-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-heading-03-font-weight, 400);
  line-height: var(--cds-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-heading-03-letter-spacing, 0);
  color: var(--cds-text-primary, #161616);
}

.cds--data-table-header__description {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  color: var(--cds-text-secondary, #525252);
}
@media (min-width: 42rem) {
  .cds--data-table-header__description {
    max-width: 50ch;
  }
}
@media (min-width: 66rem) {
  .cds--data-table-header__description {
    max-width: 80ch;
  }
}

.cds--data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.cds--data-table thead {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  background-color: var(--cds-layer-accent);
}

.cds--data-table tbody {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  width: 100%;
  background-color: var(--cds-layer);
}

.cds--data-table tr {
  width: 100%;
  height: 3rem;
  border: none;
}

.cds--data-table tbody tr,
.cds--data-table tbody tr td,
.cds--data-table tbody tr th {
  -webkit-transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9);
}

.cds--data-table tbody tr:hover {
  background: var(--cds-layer-hover);
}

.cds--data-table tbody tr:hover td,
.cds--data-table tbody tr:hover th {
  border-top: 1px solid var(--cds-layer-hover);
  border-bottom: 1px solid var(--cds-layer-hover);
  background: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}

.cds--data-table tr:hover .cds--link {
  color: var(--cds-link-secondary, #0043ce);
}

.cds--data-table tr:hover .cds--link--disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--data-table th,
.cds--data-table td {
  text-align: left;
  vertical-align: middle;
}

.cds--data-table th[align=right],
.cds--data-table td[align=right] {
  text-align: right;
}

.cds--data-table th[align=center],
.cds--data-table td[align=center] {
  text-align: center;
}

.cds--data-table th {
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: var(--cds-layer-accent);
  color: var(--cds-text-primary, #161616);
}

.cds--data-table th:last-of-type {
  position: static;
  width: auto;
}

.cds--data-table .cds--table-header-label {
  text-align: left;
}

.cds--data-table td,
.cds--data-table tbody th {
  padding-right: 1rem;
  padding-left: 1rem;
  border-top: 1px solid var(--cds-layer);
  border-bottom: 1px solid var(--cds-border-subtle);
  background: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
}
.cds--data-table td + td:first-of-type,
.cds--data-table tbody th + td:first-of-type {
  padding-left: 0.75rem;
}

@supports (-moz-appearance: none) {
  .cds--data-table td {
    background-clip: padding-box;
  }
}
.cds--data-table .cds--list-box input[role=combobox],
.cds--data-table .cds--list-box input[type=text],
.cds--data-table .cds--dropdown,
.cds--data-table .cds--list-box,
.cds--data-table .cds--number input[type=number],
.cds--data-table .cds--number__control-btn::before,
.cds--data-table .cds--number__control-btn::after,
.cds--data-table .cds--text-input,
.cds--data-table .cds--select-input {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--data-table td.cds--table-column-menu .cds--overflow-menu[aria-expanded=false]:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--data-table td.cds--table-column-menu .cds--overflow-menu[aria-expanded=false]:focus {
    outline-style: dotted;
  }
}

.cds--data-table td.cds--table-column-menu .cds--overflow-menu[aria-expanded=true]:focus {
  outline: none;
}

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cds--data-table td.cds--table-column-menu .cds--overflow-menu .cds--overflow-menu__icon {
    opacity: 0;
  }
}
.cds--data-table td.cds--table-column-menu .cds--overflow-menu.cds--overflow-menu--open .cds--overflow-menu__icon {
  opacity: 1;
}

.cds--data-table.cds--data-table--visible-overflow-menu td.cds--table-column-menu .cds--overflow-menu .cds--overflow-menu__icon,
.cds--data-table td.cds--table-column-menu .cds--overflow-menu:hover .cds--overflow-menu__icon,
.cds--data-table td.cds--table-column-menu .cds--overflow-menu:focus .cds--overflow-menu__icon,
.cds--data-table tr:hover td.cds--table-column-menu .cds--overflow-menu .cds--overflow-menu__icon {
  opacity: 1;
}

.cds--table-row--menu-option .cds--overflow-menu-options__btn .cds--overflow-menu-options__option-content svg {
  position: relative;
  top: 0.1875rem;
  margin-right: 0.5rem;
}

.cds--data-table .cds--overflow-menu:hover,
.cds--data-table .cds--overflow-menu__trigger:hover {
  background-color: var(--cds-layer-selected-hover);
}

.cds--data-table--selected .cds--overflow-menu:hover,
.cds--data-table--selected .cds--overflow-menu__trigger:hover {
  background-color: var(--cds-layer-hover);
}

.cds--data-table--selected .cds--link:not(.cds--link--disabled) {
  color: var(--cds-link-secondary, #0043ce);
}

.cds--data-table--xs td.cds--table-column-menu,
.cds--data-table--sm td.cds--table-column-menu {
  height: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.cds--data-table--sm td.cds--table-column-menu {
  height: 2rem;
}

.cds--data-table--md td.cds--table-column-menu {
  height: 2.5rem;
}

.cds--data-table--xl .cds--table-column-menu {
  padding-top: 0.5rem;
}

.cds--data-table--zebra tbody tr:not(.cds--parent-row):nth-child(odd) td {
  border-bottom: 1px solid var(--cds-layer);
}

.cds--data-table--zebra tbody tr:not(.cds--parent-row):nth-child(even) td {
  border-top: 1px solid var(--cds-layer-accent);
  border-bottom: 1px solid var(--cds-layer-accent);
  background-color: var(--cds-layer-accent);
}

.cds--data-table--zebra tbody tr:not(.cds--parent-row):hover td {
  border-top: 1px solid var(--cds-layer-hover);
  border-bottom: 1px solid var(--cds-layer-hover);
  background-color: var(--cds-layer-hover);
}

.cds--table-column-checkbox .cds--checkbox-label {
  padding-left: 0;
}

.cds--data-table th.cds--table-column-checkbox {
  position: static;
  width: 2rem;
  background: var(--cds-layer-accent);
  -webkit-transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9);
}

.cds--data-table thead th.cds--table-column-checkbox,
.cds--data-table tbody td.cds--table-column-checkbox,
.cds--data-table thead th.cds--table-expand,
.cds--data-table tbody td.cds--table-expand {
  min-width: 0;
}

.cds--data-table thead th.cds--table-column-checkbox,
.cds--data-table tbody td.cds--table-column-checkbox {
  min-width: 2.5rem;
  padding-right: 0.25rem;
  padding-left: 1rem;
}

.cds--data-table thead th.cds--table-expand,
.cds--data-table tbody td.cds--table-expand {
  width: 2rem;
  height: 2rem;
}

.cds--data-table--xs thead th.cds--table-expand,
.cds--data-table--xs tbody td.cds--table-expand {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 0 0 0.5rem;
}

.cds--data-table--sm thead th.cds--table-expand,
.cds--data-table--sm tbody td.cds--table-expand {
  width: 2rem;
  height: 2rem;
  padding: 0;
  padding-left: 0.5rem;
}

.cds--data-table--md thead th.cds--table-expand,
.cds--data-table--md tbody td.cds--table-expand {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem 0 0.25rem 0.5rem;
}

.cds--data-table--xl thead th.cds--table-expand,
.cds--data-table--xl tbody td.cds--table-expand {
  height: 4rem;
  padding-top: 0.625rem;
  padding-bottom: 1.375rem;
}

.cds--data-table--xl .cds--table-column-checkbox {
  padding-top: 0.8125rem;
}

.cds--data-table--xl .cds--table-column-radio {
  padding-top: 1rem;
}

.cds--table-column-radio {
  width: 48px;
}

.cds--table-column-radio .cds--radio-button__appearance {
  margin-right: -0.125rem;
}

.cds--data-table--zebra tbody tr:nth-child(odd).cds--data-table--selected td,
tr.cds--data-table--selected td {
  border-top: 1px solid var(--cds-layer-selected);
  border-bottom: 1px solid var(--cds-layer-active);
  background-color: var(--cds-layer-selected);
  color: var(--cds-text-primary, #161616);
}

.cds--data-table--zebra tbody tr:first-of-type:nth-child(odd).cds--data-table--selected td,
tr.cds--data-table--selected:first-of-type td {
  border-top: 1px solid var(--cds-border-subtle-selected);
}

.cds--data-table--zebra tbody tr:last-of-type:nth-child(odd).cds--data-table--selected td,
.cds--data-table--zebra tbody tr:last-of-type:nth-child(even).cds--data-table--selected td,
tr.cds--data-table--selected:last-of-type td {
  border-top: 1px solid var(--cds-layer-selected);
  border-bottom: 1px solid var(--cds-layer-selected);
}

.cds--data-table--zebra tbody tr:nth-child(even).cds--data-table--selected td {
  border-bottom: 1px solid var(--cds-layer-active);
}

.cds--data-table--zebra tbody tr:nth-child(even).cds--data-table--selected:hover td {
  border-bottom: 1px solid var(--cds-layer-selected-hover);
}

.cds--data-table--zebra tbody tr:nth-child(odd).cds--data-table--selected:hover td,
.cds--data-table tbody .cds--data-table--selected:hover td {
  border-top: 1px solid var(--cds-layer-selected-hover);
  border-bottom: 1px solid var(--cds-layer-selected-hover);
  background: var(--cds-layer-selected-hover);
  color: var(--cds-text-primary, #161616);
}

.cds--data-table--selected .cds--overflow-menu .cds--overflow-menu__icon {
  opacity: 1;
}

.cds--data-table--xs thead tr,
.cds--data-table--xs tbody tr,
.cds--data-table--xs tbody tr th {
  height: 1.5rem;
}

.cds--data-table--xs .cds--table-header-label {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.cds--data-table--xs td,
.cds--data-table--xs tbody tr th {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.cds--data-table--xs .cds--overflow-menu {
  width: 2rem;
  height: calc(100% + 1px);
}

.cds--data-table.cds--data-table--xs .cds--table-column-checkbox {
  padding-top: 0;
  padding-bottom: 0;
}

.cds--data-table.cds--data-table--xs .cds--table-column-checkbox .cds--checkbox-label {
  height: 1.4375rem;
  min-height: 1.4375rem;
}

.cds--data-table--sm thead tr,
.cds--data-table--sm tbody tr,
.cds--data-table--sm tbody tr th {
  height: 2rem;
}

.cds--data-table--sm .cds--table-header-label {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}

.cds--data-table--sm td,
.cds--data-table--sm tbody tr th {
  padding-top: 0.4375rem;
  padding-bottom: 0.375rem;
}

.cds--data-table.cds--data-table--sm .cds--table-column-checkbox {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}

.cds--data-table--sm .cds--overflow-menu {
  height: calc(100% + 1px);
}

.cds--data-table--md thead tr,
.cds--data-table--md tbody tr,
.cds--data-table--md tbody tr th {
  height: 2.5rem;
}

.cds--data-table--md .cds--table-header-label {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}

.cds--data-table--md td,
.cds--data-table--md tbody tr th {
  padding-top: 0.4375rem;
  padding-bottom: 0.375rem;
}

.cds--data-table.cds--data-table--md .cds--table-column-checkbox {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}

.cds--data-table--md .cds--table-column-menu {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}

.cds--data-table--xl thead tr,
.cds--data-table--xl tbody tr,
.cds--data-table--xl tbody tr th {
  height: 4rem;
}

.cds--data-table--xl .cds--table-header-label {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.cds--data-table--xl td,
.cds--data-table--xl tbody tr th {
  padding-top: 1rem;
}

.cds--data-table--xl th,
.cds--data-table--xl td {
  vertical-align: top;
}

.cds--data-table--xl .cds--data-table--cell-secondary-text {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
}

.cds--data-table--static {
  width: auto;
}

.cds--data-table-container--static {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.cds--data-table_inner-container {
  background-color: var(--cds-layer-accent);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.cds--data-table--sticky-header {
  display: block;
  overflow-y: scroll;
  /* When using sticky header, with a selection element in the first column, we need to set the last item to a fixed width to match the table body. We only want this to happen when the last table header does not have any text */
}
.cds--data-table--sticky-header thead,
.cds--data-table--sticky-header tbody,
.cds--data-table--sticky-header tr,
.cds--data-table--sticky-header th,
.cds--data-table--sticky-header td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cds--data-table--sticky-header thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  overflow: scroll;
  width: 100%;
  -ms-overflow-style: none;
  will-change: transform;
}
.cds--data-table--sticky-header thead tr th {
  border-bottom: 1px solid var(--cds-layer-active);
}
.cds--data-table--sticky-header tbody {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-overflow-style: none;
  overflow-x: scroll;
  will-change: transform;
}
.cds--data-table--sticky-header tr.cds--parent-row.cds--expandable-row {
  height: auto;
  min-height: 3rem;
}
.cds--data-table--sticky-header tr.cds--expandable-row:not(.cds--parent-row) {
  height: auto;
}
.cds--data-table--sticky-header .cds--table-expand {
  max-width: 3rem;
}
.cds--data-table--sticky-header thead .cds--table-expand {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cds--data-table--sticky-header .cds--parent-row {
  min-height: 3rem;
}
.cds--data-table--sticky-header:not(.cds--data-table--xs):not(.cds--data-table--xl):not(.cds--data-table--sm) td:not(.cds--table-column-menu):not(.cds--table-column-checkbox) {
  padding-top: 0.875rem;
}
.cds--data-table--sticky-header tr.cds--parent-row.cds--expandable-row:hover + tr[data-child-row] td {
  border-top: 1px solid var(--cds-layer-hover);
}
.cds--data-table--sticky-header tr.cds--expandable-row:last-of-type {
  overflow: hidden;
}
.cds--data-table--sticky-header tr.cds--data-table--selected:first-of-type td {
  border-top: none;
}
.cds--data-table--sticky-header thead th.cds--table-column-checkbox,
.cds--data-table--sticky-header tbody tr td.cds--table-column-checkbox {
  width: 2.25rem;
  min-width: 2.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cds--data-table--sticky-header.cds--data-table--xl thead th.cds--table-column-checkbox, .cds--data-table--sticky-header.cds--data-table--xl td.cds--table-column-checkbox {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.cds--data-table--sticky-header th.cds--table-column-checkbox ~ th:last-of-type:empty {
  max-width: 4rem;
}
.cds--data-table--sticky-header th:empty:not(.cds--table-expand) {
  max-width: 2.25rem;
}
.cds--data-table--sticky-header td.cds--table-column-menu {
  height: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 0;
}
.cds--data-table--sticky-header thead::-webkit-scrollbar,
.cds--data-table--sticky-header tbody::-webkit-scrollbar {
  display: none;
}
@-moz-document url-prefix() {
  .cds--data-table--sticky-header thead,
  .cds--data-table--sticky-header tbody {
    scrollbar-width: none;
  }
}
.cds--data-table--sticky-header tbody tr:last-of-type {
  border-bottom: 0;
}
.cds--data-table--sticky-header th:not(.cds--table-column-checkbox):not(.cds--table-column-menu):not(.cds--table-expand):not(.cds--table-column-icon),
.cds--data-table--sticky-header td:not(.cds--table-column-checkbox):not(.cds--table-column-menu):not(.cds--table-expand):not(.cds--table-column-icon) {
  width: 100%;
  min-width: 0;
}
.cds--data-table--sticky-header.cds--data-table--xs tr:not(.cds--expandable-row), .cds--data-table--sticky-header.cds--data-table--sm tr:not(.cds--expandable-row), .cds--data-table--sticky-header.cds--data-table--xl tr:not(.cds--expandable-row) {
  height: auto;
}
.cds--data-table--sticky-header.cds--data-table--xs tr:not(.cds--expandable-row) {
  min-height: 1.5rem;
}
.cds--data-table--sticky-header.cds--data-table--sm tr:not(.cds--expandable-row) {
  min-height: 2rem;
}
.cds--data-table--sticky-header.cds--data-table--xl tr:not(.cds--expandable-row) {
  min-height: 4rem;
}
.cds--data-table--sticky-header.cds--data-table--xs tr td.cds--table-expand {
  padding-top: 0.25rem;
}
.cds--data-table--sticky-header.cds--data-table--sm tr td.cds--table-expand {
  padding-top: 0.5rem;
}
.cds--data-table--sticky-header .cds--table-header-label {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 10px);
  padding-top: 0.9375rem;
  padding-bottom: 1rem;
  overflow-y: hidden;
}
.cds--data-table--sticky-header.cds--data-table--xs th .cds--table-header-label {
  padding-top: 0.1875rem;
  padding-bottom: 0;
}
.cds--data-table--sticky-header.cds--data-table--sm th .cds--table-header-label {
  padding-top: 0.5rem;
  padding-bottom: 0;
}
.cds--data-table--sticky-header.cds--data-table--xl th .cds--table-header-label {
  padding-top: 1rem;
}
.cds--data-table--sticky-header.cds--data-table--xl th.cds--table-expand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.cds--data-table--sticky-header.cds--data-table--xs tr.cds--parent-row .cds--table-column-checkbox, .cds--data-table--sticky-header.cds--data-table--sm tr.cds--parent-row .cds--table-column-checkbox {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cds--data-table--max-width {
  max-width: 100%;
}

.cds--data-table--sticky-header {
  max-height: rem(300px, 16px);
}

.cds--data-table .cds--form-item.cds--checkbox-wrapper:last-of-type {
  margin: 0;
}

.cds--data-table--xs .cds--form-item.cds--checkbox-wrapper:last-of-type,
.cds--data-table--sm .cds--form-item.cds--checkbox-wrapper:last-of-type {
  margin: -0.1875rem 0;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--data-table-content {
    outline: 1px solid transparent;
  }
}

.cds--table-toolbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 3rem;
  background-color: var(--cds-layer);
}

.cds--toolbar-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 3rem;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--batch-actions ~ .cds--toolbar-content {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.cds--toolbar-content .cds--search .cds--search-input {
  height: 3rem;
  padding: 0 3rem;
  background-color: transparent;
}

.cds--toolbar-content .cds--overflow-menu {
  width: 3rem;
  height: 3rem;
}

.cds--batch-actions ~ .cds--toolbar-search-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  -webkit-transition: opacity 110ms;
  transition: opacity 110ms;
}

.cds--toolbar-search-container-expandable {
  position: relative;
  width: 3rem;
  height: 3rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  -webkit-transition: width 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: width 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
}
.cds--toolbar-search-container-expandable:hover {
  background-color: var(--cds-field-hover);
}

.cds--search.cds--toolbar-search-container-expandable {
  width: 3rem;
}

.cds--toolbar-search-container-expandable .cds--search-input {
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.cds--toolbar-search-container-expandable:not(.cds--toolbar-search-container-active) .cds--search-input {
  padding: 0;
}

.cds--toolbar-search-container-disabled .cds--search-input {
  cursor: not-allowed;
}

.cds--toolbar-search-container-expandable.cds--search .cds--label {
  visibility: hidden;
}

.cds--toolbar-search-container-expandable.cds--search .cds--search-close {
  width: 3rem;
  height: 3rem;
}
.cds--toolbar-search-container-expandable.cds--search .cds--search-close::before {
  top: 0.125rem;
  height: calc(100% - 0.25rem);
  background-color: var(--cds-field-hover);
}

.cds--toolbar-search-container-expandable.cds--search .cds--search-close:focus::before {
  background-color: var(--cds-focus, #620b5b);
}

.cds--table-toolbar .cds--search--lg .cds--search-magnifier-icon {
  left: 0;
}

.cds--table-toolbar:not(.cds--table-toolbar--sm) .cds--toolbar-search-container-persistent.cds--search--lg .cds--search-magnifier-icon {
  left: 1rem;
}

.cds--table-toolbar.cds--table-toolbar--sm .cds--search--sm:not(.cds--toolbar-search-container-active):not(.cds--toolbar-search-container-persistent) .cds--search-magnifier-icon {
  left: 0;
}

.cds--table-toolbar.cds--table-toolbar--sm .cds--search--sm.cds--toolbar-search-container-active .cds--search-magnifier-icon {
  left: 0.5rem;
}

.cds--table-toolbar .cds--toolbar-search-container-persistent.cds--search--sm .cds--search-magnifier-icon {
  left: 0.5rem;
}

.cds--toolbar-search-container-expandable .cds--search-magnifier-icon {
  width: 3rem;
  height: 3rem;
  padding: 1rem;
}

.cds--toolbar-search-container-expandable.cds--search--disabled .cds--search-magnifier-icon {
  background-color: var(--cds-layer);
  cursor: not-allowed;
  -webkit-transition: background-color none;
  transition: background-color none;
}

.cds--toolbar-search-container-active .cds--search-magnifier-icon:focus,
.cds--toolbar-search-container-active .cds--search-magnifier-icon:active,
.cds--toolbar-search-container-active .cds--search-magnifier-icon:hover {
  border: none;
  background-color: transparent;
  outline: none;
}

.cds--toolbar-search-container-active.cds--search {
  width: 100%;
}

.cds--toolbar-search-container-active .cds--search-input {
  opacity: 1;
}

.cds--toolbar-search-container-active .cds--label,
.cds--toolbar-search-container-active .cds--search-input {
  padding: 0 3rem;
  cursor: text;
}

.cds--toolbar-search-container-active .cds--search-input:focus + .cds--search-close {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.cds--toolbar-search-container-active .cds--search-input:not(:-moz-placeholder-shown) {
  border: none;
  background-color: var(--cds-field-hover);
}

.cds--toolbar-search-container-active .cds--search-input:not(:placeholder-shown) {
  border: none;
  background-color: var(--cds-field-hover);
}

.cds--toolbar-search-container-persistent .cds--search-close,
.cds--toolbar-search-container-persistent .cds--search-close:hover,
.cds--toolbar-search-container-active .cds--search-close,
.cds--toolbar-search-container-active .cds--search-close:hover {
  border: none;
  background-color: transparent;
}

.cds--toolbar-search-container-persistent .cds--search-close::before {
  display: none;
}

.cds--overflow-menu.cds--toolbar-action {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  cursor: pointer;
  -webkit-transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9);
}
.cds--overflow-menu.cds--toolbar-action *,
.cds--overflow-menu.cds--toolbar-action *::before,
.cds--overflow-menu.cds--toolbar-action *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--overflow-menu.cds--toolbar-action::-moz-focus-inner {
  border: 0;
}

.cds--toolbar-action {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  -webkit-transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9);
}
.cds--toolbar-action *,
.cds--toolbar-action *::before,
.cds--toolbar-action *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--toolbar-action::-moz-focus-inner {
  border: 0;
}

.cds--toolbar-action:hover:not([disabled]) {
  background-color: var(--cds-field-hover);
}

.cds--toolbar-action:hover[aria-expanded=true] {
  background-color: var(--cds-layer);
}

.cds--toolbar-action[disabled] {
  cursor: not-allowed;
}

.cds--toolbar-action[disabled] .cds--toolbar-action__icon {
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--toolbar-action:focus:not([disabled]),
.cds--toolbar-action:active:not([disabled]) {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--toolbar-action:focus:not([disabled]),
  .cds--toolbar-action:active:not([disabled]) {
    outline-style: dotted;
  }
}
.cds--toolbar-action:focus:not([disabled]).cds--toolbar-search-container-expandable,
.cds--toolbar-action:active:not([disabled]).cds--toolbar-search-container-expandable {
  outline: none;
}

.cds--toolbar-action ~ .cds--btn {
  max-width: none;
  margin: 0;
  white-space: nowrap;
}

.cds--overflow-menu--data-table {
  height: 3rem;
}

.cds--toolbar-action__icon {
  width: auto;
  max-width: 1rem;
  height: 1rem;
  fill: var(--cds-icon-primary, #161616);
}

.cds--toolbar-search-container-persistent {
  position: relative;
  width: 100%;
  height: 3rem;
  opacity: 1;
}

.cds--toolbar-search-container-persistent + .cds--toolbar-content {
  position: relative;
  width: auto;
}

.cds--toolbar-search-container-persistent .cds--search {
  position: initial;
}

.cds--toolbar-search-container-persistent .cds--search-magnifier-icon {
  left: 1rem;
}

.cds--toolbar-search-container-persistent .cds--search-input {
  height: 3rem;
  padding: 0 3rem;
  border: none;
}

.cds--toolbar-search-container-persistent .cds--search-input:focus:not([disabled]) {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--toolbar-search-container-persistent .cds--search-input:focus:not([disabled]) {
    outline-style: dotted;
  }
}

.cds--toolbar-search-container-persistent .cds--search-input:hover:not([disabled]) {
  background-color: var(--cds-field-hover);
}

.cds--toolbar-search-container-persistent .cds--search-input:not(:-moz-placeholder-shown) {
  background-color: var(--cds-field-hover);
}

.cds--toolbar-search-container-persistent .cds--search-input:active:not([disabled]),
.cds--toolbar-search-container-persistent .cds--search-input:not(:placeholder-shown) {
  background-color: var(--cds-field-hover);
}

.cds--toolbar-search-container-persistent .cds--search-close {
  width: 3rem;
  height: 3rem;
}

.cds--batch-actions--active ~ .cds--toolbar-search-container,
.cds--batch-actions--active ~ .cds--toolbar-content {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  -webkit-transform: translate3d(0, 48px, 0);
  transform: translate3d(0, 48px, 0);
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--batch-actions {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: var(--cds-background-brand, #620b5b);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  pointer-events: none;
  -webkit-transform: translate3d(0, 48px, 0);
  transform: translate3d(0, 48px, 0);
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  will-change: transform;
}

.cds--batch-actions:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--batch-actions:focus {
    outline-style: dotted;
  }
}

.cds--batch-actions--active {
  overflow: auto hidden;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  pointer-events: all;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.cds--action-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--action-list .cds--btn {
  padding: calc(0.875rem - 3px) 16px;
  color: var(--cds-text-on-color, #ffffff);
  white-space: nowrap;
}

.cds--action-list .cds--btn:disabled {
  border-color: transparent;
  background-color: transparent;
  color: var(--cds-text-on-color, #ffffff);
  opacity: 0.5;
}

.cds--action-list .cds--btn .cds--btn__icon {
  position: static;
  margin-left: 0.5rem;
  fill: var(--cds-icon-on-color, #ffffff);
}

.cds--action-list .cds--btn .cds--btn__icon .st0 {
  fill: none;
}

.cds--batch-download {
  padding: 0.0625rem;
}

.cds--action-list .cds--btn--primary:focus::before,
.cds--action-list .cds--btn--primary::before,
.cds--action-list .cds--btn--primary:focus::after,
.cds--action-list .cds--btn--primary::after {
  display: none;
}

.cds--action-list .cds--btn--primary:focus {
  outline: 2px solid var(--cds-layer);
  outline-offset: -0.125rem;
}

.cds--action-list .cds--btn--primary:nth-child(3):hover + .cds--btn--primary.cds--batch-summary__cancel::before,
.cds--action-list .cds--btn--primary:nth-child(3):focus + .cds--btn--primary.cds--batch-summary__cancel::before {
  opacity: 0;
}

.cds--btn--primary.cds--batch-summary__cancel::before {
  position: absolute;
  top: 0.9375rem;
  left: 0;
  display: block;
  width: 0.0625rem;
  height: 1rem;
  border: none;
  background-color: var(--cds-text-on-color, #ffffff);
  content: "";
  opacity: 1;
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--btn--primary.cds--batch-summary__cancel:hover::before {
  opacity: 0;
  -webkit-transition: opacity 250ms cubic-bezier(0.5, 0, 0.1, 1);
  transition: opacity 250ms cubic-bezier(0.5, 0, 0.1, 1);
}

.cds--batch-summary {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100000;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  background-color: var(--cds-background-brand, #620b5b);
  color: var(--cds-text-on-color, #ffffff);
}

.cds--batch-summary__scroll {
  -webkit-box-shadow: 0.5px 0 0.2px var(--cds-link-primary-hover, #0043ce);
  box-shadow: 0.5px 0 0.2px var(--cds-link-primary-hover, #0043ce);
}

.cds--batch-summary__para {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}

.cds--table-toolbar--sm {
  height: 2rem;
  min-height: 2rem;
}
.cds--table-toolbar--sm .cds--toolbar-search-container-expandable,
.cds--table-toolbar--sm .cds--toolbar-search-container-persistent {
  height: 2rem;
}
.cds--table-toolbar--sm .cds--toolbar-search-container-expandable .cds--search-input,
.cds--table-toolbar--sm .cds--toolbar-search-container-persistent .cds--search-input {
  height: 2rem;
}
.cds--table-toolbar--sm .cds--toolbar-search-container-expandable .cds--search-close,
.cds--table-toolbar--sm .cds--toolbar-search-container-persistent .cds--search-close {
  width: 2rem;
  height: 2rem;
}
.cds--table-toolbar--sm .cds--toolbar-search-container-expandable .cds--search-magnifier-icon,
.cds--table-toolbar--sm .cds--toolbar-search-container-persistent .cds--search-magnifier-icon {
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
}
.cds--table-toolbar--sm .cds--toolbar-action.cds--toolbar-search-container-persistent {
  width: 100%;
}
.cds--table-toolbar--sm .cds--toolbar-search-container-expandable {
  width: 2rem;
}
.cds--table-toolbar--sm .cds--toolbar-search-container-expandable .cds--search .cds--search-input {
  padding: 0 3rem;
}
.cds--table-toolbar--sm .cds--toolbar-search-container-active {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  -webkit-transition: -webkit-box-flex 175ms cubic-bezier(0.5, 0, 0.1, 1);
  transition: -webkit-box-flex 175ms cubic-bezier(0.5, 0, 0.1, 1);
  transition: flex 175ms cubic-bezier(0.5, 0, 0.1, 1);
  transition: flex 175ms cubic-bezier(0.5, 0, 0.1, 1), -webkit-box-flex 175ms cubic-bezier(0.5, 0, 0.1, 1), -ms-flex 175ms cubic-bezier(0.5, 0, 0.1, 1);
}
.cds--table-toolbar--sm .cds--toolbar-search-container-active .cds--search-input {
  visibility: inherit;
}
.cds--table-toolbar--sm .cds--toolbar-search-container-active .cds--search-input:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  background-color: var(--cds-field-hover);
}
@media screen and (prefers-contrast) {
  .cds--table-toolbar--sm .cds--toolbar-search-container-active .cds--search-input:focus {
    outline-style: dotted;
  }
}
.cds--table-toolbar--sm .cds--toolbar-search-container-active .cds--search-input:not(:-moz-placeholder-shown) {
  background-color: var(--cds-field-hover);
}
.cds--table-toolbar--sm .cds--toolbar-search-container-active .cds--search-input:active,
.cds--table-toolbar--sm .cds--toolbar-search-container-active .cds--search-input:not(:placeholder-shown) {
  background-color: var(--cds-field-hover);
}
.cds--table-toolbar--sm .cds--toolbar-search-container-active .cds--search-magnifier-icon:focus,
.cds--table-toolbar--sm .cds--toolbar-search-container-active .cds--search-magnifier-icon:active,
.cds--table-toolbar--sm .cds--toolbar-search-container-active .cds--search-magnifier-icon:hover {
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: transparent;
}
.cds--table-toolbar--sm .cds--overflow-menu.cds--toolbar-action {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
}
.cds--table-toolbar--sm .cds--toolbar-content {
  height: 2rem;
}
.cds--table-toolbar--sm .cds--toolbar-content .cds--overflow-menu {
  width: 2rem;
  height: 2rem;
}

.cds--search--disabled .cds--search-magnifier-icon:hover {
  background-color: transparent;
}

.cds--table-toolbar--sm .cds--batch-actions .cds--action-list {
  height: 2rem;
}

.cds--table-toolbar--sm .cds--toolbar-action {
  width: 2rem;
  height: 2rem;
  padding: 0.5rem 0;
}

.cds--table-toolbar--sm .cds--btn--primary {
  height: 2rem;
  min-height: auto;
  padding-top: calc(0.375rem - 3px);
  padding-bottom: calc(0.375rem - 3px);
}

.cds--table-toolbar--sm .cds--btn--primary.cds--batch-summary__cancel::before {
  top: 0.5rem;
}

.cds--table-toolbar--sm .cds--toolbar-action ~ .cds--btn {
  overflow: hidden;
  height: 2rem;
}

.cds--table-toolbar--sm .cds--batch-summary {
  min-height: 2rem;
}

.cds--data-table tr.cds--parent-row:first-of-type td {
  border-top: 1px solid var(--cds-border-subtle);
}

.cds--expandable-row--hidden td {
  width: auto;
  padding: 1rem;
  border-top: 0;
}

tr.cds--parent-row:not(.cds--expandable-row) + tr[data-child-row] {
  height: 0;
  -webkit-transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

tr.cds--parent-row:not(.cds--expandable-row) + tr[data-child-row] td {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  background-color: var(--cds-layer-hover);
  -webkit-transition: padding 150ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: padding 150ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

tr.cds--parent-row:not(.cds--expandable-row) + tr[data-child-row] td .cds--child-row-inner-container {
  overflow: hidden;
  max-height: 0;
}

tr.cds--parent-row.cds--expandable-row + tr[data-child-row] {
  -webkit-transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

tr.cds--parent-row.cds--expandable-row + tr[data-child-row] td {
  padding-left: 4rem;
  border-bottom: 1px solid var(--cds-border-subtle);
  -webkit-transition: padding-bottom 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: padding-bottom 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: padding-bottom 110ms cubic-bezier(0.2, 0, 0.38, 0.9), transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: padding-bottom 110ms cubic-bezier(0.2, 0, 0.38, 0.9), transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

tr.cds--parent-row.cds--expandable-row + tr[data-child-row] td .cds--child-row-inner-container {
  max-height: 100%;
}

.cds--parent-row.cds--expandable-row > td,
.cds--parent-row.cds--expandable-row + tr[data-child-row] > td {
  border-bottom: 1px solid var(--cds-border-subtle);
  -webkit-box-shadow: 0 1px var(--cds-border-subtle);
  box-shadow: 0 1px var(--cds-border-subtle);
}

.cds--parent-row:not(.cds--expandable-row) + tr[data-child-row] > td {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cds--parent-row.cds--expandable-row > td:first-of-type {
  -webkit-box-shadow: none;
  box-shadow: none;
}

tr.cds--parent-row:not(.cds--expandable-row) td,
tr.cds--parent-row.cds--expandable-row td,
tr.cds--parent-row.cds--expandable-row {
  -webkit-transition: height 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: height 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

tr.cds--parent-row:not(.cds--expandable-row):first-of-type:hover td {
  border-top: 1px solid var(--cds-border-subtle);
  border-bottom: 1px solid var(--cds-border-subtle);
}

tr.cds--parent-row.cds--expandable-row:hover td {
  border-top: 1px solid var(--cds-border-subtle);
  border-bottom: 1px solid var(--cds-border-subtle);
  background-color: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}

tr.cds--parent-row.cds--expandable-row:hover td:first-of-type {
  border-bottom: 1px solid var(--cds-layer-hover);
}

tr.cds--parent-row.cds--expandable-row:hover + tr[data-child-row] td {
  border-bottom: 1px solid var(--cds-border-subtle);
  background-color: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}

tr.cds--expandable-row--hover + tr[data-child-row] td {
  border-bottom: 1px solid var(--cds-border-subtle);
}

tr.cds--expandable-row--hover {
  background-color: var(--cds-layer-hover);
}

tr.cds--expandable-row--hover td {
  border-top: 1px solid var(--cds-border-subtle);
  border-bottom: 1px solid var(--cds-border-subtle);
  background-color: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}

tr.cds--parent-row.cds--expandable-row.cds--expandable-row--hover td:first-of-type {
  border-bottom: 1px solid transparent;
}

.cds--data-table td.cds--table-expand {
  border-bottom: 1px solid var(--cds-border-subtle);
}

.cds--data-table th.cds--table-expand + .cds--table-column-checkbox,
.cds--data-table td.cds--table-expand + .cds--table-column-checkbox {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.cds--data-table th.cds--table-expand + .cds--table-column-checkbox + th,
.cds--data-table td.cds--table-expand + .cds--table-column-checkbox + td {
  padding-left: 0.5rem;
}

.cds--data-table td.cds--table-expand,
.cds--data-table th.cds--table-expand {
  padding: 0.5rem;
  padding-right: 0;
}

.cds--data-table td.cds--table-expand[data-previous-value=collapsed] {
  border-bottom: 1px solid transparent;
}

.cds--table-expand[data-previous-value=collapsed] .cds--table-expand__svg {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--table-expand__button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  height: calc(100% + 1px);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 0.5rem;
  vertical-align: inherit;
}
.cds--table-expand__button *,
.cds--table-expand__button *::before,
.cds--table-expand__button *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--table-expand__button::-moz-focus-inner {
  border: 0;
}

.cds--table-expand__button:focus {
  -webkit-box-shadow: inset 0 0 0 2px var(--cds-focus, #620b5b);
  box-shadow: inset 0 0 0 2px var(--cds-focus, #620b5b);
  outline: none;
}

.cds--table-expand__svg {
  fill: var(--cds-layer-selected-inverse, #161616);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--data-table--xl .cds--table-expand__button {
  width: 2rem;
  padding: 0;
}

tr.cds--parent-row.cds--expandable-row td.cds--table-expand + td::after {
  position: absolute;
  bottom: -0.0625rem;
  left: 0;
  width: 0.5rem;
  height: 0.0625rem;
  background: var(--cds-layer-accent);
  content: "";
}

tr.cds--parent-row.cds--expandable-row:hover td.cds--table-expand + td::after,
tr.cds--parent-row.cds--expandable-row.cds--expandable-row--hover td.cds--table-expand + td::after {
  background: var(--cds-layer-hover);
}

tr.cds--parent-row.cds--data-table--selected td.cds--table-expand + td::after {
  display: none;
}

.cds--data-table--zebra tbody tr[data-parent-row]:nth-child(4n+3) td,
.cds--data-table--zebra tbody tr[data-child-row]:nth-child(4n+4) td {
  border-bottom: 1px solid var(--cds-layer);
}

.cds--data-table--zebra tbody tr[data-parent-row]:nth-child(4n+1) td,
.cds--data-table--zebra tbody tr[data-child-row]:nth-child(4n+2) td {
  border-top: 1px solid var(--cds-layer-accent);
  border-bottom: 1px solid var(--cds-layer-accent);
  background-color: var(--cds-layer-accent);
}

.cds--data-table--zebra tr.cds--parent-row td,
.cds--data-table--zebra tr.cds--parent-row.cds--expandable-row + tr[data-child-row] td {
  -webkit-transition: border-bottom 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-top 150ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: border-bottom 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-top 150ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-top 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-top 150ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--data-table--zebra tbody tr[data-parent-row]:hover td,
.cds--data-table--zebra tbody tr[data-parent-row]:hover + tr[data-child-row] td,
.cds--data-table--zebra tbody tr[data-child-row]:hover td {
  border-top: 1px solid var(--cds-layer-hover);
  border-bottom: 1px solid var(--cds-layer-hover);
  background-color: var(--cds-layer-hover);
}

.cds--data-table--zebra tr.cds--parent-row.cds--expandable-row.cds--expandable-row--hover td {
  border-top: 1px solid var(--cds-layer-hover);
  border-bottom: 1px solid var(--cds-layer-hover);
  background: var(--cds-layer-hover);
}

tr.cds--parent-row.cds--data-table--selected:first-of-type td {
  border-top: 1px solid var(--cds-layer-active);
  border-bottom: 1px solid var(--cds-border-subtle);
  background: var(--cds-layer-selected);
  -webkit-box-shadow: 0 1px var(--cds-layer-active);
  box-shadow: 0 1px var(--cds-layer-active);
}

tr.cds--parent-row.cds--data-table--selected td {
  border-bottom: 1px solid transparent;
  background: var(--cds-layer-selected);
  -webkit-box-shadow: 0 1px var(--cds-layer-active);
  box-shadow: 0 1px var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

tr.cds--parent-row.cds--data-table--selected:last-of-type td {
  border-bottom: 1px solid transparent;
  background: var(--cds-layer-selected);
  -webkit-box-shadow: 0 1px var(--cds-border-subtle);
  box-shadow: 0 1px var(--cds-border-subtle);
}

tr.cds--parent-row.cds--data-table--selected:not(.cds--expandable-row):hover td {
  border-top: 1px solid var(--cds-layer-selected-hover);
  border-bottom: 1px solid var(--cds-border-subtle);
  background: var(--cds-layer-selected-hover);
  -webkit-box-shadow: 0 1px var(--cds-layer-selected-hover);
  box-shadow: 0 1px var(--cds-layer-selected-hover);
}

tr.cds--parent-row.cds--data-table--selected.cds--expandable-row td,
tr.cds--parent-row.cds--data-table--selected.cds--expandable-row td:first-of-type {
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: 0 1px var(--cds-layer-selected);
  box-shadow: 0 1px var(--cds-layer-selected);
}

tr.cds--parent-row.cds--data-table--selected.cds--expandable-row:hover td,
tr.cds--parent-row.cds--data-table--selected.cds--expandable-row:hover td:first-of-type,
tr.cds--parent-row.cds--data-table--selected.cds--expandable-row--hover td,
tr.cds--parent-row.cds--data-table--selected.cds--expandable-row--hover td:first-of-type {
  border-top: 1px solid var(--cds-layer-selected-hover);
  border-bottom: 1px solid transparent;
  background: var(--cds-layer-selected-hover);
  -webkit-box-shadow: 0 1px var(--cds-layer-selected-hover);
  box-shadow: 0 1px var(--cds-layer-selected-hover);
}

tr.cds--parent-row.cds--data-table--selected.cds--expandable-row + tr[data-child-row] td {
  border-top: 1px solid var(--cds-layer-active);
  border-bottom: 1px solid var(--cds-border-subtle);
  background-color: var(--cds-layer-hover);
  -webkit-box-shadow: 0 1px var(--cds-layer-active);
  box-shadow: 0 1px var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

tr.cds--parent-row.cds--data-table--selected.cds--expandable-row + tr[data-child-row]:last-of-type td {
  padding-bottom: 1.5rem;
  -webkit-box-shadow: inset 0 -1px var(--cds-layer-active);
  box-shadow: inset 0 -1px var(--cds-layer-active);
}

tr.cds--parent-row.cds--data-table--selected.cds--expandable-row:hover + tr[data-child-row] td,
tr.cds--parent-row.cds--data-table--selected.cds--expandable-row--hover + tr[data-child-row] td {
  background: var(--cds-layer-selected);
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--table-expand__button:focus .cds--table-expand__svg {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--table-expand__svg {
    fill: ButtonText;
  }
}

.cds--data-table.cds--skeleton th {
  padding-left: 1rem;
  vertical-align: middle;
}

.cds--data-table.cds--skeleton th span,
.cds--data-table.cds--skeleton td span {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  display: block;
  width: 4rem;
  height: 1rem;
}
.cds--data-table.cds--skeleton th span:hover, .cds--data-table.cds--skeleton th span:focus, .cds--data-table.cds--skeleton th span:active,
.cds--data-table.cds--skeleton td span:hover,
.cds--data-table.cds--skeleton td span:focus,
.cds--data-table.cds--skeleton td span:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--data-table.cds--skeleton th span::before,
.cds--data-table.cds--skeleton td span::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--data-table.cds--skeleton th span::before,
  .cds--data-table.cds--skeleton td span::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--data-table.cds--skeleton tr:hover td {
  border-color: var(--cds-border-subtle);
  background: transparent;
}

.cds--data-table.cds--skeleton tr:hover td:first-of-type,
.cds--data-table.cds--skeleton tr:hover td:last-of-type {
  border-color: var(--cds-border-subtle);
}

.cds--data-table.cds--skeleton .cds--table-sort {
  pointer-events: none;
}

.cds--data-table.cds--skeleton th span {
  background: var(--cds-skeleton-element, #c6c6c6);
}

.cds--data-table.cds--skeleton th span::before {
  background: var(--cds-skeleton-background, #e8e8e8);
}

.cds--data-table-container.cds--skeleton .cds--data-table-header__title {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 7.5rem;
  height: 1.5rem;
}
.cds--data-table-container.cds--skeleton .cds--data-table-header__title:hover, .cds--data-table-container.cds--skeleton .cds--data-table-header__title:focus, .cds--data-table-container.cds--skeleton .cds--data-table-header__title:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--data-table-container.cds--skeleton .cds--data-table-header__title::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--data-table-container.cds--skeleton .cds--data-table-header__title::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--data-table-container.cds--skeleton .cds--data-table-header__description {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 10rem;
  height: 1rem;
  margin-top: 0.5rem;
}
.cds--data-table-container.cds--skeleton .cds--data-table-header__description:hover, .cds--data-table-container.cds--skeleton .cds--data-table-header__description:focus, .cds--data-table-container.cds--skeleton .cds--data-table-header__description:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--data-table-container.cds--skeleton .cds--data-table-header__description::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--data-table-container.cds--skeleton .cds--data-table-header__description::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--data-table--sort th,
.cds--data-table th[aria-sort] {
  height: 3rem;
  padding: 0;
  border-top: none;
  border-bottom: none;
}

.cds--table-sort__description {
  display: none;
}

.cds--table-sort {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 1rem;
  background-color: var(--cds-layer-accent);
  color: var(--cds-text-primary, #161616);
  font: inherit;
  line-height: 1;
  text-align: left;
  -webkit-transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
}
.cds--table-sort *,
.cds--table-sort *::before,
.cds--table-sort *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--table-sort::-moz-focus-inner {
  border: 0;
}

.cds--table-sort:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--table-sort:focus {
    outline-style: dotted;
  }
}

.cds--table-sort:hover {
  background: var(--cds-layer-selected-hover);
}

.cds--table-sort:focus svg,
.cds--table-sort:hover svg {
  opacity: 1;
}

.cds--data-table.cds--data-table--sort th > .cds--table-header-label {
  padding-right: 1rem;
  padding-left: 1rem;
  line-height: 1;
}

th .cds--table-sort__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cds--data-table--sort:not(.cds--data-table--xs):not(.cds--data-table--sm):not(.cds--data-table--md):not(.cds--data-table--xl) th .cds--table-sort__flex {
  /* IE11 workaround for align-items: center and min-height
      https://github.com/philipwalton/flexbugs/issues/231 */
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .cds--data-table--sort:not(.cds--data-table--xs):not(.cds--data-table--sm):not(.cds--data-table--md):not(.cds--data-table--xl) th .cds--table-sort__flex {
    height: 2.99rem;
  }
}

.cds--data-table--xs.cds--data-table--sort th .cds--table-sort__flex {
  min-height: 1.5rem;
}

.cds--data-table--sm.cds--data-table--sort th .cds--table-sort__flex {
  min-height: 2rem;
}

.cds--data-table--md.cds--data-table--sort th .cds--table-sort__flex {
  min-height: 2.5rem;
}

.cds--data-table--xl.cds--data-table--sort th .cds--table-sort__flex {
  min-height: 4rem;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cds--table-sort .cds--table-sort__icon-inactive {
  display: block;
}

.cds--table-sort .cds--table-sort__icon {
  display: none;
}

.cds--table-sort__icon-unsorted {
  width: 1.25rem;
  min-width: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  fill: var(--cds-icon-primary, #161616);
  opacity: 0;
}

.cds--table-sort.cds--table-sort--active {
  background: var(--cds-layer-selected-hover);
}

.cds--table-sort.cds--table-sort--active .cds--table-sort__icon-unsorted {
  display: none;
}

.cds--table-sort.cds--table-sort--active .cds--table-sort__icon {
  display: block;
  opacity: 1;
}

.cds--table-sort--descending .cds--table-sort__icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cds--table-sort__icon {
  width: 1.25rem;
  min-width: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  fill: var(--cds-icon-primary, #161616);
  opacity: 1;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.5, 0, 0.1, 1);
  transition: -webkit-transform 250ms cubic-bezier(0.5, 0, 0.1, 1);
  transition: transform 250ms cubic-bezier(0.5, 0, 0.1, 1);
  transition: transform 250ms cubic-bezier(0.5, 0, 0.1, 1), -webkit-transform 250ms cubic-bezier(0.5, 0, 0.1, 1);
}

.cds--data-table--xs.cds--data-table--sort th {
  height: 1.5rem;
}

.cds--data-table--sm.cds--data-table--sort th {
  height: 2rem;
}

.cds--data-table--md.cds--data-table--sort th {
  height: 2.5rem;
}

.cds--data-table--xl.cds--data-table--sort th {
  height: 4rem;
}

.cds--data-table--xl.cds--data-table--sort th .cds--table-sort {
  display: inline-block;
  height: 4rem;
}

.cds--data-table--xl .cds--table-sort__icon-unsorted,
.cds--data-table--xl .cds--table-sort__icon {
  margin-top: 0.8125rem;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--table-sort__icon,
  .cds--table-sort__icon-unsorted {
    fill: ButtonText;
  }
}

@-webkit-keyframes fp-fade-in-down {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fp-fade-in-down {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fp-slide-left {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fp-slide-left {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes fp-slide-left-new {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fp-slide-left-new {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fp-slide-right {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fp-slide-right {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes fp-slide-right-new {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fp-slide-right-new {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fp-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fp-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fp-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fp-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.flatpickr-calendar {
  position: absolute;
  overflow: hidden;
  width: 19.6875rem;
  max-height: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
  direction: ltr;
  opacity: 0;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  visibility: hidden;
}
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .flatpickr-calendar {
    outline: 1px solid transparent;
  }
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  overflow: visible;
  max-height: 40rem;
  opacity: 1;
  visibility: inherit;
}

.flatpickr-calendar.open {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 18rem;
  height: 21rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.25rem 0.25rem 0.5rem 0.25rem;
  border: none;
  margin-top: -0.125rem;
  background-color: var(--cds-layer-01, #f4f4f4);
}
.flatpickr-calendar.open:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .flatpickr-calendar.open:focus {
    outline-style: dotted;
  }
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fp-fade-in-down 110ms cubic-bezier(0, 0, 0.38, 0.9);
  animation: fp-fade-in-down 110ms cubic-bezier(0, 0, 0.38, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .flatpickr-calendar.animate.open {
    -webkit-animation: none;
    animation: none;
  }
}

.flatpickr-calendar.inline {
  position: relative;
  top: 0.125rem;
  display: block;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.hasWeeks {
  width: auto;
}

.dayContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 15.375rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 0;
  outline: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 2.5rem;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-months {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flatpickr-month {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  color: var(--cds-text-primary, #161616);
  line-height: 1;
  text-align: center;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  fill: var(--cds-icon-primary, #161616);
  line-height: 16px;
  text-decoration: none;
  -webkit-transform: scale(1, 1) /*rtl: scale(-1,1)*/;
  transform: scale(1, 1) /*rtl: scale(-1,1)*/;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .flatpickr-prev-month,
  .flatpickr-next-month {
    fill: ButtonText;
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  .flatpickr-prev-month,
  .flatpickr-next-month {
    -webkit-transition: none;
    transition: none;
  }
}
.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  background-color: var(--cds-layer-hover);
}

.flatpickr-next-month.disabled svg,
.flatpickr-prev-month.disabled svg {
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.flatpickr-next-month.disabled:hover svg,
.flatpickr-prev-month.disabled:hover svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.flatpickr-current-month {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1.75rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-current-month .cur-month {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
.flatpickr-current-month .cur-month:hover {
  background-color: var(--cds-layer-hover);
}

.numInputWrapper {
  position: relative;
  width: 3.75rem;
}
.numInputWrapper:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
}

.numInputWrapper .numInput {
  display: inline-block;
  width: 100%;
  padding: 0.25rem;
  border: none;
  margin: 0;
  -moz-appearance: textfield;
  /* Firefox */
  background-color: var(--cds-field-01, #f4f4f4);
  color: var(--cds-text-primary, #161616);
  cursor: default;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
}
.numInputWrapper .numInput::-webkit-outer-spin-button, .numInputWrapper .numInput::-webkit-inner-spin-button {
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
  -webkit-appearance: none;
}
.numInputWrapper .numInput:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .numInputWrapper .numInput:focus {
    outline-style: dotted;
  }
}
.numInputWrapper .numInput[disabled], .numInputWrapper .numInput[disabled]:hover {
  background-color: var(--cds-layer-01, #f4f4f4);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  pointer-events: none;
}

.numInputWrapper .arrowUp {
  top: 0.25rem;
  border-bottom: 0;
}
.numInputWrapper .arrowUp::after {
  border-bottom: 0.25rem solid var(--cds-icon-primary, #161616);
}

.numInputWrapper .arrowDown {
  top: 0.6875rem;
}
.numInputWrapper .arrowDown::after {
  border-top: 0.25rem solid var(--cds-icon-primary, #161616);
}

.numInputWrapper .arrowUp,
.numInputWrapper .arrowDown {
  position: absolute;
  left: 2.6rem;
  width: 0.75rem;
  height: 50%;
  padding: 0 0.25rem 0 0.125rem;
  border: none;
  cursor: pointer;
  line-height: 50%;
  opacity: 0;
}
.numInputWrapper .arrowUp::after,
.numInputWrapper .arrowDown::after {
  position: absolute;
  top: 33%;
  display: block;
  border-right: 0.25rem solid transparent;
  border-left: 0.25rem solid transparent;
  content: "";
}
.numInputWrapper .arrowUp:hover::after,
.numInputWrapper .arrowDown:hover::after {
  border-top-color: var(--cds-button-primary, #620b5b);
  border-bottom-color: var(--cds-button-primary, #620b5b);
}
.numInputWrapper .arrowUp:active::after,
.numInputWrapper .arrowDown:active::after {
  border-top-color: var(--cds-border-interactive, #620b5b);
  border-bottom-color: var(--cds-border-interactive, #620b5b);
}

.numInput[disabled] ~ .arrowUp::after {
  border-bottom-color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.numInput[disabled] ~ .arrowDown::after {
  border-top-color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.numInputWrapper:hover .arrowUp,
.numInputWrapper:hover .arrowDown {
  opacity: 1;
}

.numInputWrapper:hover .numInput[disabled] ~ .arrowUp,
.numInputWrapper:hover .numInput[disabled] ~ .arrowDown {
  opacity: 0;
}

.flatpickr-weekdays {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.flatpickr-weekday {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  color: var(--cds-text-primary, #161616);
  cursor: default;
}

.flatpickr-days:focus {
  outline: 0;
}

.flatpickr-calendar.animate .dayContainer.slideLeft {
  -webkit-animation: fp-fade-out 400ms cubic-bezier(0.23, 1, 0.32, 1), fp-slide-left 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fp-fade-out 400ms cubic-bezier(0.23, 1, 0.32, 1), fp-slide-left 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  -webkit-animation: fp-fade-in 400ms cubic-bezier(0.23, 1, 0.32, 1), fp-slide-left 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fp-fade-in 400ms cubic-bezier(0.23, 1, 0.32, 1), fp-slide-left 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideRight {
  -webkit-animation: fp-fade-out 400ms cubic-bezier(0.23, 1, 0.32, 1), fp-slide-right 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fp-fade-out 400ms cubic-bezier(0.23, 1, 0.32, 1), fp-slide-right 400ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.flatpickr-calendar.animate .dayContainer.slideRightNew {
  -webkit-animation: fp-fade-in 400ms cubic-bezier(0.23, 1, 0.32, 1), fp-slide-right-new 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fp-fade-in 400ms cubic-bezier(0.23, 1, 0.32, 1), fp-slide-right-new 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-day {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
  -webkit-transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.flatpickr-day:hover {
  background: var(--cds-layer-hover);
}
.flatpickr-day:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  outline-color: var(--cds-button-primary, #620b5b);
}
@media screen and (prefers-contrast) {
  .flatpickr-day:focus {
    outline-style: dotted;
  }
}

.nextMonthDay,
.prevMonthDay {
  color: var(--cds-text-helper, #6f6f6f);
}

.flatpickr-day.today {
  position: relative;
  color: var(--cds-link-primary, #620b5b);
  font-weight: 600;
}
.flatpickr-day.today::after {
  position: absolute;
  bottom: 0.4375rem;
  left: 50%;
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  background-color: var(--cds-link-primary, #620b5b);
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.flatpickr-day.today.no-border {
  border: none;
}

.flatpickr-day.today.selected {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .flatpickr-day.today.selected {
    outline-style: dotted;
  }
}
.flatpickr-day.today.selected::after {
  display: none;
}

.flatpickr-day.inRange {
  background-color: var(--cds-highlight, #d0e2ff);
  color: var(--cds-text-primary, #161616);
}

.flatpickr-day.selected {
  background-color: var(--cds-button-primary, #620b5b);
  color: var(--cds-text-on-color, #ffffff);
}
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .flatpickr-day.selected {
    color: Highlight;
    outline: 1px solid Highlight;
    outline-style: dotted;
  }
}
.flatpickr-day.selected:focus {
  outline: 0.0625rem solid var(--cds-layer-02, #ffffff);
  outline-offset: -0.1875rem;
}

.flatpickr-day.startRange.selected {
  z-index: 2;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.flatpickr-day.startRange.inRange:not(.selected),
.flatpickr-day.endRange.inRange {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  z-index: 3;
  background: var(--cds-layer-01, #f4f4f4);
}
@media screen and (prefers-contrast) {
  .flatpickr-day.startRange.inRange:not(.selected),
  .flatpickr-day.endRange.inRange {
    outline-style: dotted;
  }
}

.flatpickr-day.endRange:hover {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  background: var(--cds-layer-01, #f4f4f4);
  color: var(--cds-text-primary, #161616);
}
@media screen and (prefers-contrast) {
  .flatpickr-day.endRange:hover {
    outline-style: dotted;
  }
}

.flatpickr-day.endRange.inRange.selected {
  background: var(--cds-button-primary, #620b5b);
  color: var(--cds-text-on-color, #ffffff);
}

.flatpickr-day.flatpickr-disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}
.flatpickr-day.flatpickr-disabled:hover {
  background-color: transparent;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .flatpickr-day.today,
  .flatpickr-day.inRange {
    color: Highlight;
  }
}

.cds--date-picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cds--date-picker--light .cds--date-picker__input {
  background: var(--cds-field-02, #ffffff);
}

.cds--date-picker ~ .cds--label {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.cds--date-picker-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cds--date-picker-container .cds--label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cds--date-picker-input__wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--date-picker.cds--date-picker--simple .cds--date-picker__input,
.cds--date-picker.cds--date-picker--simple .cds--label {
  width: 7.5rem;
}

.cds--date-picker.cds--date-picker--simple .cds--date-picker-input__wrapper--invalid .cds--date-picker__input,
.cds--date-picker.cds--date-picker--simple .cds--date-picker-input__wrapper--invalid ~ .cds--form-requirement,
.cds--date-picker.cds--date-picker--simple .cds--date-picker-input__wrapper--warn .cds--date-picker__input,
.cds--date-picker.cds--date-picker--simple .cds--date-picker-input__wrapper--warn ~ .cds--form-requirement {
  width: 9.5rem;
}

.cds--date-picker.cds--date-picker--simple.cds--date-picker--short .cds--date-picker__input {
  width: 5.7rem;
}

.cds--date-picker.cds--date-picker--single .cds--date-picker__input {
  width: 18rem;
}

.cds--date-picker .cds--date-picker-input__wrapper--warn ~ .cds--form-requirement {
  color: var(--cds-text-primary, #161616);
}

.cds--date-picker__input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-family: var(--cds-code-02-font-family, 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace);
  font-size: var(--cds-code-02-font-size, 0.875rem);
  font-weight: var(--cds-code-02-font-weight, 400);
  line-height: var(--cds-code-02-line-height, 1.42857);
  letter-spacing: var(--cds-code-02-letter-spacing, 0.32px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: relative;
  display: block;
  height: 2.5rem;
  padding: 0 1rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong);
  background-color: var(--cds-field);
  color: var(--cds-text-primary, #161616);
  -webkit-transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9) all;
  transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9) all;
}
.cds--date-picker__input *,
.cds--date-picker__input *::before,
.cds--date-picker__input *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--date-picker__input:focus, .cds--date-picker__input.cds--focused {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--date-picker__input:focus, .cds--date-picker__input.cds--focused {
    outline-style: dotted;
  }
}
.cds--date-picker__input:disabled {
  border-bottom: 1px solid transparent;
  background-color: var(--cds-field);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}
.cds--date-picker__input:disabled::-webkit-input-placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}
.cds--date-picker__input:disabled::-moz-placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}
.cds--date-picker__input:disabled::-ms-input-placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}
.cds--date-picker__input:disabled::placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}
.cds--date-picker__input:disabled:hover {
  border-bottom: 1px solid transparent;
}
.cds--date-picker__input::-webkit-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--date-picker__input::-moz-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--date-picker__input::-ms-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--date-picker__input::placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}

.cds--date-picker__input--lg {
  height: 3rem;
}

.cds--date-picker__input--sm {
  height: 2rem;
}

.cds--date-picker__icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 1rem;
  fill: var(--cds-icon-primary, #161616);
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cds--date-picker__icon--invalid,
.cds--date-picker__icon--warn {
  cursor: auto;
}

.cds--date-picker__icon--warn {
  fill: var(--cds-support-warning, #f1c21b);
}

.cds--date-picker__icon--warn path:first-of-type {
  fill: #000000;
  opacity: 1;
}

.cds--date-picker__icon--invalid {
  fill: var(--cds-support-error, #da1e28);
}

.cds--date-picker__icon ~ .cds--date-picker__input {
  padding-right: 3rem;
}

.cds--date-picker__input:disabled ~ .cds--date-picker__icon {
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--date-picker--range > .cds--date-picker-container:first-child {
  margin-right: 0.0625rem;
}

.cds--date-picker--range .cds--date-picker-container,
.cds--date-picker--range .cds--date-picker__input {
  width: 8.96875rem;
}

.cds--date-picker.cds--skeleton input,
.cds--date-picker__input.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 100%;
}
.cds--date-picker.cds--skeleton input:hover, .cds--date-picker.cds--skeleton input:focus, .cds--date-picker.cds--skeleton input:active,
.cds--date-picker__input.cds--skeleton:hover,
.cds--date-picker__input.cds--skeleton:focus,
.cds--date-picker__input.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--date-picker.cds--skeleton input::before,
.cds--date-picker__input.cds--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--date-picker.cds--skeleton input::before,
  .cds--date-picker__input.cds--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}
.cds--date-picker.cds--skeleton input::-webkit-input-placeholder, .cds--date-picker__input.cds--skeleton::-webkit-input-placeholder {
  color: transparent;
}
.cds--date-picker.cds--skeleton input::-moz-placeholder, .cds--date-picker__input.cds--skeleton::-moz-placeholder {
  color: transparent;
}
.cds--date-picker.cds--skeleton input::-ms-input-placeholder, .cds--date-picker__input.cds--skeleton::-ms-input-placeholder {
  color: transparent;
}
.cds--date-picker.cds--skeleton input::placeholder,
.cds--date-picker__input.cds--skeleton::placeholder {
  color: transparent;
}

.cds--date-picker.cds--skeleton .cds--label {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 4.6875rem;
  height: 0.875rem;
}
.cds--date-picker.cds--skeleton .cds--label:hover, .cds--date-picker.cds--skeleton .cds--label:focus, .cds--date-picker.cds--skeleton .cds--label:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--date-picker.cds--skeleton .cds--label::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--date-picker.cds--skeleton .cds--label::before {
    -webkit-animation: none;
    animation: none;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--date-picker__icon {
    fill: ButtonText;
  }
}

.cds--date-picker__input[readonly] {
  background: transparent;
  cursor: text;
}

.cds--date-picker__input[readonly] + .cds--date-picker__icon {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--dropdown__wrapper--inline {
  display: inline-grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 0 1.5rem;
  grid-template: auto auto/auto -webkit-min-content;
  grid-template: auto auto/auto min-content;
}
.cds--dropdown__wrapper--inline .cds--label {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}
.cds--dropdown__wrapper--inline .cds--label,
.cds--dropdown__wrapper--inline .cds--form__helper-text,
.cds--dropdown__wrapper--inline .cds--form-requirement {
  margin: 0;
}
.cds--dropdown__wrapper--inline .cds--form-requirement {
  grid-column: 2;
}

.cds--dropdown {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: relative;
  display: block;
  width: 100%;
  height: 2.5rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong);
  background-color: var(--cds-field);
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
  list-style: none;
  outline: 2px solid transparent;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--dropdown html {
  font-size: 100%;
}
.cds--dropdown body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--dropdown code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--dropdown strong {
  font-weight: 600;
}
.cds--dropdown:hover {
  background-color: var(--cds-field-hover);
}

.cds--dropdown .cds--list-box__field {
  text-align: left;
}

.cds--dropdown--lg {
  height: 3rem;
  max-height: 3rem;
}

.cds--dropdown--lg .cds--dropdown__arrow {
  top: 1rem;
}

.cds--dropdown--sm {
  height: 2rem;
  max-height: 2rem;
}

.cds--dropdown--sm .cds--dropdown__arrow {
  top: 0.5rem;
}

.cds--dropdown--open {
  border-bottom-color: var(--cds-border-subtle);
}

.cds--dropdown--invalid {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--dropdown--invalid {
    outline-style: dotted;
  }
}
.cds--dropdown--invalid .cds--dropdown-text {
  padding-right: 3.5rem;
}
.cds--dropdown--invalid + .cds--form-requirement {
  display: inline-block;
  max-height: 12.5rem;
  color: var(--cds-text-error, #da1e28);
}

.cds--dropdown__invalid-icon {
  position: absolute;
  top: 50%;
  right: 2.5rem;
  fill: var(--cds-support-error, #da1e28);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cds--dropdown--open:hover {
  background-color: var(--cds-field);
}

.cds--dropdown--open:focus {
  outline: 1px solid transparent;
}

.cds--dropdown--open .cds--dropdown-list {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  max-height: 13.75rem;
  -webkit-transition: max-height 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: max-height 110ms cubic-bezier(0, 0, 0.38, 0.9);
}

.cds--dropdown--light {
  background-color: var(--cds-field);
}
.cds--dropdown--light:hover {
  background-color: var(--cds-field-hover);
}

.cds--dropdown--up .cds--dropdown-list {
  bottom: 2rem;
}

.cds--dropdown__arrow {
  position: absolute;
  top: 0.8125rem;
  right: 1rem;
  fill: var(--cds-icon-primary, #161616);
  pointer-events: none;
  -webkit-transform-origin: 50% 45%;
  transform-origin: 50% 45%;
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

button.cds--dropdown-text {
  width: 100%;
  border: none;
  background: none;
  color: var(--cds-text-primary, #161616);
  text-align: left;
}
button.cds--dropdown-text:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  button.cds--dropdown-text:focus {
    outline-style: dotted;
  }
}

.cds--dropdown-text {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: block;
  overflow: hidden;
  height: calc(100% + 1px);
  padding-right: 2.625rem;
  padding-left: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--dropdown-list {
  outline: 2px solid transparent;
  outline-offset: -2px;
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  position: absolute;
  z-index: 9100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-height: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: var(--cds-layer);
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--dropdown-list html {
  font-size: 100%;
}
.cds--dropdown-list body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--dropdown-list code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--dropdown-list strong {
  font-weight: 600;
}

.cds--dropdown--light .cds--dropdown-list {
  background-color: var(--cds-layer);
}

.cds--dropdown:not(.cds--dropdown--open) .cds--dropdown-item {
  visibility: hidden;
}

.cds--dropdown-item {
  position: relative;
  opacity: 0;
  -webkit-transition: visibility 70ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: visibility 70ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  visibility: inherit;
}
.cds--dropdown-item:hover {
  background-color: var(--cds-layer-hover);
}
.cds--dropdown-item:hover + .cds--dropdown-item .cds--dropdown-link {
  border-color: transparent;
}
.cds--dropdown-item:active {
  background-color: var(--cds-layer-selected);
}
.cds--dropdown-item:first-of-type .cds--dropdown-link {
  border-top-color: transparent;
}

.cds--dropdown-item:last-of-type .cds--dropdown-link {
  border-bottom: none;
}

.cds--dropdown-link {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  overflow: hidden;
  height: 2.5rem;
  padding: 0.6875rem 0;
  border: 1px solid transparent;
  border-top-color: var(--cds-border-subtle);
  margin: 0 1rem;
  color: var(--cds-text-secondary, #525252);
  font-weight: normal;
  line-height: 1rem;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cds--dropdown-link:hover {
  border-color: transparent;
  color: var(--cds-text-primary, #161616);
}

.cds--dropdown--light .cds--dropdown-link {
  border-top-color: var(--cds-border-subtle-02, #e0e0e0);
}

.cds--dropdown--sm .cds--dropdown-link {
  height: 2rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}

.cds--dropdown--focused,
.cds--dropdown-link:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  padding: 0.6875rem 1rem;
  margin: 0;
}
@media screen and (prefers-contrast) {
  .cds--dropdown--focused,
  .cds--dropdown-link:focus {
    outline-style: dotted;
  }
}

.cds--dropdown-list[aria-activedescendant] .cds--dropdown-link:focus {
  padding: 0.6875rem 0;
  margin: 0 1rem;
  outline: none;
}

.cds--dropdown-list[aria-activedescendant] .cds--dropdown--focused:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  padding: 0.6875rem 1rem;
  margin: 0;
}
@media screen and (prefers-contrast) {
  .cds--dropdown-list[aria-activedescendant] .cds--dropdown--focused:focus {
    outline-style: dotted;
  }
}

.cds--dropdown-list[aria-activedescendant] .cds--dropdown-item:active {
  background-color: inherit;
}

.cds--dropdown-item:hover .cds--dropdown-link {
  border-bottom-color: var(--cds-layer-hover);
}

.cds--dropdown--open .cds--dropdown__arrow {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.cds--dropdown--open.cds--dropdown--sm .cds--dropdown-list {
  max-height: 11rem;
}

.cds--dropdown--open .cds--dropdown-item {
  opacity: 1;
}

.cds--dropdown--disabled {
  border-bottom-color: transparent;
}
.cds--dropdown--disabled:hover {
  background-color: var(--cds-field);
}
.cds--dropdown--disabled:focus {
  outline: none;
}
.cds--dropdown--disabled .cds--dropdown-text,
.cds--dropdown--disabled .cds--list-box__label {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}
.cds--dropdown--disabled .cds--dropdown__arrow,
.cds--dropdown--disabled .cds--list-box__menu-icon svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}
.cds--dropdown--disabled.cds--dropdown--light:hover {
  background-color: var(--cds-field);
}

.cds--dropdown--disabled .cds--list-box__field,
.cds--dropdown--disabled .cds--list-box__menu-icon {
  cursor: not-allowed;
}

.cds--dropdown--auto-width {
  width: auto;
  max-width: 25rem;
}

.cds--dropdown--inline {
  display: inline-block;
  width: auto;
  border-bottom-color: transparent;
  background-color: transparent;
  justify-self: start;
  -webkit-transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9);
}
.cds--dropdown--inline:hover {
  background-color: var(--cds-layer-hover);
}
.cds--dropdown--inline.cds--dropdown--disabled {
  background-color: transparent;
}
.cds--dropdown--inline .cds--dropdown__arrow {
  top: 0.5rem;
  right: 0.5rem;
}

.cds--dropdown--inline.cds--dropdown--open {
  background-color: transparent;
}

.cds--dropdown--inline .cds--dropdown-text {
  display: inline-block;
  overflow: visible;
  height: 2rem;
  padding: 0.4375rem 2rem 0.4375rem 0.75rem;
  color: var(--cds-text-primary, #161616);
}

.cds--dropdown--inline.cds--dropdown--disabled .cds--dropdown-text {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--dropdown--inline.cds--dropdown--disabled:focus .cds--dropdown-text {
  outline: 0;
}

.cds--dropdown--inline.cds--dropdown--invalid .cds--dropdown__invalid-icon {
  right: 2rem;
}

.cds--dropdown--inline.cds--dropdown--invalid .cds--dropdown-text {
  padding-right: 3.5rem;
}

.cds--dropdown--inline.cds--dropdown--open:focus .cds--dropdown-list {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
}

.cds--dropdown--inline .cds--dropdown-link {
  font-weight: normal;
}

.cds--dropdown--show-selected .cds--dropdown--selected {
  display: block;
  background-color: var(--cds-layer-selected);
  color: var(--cds-text-primary, #161616);
}
.cds--dropdown--show-selected .cds--dropdown--selected:hover {
  background-color: var(--cds-layer-selected-hover);
}
.cds--dropdown--show-selected .cds--dropdown--selected .cds--dropdown-link {
  border-top-color: transparent;
}
.cds--dropdown--show-selected .cds--dropdown--selected + .cds--dropdown-item .cds--dropdown-link {
  border-top-color: transparent;
}
.cds--dropdown--show-selected .cds--dropdown--selected .cds--list-box__menu-item__selected-icon {
  display: block;
}

.cds--dropdown-v2.cds--skeleton,
.cds--dropdown.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
}
.cds--dropdown-v2.cds--skeleton:hover, .cds--dropdown-v2.cds--skeleton:focus, .cds--dropdown-v2.cds--skeleton:active,
.cds--dropdown.cds--skeleton:hover,
.cds--dropdown.cds--skeleton:focus,
.cds--dropdown.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--dropdown-v2.cds--skeleton::before,
.cds--dropdown.cds--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--dropdown-v2.cds--skeleton::before,
  .cds--dropdown.cds--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--dropdown .cds--list-box__field {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--list-box__menu-item__option {
    outline: none;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--list-box__menu-item__selected-icon {
    fill: ButtonText;
  }
}

.cds--dropdown--readonly,
.cds--dropdown--readonly:hover {
  background-color: transparent;
}

.cds--dropdown--readonly .cds--list-box__field,
.cds--dropdown--readonly .cds--list-box__menu-icon {
  cursor: default;
}

.cds--dropdown--readonly .cds--list-box__menu-icon svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--loading {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-animation-duration: 690ms;
  animation-duration: 690ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  width: 5.5rem;
  height: 5.5rem;
}
.cds--loading *,
.cds--loading *::before,
.cds--loading *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--loading svg circle {
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  -webkit-animation-name: init-stroke;
  animation-name: init-stroke;
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--loading svg circle {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--loading__svg {
  fill: transparent;
}

.cds--loading__svg circle {
  stroke-dasharray: 276.4608 276.4608;
  stroke-linecap: butt;
  stroke-width: 10;
}

.cds--loading__stroke {
  stroke: var(--cds-interactive, #620b5b);
  stroke-dashoffset: 52.527552;
}

.cds--loading--small .cds--loading__stroke {
  stroke-dashoffset: 143.759616;
}

.cds--loading--stop {
  -webkit-animation: rotate-end-p1 700ms cubic-bezier(0, 0, 0.25, 1) forwards, rotate-end-p2 700ms cubic-bezier(0, 0, 0.25, 1) 700ms forwards;
  animation: rotate-end-p1 700ms cubic-bezier(0, 0, 0.25, 1) forwards, rotate-end-p2 700ms cubic-bezier(0, 0, 0.25, 1) 700ms forwards;
}
.cds--loading--stop svg circle {
  -webkit-animation-delay: 700ms;
  animation-delay: 700ms;
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: stroke-end;
  animation-name: stroke-end;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0, 0, 0.25, 1);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--loading--stop svg circle {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--loading--small {
  width: 1rem;
  height: 1rem;
}
.cds--loading--small circle {
  stroke-width: 16;
}

.cds--loading--small .cds--loading__svg {
  stroke: var(--cds-interactive, #620b5b);
}

.cds--loading__background {
  stroke: var(--cds-layer-accent);
  stroke-dashoffset: -22;
}

@media not all and (-webkit-min-device-pixel-ratio: 0), not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    circle.cds--loading__background {
      stroke-dasharray: 265;
      stroke-dashoffset: 0;
    }
  }
}
.cds--loading-overlay {
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--cds-overlay, rgba(22, 22, 22, 0.5));
  -webkit-transition: background-color 700ms cubic-bezier(0.4, 0.14, 0.3, 1);
  transition: background-color 700ms cubic-bezier(0.4, 0.14, 0.3, 1);
}

.cds--loading-overlay--stop {
  display: none;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
/* Stroke animations */
@-webkit-keyframes init-stroke {
  0% {
    stroke-dashoffset: 276.4608;
  }
  100% {
    stroke-dashoffset: 52.527552;
  }
}
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 276.4608;
  }
  100% {
    stroke-dashoffset: 52.527552;
  }
}
@-webkit-keyframes stroke-end {
  0% {
    stroke-dashoffset: 52.527552;
  }
  100% {
    stroke-dashoffset: 276.4608;
  }
}
@keyframes stroke-end {
  0% {
    stroke-dashoffset: 52.527552;
  }
  100% {
    stroke-dashoffset: 276.4608;
  }
}
.cds--file {
  width: 100%;
}

.cds--file--invalid {
  margin-right: 0.5rem;
  fill: var(--cds-support-error, #da1e28);
}

.cds--file--label {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  margin-bottom: 0.5rem;
  color: var(--cds-text-primary, #161616);
}
.cds--file--label html {
  font-size: 100%;
}
.cds--file--label body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--file--label code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--file--label strong {
  font-weight: 600;
}

.cds--file--label--disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--file-input {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}

.cds--file-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-right: 4rem;
  margin: 0;
}

.cds--file-browse-btn {
  display: inline-block;
  width: 100%;
  max-width: 20rem;
  color: var(--cds-link-primary, #620b5b);
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: -2px;
  -webkit-transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--file-browse-btn:focus, .cds--file-browse-btn:hover {
  outline: 2px solid var(--cds-focus, #620b5b);
}
.cds--file-browse-btn:hover, .cds--file-browse-btn:focus, .cds--file-browse-btn:active, .cds--file-browse-btn:active:visited {
  text-decoration: underline;
}
.cds--file-browse-btn:active {
  color: var(--cds-text-primary, #161616);
}

.cds--file-browse-btn--disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: no-drop;
  text-decoration: none;
}
.cds--file-browse-btn--disabled:hover, .cds--file-browse-btn--disabled:focus {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  outline: none;
  text-decoration: none;
}

.cds--file-browse-btn--disabled .cds--file__drop-container {
  border: 1px dashed var(--cds-button-disabled, #c6c6c6);
}

.cds--label-description {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  margin-bottom: 1rem;
  color: var(--cds-text-secondary, #525252);
}
.cds--label-description html {
  font-size: 100%;
}
.cds--label-description body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--label-description code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--label-description strong {
  font-weight: 600;
}

.cds--label-description--disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--file-container--drop {
  width: 100%;
}

.cds--file-btn ~ .cds--file-container {
  margin-top: 1.5rem;
}

.cds--btn ~ .cds--file-container {
  margin-top: 1rem;
}

.cds--file .cds--file-container,
.cds--file ~ .cds--file-container {
  margin-top: 0.5rem;
}

.cds--file__selected-file {
  display: grid;
  max-width: 20rem;
  min-height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.5rem;
  background-color: var(--cds-layer);
  gap: 0.75rem 1rem;
  grid-auto-rows: auto;
  grid-template-columns: 1fr auto;
  word-break: break-word;
}
.cds--file__selected-file:last-child {
  margin-bottom: 0;
}
.cds--file__selected-file .cds--form-requirement {
  display: block;
  max-height: none;
  margin: 0;
  grid-column: 1/-1;
}
.cds--file__selected-file .cds--inline-loading__animation .cds--loading {
  margin-right: 0;
}
.cds--file__selected-file .cds--file-filename {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  overflow: hidden;
  margin-left: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--file__selected-file--md {
  min-height: 2.5rem;
  gap: 0.5rem 1rem;
}

.cds--file__selected-file--sm {
  min-height: 2rem;
  gap: 0.25rem 1rem;
}

.cds--file__selected-file--invalid__wrapper {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
  max-width: 20rem;
  margin-bottom: 0.5rem;
  background-color: var(--cds-layer);
  outline-width: 1px;
}
@media screen and (prefers-contrast) {
  .cds--file__selected-file--invalid__wrapper {
    outline-style: dotted;
  }
}

.cds--file__selected-file--invalid {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
  padding: 0.75rem 0;
}
@media screen and (prefers-contrast) {
  .cds--file__selected-file--invalid {
    outline-style: dotted;
  }
}

.cds--file__selected-file--invalid.cds--file__selected-file--sm {
  padding: 0.25rem 0;
}

.cds--file__selected-file--invalid.cds--file__selected-file--md {
  padding: 0.5rem 0;
}

.cds--file__selected-file--invalid .cds--form-requirement {
  padding-top: 1rem;
  border-top: 1px solid var(--cds-border-subtle);
}

.cds--file__selected-file--invalid.cds--file__selected-file--sm .cds--form-requirement {
  padding-top: 0.4375rem;
}

.cds--file__selected-file--invalid.cds--file__selected-file--md .cds--form-requirement {
  padding-top: 0.6875rem;
}

.cds--file__selected-file--invalid .cds--form-requirement__title,
.cds--file__selected-file--invalid .cds--form-requirement__supplement {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  padding: 0 1rem;
}

.cds--file__selected-file--invalid .cds--form-requirement__title {
  color: var(--cds-text-error, #da1e28);
}

.cds--file__selected-file--invalid .cds--form-requirement__supplement {
  color: var(--cds-text-primary, #161616);
}

.cds--file__selected-file--invalid + .cds--form-requirement {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  display: block;
  overflow: visible;
  max-height: 12.5rem;
  padding: 0.5rem 1rem;
  color: var(--cds-text-error, #da1e28);
  font-weight: 400;
}

.cds--file__selected-file--invalid + .cds--form-requirement .cds--form-requirement__supplement {
  padding-bottom: 0.5rem;
  color: var(--cds-text-primary, #161616);
}

.cds--file__state-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 1.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-right: 1rem;
}
.cds--file__state-container .cds--loading__svg {
  stroke: var(--cds-icon-primary, #161616);
}

.cds--file__state-container .cds--file-complete {
  fill: var(--cds-interactive, #620b5b);
}
.cds--file__state-container .cds--file-complete:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--file__state-container .cds--file-complete:focus {
    outline-style: dotted;
  }
}
.cds--file__state-container .cds--file-complete [data-icon-path=inner-path] {
  fill: var(--cds-icon-inverse, #ffffff);
  opacity: 1;
}

.cds--file__state-container .cds--file-invalid {
  width: 1rem;
  height: 1rem;
  fill: var(--cds-support-error, #da1e28);
}

.cds--file__state-container .cds--file-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  fill: var(--cds-icon-primary, #161616);
}
.cds--file__state-container .cds--file-close:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--file__state-container .cds--file-close:focus {
    outline-style: dotted;
  }
}

.cds--file__state-container .cds--file-close svg path {
  fill: var(--cds-icon-primary, #161616);
}

.cds--file__state-container .cds--inline-loading__animation {
  margin-right: -0.5rem;
}

.cds--file__drop-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 6rem;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border: 1px dashed var(--cds-border-strong);
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}

.cds--file__drop-container--drag-over {
  background: none;
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--file__selected-file {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--file__state-container .cds--file-close svg path {
    fill: ButtonText;
  }
}

/* stylelint-enable */
.cds--list-box__wrapper--fluid.cds--list-box__wrapper {
  position: relative;
  height: 100%;
  background: var(--cds-field);
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--list-box__wrapper--fluid .cds--list-box {
  min-height: 4rem;
  padding: 0;
}

.cds--list-box__wrapper--fluid .cds--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 2rem);
  height: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.cds--list-box__wrapper--fluid .cds--label::-webkit-scrollbar,
.cds--list-box__wrapper--fluid .cds--label .cds--toggletip-label::-webkit-scrollbar {
  display: none;
}

.cds--list-box__wrapper--fluid .cds--label:not(:has(.cds--toggletip-label)),
.cds--list-box__wrapper--fluid .cds--label .cds--toggletip-label {
  -ms-overflow-style: none;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;
}

.cds--list-box__wrapper--fluid .cds--list-box__field {
  padding-top: 2.0625rem;
  padding-bottom: 0.8125rem;
  padding-left: 1rem;
}

.cds--list-box__wrapper--fluid .cds--list-box__menu-icon {
  width: 1rem;
  height: 1rem;
}

.cds--list-box__wrapper--fluid:not(.cds--list-box__wrapper--fluid--condensed) .cds--list-box__menu-item {
  height: 4rem;
}

.cds--list-box__wrapper--fluid:not(.cds--list-box__wrapper--fluid--condensed) .cds--list-box__menu-item__selected-icon {
  top: 1.25rem;
}

.cds--list-box__wrapper--fluid .cds--label--disabled .cds--toggletip-label {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--list-box__wrapper--fluid .cds--label--disabled .cds--toggletip-button {
  cursor: not-allowed;
  pointer-events: none;
}

.cds--list-box__wrapper--fluid .cds--label--disabled .cds--toggletip-button svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  z-index: 2;
  outline-offset: 0;
}
@media screen and (prefers-contrast) {
  .cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--focus {
    outline-style: dotted;
  }
}

.cds--list-box__wrapper--fluid .cds--list-box__field:focus {
  outline: none;
  outline-offset: 0;
}

.cds--list-box__wrapper--fluid :not(.cds--list-box--up) .cds--list-box__menu {
  top: calc(100% + 0.1875rem);
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid:not(.cds--list-box__wrapper--fluid--focus) {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
  z-index: 2;
  outline-offset: 0;
}
@media screen and (prefers-contrast) {
  .cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid:not(.cds--list-box__wrapper--fluid--focus) {
    outline-style: dotted;
  }
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--focus .cds--list-box:not(.cds--list-box--invalid),
.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--focus .cds--list-box .cds--text-input,
.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid .cds--list-box .cds--text-input,
.cds--list-box__wrapper--fluid .cds--combo-box.cds--list-box--warning .cds--text-input {
  border-bottom: 1px solid transparent;
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid .cds--list-box,
.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid .cds--list-box__field:focus,
.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid .cds--combo-box[data-invalid] .cds--text-input {
  outline: none;
  outline-offset: 0;
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid:focus-within {
  outline-offset: 0;
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid .cds--list-box,
.cds--list-box__wrapper--fluid .cds--list-box--warning {
  border-bottom: 1px solid transparent;
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid .cds--form-requirement,
.cds--list-box__wrapper--fluid .cds--list-box--warning ~ .cds--form-requirement {
  padding: 0.5rem 4rem 0.5rem 1rem;
  margin-top: 0;
}

.cds--list-box__wrapper--fluid .cds--list-box--warning ~ .cds--form-requirement {
  border-bottom: 1px solid var(--cds-border-strong);
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid .cds--list-box__invalid-icon,
.cds--list-box__wrapper--fluid .cds--list-box--warning .cds--list-box__invalid-icon {
  top: 5.0625rem;
  right: 1rem;
  pointer-events: none;
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid .cds--list-box[data-invalid]:not(.cds--combo-box) .cds--list-box__field {
  padding-right: 3rem;
}

.cds--list-box__wrapper--fluid.cds--list-box__wrapper--fluid--invalid .cds--list-box[data-invalid].cds--combo-box .cds--text-input {
  padding-right: 4rem;
}

.cds--list-box__wrapper--fluid .cds--list-box__divider {
  display: none;
  -webkit-transition: border-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: border-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--list-box__wrapper--fluid .cds--list-box--invalid ~ .cds--list-box__divider,
.cds--list-box__wrapper--fluid .cds--list-box--warning ~ .cds--list-box__divider {
  display: block;
  border: none;
  border-bottom: 1px solid var(--cds-border-subtle);
  margin: 0 1rem;
}

.cds--list-box__wrapper--fluid .cds--list-box--invalid:hover:not(.cds--combo-box) ~ .cds--list-box__divider,
.cds--list-box__wrapper--fluid .cds--list-box--warning:hover:not(.cds--combo-box) ~ .cds--list-box__divider {
  border-color: transparent;
}

.cds--list-box__wrapper--fluid .cds--list-box--up .cds--list-box__menu {
  bottom: 4rem;
}

.cds--list-box__wrapper--fluid .cds--skeleton {
  border-bottom: 1px solid var(--cds-skeleton-element, #c6c6c6);
  background: var(--cds-skeleton-background, #e8e8e8);
}

.cds--list-box__wrapper--fluid .cds--skeleton .cds--list-box__field {
  position: absolute;
  top: 2.25rem;
  left: 1rem;
  width: 50%;
  height: 0.5rem;
  padding: 0;
}

.cds--list-box__wrapper--fluid .cds--skeleton .cds--list-box__label {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 25%;
  height: 0.5rem;
}

.cds--list-box__wrapper--fluid .cds--skeleton .cds--list-box__field,
.cds--list-box__wrapper--fluid .cds--skeleton .cds--list-box__label {
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
}

.cds--list-box__wrapper--fluid .cds--combo-box .cds--list-box__field {
  overflow: visible;
  padding: 0;
}

.cds--list-box__wrapper--fluid .cds--combo-box .cds--text-input {
  overflow: hidden;
  padding: 2.0625rem 4rem 0.8125rem 1rem;
  text-overflow: ellipsis;
  -webkit-transition: none;
  transition: none;
  white-space: nowrap;
}

.cds--list-box__wrapper--fluid .cds--combo-box .cds--text-input:focus {
  outline: none;
}

.cds--list-box__wrapper--fluid .cds--combo-box .cds--list-box__selection {
  top: auto;
  bottom: 0.625rem;
  -webkit-transform: none;
  transform: none;
}

.cds--list-box__wrapper--fluid .cds--combo-box .cds--list-box__menu-icon {
  bottom: 0.875rem;
}

.cds--list-box__wrapper--fluid .cds--list-box[data-invalid] .cds--list-box__field .cds--text-input + .cds--list-box__invalid-icon,
.cds--list-box__wrapper--fluid .cds--list-box--warning .cds--list-box__field .cds--text-input + .cds--list-box__invalid-icon {
  right: 1rem;
}

.cds--date-picker--fluid {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: var(--cds-field);
}

.cds--date-picker--fluid .cds--date-picker {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--date-picker--fluid .cds--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 2rem);
  height: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.cds--date-picker--fluid .cds--label::-webkit-scrollbar,
.cds--date-picker--fluid .cds--label .cds--toggletip-label::-webkit-scrollbar {
  display: none;
}

.cds--date-picker--fluid .cds--label:not(:has(.cds--toggletip-label)),
.cds--date-picker--fluid .cds--label .cds--toggletip-label {
  -ms-overflow-style: none;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;
}

.cds--date-picker--fluid .cds--date-picker-input__wrapper .cds--date-picker__input {
  min-width: 9rem;
  min-height: 4rem;
  padding: 2rem 1rem 0.8125rem;
  border-bottom: none;
  background: transparent;
}

.cds--date-picker--fluid .cds--date-picker--simple .cds--date-picker__icon {
  display: none;
}

.cds--date-picker--fluid .cds--date-picker--simple .cds--date-picker__input:not(.cds--date-picker__input--invalid):not(.cds--date-picker__input--warn),
.cds--date-picker--fluid .cds--date-picker--simple .cds--date-picker--fluid--warn {
  border-bottom: 1px solid var(--cds-border-strong);
}

.cds--date-picker--fluid .cds--date-picker__icon {
  top: 2.6875rem;
}

.cds--date-picker--fluid .cds--date-picker--single .cds--date-picker__input {
  width: 100%;
}

.cds--date-picker--fluid .cds--date-picker--single {
  border-bottom: none;
}

.cds--date-picker--fluid .cds--date-picker--single .cds--date-picker__input:not(.cds--date-picker__input--invalid),
.cds--date-picker--single .cds--date-picker--fluid--warn {
  border-bottom: 1px solid var(--cds-border-strong);
}

.cds--date-picker--fluid:not(.cds--date-picker--fluid--invalid):not(.cds--date-picker--fluid--warn) .cds--date-picker--range .cds--date-picker-container:not(.cds--date-picker--fluid--invalid) {
  border-bottom: 1px solid var(--cds-border-strong);
}

.cds--date-picker--fluid .cds--date-picker--range .cds--date-picker__input,
.cds--date-picker--fluid .cds--date-picker--range .cds--date-picker-container {
  width: 100%;
  min-width: 9rem;
  min-height: 3.9375rem;
}

.cds--date-picker--fluid .cds--date-picker--range > .cds--date-picker-container:first-child {
  margin-right: 0;
}

.cds--date-picker--fluid .cds--date-picker--range > .cds--date-picker-container:last-child .cds--date-picker__input {
  border-left: 1px solid var(--cds-border-strong);
}

.cds--date-picker--fluid.cds--date-picker--fluid--invalid,
.cds--date-picker-container.cds--date-picker--fluid--invalid {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--date-picker--fluid.cds--date-picker--fluid--invalid,
  .cds--date-picker-container.cds--date-picker--fluid--invalid {
    outline-style: dotted;
  }
}

.cds--date-picker--fluid .cds--date-picker-container.cds--date-picker--fluid--invalid .cds--date-picker__input--invalid {
  outline: none;
}

.cds--date-picker--fluid .cds--date-picker-container:last-child.cds--date-picker--fluid--invalid .cds--date-picker__input--invalid,
.cds--date-picker--fluid .cds--date-picker-container.cds--date-picker--fluid--invalid + .cds--date-picker-container .cds--date-picker__input {
  border-left: none;
}

.cds--date-picker-container.cds--date-picker--fluid--invalid .cds--form-requirement,
.cds--date-picker-container.cds--date-picker--fluid--warn .cds--form-requirement {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0;
}

.cds--date-picker-container.cds--date-picker--fluid--invalid .cds--date-picker--fluid.cds--date-picker--fluid--invalid .cds--date-picker,
.cds--date-picker--fluid.cds--date-picker--fluid--invalid .cds--date-picker,
.cds--date-picker--fluid .cds--date-picker--single .cds--date-picker--fluid--warn .cds--date-picker__input,
.cds--date-picker--fluid.cds--date-picker--fluid--warn .cds--date-picker--range .cds--date-picker-container {
  border-bottom: 1px solid transparent;
}

.cds--date-picker--fluid.cds--date-picker--fluid--invalid .cds--date-picker--range > .cds--date-picker-container:last-child .cds--date-picker__input,
.cds--date-picker--fluid.cds--date-picker--fluid--warn .cds--date-picker--range > .cds--date-picker-container:last-child .cds--date-picker__input,
.cds--date-picker--fluid .cds--date-picker--fluid--warn + .cds--date-picker-container:last-child .cds--date-picker__input,
.cds--date-picker--fluid .cds--date-picker--fluid--warn.cds--date-picker-container:last-child .cds--date-picker__input {
  border-left: 1px solid transparent;
}

.cds--date-picker--fluid.cds--date-picker--fluid--invalid .cds--date-picker--range > .cds--date-picker-container:first-child::after,
.cds--date-picker--fluid.cds--date-picker--fluid--warn .cds--date-picker--range > .cds--date-picker-container:first-child::after,
.cds--date-picker--fluid .cds--date-picker--range .cds--date-picker--fluid--warn.cds--date-picker-container:first-child::after,
.cds--date-picker--fluid .cds--date-picker--range .cds--date-picker--fluid--warn.cds--date-picker-container:last-child::after {
  position: absolute;
  top: 0.5rem;
  right: 0;
  display: block;
  width: 1px;
  height: calc(100% - 1rem);
  background: var(--cds-border-strong);
  content: "";
}

.cds--date-picker--fluid .cds--date-picker--fluid--warn.cds--date-picker-container:last-child::after {
  left: 0;
}

.cds--date-picker--fluid .cds--date-picker__divider {
  width: calc(100% - 2rem);
  border-style: solid;
  border-color: var(--cds-border-subtle);
  border-bottom: none;
  margin: 0 1rem;
}

.cds--date-picker-container.cds--date-picker--fluid--invalid .cds--date-picker__icon--invalid,
.cds--date-picker-container.cds--date-picker--fluid--warn .cds--date-picker__icon--warn {
  top: 5rem;
}

.cds--date-picker--fluid .cds--date-picker--simple .cds--date-picker--fluid--invalid .cds--date-picker__icon--invalid,
.cds--date-picker--fluid .cds--date-picker--simple .cds--date-picker--fluid--warn .cds--date-picker__icon--warn {
  display: block;
}

.cds--date-picker-container.cds--date-picker--fluid--invalid .cds--date-picker__input:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--date-picker-container.cds--date-picker--fluid--invalid .cds--date-picker__input:focus {
    outline-style: dotted;
  }
}

.cds--date-picker--fluid.cds--date-picker--fluid--invalid .cds--date-picker--range ~ .cds--form-requirement,
.cds--date-picker--fluid.cds--date-picker--fluid--warn .cds--date-picker--range ~ .cds--form-requirement {
  display: block;
  overflow: visible;
  max-height: 100%;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin-top: 0;
}

.cds--date-picker--fluid.cds--date-picker--fluid--invalid .cds--date-picker--range ~ .cds--form-requirement {
  color: var(--cds-text-error, #da1e28);
}

.cds--date-picker--fluid.cds--date-picker--fluid--invalid,
.cds--date-picker--fluid.cds--date-picker--fluid--warn {
  position: relative;
}

.cds--date-picker--fluid.cds--date-picker--fluid--invalid .cds--date-picker--range + .cds--date-picker__icon,
.cds--date-picker--fluid.cds--date-picker--fluid--warn .cds--date-picker--range + .cds--date-picker__icon {
  top: 5rem;
}

.cds--date-picker--fluid__skeleton {
  height: 4rem;
  border-bottom: 1px solid var(--cds-skeleton-element, #c6c6c6);
  background: var(--cds-skeleton-background, #e8e8e8);
}

.cds--date-picker--fluid__skeleton--container {
  position: relative;
  width: 100%;
  height: 100%;
}

.cds--date-picker--fluid__skeleton .cds--skeleton {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 25%;
  height: 0.5rem;
}

.cds--date-picker--fluid__skeleton .cds--label {
  margin-bottom: 0.25rem;
}

.cds--date-picker--fluid__skeleton .cds--text-input {
  position: absolute;
  top: 2.25rem;
  left: 1rem;
  width: 50%;
  height: 0.5rem;
  padding: 0;
}

.cds--date-picker--fluid__skeleton--container .cds--date-picker__icon {
  top: auto;
  bottom: 0.5rem;
}

.cds--date-picker--fluid__skeleton--range {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.cds--date-picker--fluid__skeleton--range .cds--date-picker--fluid__skeleton--container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cds--date-picker--fluid__skeleton--range .cds--date-picker--fluid__skeleton--container:first-of-type {
  border-right: 1px solid var(--cds-skeleton-element, #c6c6c6);
}

.cds--date-picker--fluid__skeleton--range .cds--date-picker--fluid__skeleton--container .cds--date-picker__icon {
  bottom: 0.5rem;
}

.cds--list-box__wrapper--fluid .cds--multi-select--filterable--input-focused {
  outline: none;
  outline-offset: 0;
}

.cds--list-box__wrapper--fluid .cds--multi-select--filterable .cds--list-box__field .cds--text-input {
  border-bottom: 1px solid transparent;
}

.cds--list-box__wrapper--fluid .cds--multi-select--filterable.cds--combo-box .cds--list-box__field {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.cds--number {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.cds--number html {
  font-size: 100%;
}
.cds--number body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--number code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--number strong {
  font-weight: 600;
}

.cds--number input[type=number] {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  min-width: 9.375rem;
  height: 2.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 8rem;
  padding-left: 1rem;
  border: 0;
  border-bottom: 0.0625rem solid var(--cds-border-strong);
  -moz-appearance: textfield;
  background-color: var(--cds-field);
  border-radius: 0;
  color: var(--cds-text-primary, #161616);
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  font-weight: 400;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--number input[type=number]:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--number input[type=number]:focus {
    outline-style: dotted;
  }
}
.cds--number input[type=number]:disabled ~ .cds--number__controls {
  cursor: not-allowed;
  pointer-events: none;
}
.cds--number input[type=number]:disabled ~ .cds--number__controls svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}
.cds--number input[type=number]::-ms-clear {
  display: none;
}
.cds--number input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.cds--number--lg.cds--number input[type=number] {
  padding-right: 9rem;
}

.cds--number--sm.cds--number input[type=number] {
  padding-right: 7rem;
}

.cds--number input[type=number]:disabled {
  border-bottom-color: transparent;
  background-color: var(--cds-field);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--number__input-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--number__controls {
  position: absolute;
  top: 50%;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 5rem;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cds--number__controls html {
  font-size: 100%;
}
.cds--number__controls body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--number__controls code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--number__controls strong {
  font-weight: 600;
}

.cds--number__control-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 0.0625rem solid var(--cds-border-strong);
  color: var(--cds-icon-primary, #161616);
}
.cds--number__control-btn *,
.cds--number__control-btn *::before,
.cds--number__control-btn *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--number__control-btn::-moz-focus-inner {
  border: 0;
}
.cds--number__control-btn::before, .cds--number__control-btn::after {
  position: absolute;
  top: 0.125rem;
  display: block;
  width: 0.125rem;
  height: 2.25rem;
  background-color: var(--cds-field);
  content: "";
}
.cds--number__control-btn::before {
  left: 0;
}
.cds--number__control-btn::after {
  right: 0;
}
.cds--number__control-btn svg {
  fill: currentColor;
}
.cds--number__control-btn:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
  color: var(--cds-icon-primary, #161616);
  outline-offset: -2px;
  outline-width: 2px;
}
@media screen and (prefers-contrast) {
  .cds--number__control-btn:focus {
    outline-style: dotted;
  }
}
.cds--number__control-btn:hover {
  background-color: var(--cds-field-hover);
  color: var(--cds-icon-primary, #161616);
  cursor: pointer;
}
.cds--number__control-btn:hover::before, .cds--number__control-btn:hover::after {
  background-color: var(--cds-field-hover);
}
.cds--number__control-btn:focus::before, .cds--number__control-btn:focus::after, .cds--number__control-btn:hover:focus::before, .cds--number__control-btn:hover:focus::after {
  background-color: transparent;
}
.cds--number__control-btn:disabled {
  border-bottom-color: transparent;
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--number__control-btn.down-icon {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.cds--number__control-btn.up-icon {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.cds--number input[type=number]:focus ~ .cds--number__controls .cds--number__control-btn {
  border-bottom-width: 0;
}
.cds--number input[type=number]:focus ~ .cds--number__controls .cds--number__control-btn:hover {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  border: 0;
}
@media screen and (prefers-contrast) {
  .cds--number input[type=number]:focus ~ .cds--number__controls .cds--number__control-btn:hover {
    outline-style: dotted;
  }
}

.cds--number input[type=number][data-invalid] ~ .cds--number__controls .cds--number__control-btn {
  border-bottom-width: 0;
}

.cds--number input[type=number][data-invalid]:not(:focus) ~ .cds--number__controls .cds--number__control-btn:hover {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--number input[type=number][data-invalid]:not(:focus) ~ .cds--number__controls .cds--number__control-btn:hover {
    outline-style: dotted;
  }
}

.cds--number input[type=number]:focus ~ .cds--number__controls .cds--number__control-btn.up-icon::after {
  background-color: transparent;
}

.cds--number input[type=number][data-invalid] ~ .cds--number__controls .cds--number__control-btn.up-icon::after {
  background-color: var(--cds-support-error, #da1e28);
}

.cds--number input[type=number][data-invalid]:focus ~ .cds--number__controls .cds--number__control-btn.up-icon::after,
.cds--number input[type=number][data-invalid] ~ .cds--number__controls .cds--number__control-btn.up-icon:focus::after {
  background-color: var(--cds-focus, #620b5b);
}

.cds--number__rule-divider {
  position: absolute;
  z-index: 6000;
  width: 0.0625rem;
  height: 1rem;
  background-color: var(--cds-border-subtle);
}
.cds--number__rule-divider:first-of-type {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.cds--number__controls .cds--number__rule-divider:first-of-type {
  left: 0;
  background-color: transparent;
}

.cds--number__invalid + .cds--number__controls .cds--number__rule-divider:first-of-type {
  background-color: var(--cds-border-subtle);
}

.cds--number--light .cds--number__rule-divider,
.cds--number--light .cds--number__invalid + .cds--number__controls .cds--number__rule-divider:first-of-type {
  background-color: var(--cds-border-subtle-02, #e0e0e0);
}

.cds--number input[type=number]:disabled + .cds--number__controls .cds--number__rule-divider:first-of-type {
  background-color: transparent;
}

.cds--number input[type=number]:disabled + .cds--number__controls .cds--number__rule-divider {
  background-color: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--number__control-btn:focus ~ .cds--number__rule-divider {
  background-color: transparent;
}

.cds--number__invalid {
  position: absolute;
  right: 6rem;
  fill: var(--cds-support-error, #da1e28);
}

.cds--number--lg .cds--number__invalid {
  right: 7rem;
}

.cds--number--sm .cds--number__invalid {
  right: 5rem;
}

.cds--number__invalid + .cds--number__rule-divider {
  position: absolute;
  right: 5rem;
}

.cds--number--lg .cds--number__invalid + .cds--number__rule-divider {
  right: 6rem;
}

.cds--number--sm .cds--number__invalid + .cds--number__rule-divider {
  right: 4rem;
}

.cds--number__control-btn.down-icon:hover ~ .cds--number__rule-divider,
.cds--number__control-btn.up-icon:hover + .cds--number__rule-divider,
.cds--number__control-btn.down-icon:focus ~ .cds--number__rule-divider,
.cds--number__control-btn.up-icon:focus + .cds--number__rule-divider {
  background-color: transparent;
}

.cds--number__invalid--warning {
  fill: var(--cds-support-warning, #f1c21b);
}

.cds--number__invalid--warning path:first-of-type {
  fill: #000000;
  opacity: 1;
}

.cds--number--light input[type=number] {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--number--light input[type=number]:disabled {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--number--light .cds--number__control-btn::before,
.cds--number--light .cds--number__control-btn::after {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--number--light .cds--number__control-btn:focus::before,
.cds--number--light .cds--number__control-btn:focus::after {
  background-color: transparent;
}

.cds--number--light .cds--number__control-btn:hover,
.cds--number--light .cds--number__control-btn:not(:focus):hover::before,
.cds--number--light .cds--number__control-btn:not(:focus):hover::after {
  background-color: var(--cds-layer-hover-02, #e8e8e8);
}

.cds--number--lg input[type=number] {
  height: 3rem;
}

.cds--number--lg .cds--number__controls {
  width: 6rem;
}

.cds--number--lg .cds--number__control-btn {
  width: 3rem;
}
.cds--number--lg .cds--number__control-btn::before, .cds--number--lg .cds--number__control-btn::after {
  height: 2.75rem;
}

.cds--number--sm input[type=number] {
  height: 2rem;
}

.cds--number--sm .cds--number__controls {
  width: 4rem;
}

.cds--number--sm .cds--number__control-btn {
  width: 2rem;
}
.cds--number--sm .cds--number__control-btn::before, .cds--number--sm .cds--number__control-btn::after {
  height: 1.75rem;
}

.cds--number--nolabel .cds--label + .cds--form__helper-text {
  margin-top: 0;
}

.cds--number--nosteppers input[type=number] {
  padding-right: 3rem;
}

.cds--number--nosteppers .cds--number__invalid {
  right: 1rem;
}

.cds--number--readonly input[type=number] {
  background: transparent;
}

.cds--number--readonly .cds--number__control-btn {
  color: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
  pointer-events: none;
}
.cds--number--readonly .cds--number__control-btn:hover {
  background-color: transparent;
  cursor: pointer;
}
.cds--number--readonly .cds--number__control-btn:hover::before, .cds--number--readonly .cds--number__control-btn:hover::after {
  background-color: transparent;
}

.cds--number--readonly input[type=number]:focus ~ .cds--number__controls .cds--number__control-btn:hover {
  outline: none;
}

.cds--number--readonly .cds--number__control-btn::before,
.cds--number--readonly .cds--number__control-btn::after {
  background: transparent;
}

.cds--number--readonly .cds--number__controls:hover .cds--number__rule-divider:not(:first-of-type) {
  background-color: var(--cds-border-subtle);
}

.cds--number.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 100%;
  height: 2.5rem;
}
.cds--number.cds--skeleton:hover, .cds--number.cds--skeleton:focus, .cds--number.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--number.cds--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--number.cds--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}
.cds--number.cds--skeleton input[type=number] {
  display: none;
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--number__control-btn:hover,
  .cds--number__control-btn:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--number__control-btn {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--number__control-btn svg {
    fill: ButtonText;
  }
}

/* stylelint-enable */
.cds--number-input--fluid {
  position: relative;
  height: 100%;
  background: var(--cds-field);
}

.cds--number-input--fluid .cds--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 2rem);
  height: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.cds--number-input--fluid .cds--label::-webkit-scrollbar,
.cds--number-input--fluid .cds--label .cds--toggletip-label::-webkit-scrollbar {
  display: none;
}

.cds--number-input--fluid .cds--label:not(:has(.cds--toggletip-label)),
.cds--number-input--fluid .cds--label .cds--toggletip-label {
  -ms-overflow-style: none;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;
}

.cds--number-input--fluid input[type=number] {
  min-height: 4rem;
  padding: 2rem 5rem 0.8125rem 1rem;
  background: transparent;
  outline: none;
}

.cds--number-input--fluid .cds--number__controls {
  top: 1.4375rem;
  height: 2.5rem;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.cds--number-input--fluid .cds--number__control-btn,
.cds--number-input--fluid input[type=number]:focus ~ .cds--number__controls .cds--number__control-btn {
  border-bottom: 1px solid transparent;
}

.cds--number-input--fluid.cds--number-input--fluid--focus .cds--number {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--number-input--fluid.cds--number-input--fluid--focus .cds--number {
    outline-style: dotted;
  }
}

.cds--number-input--fluid:not(.cds--number-input--fluid--invalid) .cds--number .cds--number__input-wrapper:not(.cds--number__input-wrapper--warning) input[type=number]:focus ~ .cds--number__controls .cds--number__control-btn:hover {
  outline: 2px solid transparent;
}

.cds--number-input--fluid:not(.cds--number-input--fluid--invalid) .cds--number .cds--number__input-wrapper:not(.cds--number__input-wrapper--warning) input[type=number]:focus ~ .cds--number__controls .cds--number__control-btn:hover::before {
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--cds-focus, #620b5b);
}

.cds--number-input--fluid:not(.cds--number-input--fluid--invalid) .cds--number-input-wrapper:not(.cds--number-input-wrapper__warning) input[type=number]:focus ~ .cds--number__controls .cds--number__control-btn:hover {
  border-bottom: 1px solid var(--cds-focus, #620b5b);
  outline: none;
}

.cds--number-input--fluid input[type=number] ~ .cds--number__controls .cds--number__control-btn::after {
  top: 0;
  height: calc(100% - 0.0625rem);
}

.cds--number-input--fluid.cds--number-input--fluid--focus input[type=number] ~ .cds--number__controls .cds--number__control-btn.up-icon::after {
  background: var(--cds-focus, #620b5b);
}

.cds--number-input--fluid input[type=number] ~ .cds--number__controls .cds--number__control-btn.up-icon::after {
  height: 100%;
}

.cds--number-input--fluid.cds--number-input--fluid--focus input[type=number] ~ .cds--number__controls .cds--number__control-btn.up-icon:hover::after {
  height: 100%;
  background-color: var(--cds-focus, #620b5b);
}

.cds--number-input--fluid--invalid input[type=number][data-invalid] ~ .cds--number__controls .cds--number__control-btn:focus,
.cds--number-input--fluid--invalid .cds--number input[type=number][data-invalid] ~ .cds--number__controls .cds--number__control-btn:focus:hover {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--number-input--fluid--invalid input[type=number][data-invalid] ~ .cds--number__controls .cds--number__control-btn:focus,
  .cds--number-input--fluid--invalid .cds--number input[type=number][data-invalid] ~ .cds--number__controls .cds--number__control-btn:focus:hover {
    outline-style: dotted;
  }
}

.cds--number-input--fluid .cds--number-input__divider {
  display: none;
}

.cds--number-input--fluid .cds--number__invalid {
  top: 4.5625rem;
  right: 1rem;
  pointer-events: none;
}

.cds--number-input--fluid .cds--number__controls .cds--number__rule-divider:first-of-type {
  display: none;
}

.cds--number-input--fluid .cds--number__input-wrapper--warning input[type=number] {
  border-bottom: 1px solid transparent;
}

.cds--number-input--fluid .cds--number__input-wrapper--warning input[type=number]:focus {
  outline: none;
}

.cds--number-input--fluid.cds--number-input--fluid--invalid .cds--number-input__divider,
.cds--number-input--fluid .cds--number__input-wrapper--warning + .cds--number-input__divider {
  position: absolute;
  top: 3.9375rem;
  display: block;
  width: calc(100% - 2rem);
  height: 0.0625rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-subtle);
  margin: 0 1rem;
}

.cds--number-input--fluid .cds--form-requirement {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0;
}

.cds--number-input--fluid.cds--number-input--fluid--invalid:not(.cds--number-input--fluid--focus) .cds--number {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--number-input--fluid.cds--number-input--fluid--invalid:not(.cds--number-input--fluid--focus) .cds--number {
    outline-style: dotted;
  }
}

.cds--number-input--fluid--invalid .cds--number[data-invalid] input[type=number] {
  border-bottom: 1px solid transparent;
}

.cds--number-input--fluid--invalid .cds--number[data-invalid] input[type=number],
.cds--number-input--fluid--invalid .cds--number input[type=number][data-invalid] ~ .cds--number__controls .cds--number__control-btn:hover,
.cds--number-input--fluid .cds--number__input-wrapper--warning input[type=number] ~ .cds--number__controls .cds--number__control-btn:hover:not(:focus) {
  outline: none;
}

.cds--number-input--fluid.cds--number-input--invalid .cds--number__input-wrapper input[type=number] ~ .cds--number__controls .cds--number__control-btn,
.cds--number-input--fluid .cds--number__input-wrapper--warning input[type=number] ~ .cds--number__controls .cds--number__control-btn {
  border-bottom: none;
}

.cds--number-input--fluid--invalid .cds--number input[type=number][data-invalid] ~ .cds--number__controls .cds--number__control-btn,
.cds--number-input--fluid .cds--number input[type=number] ~ .cds--number__controls .cds--number__control-btn {
  border: initial;
  border-bottom-width: 0.0625rem;
}

.cds--number-input--fluid--disabled:not(.cds--number-input--fluid--invalid) .cds--number {
  border-bottom: 1px solid var(--cds-border-subtle);
}

.cds--number-input--fluid--disabled.cds--number-input--fluid--invalid .cds--number input[type=number]:disabled {
  background-color: transparent;
}

.cds--search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--search .cds--label {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}

.cds--search-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 100%;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding: 0 2.5rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--cds-field);
  color: var(--cds-text-primary, #161616);
  text-overflow: ellipsis;
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--search-input *,
.cds--search-input *::before,
.cds--search-input *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--search-input:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--search-input:focus {
    outline-style: dotted;
  }
}
.cds--search-input::-webkit-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--search-input::-moz-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--search-input::-ms-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--search-input::placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--search-input::-ms-clear {
  display: none;
}

.cds--search-input[disabled] {
  border-bottom: 1px solid transparent;
  background-color: var(--cds-field);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}
.cds--search-input[disabled]::-webkit-input-placeholder {
  color: var(--cds-field);
}
.cds--search-input[disabled]::-moz-placeholder {
  color: var(--cds-field);
}
.cds--search-input[disabled]::-ms-input-placeholder {
  color: var(--cds-field);
}
.cds--search-input[disabled]::placeholder {
  color: var(--cds-field);
}

.cds--search--light .cds--search-input {
  background: var(--cds-field-02, #ffffff);
}

.cds--search--light .cds--search-close::before {
  background: var(--cds-field-02, #ffffff);
}

.cds--search--sm .cds--search-input,
.cds--search--sm.cds--search--expandable.cds--search--expanded .cds--search-input {
  height: 2rem;
  padding: 0 2rem;
}

.cds--search--sm .cds--search-magnifier-icon {
  left: 0.5rem;
}

.cds--search--md .cds--search-input,
.cds--search--md.cds--search--expandable.cds--search--expanded .cds--search-input {
  height: 2.5rem;
  padding: 0 2.5rem;
}

.cds--search--md .cds--search-magnifier-icon {
  left: 0.75rem;
}

.cds--search--lg .cds--search-input,
.cds--search--lg.cds--search--expandable.cds--search--expanded .cds--search-input {
  height: 3rem;
  padding: 0 3rem;
}

.cds--search-magnifier-icon {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 1rem;
  width: 1rem;
  height: 1rem;
  fill: var(--cds-icon-secondary, #525252);
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cds--search-close {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  top: 0;
  right: 0;
}
.cds--search-close *,
.cds--search-close *::before,
.cds--search-close *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--search-close::-moz-focus-inner {
  border: 0;
}
.cds--search-close::before {
  position: absolute;
  top: 0.0625rem;
  left: 0;
  display: block;
  width: 2px;
  height: calc(100% - 2px);
  background-color: var(--cds-field);
  content: "";
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--search-close::before {
    -webkit-transition: none;
    transition: none;
  }
}
.cds--search-close:hover {
  border-bottom: 1px solid var(--cds-border-strong);
}
.cds--search-close:hover::before {
  background-color: var(--cds-field-hover);
}

.cds--search-button {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 0.125rem;
  background-color: var(--cds-field);
}
.cds--search-button svg {
  fill: currentColor;
  vertical-align: middle;
}

.cds--search-close svg {
  fill: inherit;
}

.cds--search-close,
.cds--search-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-width: 1px 0;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  fill: var(--cds-icon-primary, #161616);
  opacity: 1;
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), border 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), border 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  visibility: inherit;
}
.cds--search-close:hover,
.cds--search-button:hover {
  background-color: var(--cds-field-hover);
}
.cds--search-close:focus,
.cds--search-button:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--search-close:focus,
  .cds--search-button:focus {
    outline-style: dotted;
  }
}
.cds--search-close:active,
.cds--search-button:active {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
}
@media screen and (prefers-contrast) {
  .cds--search-close:active,
  .cds--search-button:active {
    outline-style: dotted;
  }
}

.cds--search--disabled .cds--search-close,
.cds--search--disabled.cds--search--expandable .cds--search-magnifier {
  cursor: not-allowed;
  outline: none;
}
.cds--search--disabled .cds--search-close:hover,
.cds--search--disabled.cds--search--expandable .cds--search-magnifier:hover {
  border-bottom-color: transparent;
  background-color: transparent;
}
.cds--search--disabled .cds--search-close:hover::before,
.cds--search--disabled.cds--search--expandable .cds--search-magnifier:hover::before {
  background-color: transparent;
}

.cds--search--disabled svg {
  fill: var(--cds-icon-on-color-disabled, #8d8d8d);
}

.cds--search-close:focus::before,
.cds--search-close:active::before {
  background-color: var(--cds-focus, #620b5b);
}

.cds--search-input:focus ~ .cds--search-close:hover {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--search-input:focus ~ .cds--search-close:hover {
    outline-style: dotted;
  }
}

.cds--search--sm .cds--search-close,
.cds--search--sm ~ .cds--search-button, .cds--search--sm.cds--search--expandable, .cds--search--sm.cds--search--expandable .cds--search-magnifier {
  width: 2rem;
  height: 2rem;
}
.cds--search--sm.cds--search--expandable .cds--search-input::-webkit-input-placeholder {
  padding: 0 2rem;
}
.cds--search--sm.cds--search--expandable .cds--search-input::-moz-placeholder {
  padding: 0 2rem;
}
.cds--search--sm.cds--search--expandable .cds--search-input::-ms-input-placeholder {
  padding: 0 2rem;
}
.cds--search--sm.cds--search--expandable .cds--search-input::placeholder {
  padding: 0 2rem;
}

.cds--search--md .cds--search-close,
.cds--search--md ~ .cds--search-button, .cds--search--md.cds--search--expandable, .cds--search--md.cds--search--expandable .cds--search-magnifier {
  width: 2.5rem;
  height: 2.5rem;
}
.cds--search--md.cds--search--expandable .cds--search-input::-webkit-input-placeholder {
  padding: 0 2.5rem;
}
.cds--search--md.cds--search--expandable .cds--search-input::-moz-placeholder {
  padding: 0 2.5rem;
}
.cds--search--md.cds--search--expandable .cds--search-input::-ms-input-placeholder {
  padding: 0 2.5rem;
}
.cds--search--md.cds--search--expandable .cds--search-input::placeholder {
  padding: 0 2.5rem;
}

.cds--search--lg .cds--search-close,
.cds--search--lg ~ .cds--search-button, .cds--search--lg.cds--search--expandable, .cds--search--lg.cds--search--expandable .cds--search-magnifier {
  width: 3rem;
  height: 3rem;
}
.cds--search--lg.cds--search--expandable .cds--search-input::-webkit-input-placeholder {
  padding: 0 3rem;
}
.cds--search--lg.cds--search--expandable .cds--search-input::-moz-placeholder {
  padding: 0 3rem;
}
.cds--search--lg.cds--search--expandable .cds--search-input::-ms-input-placeholder {
  padding: 0 3rem;
}
.cds--search--lg.cds--search--expandable .cds--search-input::placeholder {
  padding: 0 3rem;
}

.cds--search-close--hidden {
  opacity: 0;
  visibility: hidden;
}

.cds--search--lg.cds--skeleton .cds--search-input,
.cds--search--md.cds--skeleton .cds--search-input,
.cds--search--sm.cds--skeleton .cds--search-input {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 100%;
}
.cds--search--lg.cds--skeleton .cds--search-input:hover, .cds--search--lg.cds--skeleton .cds--search-input:focus, .cds--search--lg.cds--skeleton .cds--search-input:active,
.cds--search--md.cds--skeleton .cds--search-input:hover,
.cds--search--md.cds--skeleton .cds--search-input:focus,
.cds--search--md.cds--skeleton .cds--search-input:active,
.cds--search--sm.cds--skeleton .cds--search-input:hover,
.cds--search--sm.cds--skeleton .cds--search-input:focus,
.cds--search--sm.cds--skeleton .cds--search-input:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--search--lg.cds--skeleton .cds--search-input::before,
.cds--search--md.cds--skeleton .cds--search-input::before,
.cds--search--sm.cds--skeleton .cds--search-input::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--search--lg.cds--skeleton .cds--search-input::before,
  .cds--search--md.cds--skeleton .cds--search-input::before,
  .cds--search--sm.cds--skeleton .cds--search-input::before {
    -webkit-animation: none;
    animation: none;
  }
}
.cds--search--lg.cds--skeleton .cds--search-input::-webkit-input-placeholder, .cds--search--md.cds--skeleton .cds--search-input::-webkit-input-placeholder, .cds--search--sm.cds--skeleton .cds--search-input::-webkit-input-placeholder {
  color: transparent;
}
.cds--search--lg.cds--skeleton .cds--search-input::-moz-placeholder, .cds--search--md.cds--skeleton .cds--search-input::-moz-placeholder, .cds--search--sm.cds--skeleton .cds--search-input::-moz-placeholder {
  color: transparent;
}
.cds--search--lg.cds--skeleton .cds--search-input::-ms-input-placeholder, .cds--search--md.cds--skeleton .cds--search-input::-ms-input-placeholder, .cds--search--sm.cds--skeleton .cds--search-input::-ms-input-placeholder {
  color: transparent;
}
.cds--search--lg.cds--skeleton .cds--search-input::placeholder,
.cds--search--md.cds--skeleton .cds--search-input::placeholder,
.cds--search--sm.cds--skeleton .cds--search-input::placeholder {
  color: transparent;
}

.cds--search--expandable {
  -webkit-transition: width 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: width 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--search--expandable.cds--search--expanded {
  width: 100%;
}

.cds--search--expandable .cds--search-input {
  width: 0;
  padding: 0;
  -webkit-transition: padding 70ms cubic-bezier(0.2, 0, 0.38, 0.9), width 0s linear 70ms;
  transition: padding 70ms cubic-bezier(0.2, 0, 0.38, 0.9), width 0s linear 70ms;
}
.cds--search--expandable .cds--search-input::-webkit-input-placeholder {
  position: relative;
  opacity: 0;
  -webkit-transition-duration: 70ms;
  transition-duration: 70ms;
  -webkit-transition-property: padding, opacity;
  transition-property: padding, opacity;
  -webkit-transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--search--expandable .cds--search-input::-moz-placeholder {
  position: relative;
  opacity: 0;
  transition-duration: 70ms;
  -moz-transition-property: padding, opacity;
  transition-property: padding, opacity;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--search--expandable .cds--search-input::-ms-input-placeholder {
  position: relative;
  opacity: 0;
  transition-duration: 70ms;
  -ms-transition-property: padding, opacity;
  transition-property: padding, opacity;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--search--expandable .cds--search-input::placeholder {
  position: relative;
  opacity: 0;
  -webkit-transition-duration: 70ms;
  transition-duration: 70ms;
  -webkit-transition-property: padding, opacity;
  transition-property: padding, opacity;
  -webkit-transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--search--expandable.cds--search--expanded .cds--search-input {
  width: 100%;
  -webkit-transition: padding 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: padding 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--search--expandable.cds--search--expanded .cds--search-input::-webkit-input-placeholder {
  position: relative;
  padding: 0;
  opacity: 1;
}
.cds--search--expandable.cds--search--expanded .cds--search-input::-moz-placeholder {
  position: relative;
  padding: 0;
  opacity: 1;
}
.cds--search--expandable.cds--search--expanded .cds--search-input::-ms-input-placeholder {
  position: relative;
  padding: 0;
  opacity: 1;
}
.cds--search--expandable.cds--search--expanded .cds--search-input::placeholder {
  position: relative;
  padding: 0;
  opacity: 1;
}

.cds--search--expandable .cds--search-magnifier {
  position: absolute;
  cursor: pointer;
}

.cds--search--expandable .cds--search-magnifier:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
}

.cds--search--expandable.cds--search--expanded .cds--search-magnifier {
  pointer-events: none;
}

.cds--search--expandable .cds--search-magnifier-icon {
  fill: var(--cds-icon-primary, #161616);
}

.cds--search--expandable.cds--search--expanded .cds--search-magnifier-icon {
  fill: var(--cds-icon-secondary, #525252);
}

.cds--search--expandable.cds--search--disabled svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--search-close svg,
  .cds--search-magnifier-icon {
    fill: ButtonText;
  }
}

.cds--search--fluid {
  height: 4rem;
}

.cds--search--fluid .cds--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: initial;
  width: calc(100% - 2rem);
  height: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  clip: initial;
  white-space: initial;
}

.cds--search--fluid .cds--label::-webkit-scrollbar,
.cds--search--fluid .cds--label .cds--toggletip-label::-webkit-scrollbar {
  display: none;
}

.cds--search--fluid .cds--label:not(:has(.cds--toggletip-label)),
.cds--search--fluid .cds--label .cds--toggletip-label {
  -ms-overflow-style: none;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;
}

.cds--search--fluid .cds--search-input {
  height: 100%;
  padding: 2rem 5.5rem 0.8125rem 1rem;
}

.cds--search--fluid .cds--search-magnifier-icon {
  top: auto;
  right: 1rem;
  bottom: 0.8125rem;
  left: auto;
  -webkit-transform: none;
  transform: none;
}

.cds--search--fluid .cds--search-close {
  top: auto;
  right: 3rem;
  bottom: 0;
  left: auto;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--search--fluid .cds--search-close::before {
  position: absolute;
  top: auto;
  right: -0.0625rem;
  bottom: 0.875rem;
  left: auto;
  display: block;
  width: 0.0625rem;
  height: 1rem;
  background: var(--cds-border-subtle);
  content: "";
}

.cds--search--fluid .cds--search-input:focus ~ .cds--search-close:hover {
  outline: none;
}

.cds--search--fluid .cds--search-close::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 0.125rem;
  content: "";
}

.cds--search--fluid .cds--search-input:focus ~ .cds--search-close::after {
  background: var(--cds-focus, #620b5b);
}

.cds--search--fluid .cds--search-input:not(:focus) ~ .cds--search-close:not([disabled])::after {
  height: 0.0625rem;
  background: var(--cds-border-strong);
}

.cds--search--fluid .cds--search-close svg {
  margin-bottom: 0.125rem;
}

.cds--search--fluid .cds--search-input:disabled ~ .cds--search-close:hover::before {
  background: var(--cds-border-subtle);
}

.cds--search--fluid.cds--search--disabled .cds--label {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--search--fluid.cds--search--disabled .cds--search-input[disabled] {
  border-bottom: 1px solid var(--cds-border-subtle);
}

.cds--select {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.cds--select *,
.cds--select *::before,
.cds--select *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--select-input__wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--select-input {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0 3rem 0 1rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--cds-field);
  border-radius: 0;
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
  font-family: inherit;
  opacity: 1;
  -webkit-transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--select-input:hover {
  background-color: var(--cds-field-hover);
}
.cds--select-input::-ms-expand {
  display: none;
}
@-moz-document url-prefix() {
  .cds--select-input:-moz-focusring, .cds--select-input::-moz-focus-inner {
    background-image: none;
    color: transparent;
    text-shadow: 0 0 0 #000000;
  }
}
.cds--select-input:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  color: var(--cds-text-primary, #161616);
}
@media screen and (prefers-contrast) {
  .cds--select-input:focus {
    outline-style: dotted;
  }
}
.cds--select-input:disabled, .cds--select-input:hover:disabled {
  border-bottom-color: transparent;
  background-color: var(--cds-field);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--select-input--sm {
  height: 2rem;
  max-height: 2rem;
}

.cds--select-input--lg {
  height: 3rem;
  max-height: 3rem;
}

.cds--select--disabled .cds--label,
.cds--select--disabled .cds--form__helper-text {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--select-input__wrapper[data-invalid] .cds--select-input,
.cds--select--warning .cds--select-input {
  padding-right: 4rem;
}

.cds--select-input:disabled ~ .cds--select__arrow {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--select--light .cds--select-input {
  background-color: var(--cds-field);
}
.cds--select--light .cds--select-input:hover {
  background-color: var(--cds-field-hover);
}
.cds--select--light .cds--select-input:disabled, .cds--select--light .cds--select-input:hover:disabled {
  background-color: var(--cds-field);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--select__arrow {
  position: absolute;
  top: 0;
  right: 1rem;
  height: 100%;
  fill: var(--cds-icon-primary, #161616);
  pointer-events: none;
}
@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
  .cds--select__arrow path {
    fill: ButtonText;
  }
}

.cds--select__invalid-icon {
  position: absolute;
  right: 2.5rem;
}

.cds--select-input__wrapper[data-invalid] .cds--select-input ~ .cds--select__invalid-icon {
  fill: var(--cds-support-error, #da1e28);
}

.cds--select__invalid-icon--warning {
  fill: var(--cds-support-warning, #f1c21b);
}

.cds--select__invalid-icon--warning path[fill] {
  fill: var(--cds-icon-primary, #161616);
  opacity: 1;
}

optgroup.cds--select-optgroup,
.cds--select-option {
  background-color: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}
optgroup.cds--select-optgroup:disabled,
.cds--select-option:disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--select--inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--select--inline.cds--select--invalid .cds--label,
.cds--select--inline.cds--select--invalid .cds--form__helper-text {
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-top: 0.8125rem;
}

.cds--select--inline .cds--form__helper-text {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.cds--select--inline .cds--label {
  margin: 0 0.5rem 0 0;
  white-space: nowrap;
}

.cds--select--inline .cds--select-input {
  width: auto;
  padding-right: 2rem;
  padding-left: 0.5rem;
  border-bottom: none;
  background-color: transparent;
  color: var(--cds-text-primary, #161616);
}

.cds--select--inline .cds--select-input:focus,
.cds--select--inline .cds--select-input:focus option,
.cds--select--inline .cds--select-input:focus optgroup {
  background-color: var(--cds-background, #ffffff);
}

.cds--select--inline .cds--select-input[disabled],
.cds--select--inline .cds--select-input[disabled]:hover {
  background-color: var(--cds-field);
}

.cds--select--inline .cds--select__arrow {
  right: 0.5rem;
}

.cds--select--inline.cds--select--invalid .cds--select-input {
  padding-right: 3.5rem;
}

.cds--select--inline.cds--select--invalid .cds--select-input ~ .cds--select__invalid-icon {
  right: 2rem;
}

.cds--select--inline .cds--select-input:disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}
.cds--select--inline .cds--select-input:disabled ~ * {
  cursor: not-allowed;
}

.cds--select--readonly .cds--select-input {
  background-color: transparent;
  cursor: default;
}

.cds--select--readonly .cds--select__arrow {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--select.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 100%;
  height: 2.5rem;
}
.cds--select.cds--skeleton:hover, .cds--select.cds--skeleton:focus, .cds--select.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--select.cds--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--select.cds--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--select.cds--skeleton .cds--select-input {
  display: none;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--select__arrow {
    fill: ButtonText;
  }
}

.cds--select--fluid .cds--select {
  position: relative;
  height: 100%;
  background: var(--cds-field);
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--select--fluid .cds--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 2rem);
  height: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.cds--select--fluid .cds--label::-webkit-scrollbar,
.cds--select--fluid .cds--label .cds--toggletip-label::-webkit-scrollbar {
  display: none;
}

.cds--select--fluid .cds--label:not(:has(.cds--toggletip-label)),
.cds--select--fluid .cds--label .cds--toggletip-label {
  -ms-overflow-style: none;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;
}

.cds--select--fluid .cds--select-input {
  min-height: 4rem;
  padding: 2rem 2rem 0.8125rem 1rem;
  outline: none;
  text-overflow: ellipsis;
}

.cds--select--fluid .cds--select__arrow {
  top: 2rem;
  height: 1rem;
}

.cds--select--fluid .cds--select__divider {
  display: none;
  -webkit-transition: border-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: border-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--select--fluid .cds--select--invalid .cds--select-input__wrapper {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--select--fluid .cds--select--invalid .cds--select-input__wrapper {
    outline-style: dotted;
  }
}

.cds--select--fluid .cds--select--invalid .cds--select__divider,
.cds--select--fluid .cds--select--warning .cds--select__divider {
  display: block;
  width: calc(100% - 2rem);
  border: none;
  border-bottom: 1px solid var(--cds-border-subtle);
  margin: 0 1rem;
}

.cds--select--fluid .cds--select-input__wrapper[data-invalid] .cds--select-input,
.cds--select--fluid .cds--select--warning .cds--select-input {
  padding-right: 2rem;
  border-bottom: 1px solid transparent;
}

.cds--select--fluid .cds--select--warning {
  border-bottom: 1px solid var(--cds-border-strong);
}

.cds--select--fluid .cds--select-input__wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cds--select--fluid .cds--select-input__wrapper[data-invalid] .cds--select-input:not(:focus),
.cds--select--fluid .cds--select--warning .cds--select-input:not(:focus) {
  outline: none;
}

.cds--select--fluid .cds--select-input__wrapper[data-invalid] .cds--form-requirement,
.cds--select--fluid .cds--select--warning .cds--form-requirement {
  display: block;
  overflow: visible;
  max-height: 100%;
}

.cds--select--fluid .cds--select-input__wrapper[data-invalid] .cds--form-requirement {
  color: var(--cds-text-error, #da1e28);
}

.cds--select--fluid .cds--form-requirement {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0;
}

.cds--select--fluid .cds--select-input__wrapper[data-invalid]:hover + .cds--select__divider,
.cds--select--fluid .cds--select--warning .cds--select-input__wrapper:hover + .cds--select__divider {
  border-color: transparent;
}

.cds--select--fluid .cds--select--invalid .cds--select__invalid-icon,
.cds--select--fluid .cds--select--warning .cds--select__invalid-icon {
  top: 4.5625rem;
  right: 1rem;
  pointer-events: none;
}

.cds--select--fluid .cds--select--fluid--focus .cds--select-input__wrapper {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--select--fluid .cds--select--fluid--focus .cds--select-input__wrapper {
    outline-style: dotted;
  }
}

.cds--select--fluid .cds--select--fluid--focus .cds--select-input,
.cds--select--fluid .cds--select--fluid--focus.cds--select--warning {
  border-bottom: 1px solid transparent;
}

.cds--select--fluid .cds--select--disabled .cds--toggletip-label {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--select--fluid .cds--select--disabled .cds--toggletip-button {
  pointer-events: none;
}

.cds--select--fluid .cds--select--disabled .cds--toggletip-button svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-area--fluid .cds--text-area__wrapper {
  position: relative;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: var(--cds-field);
}

.cds--modal .cds--text-area--fluid .cds--text-area__wrapper {
  background: var(--cds-field-02, #ffffff);
}

.cds--text-area--fluid .cds--text-area__label-wrapper {
  position: relative;
  height: 100%;
}

.cds--text-area--fluid .cds--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 2rem);
  height: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.cds--text-area--fluid .cds--label::-webkit-scrollbar,
.cds--text-area--fluid .cds--label .cds--toggletip-label::-webkit-scrollbar {
  display: none;
}

.cds--text-area--fluid .cds--label:not(:has(.cds--toggletip-label)),
.cds--text-area--fluid .cds--label .cds--toggletip-label {
  -ms-overflow-style: none;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;
}

.cds--text-area--fluid div.cds--label {
  right: 1rem;
  left: initial;
}

.cds--text-area--fluid .cds--text-area {
  min-height: 4rem;
  padding: 0 1rem 0.8125rem;
  margin-top: 2rem;
  outline: none;
}

.cds--text-area--fluid .cds--form__helper-text {
  display: none;
}

.cds--text-area--fluid .cds--text-area--invalid,
.cds--text-area--fluid .cds--text-area:focus {
  border-bottom: 1px solid transparent;
}

.cds--text-area__divider,
.cds--text-area--fluid .cds--text-area__divider {
  display: none;
}

.cds--text-area--fluid .cds--text-area--invalid + .cds--text-area__divider {
  display: block;
  border-style: solid;
  border-color: var(--cds-border-subtle);
  border-bottom: none;
  margin: 0 1rem;
}

.cds--text-area--fluid .cds--text-area--invalid + .cds--text-area__divider + .cds--form-requirement {
  position: relative;
  display: block;
  overflow: visible;
  max-height: 12.5rem;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0;
  background: var(--cds-field);
  color: var(--cds-text-error, #da1e28);
}

.cds--modal .cds--text-area--fluid .cds--text-area--invalid + .cds--text-area__divider + .cds--form-requirement {
  background: var(--cds-field-02, #ffffff);
}

.cds--text-area--fluid .cds--text-area__invalid-icon {
  top: 0.5rem;
}

.cds--text-area--fluid .cds--text-area__wrapper[data-invalid]:not(:focus) {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
  outline-offset: 0;
}
@media screen and (prefers-contrast) {
  .cds--text-area--fluid .cds--text-area__wrapper[data-invalid]:not(:focus) {
    outline-style: dotted;
  }
}

.cds--text-area--fluid .cds--text-area__wrapper[data-invalid]:focus-within,
.cds--text-area--fluid .cds--text-area__wrapper:focus-within {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  outline-offset: 0;
}
@media screen and (prefers-contrast) {
  .cds--text-area--fluid .cds--text-area__wrapper[data-invalid]:focus-within,
  .cds--text-area--fluid .cds--text-area__wrapper:focus-within {
    outline-style: dotted;
  }
}

.cds--text-area--fluid .cds--text-area__wrapper[data-invalid] > .cds--text-area--invalid:focus,
.cds--text-area--fluid .cds--text-area__wrapper > .cds--text-area:focus,
.cds--text-area--fluid .cds--text-area__wrapper > .cds--text-area:active,
.cds--text-area--fluid .cds--text-area__wrapper[data-invalid] > .cds--text-area--invalid {
  outline: none;
  -webkit-transition: none;
  transition: none;
}

.cds--text-area--fluid__skeleton {
  padding: 1rem;
  border-bottom: 1px solid var(--cds-skeleton-element, #c6c6c6);
  background: var(--cds-skeleton-background, #e8e8e8);
}

.cds--text-area--fluid__skeleton .cds--skeleton,
.cds--text-area--fluid__skeleton .cds--text-area.cds--skeleton::before {
  height: 0.5rem;
}

.cds--text-area--fluid__skeleton .cds--label {
  margin-bottom: 0.75rem;
}

.cds--text-area--fluid__skeleton .cds--text-area.cds--skeleton {
  width: 80%;
  height: 4rem;
}

.cds--text-input--fluid.cds--text-input-wrapper {
  position: relative;
  height: 100%;
  background: var(--cds-field);
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--text-input--fluid .cds--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 2rem);
  height: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.cds--text-input--fluid .cds--label::-webkit-scrollbar,
.cds--text-input--fluid .cds--label .cds--toggletip-label::-webkit-scrollbar {
  display: none;
}

.cds--text-input--fluid .cds--label:not(:has(.cds--toggletip-label)),
.cds--text-input--fluid .cds--label .cds--toggletip-label {
  -ms-overflow-style: none;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;
}

.cds--text-input--fluid .cds--form__helper-text {
  display: none;
}

.cds--text-input--fluid .cds--text-input {
  min-height: 4rem;
  padding: 2rem 1rem 0.8125rem;
}

.cds--text-input__divider,
.cds--text-input--fluid .cds--text-input__divider {
  display: none;
}

.cds--text-input--fluid .cds--text-input__field-wrapper[data-invalid],
.cds--text-input--fluid .cds--text-input__field-wrapper--warning {
  display: block;
}

.cds--text-input--fluid input[data-invalid] {
  outline: none;
}

.cds--text-input--fluid .cds--form-requirement {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0;
}

.cds--text-input--fluid .cds--text-input--invalid,
.cds--text-input--fluid .cds--text-input--warning {
  border-bottom: none;
}

.cds--text-input--fluid .cds--text-input--invalid + .cds--text-input__divider,
.cds--text-input--fluid .cds--text-input--warning + .cds--text-input__divider {
  display: block;
  border-style: solid;
  border-color: var(--cds-border-subtle);
  border-bottom: none;
  margin: 0 1rem;
}

.cds--text-input--fluid .cds--text-input__invalid-icon {
  top: 5rem;
}

.cds--text-input--fluid .cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid,
.cds--text-input--fluid .cds--text-input__field-wrapper--warning > .cds--text-input--warning {
  outline: none;
}

.cds--text-input--fluid .cds--text-input__field-wrapper--warning {
  border-bottom: 1px solid var(--cds-border-strong);
}

.cds--text-input--fluid .cds--text-input__field-wrapper[data-invalid]:not(:focus) {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--text-input--fluid .cds--text-input__field-wrapper[data-invalid]:not(:focus) {
    outline-style: dotted;
  }
}

.cds--text-input--fluid .cds--text-input__field-wrapper[data-invalid]:focus-within,
.cds--text-input--fluid .cds--text-input__field-wrapper--warning:focus-within {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--text-input--fluid .cds--text-input__field-wrapper[data-invalid]:focus-within,
  .cds--text-input--fluid .cds--text-input__field-wrapper--warning:focus-within {
    outline-style: dotted;
  }
}

.cds--text-input--fluid .cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid:focus,
.cds--text-input--fluid .cds--text-input__field-wrapper--warning > .cds--text-input--warning:focus {
  outline: none;
}

.cds--text-input--fluid .cds--text-input.cds--password-input {
  padding-right: 2.5rem;
}

.cds--text-input--fluid.cds--password-input-wrapper .cds--text-input__invalid-icon {
  right: 1rem;
}

.cds--text-input--fluid .cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger {
  top: 1.625rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
}

.cds--text-input--fluid__skeleton {
  position: relative;
  height: 4rem;
  border-bottom: 1px solid var(--cds-skeleton-element, #c6c6c6);
  background: var(--cds-skeleton-background, #e8e8e8);
}

.cds--text-input--fluid__skeleton .cds--skeleton {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 25%;
  height: 0.5rem;
}

.cds--text-input--fluid__skeleton .cds--label {
  position: absolute;
  top: 2.25rem;
  left: 1rem;
  width: 50%;
  height: 0.5rem;
  padding: 0;
}

.cds--time-picker--fluid {
  background: var(--cds-field);
}

.cds--time-picker--fluid .cds--time-picker--fluid__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cds--time-picker--fluid__wrapper > *:nth-child(1),
.cds--time-picker--fluid__wrapper > *:nth-child(2) {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}

.cds--time-picker--equal-width .cds--time-picker--fluid__wrapper > * {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.cds--time-picker--fluid__wrapper > *:nth-child(2):not(:last-child) .cds--select-input__wrapper::before,
.cds--time-picker--fluid__wrapper > *:last-child .cds--select-input__wrapper::before {
  position: absolute;
  display: block;
  width: 1px;
  height: calc(100% - 1px);
  background-color: var(--cds-border-strong);
  content: "";
  opacity: 1;
  -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--time-picker--fluid__wrapper .cds--select-input__wrapper::after {
  right: 0;
}

.cds--time-picker--fluid__wrapper .cds--select-input__wrapper::before {
  left: 0;
}

.cds--time-picker--fluid:not(.cds--time-picker--fluid--disabled) .cds--time-picker--fluid__wrapper > *:nth-child(2):not(:last-child):hover .cds--select-input__wrapper::before,
.cds--time-picker--fluid:not(.cds--time-picker--fluid--disabled) .cds--time-picker--fluid__wrapper > *:nth-child(2):hover:not(:last-child) ~ * .cds--select-input__wrapper::before,
.cds--time-picker--fluid:not(.cds--time-picker--fluid--disabled) .cds--time-picker--fluid__wrapper > *:last-child:hover .cds--select-input__wrapper::before {
  opacity: 0;
}

.cds--time-picker--fluid--disabled .cds--time-picker--fluid__wrapper .cds--select--disabled .cds--select-input__wrapper::before {
  background: var(--cds-border-disabled, #c6c6c6);
}

.cds--time-picker--fluid .cds--form-requirement {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0;
  background: var(--cds-field);
}

.cds--time-picker--fluid--invalid {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
  position: relative;
}
@media screen and (prefers-contrast) {
  .cds--time-picker--fluid--invalid {
    outline-style: dotted;
  }
}

.cds--time-picker--fluid--invalid .cds--text-input,
.cds--time-picker--fluid--invalid .cds--text-input-wrapper,
.cds--time-picker--fluid--invalid .cds--select-input,
.cds--time-picker--fluid--invalid .cds--select {
  background: transparent;
}

.cds--time-picker--fluid--invalid .cds--select-input {
  padding-top: 1.875rem;
  border-top: 2px solid transparent;
}

.cds--select--fluid:last-of-type .cds--select-input {
  border-right: 2px solid transparent;
}

.cds--time-picker--fluid--invalid .cds--select-input:hover:not([disabled]) {
  background: var(--cds-field-hover);
}

.cds--time-picker--fluid--invalid .cds--select-input:hover {
  border-top: 2px solid var(--cds-support-error, #da1e28);
}

.cds--time-picker--fluid--invalid .cds--select--fluid:last-of-type .cds--select-input:hover {
  border-right: 2px solid var(--cds-support-error, #da1e28);
}

.cds--time-picker--fluid--warning {
  position: relative;
}

.cds--time-picker__icon {
  display: none;
}

.cds--time-picker--fluid--invalid .cds--time-picker__icon,
.cds--time-picker--fluid--warning .cds--time-picker__icon {
  position: absolute;
  top: 4.5rem;
  right: 1rem;
  display: block;
}

.cds--time-picker--fluid--invalid .cds--time-picker__icon {
  fill: var(--cds-support-error, #da1e28);
}

.cds--time-picker--fluid--warning .cds--time-picker__icon {
  fill: var(--cds-support-warning, #f1c21b);
}

.cds--time-picker--fluid--warning .cds--time-picker__icon path:first-of-type {
  fill: var(--cds-icon-primary, #161616);
}

.cds--time-picker--fluid--invalid .cds--form-requirement,
.cds--time-picker--fluid--warning .cds--form-requirement {
  display: block;
  overflow: visible;
  max-height: 100%;
}

.cds--time-picker--fluid--invalid .cds--form-requirement {
  color: var(--cds-text-error, #da1e28);
}

.cds--time-picker--fluid--invalid .cds--time-picker--fluid__wrapper > *:nth-child(2):not(:last-child) .cds--select-input__wrapper::before,
.cds--time-picker--fluid--invalid .cds--time-picker--fluid__wrapper > *:last-child .cds--select-input__wrapper::before,
.cds--time-picker--fluid--warning .cds--time-picker--fluid__wrapper > *:nth-child(2):not(:last-child) .cds--select-input__wrapper::before,
.cds--time-picker--fluid--warning .cds--time-picker--fluid__wrapper > *:last-child .cds--select-input__wrapper::before {
  top: 8px;
  height: calc(100% - 1rem);
}

.cds--time-picker--fluid--invalid .cds--text-input,
.cds--time-picker--fluid--warning .cds--text-input,
.cds--time-picker--fluid--invalid .cds--select-input,
.cds--time-picker--fluid--warning .cds--select-input {
  border-bottom: 1px solid transparent;
}

.cds--time-picker--fluid.cds--time-picker--fluid--invalid .cds--time-picker__divider,
.cds--time-picker--fluid.cds--time-picker--fluid--warning .cds--time-picker__divider {
  width: calc(100% - 2rem);
  border-style: solid;
  border-color: var(--cds-border-subtle);
  border-bottom: none;
  margin: 0 1rem;
}

.cds--time-picker--fluid--skeleton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 4rem;
}

.cds--time-picker--fluid--skeleton > * {
  width: auto;
  height: 100%;
}

.cds--time-picker--fluid--skeleton > *:nth-child(1),
.cds--time-picker--fluid--skeleton > *:nth-child(2) {
  width: 25%;
}

.cds--time-picker--fluid--skeleton > *:last-child,
.cds--time-picker--fluid--skeleton.cds--time-picker--equal-width > *:first-child {
  width: 50%;
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
.cds--inline-loading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--inline-loading__text {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  color: var(--cds-text-secondary, #525252);
}

.cds--inline-loading__animation {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.cds--inline-loading__checkmark-container {
  fill: var(--cds-support-success, #198038);
}
.cds--inline-loading__checkmark-container.cds--inline-loading__svg {
  position: absolute;
  top: 0.75rem;
  width: 0.75rem;
}
.cds--inline-loading__checkmark-container[hidden] {
  display: none;
}

.cds--inline-loading__checkmark {
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: stroke;
  animation-name: stroke;
  fill: none;
  stroke: var(--cds-interactive, #620b5b);
  stroke-dasharray: 12;
  stroke-dashoffset: 12;
  stroke-width: 1.8;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.cds--inline-loading--error {
  width: 1rem;
  height: 1rem;
  fill: var(--cds-support-error, #da1e28);
}
.cds--inline-loading--error[hidden] {
  display: none;
}

.cds--loading--small .cds--inline-loading__svg {
  stroke: var(--cds-interactive, #620b5b);
}

/* If IE11 Don't show check animation */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .cds--inline-loading__checkmark-container {
    top: 1px;
    right: 0.5rem;
  }

  .cds--inline-loading__checkmark {
    -webkit-animation: none;
    animation: none;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
  }
}
.cds--list--nested,
.cds--list--unordered,
.cds--list--ordered,
.cds--list--ordered--native {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  list-style: none;
}
.cds--list--nested *,
.cds--list--nested *::before,
.cds--list--nested *::after,
.cds--list--unordered *,
.cds--list--unordered *::before,
.cds--list--unordered *::after,
.cds--list--ordered *,
.cds--list--ordered *::before,
.cds--list--ordered *::after,
.cds--list--ordered--native *,
.cds--list--ordered--native *::before,
.cds--list--ordered--native *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--list--expressive,
.cds--list--expressive .cds--list--nested {
  font-size: var(--cds-body-02-font-size, 1rem);
  font-weight: var(--cds-body-02-font-weight, 400);
  line-height: var(--cds-body-02-line-height, 1.5);
  letter-spacing: var(--cds-body-02-letter-spacing, 0);
}

.cds--list--ordered--native {
  list-style: decimal;
}

.cds--list__item {
  color: var(--cds-text-primary, #161616);
}

.cds--list--nested {
  margin-left: 2rem;
}

.cds--list--nested .cds--list__item {
  padding-left: 0.25rem;
}

.cds--list--ordered:not(.cds--list--nested) {
  counter-reset: item;
}

.cds--list--ordered:not(.cds--list--nested) > .cds--list__item {
  position: relative;
}

.cds--list--ordered:not(.cds--list--nested) > .cds--list__item::before {
  position: absolute;
  left: -1.5rem;
  content: counter(item) ".";
  counter-increment: item;
}

.cds--list--ordered.cds--list--nested,
.cds--list--ordered--native.cds--list--nested {
  list-style-type: lower-latin;
}

.cds--list--unordered > .cds--list__item {
  position: relative;
}
.cds--list--unordered > .cds--list__item::before {
  position: absolute;
  left: calc(-1 * 1rem);
  content: "–";
}

.cds--list--unordered.cds--list--nested > .cds--list__item::before {
  left: calc(-1 * 0.75rem);
  content: "▪";
}

.cds--menu {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  position: fixed;
  z-index: 9000;
  min-width: 13rem;
  max-width: 18rem;
  padding: 0.25rem 0;
  background-color: var(--cds-layer);
  visibility: hidden;
}

.cds--menu--open {
  visibility: visible;
}
.cds--menu--open:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--menu--open:focus {
    outline-style: dotted;
  }
}

.cds--menu--invisible {
  opacity: 0;
  pointer-events: none;
}

.cds--menu-option {
  position: relative;
  display: list-item;
  height: 2rem;
  background-color: var(--cds-layer);
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--menu-option:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--menu-option:focus {
    outline-style: dotted;
  }
}

.cds--menu-option--active,
.cds--menu-option:hover {
  background-color: var(--cds-layer-hover);
}

.cds--menu-option--danger:hover,
.cds--menu-option--danger:focus {
  background-color: var(--cds-button-danger-primary, #da1e28);
  color: var(--cds-text-on-color, #ffffff);
}

.cds--menu-option > .cds--menu {
  margin-top: calc(0.25rem * -1);
}

.cds--menu-option__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 1rem;
}

.cds--menu-option__content--disabled {
  background-color: var(--cds-layer);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--menu-option__content--disabled .cds--menu-option__label,
.cds--menu-option__content--disabled .cds--menu-option__info,
.cds--menu-option__content--disabled .cds--menu-option__icon {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--menu-option__content--indented .cds--menu-option__label {
  margin-left: 1rem;
}

.cds--menu-option__label {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0.25rem 0;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--menu-option__info {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 1rem;
}

.cds--menu-option__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1rem;
  height: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0.5rem;
}

.cds--menu-divider {
  display: list-item;
  width: 100%;
  height: 1px;
  margin: 0.25rem 0;
  background-color: var(--cds-border-subtle);
}

.cds--menu--md .cds--menu-option {
  height: 2.5rem;
}

.cds--menu--lg .cds--menu-option {
  height: 3rem;
}

.cds--modal {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--cds-overlay, rgba(22, 22, 22, 0.5));
  content: "";
  opacity: 0;
  -webkit-transition: opacity 240ms cubic-bezier(0.4, 0.14, 1, 1), visibility 0ms linear 240ms;
  transition: opacity 240ms cubic-bezier(0.4, 0.14, 1, 1), visibility 0ms linear 240ms;
  visibility: hidden;
}
.cds--modal.is-visible {
  opacity: 1;
  -webkit-transition: opacity 240ms cubic-bezier(0, 0, 0.3, 1), visibility 0ms linear;
  transition: opacity 240ms cubic-bezier(0, 0, 0.3, 1), visibility 0ms linear;
  visibility: inherit;
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--modal.is-visible {
    -webkit-transition: none;
    transition: none;
  }
}
.cds--modal .cds--pagination,
.cds--modal .cds--pagination__control-buttons,
.cds--modal .cds--text-input,
.cds--modal .cds--text-area,
.cds--modal .cds--search-input,
.cds--modal .cds--select-input,
.cds--modal .cds--dropdown,
.cds--modal .cds--dropdown-list,
.cds--modal .cds--number input[type=number],
.cds--modal .cds--date-picker__input,
.cds--modal .cds--multi-select,
.cds--modal .cds--number__control-btn::before,
.cds--modal .cds--number__control-btn::after {
  background-color: var(--cds-field-02, #ffffff);
}
.cds--modal .cds--number__rule-divider {
  background-color: var(--cds-border-subtle-02, #e0e0e0);
}

.cds--modal.is-visible .cds--modal-container {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 240ms cubic-bezier(0, 0, 0.3, 1);
  transition: -webkit-transform 240ms cubic-bezier(0, 0, 0.3, 1);
  transition: transform 240ms cubic-bezier(0, 0, 0.3, 1);
  transition: transform 240ms cubic-bezier(0, 0, 0.3, 1), -webkit-transform 240ms cubic-bezier(0, 0, 0.3, 1);
}

.cds--modal-container {
  position: fixed;
  top: 0;
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: var(--cds-layer);
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  outline: 3px solid transparent;
  outline-offset: -3px;
  -webkit-transform: translate3d(0, -24px, 0);
  transform: translate3d(0, -24px, 0);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: -webkit-transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: -webkit-transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: transform 240ms cubic-bezier(0.4, 0.14, 1, 1), -webkit-transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
}
@media (min-width: 42rem) {
  .cds--modal-container {
    position: static;
    width: 84%;
    height: auto;
    max-height: 90%;
  }
}
@media (min-width: 66rem) {
  .cds--modal-container {
    width: 60%;
    max-height: 84%;
  }
}
@media (min-width: 82rem) {
  .cds--modal-container {
    width: 48%;
  }
}
.cds--modal-container .cds--modal-container-body {
  display: contents;
}

.cds--modal-content {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  position: relative;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 3rem;
  color: var(--cds-text-primary, #161616);
  font-weight: 400;
  grid-column: 1/-1;
  grid-row: 2/-2;
  overflow-y: auto;
}
.cds--modal-content:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--modal-content:focus {
    outline-style: dotted;
  }
}

.cds--modal-content > p,
.cds--modal-content__regular-content {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  padding-right: calc(20% - 2rem);
}

.cds--modal-content--with-form {
  padding-right: 1rem;
}

.cds--modal-header {
  padding-top: 1rem;
  padding-right: 3rem;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  grid-column: 1/-1;
  grid-row: 1/1;
}

.cds--modal-header__label {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  margin-bottom: 0.25rem;
  color: var(--cds-text-secondary, #525252);
}

.cds--modal-header__heading {
  font-size: var(--cds-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-heading-03-font-weight, 400);
  line-height: var(--cds-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-heading-03-letter-spacing, 0);
  color: var(--cds-text-primary, #161616);
}

.cds--modal-container--xs .cds--modal-content__regular-content {
  padding-right: 1rem;
}
.cds--modal-container--xs .cds--modal-content > p {
  padding-right: 0;
}
@media (min-width: 42rem) {
  .cds--modal-container--xs {
    width: 48%;
  }
}
@media (min-width: 66rem) {
  .cds--modal-container--xs {
    width: 32%;
    max-height: 48%;
  }
}
@media (min-width: 82rem) {
  .cds--modal-container--xs {
    width: 24%;
  }
}

.cds--modal-container--sm .cds--modal-content__regular-content {
  padding-right: 1rem;
}
.cds--modal-container--sm .cds--modal-content > p {
  padding-right: 0;
}
@media (min-width: 42rem) {
  .cds--modal-container--sm {
    width: 60%;
  }
}
@media (min-width: 66rem) {
  .cds--modal-container--sm {
    width: 42%;
    max-height: 72%;
  }
  .cds--modal-container--sm .cds--modal-content > p,
  .cds--modal-container--sm .cds--modal-content__regular-content {
    padding-right: 20%;
  }
}
@media (min-width: 82rem) {
  .cds--modal-container--sm {
    width: 36%;
  }
}

@media (min-width: 42rem) {
  .cds--modal-container--lg {
    width: 96%;
  }
}
@media (min-width: 66rem) {
  .cds--modal-container--lg {
    width: 84%;
    max-height: 96%;
  }
}
@media (min-width: 82rem) {
  .cds--modal-container--lg {
    width: 72%;
  }
}

.cds--modal-scroll-content > *:last-child {
  padding-bottom: 2rem;
}

.cds--modal-content--overflow-indicator {
  position: absolute;
  bottom: 3rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(var(--cds-layer)));
  background-image: linear-gradient(to bottom, transparent, var(--cds-layer));
  content: "";
  grid-column: 1/-1;
  grid-row: 2/-2;
  pointer-events: none;
}

@media not all and (-webkit-min-device-pixel-ratio: 0), not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .cds--modal-content--overflow-indicator {
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(var(--cds-layer), 0)), to(var(--cds-layer)));
      background-image: linear-gradient(to bottom, rgba(var(--cds-layer), 0), var(--cds-layer));
    }
  }
}
.cds--modal-content:focus ~ .cds--modal-content--overflow-indicator {
  width: calc(100% - 4px);
  margin: 0 2px 2px;
}

@media screen and (-ms-high-contrast: active) {
  .cds--modal-scroll-content > *:last-child {
    padding-bottom: 0;
  }

  .cds--modal-content--overflow-indicator {
    display: none;
  }
}
.cds--modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 4rem;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: auto;
  grid-column: 1/-1;
  grid-row: -1/-1;
}

.cds--modal-footer .cds--btn {
  max-width: none;
  height: 4rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin: 0;
}

.cds--modal-footer--three-button .cds--btn {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cds--modal-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--modal-close:hover {
  background-color: var(--cds-layer-hover);
}
.cds--modal-close:focus {
  border-color: var(--cds-focus, #620b5b);
  outline: none;
}

.cds--modal-close::-moz-focus-inner {
  border: 0;
}

.cds--modal-close__icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--cds-icon-primary, #161616);
}

.cds--body--with-modal-open {
  overflow: hidden;
}

.cds--body--with-modal-open .cds--modal .cds--tooltip,
.cds--body--with-modal-open .cds--modal .cds--overflow-menu-options {
  z-index: 9000;
}

.cds--modal-container--full-width .cds--modal-content {
  padding: 0;
  margin: 0;
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--modal-close__icon {
    fill: ButtonText;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--modal-close:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

/* stylelint-enable */
.cds--multi-select .cds--tag {
  min-width: auto;
  margin: 0 0.5rem 0 0;
}

.cds--multi-select--filterable .cds--tag {
  margin: 0 0.5rem 0 1rem;
}

.cds--multi-select .cds--list-box__menu {
  min-width: auto;
}

.cds--multi-select .cds--list-box__menu-item__option .cds--checkbox-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--multi-select .cds--list-box__menu-item__option .cds--checkbox-label {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding-left: 1.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--multi-select .cds--list-box__menu-item__option > .cds--form-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0;
}

.cds--multi-select .cds--list-box__menu-item .cds--checkbox:checked ~ .cds--checkbox-label-text {
  color: var(--cds-text-primary, #161616);
}

.cds--multi-select--filterable {
  -webkit-transition: outline-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--multi-select--filterable.cds--combo-box .cds--text-input {
  border: 0.125rem solid transparent;
  background-clip: padding-box;
  outline: none;
}

.cds--multi-select--filterable--input-focused {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--multi-select--filterable--input-focused {
    outline-style: dotted;
  }
}

.cds--multi-select--filterable.cds--multi-select--selected .cds--text-input {
  padding-left: 0;
}

.cds--multi-select--filterable.cds--list-box--disabled:hover:not(.cds--multi-select--filterable) .cds--text-input {
  background-color: var(--cds-field);
}

.cds--multi-select--filterable .cds--list-box__selection--multi {
  margin: 0 0 0 1rem;
}

.cds--multi-select--filterable.cds--multi-select--inline,
.cds--multi-select--filterable.cds--multi-select--inline .cds--text-input {
  border-bottom: 0;
  background-color: transparent;
}

.cds--multi-select:not(.cds--list-box--expanded) .cds--list-box__menu {
  visibility: hidden;
}

.cds--multi-select.cds--multi-select--readonly,
.cds--multi-select.cds--multi-select--readonly:hover {
  background-color: transparent;
}

.cds--multi-select.cds--multi-select--readonly .cds--list-box__menu-icon svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--multi-select.cds--multi-select--readonly .cds--tag--filter,
.cds--multi-select.cds--multi-select--readonly .cds--tag__close-icon:hover {
  background-color: transparent;
  color: var(--cds-text-primary, #161616);
  cursor: default;
}

.cds--multi-select.cds--multi-select--readonly .cds--tag--filter {
  -webkit-box-shadow: 0 0 0 1px var(--cds-background-inverse, #393939);
  box-shadow: 0 0 0 1px var(--cds-background-inverse, #393939);
}

.cds--multi-select.cds--multi-select--readonly .cds--tag--filter svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--multi-select.cds--multi-select--readonly .cds--list-box__field,
.cds--multi-select.cds--multi-select--readonly .cds--list-box__menu-icon {
  cursor: default;
}

.cds--inline-notification {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 18rem;
  max-width: 18rem;
  height: auto;
  min-height: 3rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: var(--cds-text-inverse, #ffffff);
}
.cds--inline-notification html {
  font-size: 100%;
}
.cds--inline-notification body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--inline-notification code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--inline-notification strong {
  font-weight: 600;
}
@media (min-width: 42rem) {
  .cds--inline-notification {
    max-width: 38rem;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media (min-width: 66rem) {
  .cds--inline-notification {
    max-width: 46rem;
  }
}
@media (min-width: 99rem) {
  .cds--inline-notification {
    max-width: 52rem;
  }
}

.cds--inline-notification:not(.cds--inline-notification--low-contrast) a {
  color: var(--cds-link-inverse, #78a9ff);
}

.cds--inline-notification a {
  text-decoration: none;
}

.cds--inline-notification a:hover {
  text-decoration: underline;
}

.cds--inline-notification a:focus {
  outline: 1px solid var(--cds-link-inverse, #78a9ff);
}

.cds--inline-notification.cds--inline-notification--low-contrast a:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--inline-notification.cds--inline-notification--low-contrast a:focus {
    outline-style: dotted;
  }
}

.cds--inline-notification--low-contrast {
  color: var(--cds-text-primary, #161616);
}
.cds--inline-notification--low-contrast::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  content: "";
  -webkit-filter: opacity(0.4);
  filter: opacity(0.4);
  pointer-events: none;
}

.cds--inline-notification--error {
  border-left: 3px solid var(--cds-support-error-inverse, #fa4d56);
  background: var(--cds-background-inverse, #393939);
}
.cds--inline-notification--error .cds--inline-notification__icon,
.cds--inline-notification--error .cds--toast-notification__icon,
.cds--inline-notification--error .cds--actionable-notification__icon {
  fill: var(--cds-support-error-inverse, #fa4d56);
}

.cds--inline-notification--low-contrast.cds--inline-notification--error {
  border-left: 3px solid var(--cds-support-error, #da1e28);
  background: var(--cds-notification-background-error, #fff1f1);
}
.cds--inline-notification--low-contrast.cds--inline-notification--error .cds--inline-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--error .cds--toast-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--error .cds--actionable-notification__icon {
  fill: var(--cds-support-error, #da1e28);
}
.cds--inline-notification--low-contrast.cds--inline-notification--error::before {
  border-color: var(--cds-support-error, #da1e28);
}

.cds--inline-notification--success {
  border-left: 3px solid var(--cds-support-success-inverse, #42be65);
  background: var(--cds-background-inverse, #393939);
}
.cds--inline-notification--success .cds--inline-notification__icon,
.cds--inline-notification--success .cds--toast-notification__icon,
.cds--inline-notification--success .cds--actionable-notification__icon {
  fill: var(--cds-support-success-inverse, #42be65);
}

.cds--inline-notification--low-contrast.cds--inline-notification--success {
  border-left: 3px solid var(--cds-support-success, #198038);
  background: var(--cds-notification-background-success, #defbe6);
}
.cds--inline-notification--low-contrast.cds--inline-notification--success .cds--inline-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--success .cds--toast-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--success .cds--actionable-notification__icon {
  fill: var(--cds-support-success, #198038);
}
.cds--inline-notification--low-contrast.cds--inline-notification--success::before {
  border-color: var(--cds-support-success, #198038);
}

.cds--inline-notification--info,
.cds--inline-notification--info-square {
  border-left: 3px solid var(--cds-support-info-inverse, #4589ff);
  background: var(--cds-background-inverse, #393939);
}
.cds--inline-notification--info .cds--inline-notification__icon,
.cds--inline-notification--info .cds--toast-notification__icon,
.cds--inline-notification--info .cds--actionable-notification__icon,
.cds--inline-notification--info-square .cds--inline-notification__icon,
.cds--inline-notification--info-square .cds--toast-notification__icon,
.cds--inline-notification--info-square .cds--actionable-notification__icon {
  fill: var(--cds-support-info-inverse, #4589ff);
}

.cds--inline-notification--low-contrast.cds--inline-notification--info,
.cds--inline-notification--low-contrast.cds--inline-notification--info-square {
  border-left: 3px solid var(--cds-support-info, #0043ce);
  background: var(--cds-notification-background-info, #edf5ff);
}
.cds--inline-notification--low-contrast.cds--inline-notification--info .cds--inline-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--info .cds--toast-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--info .cds--actionable-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--info-square .cds--inline-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--info-square .cds--toast-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--info-square .cds--actionable-notification__icon {
  fill: var(--cds-support-info, #0043ce);
}
.cds--inline-notification--low-contrast.cds--inline-notification--info::before,
.cds--inline-notification--low-contrast.cds--inline-notification--info-square::before {
  border-color: var(--cds-support-info, #0043ce);
}

.cds--inline-notification--warning,
.cds--inline-notification--warning-alt {
  border-left: 3px solid var(--cds-support-warning-inverse, #f1c21b);
  background: var(--cds-background-inverse, #393939);
}
.cds--inline-notification--warning .cds--inline-notification__icon,
.cds--inline-notification--warning .cds--toast-notification__icon,
.cds--inline-notification--warning .cds--actionable-notification__icon,
.cds--inline-notification--warning-alt .cds--inline-notification__icon,
.cds--inline-notification--warning-alt .cds--toast-notification__icon,
.cds--inline-notification--warning-alt .cds--actionable-notification__icon {
  fill: var(--cds-support-warning-inverse, #f1c21b);
}

.cds--inline-notification--low-contrast.cds--inline-notification--warning,
.cds--inline-notification--low-contrast.cds--inline-notification--warning-alt {
  border-left: 3px solid var(--cds-support-warning, #f1c21b);
  background: var(--cds-notification-background-warning, #fdf6dd);
}
.cds--inline-notification--low-contrast.cds--inline-notification--warning .cds--inline-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--warning .cds--toast-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--warning .cds--actionable-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--warning-alt .cds--inline-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--warning-alt .cds--toast-notification__icon,
.cds--inline-notification--low-contrast.cds--inline-notification--warning-alt .cds--actionable-notification__icon {
  fill: var(--cds-support-warning, #f1c21b);
}
.cds--inline-notification--low-contrast.cds--inline-notification--warning::before,
.cds--inline-notification--low-contrast.cds--inline-notification--warning-alt::before {
  border-color: var(--cds-support-warning, #f1c21b);
}

.cds--inline-notification--warning .cds--inline-notification__icon path[opacity="0"] {
  fill: #000000;
  opacity: 1;
}

.cds--inline-notification__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 3rem 0 0.8125rem;
}
@media (min-width: 42rem) {
  .cds--inline-notification__details {
    margin: 0 0.8125rem;
  }
}

.cds--inline-notification__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: 0.875rem;
  margin-right: 1rem;
}

.cds--inline-notification__text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.9375rem 0;
}

.cds--inline-notification__title {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  margin: 0 0.25rem 0 0;
}

.cds--inline-notification__subtitle {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  word-break: break-word;
}

.cds--inline-notification__action-button.cds--btn--ghost {
  height: 2rem;
  margin-bottom: 0.5rem;
  margin-left: 2.5rem;
}
@media (min-width: 42rem) {
  .cds--inline-notification__action-button.cds--btn--ghost {
    margin: 0.5rem 0;
  }
}

.cds--inline-notification:not(.cds--inline-notification--low-contrast) .cds--inline-notification__action-button.cds--btn--ghost {
  color: var(--cds-link-inverse, #78a9ff);
}

.cds--inline-notification__action-button.cds--btn--ghost:active,
.cds--inline-notification__action-button.cds--btn--ghost:hover {
  background-color: var(--cds-background-inverse-hover, #474747);
}

.cds--inline-notification--low-contrast .cds--inline-notification__action-button.cds--btn--ghost:active,
.cds--inline-notification--low-contrast .cds--inline-notification__action-button.cds--btn--ghost:hover {
  background-color: var(--cds-notification-action-hover, #edf5ff);
}

.cds--inline-notification__action-button.cds--btn--ghost:focus {
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 2px solid var(--cds-focus-inverse, #ffffff);
  outline-offset: -2px;
}

.cds--inline-notification--low-contrast .cds--inline-notification__action-button.cds--btn--ghost:focus {
  outline-color: var(--cds-focus, #620b5b);
}

.cds--inline-notification--hide-close-button .cds--inline-notification__action-button.cds--btn--ghost {
  margin-right: 0.5rem;
}

.cds--inline-notification__close-button {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  height: 3rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--inline-notification__close-button:focus {
  outline: 2px solid var(--cds-focus-inverse, #ffffff);
  outline-offset: -2px;
}
.cds--inline-notification__close-button .cds--inline-notification__close-icon {
  fill: var(--cds-icon-inverse, #ffffff);
}
@media (min-width: 42rem) {
  .cds--inline-notification__close-button {
    position: static;
  }
}

.cds--inline-notification--low-contrast .cds--inline-notification__close-button:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--inline-notification--low-contrast .cds--inline-notification__close-button:focus {
    outline-style: dotted;
  }
}

.cds--inline-notification--low-contrast .cds--inline-notification__close-button .cds--inline-notification__close-icon {
  fill: var(--cds-icon-primary, #161616);
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--inline-notification {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--inline-notification__close-button:focus,
  .cds--btn.cds--btn--ghost.cds--inline-notification__action-button:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--inline-notification .cds--inline-notification__icon {
    fill: ButtonText;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--inline-notification .cds--inline-notification__close-icon {
    fill: ButtonText;
  }
}

/* stylelint-enable */
.cds--toast-notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 18rem;
  height: auto;
  padding-left: 0.8125rem;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  color: var(--cds-text-inverse, #ffffff);
}
.cds--toast-notification html {
  font-size: 100%;
}
.cds--toast-notification body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--toast-notification code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--toast-notification strong {
  font-weight: 600;
}
@media (min-width: 99rem) {
  .cds--toast-notification {
    width: 22rem;
  }
}

.cds--toast-notification:not(.cds--toast-notification--low-contrast) a {
  color: var(--cds-link-inverse, #78a9ff);
}

.cds--toast-notification a {
  text-decoration: none;
}

.cds--toast-notification a:hover {
  text-decoration: underline;
}

.cds--toast-notification a:focus {
  outline: 1px solid var(--cds-link-inverse, #78a9ff);
}

.cds--toast-notification.cds--toast-notification--low-contrast a:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--toast-notification.cds--toast-notification--low-contrast a:focus {
    outline-style: dotted;
  }
}

.cds--toast-notification--low-contrast {
  color: var(--cds-text-primary, #161616);
}

.cds--toast-notification--error {
  border-left: 3px solid var(--cds-support-error-inverse, #fa4d56);
  background: var(--cds-background-inverse, #393939);
}
.cds--toast-notification--error .cds--inline-notification__icon,
.cds--toast-notification--error .cds--toast-notification__icon,
.cds--toast-notification--error .cds--actionable-notification__icon {
  fill: var(--cds-support-error-inverse, #fa4d56);
}

.cds--toast-notification--low-contrast.cds--toast-notification--error {
  border-left: 3px solid var(--cds-support-error, #da1e28);
  background: var(--cds-notification-background-error, #fff1f1);
}
.cds--toast-notification--low-contrast.cds--toast-notification--error .cds--inline-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--error .cds--toast-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--error .cds--actionable-notification__icon {
  fill: var(--cds-support-error, #da1e28);
}

.cds--toast-notification--success {
  border-left: 3px solid var(--cds-support-success-inverse, #42be65);
  background: var(--cds-background-inverse, #393939);
}
.cds--toast-notification--success .cds--inline-notification__icon,
.cds--toast-notification--success .cds--toast-notification__icon,
.cds--toast-notification--success .cds--actionable-notification__icon {
  fill: var(--cds-support-success-inverse, #42be65);
}

.cds--toast-notification--low-contrast.cds--toast-notification--success {
  border-left: 3px solid var(--cds-support-success, #198038);
  background: var(--cds-notification-background-success, #defbe6);
}
.cds--toast-notification--low-contrast.cds--toast-notification--success .cds--inline-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--success .cds--toast-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--success .cds--actionable-notification__icon {
  fill: var(--cds-support-success, #198038);
}

.cds--toast-notification--info,
.cds--toast-notification--info-square {
  border-left: 3px solid var(--cds-support-info-inverse, #4589ff);
  background: var(--cds-background-inverse, #393939);
}
.cds--toast-notification--info .cds--inline-notification__icon,
.cds--toast-notification--info .cds--toast-notification__icon,
.cds--toast-notification--info .cds--actionable-notification__icon,
.cds--toast-notification--info-square .cds--inline-notification__icon,
.cds--toast-notification--info-square .cds--toast-notification__icon,
.cds--toast-notification--info-square .cds--actionable-notification__icon {
  fill: var(--cds-support-info-inverse, #4589ff);
}

.cds--toast-notification--low-contrast.cds--toast-notification--info,
.cds--toast-notification--low-contrast.cds--toast-notification--info-square {
  border-left: 3px solid var(--cds-support-info, #0043ce);
  background: var(--cds-notification-background-info, #edf5ff);
}
.cds--toast-notification--low-contrast.cds--toast-notification--info .cds--inline-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--info .cds--toast-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--info .cds--actionable-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--info-square .cds--inline-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--info-square .cds--toast-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--info-square .cds--actionable-notification__icon {
  fill: var(--cds-support-info, #0043ce);
}

.cds--toast-notification--warning,
.cds--toast-notification--warning-alt {
  border-left: 3px solid var(--cds-support-warning-inverse, #f1c21b);
  background: var(--cds-background-inverse, #393939);
}
.cds--toast-notification--warning .cds--inline-notification__icon,
.cds--toast-notification--warning .cds--toast-notification__icon,
.cds--toast-notification--warning .cds--actionable-notification__icon,
.cds--toast-notification--warning-alt .cds--inline-notification__icon,
.cds--toast-notification--warning-alt .cds--toast-notification__icon,
.cds--toast-notification--warning-alt .cds--actionable-notification__icon {
  fill: var(--cds-support-warning-inverse, #f1c21b);
}

.cds--toast-notification--low-contrast.cds--toast-notification--warning,
.cds--toast-notification--low-contrast.cds--toast-notification--warning-alt {
  border-left: 3px solid var(--cds-support-warning, #f1c21b);
  background: var(--cds-notification-background-warning, #fdf6dd);
}
.cds--toast-notification--low-contrast.cds--toast-notification--warning .cds--inline-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--warning .cds--toast-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--warning .cds--actionable-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--warning-alt .cds--inline-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--warning-alt .cds--toast-notification__icon,
.cds--toast-notification--low-contrast.cds--toast-notification--warning-alt .cds--actionable-notification__icon {
  fill: var(--cds-support-warning, #f1c21b);
}

.cds--toast-notification--warning .cds--toast-notification__icon path[opacity="0"] {
  fill: #000000;
  opacity: 1;
}

.cds--toast-notification__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: 1rem;
  margin-right: 1rem;
}

.cds--toast-notification__details {
  margin-right: 1rem;
}

.cds--toast-notification__close-button {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  border: none;
  margin-left: auto;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: outline 250ms, background-color 250ms;
  transition: outline 250ms, background-color 250ms;
}
.cds--toast-notification__close-button:focus {
  outline: 2px solid var(--cds-focus-inverse, #ffffff);
  outline-offset: -2px;
}
.cds--toast-notification__close-button .cds--toast-notification__close-icon {
  fill: var(--cds-icon-inverse, #ffffff);
}

.cds--toast-notification--low-contrast .cds--toast-notification__close-button:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--toast-notification--low-contrast .cds--toast-notification__close-button:focus {
    outline-style: dotted;
  }
}

.cds--toast-notification--low-contrast .cds--toast-notification__close-button .cds--toast-notification__close-icon {
  fill: var(--cds-icon-primary, #161616);
}

.cds--toast-notification__title {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  margin-top: 1rem;
  font-weight: 600;
  word-break: break-word;
}

.cds--toast-notification__subtitle {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--cds-text-inverse, #ffffff);
  word-break: break-word;
}

.cds--toast-notification--low-contrast .cds--toast-notification__subtitle {
  color: var(--cds-text-primary, #161616);
}

.cds--toast-notification__caption {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  color: var(--cds-text-inverse, #ffffff);
}

.cds--toast-notification--low-contrast .cds--toast-notification__caption {
  color: var(--cds-text-primary, #161616);
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--toast-notification {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--toast-notification__close-button:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--toast-notification .cds--toast-notification__close-icon {
    fill: ButtonText;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--toast-notification .cds--toast-notification__icon {
    fill: ButtonText;
  }
}

/* stylelint-enable */
.cds--actionable-notification {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 18rem;
  max-width: 18rem;
  height: auto;
  min-height: 3rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: var(--cds-text-inverse, #ffffff);
}
.cds--actionable-notification html {
  font-size: 100%;
}
.cds--actionable-notification body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--actionable-notification code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--actionable-notification strong {
  font-weight: 600;
}
@media (min-width: 42rem) {
  .cds--actionable-notification {
    max-width: 38rem;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media (min-width: 66rem) {
  .cds--actionable-notification {
    max-width: 46rem;
  }
}
@media (min-width: 99rem) {
  .cds--actionable-notification {
    max-width: 52rem;
  }
}

.cds--actionable-notification--toast {
  min-width: 18rem;
  max-width: 18rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) a {
  color: var(--cds-link-inverse, #78a9ff);
}

.cds--actionable-notification a {
  text-decoration: none;
}

.cds--actionable-notification a:hover {
  text-decoration: underline;
}

.cds--actionable-notification a:focus {
  outline: 1px solid var(--cds-focus-inverse, #ffffff);
}

.cds--actionable-notification.cds--actionable-notification--low-contrast a:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--actionable-notification.cds--actionable-notification--low-contrast a:focus {
    outline-style: dotted;
  }
}

.cds--actionable-notification--low-contrast {
  color: var(--cds-text-primary, #161616);
}

.cds--actionable-notification--low-contrast:not(.cds--actionable-notification--toast)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  content: "";
  -webkit-filter: opacity(0.4);
  filter: opacity(0.4);
  pointer-events: none;
}

.cds--actionable-notification--error {
  border-left: 3px solid var(--cds-support-error-inverse, #fa4d56);
  background: var(--cds-background-inverse, #393939);
}
.cds--actionable-notification--error .cds--inline-notification__icon,
.cds--actionable-notification--error .cds--toast-notification__icon,
.cds--actionable-notification--error .cds--actionable-notification__icon {
  fill: var(--cds-support-error-inverse, #fa4d56);
}

.cds--actionable-notification--low-contrast.cds--actionable-notification--error {
  border-left: 3px solid var(--cds-support-error, #da1e28);
  background: var(--cds-notification-background-error, #fff1f1);
}
.cds--actionable-notification--low-contrast.cds--actionable-notification--error .cds--inline-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--error .cds--toast-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--error .cds--actionable-notification__icon {
  fill: var(--cds-support-error, #da1e28);
}
.cds--actionable-notification--low-contrast.cds--actionable-notification--error::before {
  border-color: var(--cds-support-error, #da1e28);
}

.cds--actionable-notification--success {
  border-left: 3px solid var(--cds-support-success-inverse, #42be65);
  background: var(--cds-background-inverse, #393939);
}
.cds--actionable-notification--success .cds--inline-notification__icon,
.cds--actionable-notification--success .cds--toast-notification__icon,
.cds--actionable-notification--success .cds--actionable-notification__icon {
  fill: var(--cds-support-success-inverse, #42be65);
}

.cds--actionable-notification--low-contrast.cds--actionable-notification--success {
  border-left: 3px solid var(--cds-support-success, #198038);
  background: var(--cds-notification-background-success, #defbe6);
}
.cds--actionable-notification--low-contrast.cds--actionable-notification--success .cds--inline-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--success .cds--toast-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--success .cds--actionable-notification__icon {
  fill: var(--cds-support-success, #198038);
}
.cds--actionable-notification--low-contrast.cds--actionable-notification--success::before {
  border-color: var(--cds-support-success, #198038);
}

.cds--actionable-notification--info,
.cds--actionable-notification--info-square {
  border-left: 3px solid var(--cds-support-info-inverse, #4589ff);
  background: var(--cds-background-inverse, #393939);
}
.cds--actionable-notification--info .cds--inline-notification__icon,
.cds--actionable-notification--info .cds--toast-notification__icon,
.cds--actionable-notification--info .cds--actionable-notification__icon,
.cds--actionable-notification--info-square .cds--inline-notification__icon,
.cds--actionable-notification--info-square .cds--toast-notification__icon,
.cds--actionable-notification--info-square .cds--actionable-notification__icon {
  fill: var(--cds-support-info-inverse, #4589ff);
}

.cds--actionable-notification--low-contrast.cds--actionable-notification--info,
.cds--actionable-notification--low-contrast.cds--actionable-notification--info-square {
  border-left: 3px solid var(--cds-support-info, #0043ce);
  background: var(--cds-notification-background-info, #edf5ff);
}
.cds--actionable-notification--low-contrast.cds--actionable-notification--info .cds--inline-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--info .cds--toast-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--info .cds--actionable-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--info-square .cds--inline-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--info-square .cds--toast-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--info-square .cds--actionable-notification__icon {
  fill: var(--cds-support-info, #0043ce);
}
.cds--actionable-notification--low-contrast.cds--actionable-notification--info::before,
.cds--actionable-notification--low-contrast.cds--actionable-notification--info-square::before {
  border-color: var(--cds-support-info, #0043ce);
}

.cds--actionable-notification--warning,
.cds--actionable-notification--warning-alt {
  border-left: 3px solid var(--cds-support-warning-inverse, #f1c21b);
  background: var(--cds-background-inverse, #393939);
}
.cds--actionable-notification--warning .cds--inline-notification__icon,
.cds--actionable-notification--warning .cds--toast-notification__icon,
.cds--actionable-notification--warning .cds--actionable-notification__icon,
.cds--actionable-notification--warning-alt .cds--inline-notification__icon,
.cds--actionable-notification--warning-alt .cds--toast-notification__icon,
.cds--actionable-notification--warning-alt .cds--actionable-notification__icon {
  fill: var(--cds-support-warning-inverse, #f1c21b);
}

.cds--actionable-notification--low-contrast.cds--actionable-notification--warning,
.cds--actionable-notification--low-contrast.cds--actionable-notification--warning-alt {
  border-left: 3px solid var(--cds-support-warning, #f1c21b);
  background: var(--cds-notification-background-warning, #fdf6dd);
}
.cds--actionable-notification--low-contrast.cds--actionable-notification--warning .cds--inline-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--warning .cds--toast-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--warning .cds--actionable-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--warning-alt .cds--inline-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--warning-alt .cds--toast-notification__icon,
.cds--actionable-notification--low-contrast.cds--actionable-notification--warning-alt .cds--actionable-notification__icon {
  fill: var(--cds-support-warning, #f1c21b);
}
.cds--actionable-notification--low-contrast.cds--actionable-notification--warning::before,
.cds--actionable-notification--low-contrast.cds--actionable-notification--warning-alt::before {
  border-color: var(--cds-support-warning, #f1c21b);
}

.cds--actionable-notification--warning .cds--toast-notification__icon path[opacity="0"] {
  fill: #000000;
  opacity: 1;
}

.cds--actionable-notification__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 3rem 0 0.8125rem;
}

@media (min-width: 42rem) {
  .cds--actionable-notification:not(.cds--actionable-notification--toast) .cds--actionable-notification__details {
    margin: 0 0.8125rem;
  }
}

.cds--actionable-notification .cds--inline-notification__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: 0.875rem;
  margin-right: 1rem;
}

.cds--actionable-notification .cds--toast-notification__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: 1rem;
  margin-right: 1rem;
}

.cds--actionable-notification__text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.9375rem 0;
}

.cds--actionable-notification--toast .cds--actionable-notification__text-wrapper {
  padding: 0.9375rem 0 1.4375rem 0;
}

.cds--actionable-notification__content {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  word-break: break-word;
}

.cds--actionable-notification--toast .cds--actionable-notification__content {
  display: block;
}

.cds--actionable-notification__title {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  margin-right: 0.25rem;
  font-weight: 600;
  word-break: break-word;
}

.cds--actionable-notification--toast .cds--actionable-notification__title {
  margin-right: 0;
}

.cds--actionable-notification__subtitle {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  margin-top: 0;
  color: var(--cds-text-inverse, #ffffff);
  word-break: break-word;
}

.cds--actionable-notification--low-contrast .cds--actionable-notification__subtitle {
  color: var(--cds-text-primary, #161616);
}

/* Ghost action button when inline */
.cds--actionable-notification__action-button.cds--btn--ghost {
  height: 2rem;
  margin-bottom: 0.5rem;
  margin-left: 2.5rem;
}
@media (min-width: 42rem) {
  .cds--actionable-notification__action-button.cds--btn--ghost {
    margin: 0.5rem 0;
  }
}

.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--ghost {
  color: var(--cds-link-inverse, #78a9ff);
}

.cds--actionable-notification__action-button.cds--btn--ghost:active,
.cds--actionable-notification__action-button.cds--btn--ghost:hover {
  background-color: var(--cds-background-inverse-hover, #474747);
}

.cds--actionable-notification--low-contrast .cds--actionable-notification__action-button.cds--btn--ghost:active,
.cds--actionable-notification--low-contrast .cds--actionable-notification__action-button.cds--btn--ghost:hover {
  background-color: var(--cds-notification-action-hover, #edf5ff);
}

.cds--actionable-notification__action-button.cds--btn--ghost:focus {
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 2px solid var(--cds-focus-inverse, #ffffff);
  outline-offset: -2px;
}

.cds--actionable-notification--low-contrast .cds--actionable-notification__action-button.cds--btn--ghost:focus {
  outline-color: var(--cds-focus, #620b5b);
}

.cds--actionable-notification--hide-close-button .cds--actionable-notification__action-button.cds--btn--ghost {
  margin-right: 0.5rem;
}

/* Tertiary action button when not inline (toast) */
.cds--actionable-notification__action-button.cds--btn--tertiary {
  padding: 0 1rem;
  margin-bottom: 1rem;
  margin-left: calc(2rem + 1.25rem - 0.125rem);
}

.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary {
  border-width: 1px;
  border-style: solid;
  border-color: var(--cds-notification-action-tertiary-inverse, #ffffff);
  background-color: transparent;
  color: var(--cds-notification-action-tertiary-inverse, #ffffff);
}
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary:hover {
  background-color: var(--cds-notification-action-tertiary-inverse-hover, #f4f4f4);
}
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #620b5b));
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #620b5b)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary:active {
  background-color: var(--cds-notification-action-tertiary-inverse-active, #c6c6c6);
}
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary .cds--btn__icon,
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}

.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary:hover {
  color: var(--cds-notification-action-tertiary-inverse-text, #161616);
}

.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary:focus {
  border-color: var(--cds-focus-inverse, #ffffff);
  background-color: var(--cds-notification-action-tertiary-inverse, #ffffff);
  -webkit-box-shadow: inset 0 0 0 1px var(--cds-focus-inverse, #ffffff), inset 0 0 0 2px var(--cds-background-inverse, #393939);
  box-shadow: inset 0 0 0 1px var(--cds-focus-inverse, #ffffff), inset 0 0 0 2px var(--cds-background-inverse, #393939);
  color: var(--cds-notification-action-tertiary-inverse-text, #161616);
}

.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary:active {
  border-color: transparent;
  background-color: var(--cds-notification-action-tertiary-inverse-active, #c6c6c6);
  color: var(--cds-notification-action-tertiary-inverse-text, #161616);
}

.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary:disabled,
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary:hover:disabled,
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary:focus:disabled,
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary.cds--btn--disabled,
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary.cds--btn--disabled:hover,
.cds--actionable-notification:not(.cds--actionable-notification--low-contrast) .cds--actionable-notification__action-button.cds--btn--tertiary.cds--btn--disabled:focus {
  background: transparent;
  color: var(--cds-notification-action-tertiary-inverse-text-on-color-disabled, rgba(255, 255, 255, 0.25));
  outline: none;
}

.cds--actionable-notification--hide-close-button .cds--actionable-notification__action-button.cds--btn--tertiary {
  margin-right: 0.5rem;
}

.cds--actionable-notification__close-button {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  height: 3rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--actionable-notification__close-button:focus {
  outline: 2px solid var(--cds-focus-inverse, #ffffff);
  outline-offset: -2px;
}
.cds--actionable-notification__close-button .cds--actionable-notification__close-icon {
  fill: var(--cds-icon-inverse, #ffffff);
}
@media (min-width: 42rem) {
  .cds--actionable-notification__close-button {
    position: static;
  }
}

@media (min-width: 42rem) {
  .cds--actionable-notification--toast .cds--actionable-notification__close-button {
    position: absolute;
  }
}

.cds--actionable-notification--low-contrast .cds--actionable-notification__close-button:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--actionable-notification--low-contrast .cds--actionable-notification__close-button:focus {
    outline-style: dotted;
  }
}

.cds--actionable-notification--low-contrast .cds--actionable-notification__close-button .cds--actionable-notification__close-icon {
  fill: var(--cds-icon-primary, #161616);
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--actionable-notification {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--actionable-notification__close-button:focus,
  .cds--btn.cds--btn--ghost.cds--actionable-notification__action-button:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--actionable-notification .cds--inline-notification__icon,
  .cds--actionable-notification .cds--toast-notification__icon,
  .cds--actionable-notification .cds--actionable-notification__close-icon {
    fill: ButtonText;
  }
}

/* stylelint-enable */
.cds--overflow-menu,
.cds--overflow-menu__trigger {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
}
.cds--overflow-menu *,
.cds--overflow-menu *::before,
.cds--overflow-menu *::after,
.cds--overflow-menu__trigger *,
.cds--overflow-menu__trigger *::before,
.cds--overflow-menu__trigger *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--overflow-menu::-moz-focus-inner,
.cds--overflow-menu__trigger::-moz-focus-inner {
  border: 0;
}
.cds--overflow-menu html,
.cds--overflow-menu__trigger html {
  font-size: 100%;
}
.cds--overflow-menu body,
.cds--overflow-menu__trigger body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--overflow-menu code,
.cds--overflow-menu__trigger code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--overflow-menu strong,
.cds--overflow-menu__trigger strong {
  font-weight: 600;
}
.cds--overflow-menu:focus,
.cds--overflow-menu__trigger:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--overflow-menu:focus,
  .cds--overflow-menu__trigger:focus {
    outline-style: dotted;
  }
}
.cds--overflow-menu:hover,
.cds--overflow-menu__trigger:hover {
  background-color: var(--cds-layer-hover);
}

.cds--overflow-menu--sm {
  width: 2rem;
  height: 2rem;
}

.cds--overflow-menu--lg {
  width: 3rem;
  height: 3rem;
}

.cds--overflow-menu__trigger.cds--tooltip--a11y.cds--tooltip__trigger:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--overflow-menu__trigger.cds--tooltip--a11y.cds--tooltip__trigger:focus {
    outline-style: dotted;
  }
}
.cds--overflow-menu__trigger.cds--tooltip--a11y.cds--tooltip__trigger:focus svg {
  outline: none;
}

.cds--overflow-menu.cds--overflow-menu--open,
.cds--overflow-menu.cds--overflow-menu--open .cds--overflow-menu__trigger {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  background-color: var(--cds-layer);
  -webkit-transition: none;
  transition: none;
}

.cds--overflow-menu--light.cds--overflow-menu--open,
.cds--overflow-menu--light.cds--overflow-menu--open .cds--overflow-menu__trigger {
  background-color: var(--cds-layer);
}

.cds--overflow-menu__icon {
  width: 1rem;
  height: 1rem;
  fill: var(--cds-icon-primary, #161616);
}

.cds--overflow-menu-options {
  -webkit-box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  position: absolute;
  z-index: 6000;
  top: 32px;
  left: 0;
  display: none;
  width: 10rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: var(--cds-layer);
  list-style: none;
}
.cds--overflow-menu-options html {
  font-size: 100%;
}
.cds--overflow-menu-options body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--overflow-menu-options code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--overflow-menu-options strong {
  font-weight: 600;
}
.cds--overflow-menu-options::after {
  position: absolute;
  display: block;
  background-color: var(--cds-layer);
  content: "";
  -webkit-transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--overflow-menu-options::after {
    -webkit-transition: none;
    transition: none;
  }
}

.cds--overflow-menu.cds--overflow-menu--open:hover {
  background-color: var(--cds-layer);
}

.cds--overflow-menu-options--light {
  background-color: var(--cds-layer);
}
.cds--overflow-menu-options--light::after {
  background-color: var(--cds-layer);
}

.cds--overflow-menu.cds--overflow-menu--light.cds--overflow-menu--open:hover {
  background-color: var(--cds-layer);
}

.cds--overflow-menu-options[data-floating-menu-direction=bottom]:not(.cds--breadcrumb-menu-options)::after {
  top: -0.1875rem;
  left: 0;
  width: 2.5rem;
  height: 0.1875rem;
}

.cds--overflow-menu-options[data-floating-menu-direction=top]::after {
  bottom: -0.5rem;
  left: 0;
  width: 2.5rem;
  height: 0.5rem;
}

.cds--overflow-menu-options[data-floating-menu-direction=left]::after {
  top: 0;
  right: -0.375rem;
  width: 0.375rem;
  height: 2.5rem;
}

.cds--overflow-menu-options[data-floating-menu-direction=right]::after {
  top: 0;
  left: -0.375rem;
  width: 0.375rem;
  height: 2.5rem;
}

.cds--overflow-menu-options--sm.cds--overflow-menu-options[data-floating-menu-direction=bottom]::after, .cds--overflow-menu-options--sm.cds--overflow-menu-options[data-floating-menu-direction=top]::after {
  width: 2rem;
}
.cds--overflow-menu-options--sm.cds--overflow-menu-options[data-floating-menu-direction=left]::after, .cds--overflow-menu-options--sm.cds--overflow-menu-options[data-floating-menu-direction=right]::after {
  height: 2rem;
}

.cds--overflow-menu-options--lg.cds--overflow-menu-options[data-floating-menu-direction=bottom]::after, .cds--overflow-menu-options--lg.cds--overflow-menu-options[data-floating-menu-direction=top]::after {
  width: 3rem;
}
.cds--overflow-menu-options--lg.cds--overflow-menu-options[data-floating-menu-direction=left]::after, .cds--overflow-menu-options--lg.cds--overflow-menu-options[data-floating-menu-direction=right]::after {
  height: 3rem;
}

.cds--overflow-menu--flip.cds--overflow-menu-options[data-floating-menu-direction=top]::after,
.cds--overflow-menu--flip.cds--overflow-menu-options[data-floating-menu-direction=bottom]::after {
  right: 0;
  left: auto;
}

.cds--overflow-menu--flip.cds--overflow-menu-options[data-floating-menu-direction=left]::after,
.cds--overflow-menu--flip.cds--overflow-menu-options[data-floating-menu-direction=right]::after {
  top: auto;
  bottom: 0;
}

.cds--overflow-menu-options--open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cds--overflow-menu-options__content {
  width: 100%;
}

.cds--overflow-menu-options__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  -webkit-transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
}
.cds--overflow-menu-options__option html {
  font-size: 100%;
}
.cds--overflow-menu-options__option body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--overflow-menu-options__option code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--overflow-menu-options__option strong {
  font-weight: 600;
}

.cds--overflow-menu-options--sm .cds--overflow-menu-options__option {
  height: 2rem;
}

.cds--overflow-menu-options--lg .cds--overflow-menu-options__option {
  height: 3rem;
}

.cds--overflow-menu--divider {
  border-top: 1px solid var(--cds-border-subtle);
}

.cds--overflow-menu--light .cds--overflow-menu--divider {
  border-top: 1px solid var(--cds-border-subtle);
}

a.cds--overflow-menu-options__btn::before {
  display: inline-block;
  height: 100%;
  content: "";
  vertical-align: middle;
}

.cds--overflow-menu-options__btn {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  max-width: 11.25rem;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  border: none;
  background-color: transparent;
  color: var(--cds-text-secondary, #525252);
  cursor: pointer;
  font-family: inherit;
  font-weight: 400;
  text-align: left;
  -webkit-transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9), color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9), color 110ms cubic-bezier(0, 0, 0.38, 0.9);
}
.cds--overflow-menu-options__btn:hover {
  color: var(--cds-text-primary, #161616);
}
.cds--overflow-menu-options__btn:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--overflow-menu-options__btn:focus {
    outline-style: dotted;
  }
}
.cds--overflow-menu-options__btn::-moz-focus-inner {
  border: none;
}

.cds--overflow-menu-options__btn svg {
  fill: var(--cds-icon-secondary, #525252);
}

.cds--overflow-menu-options__btn:hover svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--overflow-menu-options__option-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--overflow-menu-options__option:hover {
  background-color: var(--cds-layer-hover);
}

.cds--overflow-menu-options__option--danger .cds--overflow-menu-options__btn:hover,
.cds--overflow-menu-options__option--danger .cds--overflow-menu-options__btn:focus {
  background-color: var(--cds-button-danger-primary, #da1e28);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--overflow-menu-options__option--danger .cds--overflow-menu-options__btn:hover svg,
.cds--overflow-menu-options__option--danger .cds--overflow-menu-options__btn:focus svg {
  fill: currentColor;
}

.cds--overflow-menu-options__option--disabled:hover {
  background-color: var(--cds-layer);
  cursor: not-allowed;
}

.cds--overflow-menu-options__option--disabled .cds--overflow-menu-options__btn {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}
.cds--overflow-menu-options__option--disabled .cds--overflow-menu-options__btn:hover, .cds--overflow-menu-options__option--disabled .cds--overflow-menu-options__btn:active, .cds--overflow-menu-options__option--disabled .cds--overflow-menu-options__btn:focus {
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: var(--cds-layer);
}

.cds--overflow-menu-options__option--disabled .cds--overflow-menu-options__btn svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--overflow-menu--flip {
  left: -140px;
}
.cds--overflow-menu--flip::before {
  left: 145px;
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--overflow-menu:focus,
  .cds--overflow-menu-options__btn:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--overflow-menu svg {
    fill: ButtonText;
  }
}

/*stylelint-enable */
.cds--data-table-container + .cds--pagination {
  border-top: 0;
}

.cds--pagination {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 1px);
  min-height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid var(--cds-border-subtle);
  background-color: var(--cds-layer);
  overflow-x: auto;
}
.cds--pagination *,
.cds--pagination *::before,
.cds--pagination *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
@media (min-width: 42rem) {
  .cds--pagination {
    overflow: initial;
  }
  .cds--pagination .cds--pagination__control-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 41.98rem) {
  .cds--pagination .cds--pagination__left > *,
  .cds--pagination .cds--pagination__right > * {
    display: none;
  }
  .cds--pagination .cds--pagination__items-count {
    display: initial;
  }
  .cds--pagination .cds--pagination__control-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.cds--pagination--sm {
  min-height: 2rem;
}

.cds--pagination--lg {
  min-height: 3rem;
}

.cds--pagination .cds--select {
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--pagination .cds--select-input--inline__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.cds--pagination .cds--select-input {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  width: auto;
  min-width: auto;
  height: 100%;
  line-height: 2.5rem;
}

.cds--pagination .cds--select--inline .cds--select-input {
  padding: 0 2.25rem 0 1rem;
}

.cds--pagination--sm .cds--select-input {
  line-height: 2rem;
}

.cds--pagination--lg .cds--select-input {
  line-height: 3rem;
}

.cds--pagination .cds--select-input:hover {
  background: var(--cds-layer-hover);
}

.cds--pagination .cds--select--inline .cds--select-input:focus,
.cds--pagination .cds--select--inline .cds--select-input:focus option,
.cds--pagination .cds--select--inline .cds--select-input:focus optgroup {
  background-color: var(--cds-layer);
}

.cds--pagination .cds--select__arrow {
  top: 50%;
  -webkit-transform: translate(-0.5rem, -50%);
  transform: translate(-0.5rem, -50%);
}

.cds--pagination .cds--select__item-count .cds--select-input {
  border-right: 1px solid var(--cds-border-subtle);
}

.cds--pagination__right {
  border-left: 1px solid var(--cds-border-subtle);
}

.cds--pagination__left,
.cds--pagination__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--pagination__left > .cds--form-item,
.cds--pagination__right > .cds--form-item {
  height: 100%;
}

.cds--pagination__left .cds--pagination__text,
.cds--pagination__right .cds--pagination__text {
  white-space: nowrap;
}

.cds--pagination__left .cds--pagination__text {
  margin-right: 0.0625rem;
}

.cds--pagination__right .cds--pagination__text {
  margin-right: 1rem;
  margin-left: 0.0625rem;
}

.cds--pagination__right .cds--pagination__text.cds--pagination__page-text {
  margin-right: 0.0625rem;
  margin-left: 1rem;
}

.cds--pagination__right .cds--pagination__text:empty {
  margin: 0;
}

.cds--pagination__left {
  padding: 0 1rem 0 0;
}
@media (min-width: 42rem) {
  .cds--pagination__left {
    padding: 0 1rem;
  }
}

@media (min-width: 42rem) {
  .cds--pagination__text {
    display: inline-block;
  }
}

span.cds--pagination__text {
  margin-left: 1rem;
  color: var(--cds-text-primary, #161616);
}

span.cds--pagination__text.cds--pagination__items-count {
  color: var(--cds-text-secondary, #525252);
}

.cds--pagination__button,
.cds--btn--ghost.cds--pagination__button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  border-left: 1px solid var(--cds-border-subtle);
  margin: 0;
  background: none;
  cursor: pointer;
  fill: var(--cds-icon-primary, #161616);
  -webkit-transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--pagination__button *,
.cds--pagination__button *::before,
.cds--pagination__button *::after,
.cds--btn--ghost.cds--pagination__button *,
.cds--btn--ghost.cds--pagination__button *::before,
.cds--btn--ghost.cds--pagination__button *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--pagination--sm .cds--pagination__button,
.cds--pagination--sm .cds--btn--ghost.cds--pagination__button {
  width: 2rem;
  height: 2rem;
}

.cds--pagination--lg .cds--pagination__button,
.cds--pagination--lg .cds--btn--ghost.cds--pagination__button {
  width: 3rem;
  height: 3rem;
}

.cds--pagination__button:focus,
.cds--btn--ghost:focus.cds--pagination__button {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
  border-left: 0;
}
@media screen and (prefers-contrast) {
  .cds--pagination__button:focus,
  .cds--btn--ghost:focus.cds--pagination__button {
    outline-style: dotted;
  }
}

.cds--pagination__button:hover,
.cds--btn--ghost:hover.cds--pagination__button {
  background: var(--cds-layer-hover);
}

.cds--pagination__button--no-index,
.cds--btn--ghost.cds--pagination__button--no-index {
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--pagination__button:disabled:hover,
.cds--pagination__button--no-index:hover,
.cds--btn--ghost:disabled:hover.cds--pagination__button,
.cds--btn--ghost:hover.cds--pagination__button--no-index {
  border-color: var(--cds-border-subtle);
  background: var(--cds-layer);
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--pagination.cds--skeleton .cds--skeleton__text {
  margin-right: 1rem;
  margin-bottom: 0;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--pagination__button,
  .cds--btn--ghost.cds--pagination__button {
    outline: 1px solid transparent;
  }
}

.cds--unstable-pagination {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid var(--cds-border-subtle);
  border-bottom: 1px solid transparent;
  background-color: var(--cds-layer);
}
.cds--unstable-pagination *,
.cds--unstable-pagination *::before,
.cds--unstable-pagination *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--unstable-pagination__text {
  margin: 0 1rem;
  color: var(--cds-text-secondary, #525252);
}
@media (min-width: 42rem) {
  .cds--unstable-pagination__text {
    display: inline-block;
  }
}

.cds--unstable-pagination__left,
.cds--unstable-pagination__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--unstable-pagination__left {
  padding: 0 1rem 0 0;
}

.cds--unstable-pagination__left > .cds--form-item,
.cds--unstable-pagination__right > .cds--form-item {
  height: 100%;
}

.cds--unstable-pagination__left .cds--unstable-pagination__text {
  margin-right: 0.0625rem;
}

.cds--unstable-pagination__right .cds--unstable-pagination__text {
  margin-right: 1rem;
  margin-left: 0.0625rem;
}

.cds--unstable-pagination__button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  border-left: 1px solid var(--cds-border-subtle);
  margin: 0;
  background: none;
  color: var(--cds-icon-primary, #161616);
  cursor: pointer;
  fill: var(--cds-icon-primary, #161616);
  -webkit-transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--unstable-pagination__button *,
.cds--unstable-pagination__button *::before,
.cds--unstable-pagination__button *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--unstable-pagination__button .cds--btn__icon {
  width: initial;
  height: initial;
}

.cds--unstable-pagination__button.cds--btn--icon-only.cds--tooltip__trigger:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--unstable-pagination__button.cds--btn--icon-only.cds--tooltip__trigger:focus {
    outline-style: dotted;
  }
}

.cds--unstable-pagination__button:hover {
  background: var(--cds-layer-hover);
  color: var(--cds-icon-primary, #161616);
}

.cds--unstable-pagination__button--no-index {
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--unstable-pagination__button.cds--btn:disabled {
  border-color: var(--cds-border-subtle);
  background: transparent;
}

.cds--unstable-pagination__button:disabled:hover,
.cds--unstable-pagination__button--no-index:hover {
  background: transparent;
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--unstable-pagination__page-selector,
.cds--unstable-pagination__page-sizer {
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--unstable-pagination__page-selector .cds--select-input--inline__wrapper,
.cds--unstable-pagination__page-sizer .cds--select-input--inline__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.cds--unstable-pagination__page-selector .cds--select-input,
.cds--unstable-pagination__page-sizer .cds--select-input {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  width: auto;
  min-width: auto;
  height: 100%;
  padding: 0 2.25rem 0 1rem;
  line-height: 2.5rem;
}

.cds--unstable-pagination__page-selector .cds--select-input:hover,
.cds--unstable-pagination__page-sizer .cds--select-input:hover {
  background: var(--cds-layer-hover);
}

.cds--unstable-pagination__page-selector .cds--select__arrow,
.cds--unstable-pagination__page-sizer .cds--select__arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (min-width: 42rem) {
  .cds--unstable-pagination__page-selector .cds--select__arrow,
  .cds--unstable-pagination__page-sizer .cds--select__arrow {
    right: 1rem;
  }
}

.cds--unstable-pagination__page-selector {
  border-left: 1px solid var(--cds-border-subtle);
}

.cds--unstable-pagination__page-sizer {
  border-right: 1px solid var(--cds-border-subtle);
}

.cds--pagination-nav {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  line-height: 0;
}
.cds--pagination-nav html {
  font-size: 100%;
}
.cds--pagination-nav body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--pagination-nav code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--pagination-nav strong {
  font-weight: 600;
}

.cds--pagination-nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
}

.cds--pagination-nav__list-item {
  padding: 0;
}
.cds--pagination-nav__list-item:first-child {
  padding-left: 0;
}
.cds--pagination-nav__list-item:last-child {
  padding-right: 0;
}

.cds--pagination-nav__page {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  position: relative;
  display: block;
  min-width: 3rem;
  padding: 1.0625rem 0.25rem;
  border-radius: 0;
  color: var(--cds-text-primary, #161616);
  font-weight: 400;
  line-height: 1;
  outline: 0;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cds--pagination-nav__page *,
.cds--pagination-nav__page *::before,
.cds--pagination-nav__page *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--pagination-nav__page::-moz-focus-inner {
  border: 0;
}
.cds--pagination-nav__page:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}
.cds--pagination-nav__page:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--pagination-nav__page:focus {
    outline-style: dotted;
  }
}
.cds--pagination-nav__page:disabled, .cds--pagination-nav__page.cds--pagination-nav__page--disabled {
  background: none;
  color: rgba(var(--cds-text-secondary, #525252), 0.5);
  outline: none;
  pointer-events: none;
}
.cds--pagination-nav__page:not(.cds--pagination-nav__page--direction)::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 0;
  height: 0.25rem;
  background-color: var(--cds-border-interactive, #620b5b);
  content: "";
  opacity: 0;
  -webkit-transition: width 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: width 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--pagination-nav__page:not(.cds--pagination-nav__page--direction)::after {
    -webkit-transition: none;
    transition: none;
  }
}
.cds--pagination-nav__page--active + .cds--pagination-nav__page::after, .cds--pagination-nav__page.cds--pagination-nav__page--active::after {
  left: calc(50% - 0.5rem);
  width: 1rem;
  opacity: 1;
}
.cds--pagination-nav__page.cds--pagination-nav__page--active {
  background-color: initial;
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
}
.cds--pagination-nav__page .cds--pagination-nav__icon {
  fill: currentColor;
  pointer-events: none;
}

.cds--pagination-nav__page--direction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 0;
}

.cds--pagination-nav__select {
  position: relative;
}

.cds--pagination-nav__page--select {
  max-height: 3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: calc(50% - 4.5px);
}
@-moz-document url-prefix() {
  .cds--pagination-nav__page--select {
    text-indent: 0;
  }
}

.cds--pagination-nav__select-icon-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.cds--pagination-nav__select-icon-wrapper:not(.cds--pagination-nav__page--direction)::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 0;
  height: 0.25rem;
  background-color: var(--cds-border-interactive, #620b5b);
  content: "";
  opacity: 0;
  -webkit-transition: width 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: width 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--pagination-nav__select-icon-wrapper:not(.cds--pagination-nav__page--direction)::after {
    -webkit-transition: none;
    transition: none;
  }
}
.cds--pagination-nav__page--active + .cds--pagination-nav__select-icon-wrapper::after, .cds--pagination-nav__select-icon-wrapper.cds--pagination-nav__page--active::after {
  left: calc(50% - 0.5rem);
  width: 1rem;
  opacity: 1;
}
.cds--pagination-nav__page--active + .cds--pagination-nav__select-icon-wrapper .cds--pagination-nav__select-icon {
  display: none;
}

.cds--pagination-nav__select-icon {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: calc(50% - 0.5rem);
  pointer-events: none;
}

.cds--pagination-nav__accessibility-label {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}

.cds--popover-container {
  position: relative;
  display: inline-block;
}

.cds--popover--high-contrast .cds--popover {
  --cds-popover-background-color: var(--cds-background-inverse, #393939);
  --cds-popover-text-color: var(--cds-text-inverse, #ffffff);
}

.cds--popover--drop-shadow .cds--popover {
  --cds-popover-drop-shadow: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
}

.cds--popover--caret {
  --cds-popover-offset: 0.625rem;
}

.cds--popover {
  position: absolute;
  z-index: 6000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-filter: var(--cds-popover-drop-shadow, none);
  filter: var(--cds-popover-drop-shadow, none);
  pointer-events: none;
}

.cds--popover-content {
  position: absolute;
  z-index: 6000;
  display: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 23rem;
  background-color: var(--cds-popover-background-color, var(--cds-layer));
  border-radius: var(--cds-popover-border-radius, 2px);
  color: var(--cds-popover-text-color, var(--cds-text-primary, #161616));
  pointer-events: auto;
}

.cds--popover--open .cds--popover-content {
  display: block;
}

.cds--popover-content::before {
  position: absolute;
  display: none;
  content: "";
}

.cds--popover--open .cds--popover-content::before {
  display: block;
}

.cds--popover-caret {
  position: absolute;
  z-index: 6000;
  display: none;
  background-color: var(--cds-popover-background-color, var(--cds-layer));
  will-change: transform;
}

.cds--popover--open.cds--popover--caret .cds--popover-caret {
  display: block;
}

.cds--popover--bottom .cds--popover-content {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, calc(100% + var(--cds-popover-offset, 0rem)));
  transform: translate(-50%, calc(100% + var(--cds-popover-offset, 0rem)));
}

.cds--popover--bottom-left .cds--popover-content {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(100% + var(--cds-popover-offset, 0rem)));
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(100% + var(--cds-popover-offset, 0rem)));
}

.cds--popover--bottom-right .cds--popover-content {
  right: 0;
  bottom: 0;
  -webkit-transform: translate(var(--cds-popover-offset, 0rem), calc(100% + var(--cds-popover-offset, 0rem)));
  transform: translate(var(--cds-popover-offset, 0rem), calc(100% + var(--cds-popover-offset, 0rem)));
}

.cds--popover--bottom .cds--popover-content::before,
.cds--popover--bottom-left .cds--popover-content::before,
.cds--popover--bottom-right .cds--popover-content::before {
  top: 0;
  right: 0;
  left: 0;
  height: var(--cds-popover-offset, 0rem);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.cds--popover--bottom .cds--popover-caret,
.cds--popover--bottom-left .cds--popover-caret,
.cds--popover--bottom-right .cds--popover-caret {
  bottom: 0;
  left: 50%;
  width: var(--cds-popover-caret-width, 0.75rem);
  height: var(--cds-popover-caret-height, 0.375rem);
  -webkit-clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  -webkit-transform: translate(-50%, var(--cds-popover-offset, 0rem));
  transform: translate(-50%, var(--cds-popover-offset, 0rem));
}

.cds--popover--top .cds--popover-content {
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, calc(-100% - var(--cds-popover-offset, 0rem)));
  transform: translate(-50%, calc(-100% - var(--cds-popover-offset, 0rem)));
}

.cds--popover--top-left .cds--popover-content {
  top: 0;
  left: 0;
  -webkit-transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(-100% - var(--cds-popover-offset, 0rem)));
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(-100% - var(--cds-popover-offset, 0rem)));
}

.cds--popover--top-right .cds--popover-content {
  top: 0;
  right: 0;
  -webkit-transform: translate(var(--cds-popover-offset, 0rem), calc(-100% - var(--cds-popover-offset, 0rem)));
  transform: translate(var(--cds-popover-offset, 0rem), calc(-100% - var(--cds-popover-offset, 0rem)));
}

.cds--popover--top .cds--popover-content::before,
.cds--popover--top-left .cds--popover-content::before,
.cds--popover--top-right .cds--popover-content::before {
  right: 0;
  bottom: 0;
  left: 0;
  height: var(--cds-popover-offset, 0rem);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.cds--popover--top .cds--popover-caret,
.cds--popover--top-left .cds--popover-caret,
.cds--popover--top-right .cds--popover-caret {
  top: 0;
  left: 50%;
  width: var(--cds-popover-caret-width, 0.75rem);
  height: var(--cds-popover-caret-height, 0.375rem);
  -webkit-clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  -webkit-transform: translate(-50%, calc(-1 * var(--cds-popover-offset, 0rem)));
  transform: translate(-50%, calc(-1 * var(--cds-popover-offset, 0rem)));
}

.cds--popover--right .cds--popover-content {
  top: 50%;
  left: 100%;
  -webkit-transform: translate(var(--cds-popover-offset, 0rem), -50%);
  transform: translate(var(--cds-popover-offset, 0rem), -50%);
}

.cds--popover--right-top .cds--popover-content {
  top: 50%;
  left: 100%;
  -webkit-transform: translate(var(--cds-popover-offset, 0rem), calc(0.5 * var(--cds-popover-offset, 0rem) * -1 - 16px));
  transform: translate(var(--cds-popover-offset, 0rem), calc(0.5 * var(--cds-popover-offset, 0rem) * -1 - 16px));
}

.cds--popover--right-bottom .cds--popover-content {
  bottom: 50%;
  left: 100%;
  -webkit-transform: translate(var(--cds-popover-offset, 0rem), calc(0.5 * var(--cds-popover-offset, 0rem) + 16px));
  transform: translate(var(--cds-popover-offset, 0rem), calc(0.5 * var(--cds-popover-offset, 0rem) + 16px));
}

.cds--popover--right .cds--popover-content::before,
.cds--popover--right-top .cds--popover-content::before,
.cds--popover--right-bottom .cds--popover-content::before {
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--cds-popover-offset, 0rem);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.cds--popover--right .cds--popover-caret,
.cds--popover--right-top .cds--popover-caret,
.cds--popover--right-bottom .cds--popover-caret {
  top: 50%;
  left: 100%;
  width: var(--cds-popover-caret-height, 0.375rem);
  height: var(--cds-popover-caret-width, 0.75rem);
  -webkit-clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
  clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
  -webkit-transform: translate(calc(var(--cds-popover-offset, 0rem) - 100%), -50%);
  transform: translate(calc(var(--cds-popover-offset, 0rem) - 100%), -50%);
}

.cds--popover--left .cds--popover-content {
  top: 50%;
  right: 100%;
  -webkit-transform: translate(calc(-1 * var(--cds-popover-offset, 0rem) + 0.1px), -50%);
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem) + 0.1px), -50%);
}

.cds--popover--left-top .cds--popover-content {
  top: -50%;
  right: 100%;
  -webkit-transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(-0.5 * var(--cds-popover-offset, 0rem)));
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(-0.5 * var(--cds-popover-offset, 0rem)));
}

.cds--popover--left-bottom .cds--popover-content {
  right: 100%;
  bottom: -50%;
  -webkit-transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(0.5 * var(--cds-popover-offset, 0rem)));
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(0.5 * var(--cds-popover-offset, 0rem)));
}

.cds--popover--left .cds--popover-content::before,
.cds--popover--left-top .cds--popover-content::before,
.cds--popover--left-bottom .cds--popover-content::before {
  top: 0;
  right: 0;
  bottom: 0;
  width: var(--cds-popover-offset, 0rem);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.cds--popover--left .cds--popover-caret,
.cds--popover--left-top .cds--popover-caret,
.cds--popover--left-bottom .cds--popover-caret {
  top: 50%;
  right: 100%;
  width: var(--cds-popover-caret-height, 0.375rem);
  height: var(--cds-popover-caret-width, 0.75rem);
  -webkit-clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  -webkit-transform: translate(calc(-1 * var(--cds-popover-offset, 0rem) + 100%), -50%);
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem) + 100%), -50%);
}

.cds--progress-bar__label {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 3rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  color: var(--cds-text-primary, #161616);
}

.cds--progress-bar__label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--progress-bar__track {
  position: relative;
  width: 100%;
  min-width: 3rem;
  height: 0.5rem;
  background-color: var(--cds-layer);
}

.cds--progress-bar--big .cds--progress-bar__track {
  height: 0.5rem;
}

.cds--progress-bar--small .cds--progress-bar__track {
  height: 0.25rem;
}

.cds--progress-bar__bar {
  display: block;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  color: var(--cds-interactive, #620b5b);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 center /*rtl:100% center*/;
  transform-origin: 0 center /*rtl:100% center*/;
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--progress-bar--indeterminate .cds--progress-bar__track::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1400ms;
  animation-duration: 1400ms;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: progress-bar-indeterminate;
  animation-name: progress-bar-indeterminate;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(12.5%, var(--cds-interactive, #620b5b)), color-stop(12.5%, transparent));
  background-image: linear-gradient(90deg, var(--cds-interactive, #620b5b) 12.5%, transparent 12.5%);
  background-position-x: 0%;
  background-size: 200% 100%;
  content: "";
}

.cds--progress-bar__helper-text {
  font-size: var(--cds-helper-text-01-font-size, 0.75rem);
  line-height: var(--cds-helper-text-01-line-height, 1.33333);
  letter-spacing: var(--cds-helper-text-01-letter-spacing, 0.32px);
  margin-top: 0.5rem;
  color: var(--cds-text-secondary, #525252);
}

.cds--progress-bar__status-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 1rem;
}

.cds--progress-bar--finished .cds--progress-bar__bar,
.cds--progress-bar--finished .cds--progress-bar__status-icon {
  color: var(--cds-support-success, #198038);
}

.cds--progress-bar--error .cds--progress-bar__bar,
.cds--progress-bar--error .cds--progress-bar__status-icon,
.cds--progress-bar--error .cds--progress-bar__helper-text {
  color: var(--cds-support-error, #da1e28);
}

.cds--progress-bar--finished .cds--progress-bar__bar,
.cds--progress-bar--error .cds--progress-bar__bar {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.cds--progress-bar--finished.cds--progress-bar--inline .cds--progress-bar__track,
.cds--progress-bar--error.cds--progress-bar--inline .cds--progress-bar__track {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}

.cds--progress-bar--finished.cds--progress-bar--inline .cds--progress-bar__label,
.cds--progress-bar--error.cds--progress-bar--inline .cds--progress-bar__label {
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-right: 0;
}

@-webkit-keyframes progress-bar-indeterminate {
  0% {
    background-position-x: 25%;
  }
  80%, 100% {
    background-position-x: -105%;
  }
}

@keyframes progress-bar-indeterminate {
  0% {
    background-position-x: 25%;
  }
  80%, 100% {
    background-position-x: -105%;
  }
}
.cds--progress-bar--inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--progress-bar--inline .cds--progress-bar__label {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-bottom: 0;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.cds--progress-bar--inline .cds--progress-bar__track {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.cds--progress-bar--inline .cds--progress-bar__helper-text {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}

.cds--progress-bar--indented .cds--progress-bar__label,
.cds--progress-bar--indented .cds--progress-bar__helper-text {
  padding-inline: 1rem;
}

.cds--tooltip {
  --cds-popover-offset: 12px;
}

.cds--tooltip-content {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  max-width: 18rem;
  padding: var(--cds-tooltip-padding-block, 1rem) var(--cds-tooltip-padding-inline, 1rem);
  color: var(--cds-text-inverse, #ffffff);
}

.cds--icon-tooltip {
  --cds-tooltip-padding-block: 0.125rem;
  --cds-popover-caret-width: 0.5rem;
  --cds-popover-caret-height: 0.25rem;
  --cds-popover-offset: 0.5rem;
}

.cds--icon-tooltip .cds--tooltip-content {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}

.cds--definition-term {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  border-bottom: 1px dotted var(--cds-border-strong);
  border-radius: 0;
  color: var(--cds-text-primary, #161616);
}
.cds--definition-term *,
.cds--definition-term *::before,
.cds--definition-term *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--definition-term::-moz-focus-inner {
  border: 0;
}

.cds--definition-term:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
  border-bottom-color: var(--cds-border-interactive, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--definition-term:focus {
    outline-style: dotted;
  }
}

.cds--definition-term:hover {
  border-bottom-color: var(--cds-border-interactive, #620b5b);
}

.cds--definition-tooltip {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  max-width: 11rem;
  padding: 0.5rem 1rem;
}

.cds--progress {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}
.cds--progress *,
.cds--progress *::before,
.cds--progress *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--progress-step {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: visible;
  width: 8rem;
  min-width: 7rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.cds--progress-step .cds--tooltip__label {
  display: block;
}

.cds--progress--space-equal .cds--progress-step {
  min-width: 8rem;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.cds--progress-line {
  position: absolute;
  left: 0;
  width: 8rem;
  height: 2px;
  border: 1px inset transparent;
}

.cds--progress--space-equal .cds--progress-line {
  width: 100%;
  min-width: 8rem;
}

.cds--progress-step svg {
  position: relative;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: 0.625rem 0.5rem 0 0;
  border-radius: 50%;
  fill: var(--cds-interactive, #620b5b);
}

.cds--progress--space-equal .cds--progress-text {
  overflow: hidden;
}

.cds--progress-label {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  overflow: hidden;
  max-width: 5.5rem;
  margin: 0.5rem 0 0 0;
  color: var(--cds-text-primary, #161616);
  line-height: 1.45;
  text-overflow: ellipsis;
  -webkit-transition: color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9), color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9), color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  white-space: nowrap;
}
.cds--progress-label::before {
  display: block;
  content: "";
}

.cds--progress-label:hover {
  -webkit-box-shadow: 0 0.0625rem var(--cds-link-primary-hover, #0043ce);
  box-shadow: 0 0.0625rem var(--cds-link-primary-hover, #0043ce);
  color: var(--cds-link-primary-hover, #0043ce);
  cursor: pointer;
}

.cds--progress--space-equal .cds--progress-label {
  max-width: 100%;
  margin-right: 0.75rem;
}

.cds--progress-step-button:not(.cds--progress-step-button--unclickable):focus {
  outline: none;
}
.cds--progress-step-button:not(.cds--progress-step-button--unclickable):focus-visible .cds--progress-label {
  color: var(--cds-focus, #620b5b);
  outline: 0.0625rem solid var(--cds-focus, #620b5b);
}
.cds--progress-step-button:not(.cds--progress-step-button--unclickable) .cds--progress-label:active {
  -webkit-box-shadow: 0 0.0625rem 0 0 var(--cds-text-primary, #161616);
  box-shadow: 0 0.0625rem 0 0 var(--cds-text-primary, #161616);
  color: var(--cds-text-primary, #161616);
}

.cds--progress-label-overflow:hover ~ .cds--tooltip,
.cds--progress-label-overflow:focus ~ .cds--tooltip {
  visibility: inherit;
}

.cds--progress-step .cds--tooltip .cds--tooltip__caret {
  margin-left: 0.625rem;
}

.cds--tooltip__text {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

.cds--progress-step .cds--tooltip {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  display: block;
  width: 7.8125rem;
  min-width: 7.1875rem;
  min-height: 1.5rem;
  padding: 0.5rem 1rem;
  margin-top: 2.5rem;
  margin-left: 1.375rem;
  color: var(--cds-text-inverse, #ffffff);
  visibility: hidden;
}

.cds--progress-step .cds--tooltip_multi {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  width: 9.375rem;
  height: auto;
  color: var(--cds-text-inverse, #ffffff);
}

.cds--progress-optional {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  position: absolute;
  left: 0;
  margin-top: 1.75rem;
  margin-left: 1.5rem;
  color: var(--cds-text-secondary, #525252);
  text-align: start;
}

.cds--progress-step--current .cds--progress-line {
  background-color: var(--cds-interactive, #620b5b);
}

.cds--progress-step--incomplete svg {
  fill: var(--cds-icon-primary, #161616);
}
.cds--progress-step--incomplete .cds--progress-line {
  background-color: var(--cds-border-subtle);
}

.cds--progress-step--complete .cds--progress-line {
  background-color: var(--cds-interactive, #620b5b);
}

.cds--progress-step-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
}
.cds--progress-step-button *,
.cds--progress-step-button *::before,
.cds--progress-step-button *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--progress-step-button::-moz-focus-inner {
  border: 0;
}

.cds--progress-step-button--unclickable {
  cursor: default;
  outline: none;
}

.cds--progress-step-button--unclickable .cds--progress-label:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--cds-text-primary, #161616);
  cursor: default;
}

.cds--progress-step-button--unclickable .cds--tooltip__label:hover {
  -webkit-box-shadow: 0 0.0625rem var(--cds-link-primary, #620b5b);
  box-shadow: 0 0.0625rem var(--cds-link-primary, #620b5b);
  color: var(--cds-link-primary, #620b5b);
  cursor: pointer;
}

.cds--progress-step--disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.cds--progress-step--disabled svg {
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}
.cds--progress-step--disabled .cds--progress-label,
.cds--progress-step--disabled .cds--progress-label:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}
.cds--progress-step--disabled .cds--progress-line {
  cursor: not-allowed;
}
.cds--progress-step--disabled .cds--progress-label-overflow:hover ~ .cds--tooltip--definition .cds--tooltip--definition__bottom {
  display: none;
}

.cds--progress__warning > * {
  fill: var(--cds-support-error, #da1e28);
}

.cds--progress.cds--skeleton .cds--progress-label {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 2.5rem;
  height: 0.875rem;
  margin-top: 0.625rem;
}
.cds--progress.cds--skeleton .cds--progress-label:hover, .cds--progress.cds--skeleton .cds--progress-label:focus, .cds--progress.cds--skeleton .cds--progress-label:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--progress.cds--skeleton .cds--progress-label::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--progress.cds--skeleton .cds--progress-label::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--progress.cds--progress--vertical.cds--skeleton .cds--progress-label {
  margin-top: 0.0625rem;
}

.cds--progress--vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cds--progress--vertical,
.cds--progress-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cds--progress--vertical .cds--progress-step,
.cds--progress--vertical .cds--progress-step-button {
  width: initial;
  min-width: initial;
  min-height: 3.625rem;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.cds--progress--vertical .cds--progress-step svg,
.cds--progress--vertical .cds--progress-step-button svg {
  display: inline-block;
  margin: 0.0625rem 0.5rem 0;
}

.cds--progress--vertical .cds--progress-label {
  display: inline-block;
  width: initial;
  max-width: 10rem;
  margin: 0;
  vertical-align: top;
  white-space: initial;
}

.cds--progress--vertical .cds--progress-step .cds--tooltip {
  margin-top: 0.5rem;
}

.cds--progress--vertical .cds--progress-optional {
  position: static;
  width: 100%;
  margin: auto 0;
}

.cds--progress--vertical .cds--progress-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
}

.cds--radio-button-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cds--radio-button-group *,
.cds--radio-button-group *::before,
.cds--radio-button-group *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--label + .cds--form-item .cds--radio-button-group {
  margin-top: 0;
}

.cds--radio-button-group--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.cds--radio-button-group--vertical.cds--radio-button-group--label-left {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.cds--radio-button-group--vertical .cds--radio-button__label {
  margin-right: 0;
  line-height: 1.25;
}
.cds--radio-button-group--vertical .cds--radio-button__label:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.cds--radio-button {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
  visibility: inherit;
}

.cds--radio-button__label {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer;
}

.cds--radio-button__appearance {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  width: 1.125rem;
  height: 1.125rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border: 1px solid var(--cds-icon-primary, #161616);
  margin: 0.0625rem 0.5rem 0.125rem 0.125rem;
  background-color: transparent;
  border-radius: 50%;
}
.cds--radio-button__appearance *,
.cds--radio-button__appearance *::before,
.cds--radio-button__appearance *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.cds--radio-button:checked + .cds--radio-button__label .cds--radio-button__appearance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-color: var(--cds-icon-primary, #161616);
}
.cds--radio-button:checked + .cds--radio-button__label .cds--radio-button__appearance::before {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: var(--cds-icon-primary, #161616);
  border-radius: 50%;
  content: "";
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--radio-button:checked + .cds--radio-button__label .cds--radio-button__appearance::before {
    fill: ButtonText;
    background-color: ButtonText;
  }
}

.cds--radio-button:disabled + .cds--radio-button__label {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--radio-button:disabled + .cds--radio-button__label .cds--radio-button__appearance,
.cds--radio-button:disabled:checked + .cds--radio-button__label .cds--radio-button__appearance {
  border-color: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}
.cds--radio-button:disabled + .cds--radio-button__label .cds--radio-button__appearance::before,
.cds--radio-button:disabled:checked + .cds--radio-button__label .cds--radio-button__appearance::before {
  background-color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--radio-button-group--readonly .cds--radio-button + .cds--radio-button__label .cds--radio-button__appearance {
  border-color: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--radio-button-group--readonly .cds--radio-button__label {
  cursor: default;
}

.cds--radio-button-group--readonly .cds--radio-button__label-text {
  cursor: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.cds--radio-button:focus + .cds--radio-button__label .cds--radio-button__appearance {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: 1.5px;
}

.cds--radio-button__label.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem;
  height: 1.125rem;
}
.cds--radio-button__label.cds--skeleton:hover, .cds--radio-button__label.cds--skeleton:focus, .cds--radio-button__label.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--radio-button__label.cds--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--radio-button__label.cds--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--radio-button__label.cds--skeleton .cds--radio-button__appearance {
  display: none;
}

.cds--radio-button-wrapper .cds--radio-button__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
}

.cds--radio-button-wrapper:not(:last-of-type) {
  margin-right: 1rem;
}

.cds--radio-button-group--vertical .cds--radio-button-wrapper:not(:last-of-type) {
  margin-right: 0;
  margin-bottom: 0.5rem;
}

.cds--radio-button-group--label-right .cds--radio-button__label,
.cds--radio-button-wrapper.cds--radio-button-wrapper--label-right .cds--radio-button__label {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.cds--radio-button-group--label-left .cds--radio-button__label,
.cds--radio-button-wrapper.cds--radio-button-wrapper--label-left .cds--radio-button__label {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.cds--radio-button-group--label-left .cds--radio-button__appearance,
.cds--radio-button-wrapper.cds--radio-button-wrapper--label-left .cds--radio-button__appearance {
  margin-right: 0;
  margin-left: 0.5rem;
}

.cds--icon--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
.cds--icon--skeleton:hover, .cds--icon--skeleton:focus, .cds--icon--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--icon--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--icon--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--skeleton__placeholder {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem;
  height: 6.25rem;
}
.cds--skeleton__placeholder:hover, .cds--skeleton__placeholder:focus, .cds--skeleton__placeholder:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--skeleton__placeholder::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--skeleton__placeholder::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--skeleton__text {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 100%;
  height: 1rem;
  margin-bottom: 0.5rem;
}
.cds--skeleton__text:hover, .cds--skeleton__text:focus, .cds--skeleton__text:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--skeleton__text::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--skeleton__text::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--skeleton__heading {
  height: 1.5rem;
}

.cds--slider-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cds--slider {
  position: relative;
  width: 100%;
  min-width: 12.5rem;
  max-width: 40rem;
  padding: 1rem 0;
  margin: 0 1rem;
  cursor: pointer;
}

.cds--slider__range-label {
  font-family: var(--cds-code-02-font-family, 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace);
  font-size: var(--cds-code-02-font-size, 0.875rem);
  font-weight: var(--cds-code-02-font-weight, 400);
  line-height: var(--cds-code-02-line-height, 1.42857);
  letter-spacing: var(--cds-code-02-letter-spacing, 0.32px);
  color: var(--cds-text-primary, #161616);
  white-space: nowrap;
}
.cds--slider__range-label:last-of-type {
  margin-right: 1rem;
}

.cds--slider__track {
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background: var(--cds-border-subtle);
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.cds--slider__track:before {
  position: absolute;
  top: -0.3125rem;
  left: 50%;
  display: inline-block;
  width: 0.125rem;
  height: 0.25rem;
  background: var(--cds-border-subtle);
  content: "";
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.cds--slider__filled-track {
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background: var(--cds-layer-selected-inverse, #161616);
  pointer-events: none;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition: background 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--slider__thumb {
  position: absolute;
  z-index: 3;
  width: 0.875rem;
  height: 0.875rem;
  background: var(--cds-layer-selected-inverse, #161616);
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 2px transparent;
  box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 2px transparent;
  outline: none;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: background 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background 110ms cubic-bezier(0.2, 0, 0.38, 0.9), box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background 110ms cubic-bezier(0.2, 0, 0.38, 0.9), box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--slider__thumb:hover {
  -webkit-transform: translate(-50%, -50%) scale(1.4286);
  transform: translate(-50%, -50%) scale(1.4286);
}
.cds--slider__thumb:focus {
  background-color: var(--cds-interactive, #620b5b);
  -webkit-box-shadow: inset 0 0 0 2px var(--cds-interactive, #620b5b), inset 0 0 0 3px var(--cds-layer);
  box-shadow: inset 0 0 0 2px var(--cds-interactive, #620b5b), inset 0 0 0 3px var(--cds-layer);
  -webkit-transform: translate(-50%, -50%) scale(1.4286);
  transform: translate(-50%, -50%) scale(1.4286);
}
.cds--slider__thumb:active {
  -webkit-box-shadow: inset 0 0 0 2px var(--cds-interactive, #620b5b);
  box-shadow: inset 0 0 0 2px var(--cds-interactive, #620b5b);
  -webkit-transform: translate(-50%, -50%) scale(1.4286);
  transform: translate(-50%, -50%) scale(1.4286);
}

.cds--slider__input {
  display: none;
}

.cds--slider-text-input,
.cds-slider-text-input {
  width: 4rem;
  height: 2.5rem;
  -moz-appearance: textfield;
  text-align: center;
}
.cds--slider-text-input::-webkit-outer-spin-button, .cds--slider-text-input::-webkit-inner-spin-button,
.cds-slider-text-input::-webkit-outer-spin-button,
.cds-slider-text-input::-webkit-inner-spin-button {
  display: none;
}

.cds--slider-text-input--hidden {
  display: none;
}

.cds--slider-text-input.cds--text-input--invalid {
  padding-right: 1rem;
}

.cds--slider__thumb:focus ~ .cds--slider__filled-track {
  background-color: var(--cds-interactive, #620b5b);
}

.cds--label--disabled ~ .cds--slider-container > .cds--slider__range-label {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--slider--disabled.cds--slider {
  cursor: not-allowed;
}

.cds--slider--disabled .cds--slider__thumb {
  background-color: var(--cds-border-disabled, #c6c6c6);
}
.cds--slider--disabled .cds--slider__thumb:hover {
  cursor: not-allowed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cds--slider--disabled .cds--slider__thumb:focus {
  background-color: var(--cds-border-disabled, #c6c6c6);
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cds--slider--disabled .cds--slider__thumb:active {
  background: var(--cds-border-disabled, #c6c6c6);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cds--slider--disabled .cds--slider__track,
.cds--slider--disabled .cds--slider__filled-track,
.cds--slider--disabled .cds--slider__thumb:focus ~ .cds--slider__filled-track {
  background-color: var(--cds-border-disabled, #c6c6c6);
}

.cds--slider--disabled ~ .cds--form-item .cds--slider-text-input,
.cds--slider--disabled ~ .cds--slider-text-input {
  border: none;
  background-color: var(--cds-field);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
  -webkit-transition: none;
  transition: none;
}
.cds--slider--disabled ~ .cds--form-item .cds--slider-text-input:active, .cds--slider--disabled ~ .cds--form-item .cds--slider-text-input:focus, .cds--slider--disabled ~ .cds--form-item .cds--slider-text-input:hover,
.cds--slider--disabled ~ .cds--slider-text-input:active,
.cds--slider--disabled ~ .cds--slider-text-input:focus,
.cds--slider--disabled ~ .cds--slider-text-input:hover {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  outline: none;
}

.cds--slider--readonly {
  cursor: default;
}

.cds--slider--readonly .cds--slider__thumb {
  width: 0;
  height: 0;
}

.cds--slider--readonly ~ .cds--slider-text-input {
  background-color: transparent;
}

.cds--slider-container.cds--skeleton .cds--slider__range-label {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 1.25rem;
  height: 0.75rem;
}
.cds--slider-container.cds--skeleton .cds--slider__range-label:hover, .cds--slider-container.cds--skeleton .cds--slider__range-label:focus, .cds--slider-container.cds--skeleton .cds--slider__range-label:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--slider-container.cds--skeleton .cds--slider__range-label::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--slider-container.cds--skeleton .cds--slider__range-label::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--slider-container.cds--skeleton .cds--slider__track {
  cursor: default;
  pointer-events: none;
}

.cds--slider-container.cds--skeleton .cds--slider__thumb {
  left: 50%;
  cursor: default;
  pointer-events: none;
}

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--slider__thumb {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--slider__thumb:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--slider__track {
    outline: 1px solid transparent;
  }
}

/* stylelint-enable */
.cds--stack-horizontal {
  display: inline-grid;
  -webkit-column-gap: var(--cds-stack-gap, 0);
  -moz-column-gap: var(--cds-stack-gap, 0);
  column-gap: var(--cds-stack-gap, 0);
  grid-auto-flow: column;
}

.cds--stack-vertical {
  display: grid;
  grid-auto-flow: row;
  row-gap: var(--cds-stack-gap, 0);
}

.cds--stack-scale-1 {
  --cds-stack-gap: 0.125rem;
}

.cds--stack-scale-2 {
  --cds-stack-gap: 0.25rem;
}

.cds--stack-scale-3 {
  --cds-stack-gap: 0.5rem;
}

.cds--stack-scale-4 {
  --cds-stack-gap: 0.75rem;
}

.cds--stack-scale-5 {
  --cds-stack-gap: 1rem;
}

.cds--stack-scale-6 {
  --cds-stack-gap: 1.5rem;
}

.cds--stack-scale-7 {
  --cds-stack-gap: 2rem;
}

.cds--stack-scale-8 {
  --cds-stack-gap: 2.5rem;
}

.cds--stack-scale-9 {
  --cds-stack-gap: 3rem;
}

.cds--stack-scale-10 {
  --cds-stack-gap: 4rem;
}

.cds--stack-scale-11 {
  --cds-stack-gap: 5rem;
}

.cds--stack-scale-12 {
  --cds-stack-gap: 6rem;
}

.cds--stack-scale-13 {
  --cds-stack-gap: 10rem;
}

.cds--structured-list--selection .cds--structured-list-td,
.cds--structured-list--selection .cds--structured-list-th {
  padding-right: 1rem;
  padding-left: 1rem;
}
.cds--structured-list--selection .cds--structured-list-td:first-child,
.cds--structured-list--selection .cds--structured-list-th:first-child {
  padding-right: 1rem;
  padding-left: 1rem;
}

.cds--structured-list-row--focused-within {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--structured-list-row--focused-within {
    outline-style: dotted;
  }
}

.cds--structured-list {
  display: table;
  width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  overflow-x: auto;
}
.cds--structured-list html {
  font-size: 100%;
}
.cds--structured-list body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--structured-list code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--structured-list strong {
  font-weight: 600;
}
.cds--structured-list.cds--structured-list--condensed .cds--structured-list-td, .cds--structured-list.cds--structured-list--condensed .cds--structured-list-th {
  padding: 0.5rem;
}
.cds--structured-list .cds--structured-list-row .cds--structured-list-td:first-of-type,
.cds--structured-list .cds--structured-list-row .cds--structured-list-th:first-of-type {
  padding-left: 1rem;
}
.cds--structured-list.cds--structured-list--flush .cds--structured-list-row .cds--structured-list-td, .cds--structured-list.cds--structured-list--flush .cds--structured-list-row .cds--structured-list-th, .cds--structured-list.cds--structured-list--flush .cds--structured-list-row .cds--structured-list-td:first-of-type, .cds--structured-list.cds--structured-list--flush .cds--structured-list-row .cds--structured-list-th:first-of-type {
  padding-right: 1rem;
  padding-left: 0;
}

.cds--structured-list-row {
  display: table-row;
  border-bottom: 1px solid var(--cds-border-subtle);
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--structured-list--selection .cds--structured-list-row:hover:not(.cds--structured-list-row--header-row):not(.cds--structured-list-row--selected) {
  border-bottom: 1px solid var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  cursor: pointer;
}

.cds--structured-list-row.cds--structured-list-row--selected {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
}

.cds--structured-list-row.cds--structured-list-row--header-row {
  border-bottom: 1px solid var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  cursor: inherit;
}

.cds--structured-list--selection .cds--structured-list-row:hover:not(.cds--structured-list-row--header-row) > .cds--structured-list-td,
.cds--structured-list-row.cds--structured-list-row--selected > .cds--structured-list-td {
  color: var(--cds-text-primary, #161616);
}

.cds--structured-list--selection .cds--structured-list-row:hover:not(.cds--structured-list-row--header-row) > .cds--structured-list-td {
  border-top: 1px solid var(--cds-border-subtle);
}

.cds--structured-list-thead {
  display: table-header-group;
  vertical-align: middle;
}

.cds--structured-list-th {
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  display: table-cell;
  height: 2.5rem;
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
  text-align: left;
  text-transform: none;
  vertical-align: top;
}
.cds--structured-list-th html {
  font-size: 100%;
}
.cds--structured-list-th body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--structured-list-th code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--structured-list-th strong {
  font-weight: 600;
}

.cds--structured-list-tbody {
  display: table-row-group;
  vertical-align: middle;
}

.cds--structured-list-td {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  padding: 1rem 0.5rem 1.5rem 0.5rem;
  position: relative;
  display: table-cell;
  max-width: 36rem;
  color: var(--cds-text-secondary, #525252);
  border-top: 1px solid var(--cds-border-subtle);
  -webkit-transition: color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--structured-list-td html {
  font-size: 100%;
}
.cds--structured-list-td body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--structured-list-td code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--structured-list-td strong {
  font-weight: 600;
}

.cds--structured-list-content--nowrap {
  white-space: nowrap;
}

.cds--structured-list-svg {
  display: inline-block;
  fill: transparent;
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  vertical-align: middle;
}

.cds--structured-list-input:checked + .cds--structured-list-row .cds--structured-list-svg,
.cds--structured-list-input:checked + .cds--structured-list-td .cds--structured-list-svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--structured-list.cds--skeleton .cds--structured-list-th:first-child {
  width: 8%;
}
.cds--structured-list.cds--skeleton .cds--structured-list-th:nth-child(3n+2) {
  width: 30%;
}
.cds--structured-list.cds--skeleton .cds--structured-list-th:nth-child(3n+3) {
  width: 15%;
}
.cds--structured-list.cds--skeleton span {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  display: block;
  width: 75%;
  height: 1rem;
}
.cds--structured-list.cds--skeleton span:hover, .cds--structured-list.cds--skeleton span:focus, .cds--structured-list.cds--skeleton span:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--structured-list.cds--skeleton span::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--structured-list.cds--skeleton span::before {
    -webkit-animation: none;
    animation: none;
  }
}

.cds--structured-list.cds--structured-list--selection.cds--skeleton .cds--structured-list-th:first-child {
  width: 5%;
}
.cds--structured-list.cds--structured-list--selection.cds--skeleton .cds--structured-list-th:first-child span {
  display: none;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--structured-list-input:checked + .cds--structured-list-td .cds--structured-list-svg {
    fill: ButtonText;
  }
}

.cds--tabs {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  min-height: 2.5rem;
  max-height: 4rem;
  color: var(--cds-text-primary, #161616);
}
.cds--tabs html {
  font-size: 100%;
}
.cds--tabs body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--tabs code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--tabs strong {
  font-weight: 600;
}
.cds--tabs.cds--tabs--contained {
  min-height: 3rem;
}
.cds--tabs .cds--tab--list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  will-change: scroll-position;
}
.cds--tabs .cds--tab--list::-webkit-scrollbar {
  display: none;
}
.cds--tabs .cds--tabs__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cds--tabs .cds--tab--overflow-nav-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.5rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--cds-background, #ffffff);
}
.cds--tabs .cds--tab--overflow-nav-button *,
.cds--tabs .cds--tab--overflow-nav-button *::before,
.cds--tabs .cds--tab--overflow-nav-button *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--tabs .cds--tab--overflow-nav-button::-moz-focus-inner {
  border: 0;
}
.cds--tabs .cds--tab--overflow-nav-button:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--tabs .cds--tab--overflow-nav-button:focus {
    outline-style: dotted;
  }
}
.cds--tabs .cds--tab--overflow-nav-button--hidden {
  display: none;
}
.cds--tabs.cds--tabs--contained .cds--tab--overflow-nav-button {
  width: 3rem;
  margin: 0;
  background-color: var(--cds-layer-accent);
}
.cds--tabs .cds--tab--overflow-nav-button svg {
  fill: var(--cds-icon-primary, #161616);
}
.cds--tabs .cds--tab--overflow-nav-button--next {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.cds--tabs .cds--tab--overflow-nav-button--next::before {
  position: absolute;
  z-index: 1;
  left: -0.5rem;
  width: 0.5rem;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(var(--cds-background, #ffffff)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--cds-background, #ffffff));
  content: "";
}
.cds--tabs.cds--tabs--contained .cds--tab--overflow-nav-button--next::before {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(var(--cds-layer-accent)));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), var(--cds-layer-accent));
}
.cds--tabs .cds--tab--overflow-nav-button--previous {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.cds--tabs .cds--tab--overflow-nav-button--previous::before {
  position: absolute;
  z-index: 1;
  right: -0.5rem;
  width: 0.5rem;
  height: 100%;
  background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(var(--cds-background, #ffffff)));
  background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--cds-background, #ffffff));
  content: "";
}
.cds--tabs.cds--tabs--contained .cds--tab--overflow-nav-button--previous::before {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(var(--cds-layer-accent)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), var(--cds-layer-accent));
}
.cds--tabs .cds--tabs--light .cds--tabs__overflow-indicator--left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(var(--cds-layer)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), var(--cds-layer));
}
.cds--tabs .cds--tabs--light .cds--tabs__overflow-indicator--right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(var(--cds-layer)));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), var(--cds-layer));
}
.cds--tabs.cds--tabs--contained .cds--tabs__overflow-indicator--left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(var(--cds-layer-accent)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), var(--cds-layer-accent));
}
.cds--tabs.cds--tabs--contained .cds--tabs__overflow-indicator--right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(var(--cds-layer-accent)));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), var(--cds-layer-accent));
}
@media not all and (-webkit-min-device-pixel-ratio: 0), not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .cds--tabs .cds--tabs__overflow-indicator--left {
      background-image: -webkit-gradient(linear, right top, left top, from(rgba(var(--cds-background, #ffffff), 0)), to(var(--cds-background, #ffffff)));
      background-image: linear-gradient(to left, rgba(var(--cds-background, #ffffff), 0), var(--cds-background, #ffffff));
    }
    .cds--tabs .cds--tabs__overflow-indicator--right {
      background-image: -webkit-gradient(linear, left top, right top, from(rgba(var(--cds-background, #ffffff), 0)), to(var(--cds-background, #ffffff)));
      background-image: linear-gradient(to right, rgba(var(--cds-background, #ffffff), 0), var(--cds-background, #ffffff));
    }
    .cds--tabs.cds--tabs--contained .cds--tabs__overflow-indicator--left {
      background-image: -webkit-gradient(linear, right top, left top, from(rgba(var(--cds-layer-accent), 0)), to(var(--cds-layer-accent)));
      background-image: linear-gradient(to left, rgba(var(--cds-layer-accent), 0), var(--cds-layer-accent));
    }
    .cds--tabs.cds--tabs--contained .cds--tabs__overflow-indicator--right {
      background-image: -webkit-gradient(linear, left top, right top, from(rgba(var(--cds-layer-accent), 0)), to(var(--cds-layer-accent)));
      background-image: linear-gradient(to right, rgba(var(--cds-layer-accent), 0), var(--cds-layer-accent));
    }
  }
}
.cds--tabs .cds--tabs__nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding: 0;
  cursor: pointer;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--tabs .cds--tabs__nav-item html {
  font-size: 100%;
}
.cds--tabs .cds--tabs__nav-item body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--tabs .cds--tabs__nav-item code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--tabs .cds--tabs__nav-item strong {
  font-weight: 600;
}
.cds--tabs .cds--tabs__nav-item + .cds--tabs__nav-item {
  margin-left: 0.0625rem;
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item {
  background-color: var(--cds-layer-accent);
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item + .cds--tabs__nav-item {
  margin-left: 0;
  -webkit-box-shadow: -0.0625rem 0 0 0 var(--cds-border-strong);
  box-shadow: -0.0625rem 0 0 0 var(--cds-border-strong);
}
.cds--tabs .cds--tabs__nav-item .cds--tabs__nav-link {
  -webkit-transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--tabs .cds--tabs__nav-link {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  overflow: hidden;
  max-width: 10rem;
  padding: 0.75rem 1rem 0.5rem;
  border-bottom: 2px solid var(--cds-border-subtle);
  color: var(--cds-text-secondary, #525252);
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  -webkit-transition: border 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: border 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  white-space: nowrap;
}
.cds--tabs .cds--tabs__nav-link *,
.cds--tabs .cds--tabs__nav-link *::before,
.cds--tabs .cds--tabs__nav-link *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--tabs .cds--tabs__nav-link::-moz-focus-inner {
  border: 0;
}
.cds--tabs .cds--tabs__nav-link:focus, .cds--tabs .cds--tabs__nav-link:active {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--tabs .cds--tabs__nav-link:focus, .cds--tabs .cds--tabs__nav-link:active {
    outline-style: dotted;
  }
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-link {
  height: 3rem;
  padding: 0.5rem 1rem;
  border-bottom: 0;
  line-height: calc(3rem - (0.5rem * 2));
}
.cds--tabs.cds--tabs__icon--default .cds--tab--list, .cds--tabs.cds--tabs__icon--lg .cds--tab--list {
  overflow-x: visible;
}
.cds--tabs .cds--tabs__nav-item--icon, .cds--tabs.cds--tabs--contained .cds--tabs__nav-item--icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: var(--cds-icon-tab-size, 2.5rem);
  height: var(--cds-icon-tab-size, 2.5rem);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
}
.cds--tabs.cds--tabs__icon--lg {
  --cds-icon-tab-size: 3rem;
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item:hover {
  background-color: var(--cds-layer-selected-hover);
}
.cds--tabs .cds--tabs__nav-item--selected {
  border-bottom: 2px solid var(--cds-border-interactive, #620b5b);
  -webkit-transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected + .cds--tabs__nav-item {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item.cds--tabs__nav-item--selected {
  -webkit-box-shadow: inset 0 2px 0 0 var(--cds-border-interactive, #620b5b);
  box-shadow: inset 0 2px 0 0 var(--cds-border-interactive, #620b5b);
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected,
.cds--tabs .cds--tabs__nav-item--selected,
.cds--tabs .cds--tabs__nav-item--selected:focus .cds--tabs__nav-link:focus,
.cds--tabs .cds--tabs__nav-item--selected:active .cds--tabs__nav-link:active {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  color: var(--cds-text-primary, #161616);
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected, .cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected:hover {
  background-color: var(--cds-layer);
  line-height: calc(3rem - (0.5rem * 2));
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected .cds--tabs__nav-link:focus,
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected .cds--tabs__nav-link:active, .cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected:hover .cds--tabs__nav-link:focus,
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected:hover .cds--tabs__nav-link:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cds--tabs.cds--tabs--light.cds--tabs--contained .cds--tabs__nav-item--selected, .cds--tabs.cds--tabs--light.cds--tabs--contained .cds--tabs__nav-item--selected:hover {
  background-color: var(--cds-background, #ffffff);
}
.cds--tabs .cds--tabs__nav-item:hover .cds--tabs__nav-link {
  border-bottom: 2px solid var(--cds-border-strong);
  color: var(--cds-text-primary, #161616);
}
.cds--tabs .cds--tabs__nav-item--disabled {
  border-bottom: 2px solid var(--cds-border-disabled, #c6c6c6);
  background-color: transparent;
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  outline: none;
}
.cds--tabs .cds--tabs__nav-item--disabled:hover {
  border-bottom: 2px solid var(--cds-border-disabled, #c6c6c6);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item.cds--tabs__nav-item--disabled, .cds--tabs.cds--tabs--contained .cds--tabs__nav-item.cds--tabs__nav-item--disabled:hover {
  background-color: var(--cds-button-disabled, #c6c6c6);
}
.cds--tabs .cds--tabs__nav-item--disabled:focus,
.cds--tabs .cds--tabs__nav-item--disabled:active {
  border-bottom: 2px solid var(--cds-border-disabled, #c6c6c6);
  outline: none;
  pointer-events: none;
}
.cds--tabs .cds--tabs--light .cds--tabs__nav-item--disabled .cds--tabs__nav-link {
  border-bottom-color: var(--cds-border-subtle);
}
.cds--tabs .cds--tabs--light .cds--tabs__nav-item--disabled:hover .cds--tabs__nav-link {
  border-bottom-color: var(--cds-border-subtle);
}
.cds--tabs .cds--tabs--light .cds--tabs__nav-item--disabled .cds--tabs__nav-link:focus,
.cds--tabs .cds--tabs--light .cds--tabs__nav-item--disabled .cds--tabs__nav-link:active {
  border-bottom-color: var(--cds-border-subtle);
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item--disabled {
  border-bottom: none;
  color: var(--cds-text-on-color-disabled, #8d8d8d);
}

.cds--tab-content {
  padding: 1rem;
}
.cds--tab-content:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--tab-content:focus {
    outline-style: dotted;
  }
}

.cds--tab-content--interactive:focus {
  outline: none;
}

.cds--tabs.cds--skeleton {
  cursor: default;
  pointer-events: none;
}

.cds--skeleton.cds--tabs:not(.cds--tabs--contained) .cds--tabs__nav-link {
  border-bottom: 2px solid var(--cds-skeleton-element, #c6c6c6);
}

.cds--tabs.cds--skeleton .cds--tabs__nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 10rem;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
}

.cds--tabs.cds--skeleton .cds--tabs__nav-link span {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  display: block;
  width: 100%;
  height: 0.875rem;
}
.cds--tabs.cds--skeleton .cds--tabs__nav-link span:hover, .cds--tabs.cds--skeleton .cds--tabs__nav-link span:focus, .cds--tabs.cds--skeleton .cds--tabs__nav-link span:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--tabs.cds--skeleton .cds--tabs__nav-link span::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--tabs.cds--skeleton .cds--tabs__nav-link span::before {
    -webkit-animation: none;
    animation: none;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--tabs__nav-item .cds--tabs__nav-item--selected .cds--tabs__nav-item--selected {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--tabs .cds--tabs__nav-item--disabled .cds--tabs__nav-link {
    color: GrayText;
    fill: GrayText;
  }
}

.cds--text-area {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  min-width: 10rem;
  height: 100%;
  min-height: 2.5rem;
  padding: 0.6875rem 1rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong);
  background-color: var(--cds-field);
  color: var(--cds-text-primary, #161616);
  font-family: inherit;
  resize: vertical;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--text-area html {
  font-size: 100%;
}
.cds--text-area body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--text-area code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--text-area strong {
  font-weight: 600;
}

.cds--text-area:focus,
.cds--text-area:active {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--text-area:focus,
  .cds--text-area:active {
    outline-style: dotted;
  }
}

.cds--text-area::-webkit-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
}

.cds--text-area::-moz-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
}

.cds--text-area::-ms-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
}

.cds--text-area::placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
}

.cds--text-area--light {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--text-area--invalid {
  padding-right: 2.5rem;
}

.cds--text-area__wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.cds--text-area__invalid-icon {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  fill: var(--cds-support-error, #da1e28);
}

.cds--text-area__counter-alert {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
}

.cds--text-area:disabled {
  border-bottom: 1px solid transparent;
  background-color: var(--cds-field);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
  outline: none;
  resize: none;
}

.cds--text-area:disabled::-webkit-input-placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-area:disabled::-moz-placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-area:disabled::-ms-input-placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-area:disabled::placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-area__wrapper--readonly .cds--text-area {
  background: transparent;
}

.cds--text-area.cds--text-area--light:disabled {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--text-area.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  width: 100%;
  height: 6.25rem;
}
.cds--text-area.cds--skeleton:hover, .cds--text-area.cds--skeleton:focus, .cds--text-area.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--text-area.cds--skeleton::before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: 3000ms ease-in-out skeleton infinite;
  animation: 3000ms ease-in-out skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  content: "";
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--text-area.cds--skeleton::before {
    -webkit-animation: none;
    animation: none;
  }
}
.cds--text-area.cds--skeleton::-webkit-input-placeholder {
  color: transparent;
}
.cds--text-area.cds--skeleton::-moz-placeholder {
  color: transparent;
}
.cds--text-area.cds--skeleton::-ms-input-placeholder {
  color: transparent;
}
.cds--text-area.cds--skeleton::placeholder {
  color: transparent;
}

.cds--text-area__label-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cds--tile-group html {
  font-size: 100%;
}
.cds--tile-group body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--tile-group code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--tile-group strong {
  font-weight: 600;
}

.cds--tile {
  position: relative;
  display: block;
  min-width: 8rem;
  min-height: 4rem;
  padding: 1rem;
  background-color: var(--cds-layer);
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}
.cds--tile:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--tile:focus {
    outline-style: dotted;
  }
}

.cds--tile--light {
  background-color: var(--cds-layer);
}

.cds--tile--clickable,
.cds--tile--selectable {
  cursor: pointer;
  -webkit-transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--tile--clickable:hover,
.cds--tile--selectable:hover {
  background: var(--cds-layer-hover);
}

.cds--tile--clickable {
  color: var(--cds-text-primary, #161616);
  text-decoration: none;
}
.cds--tile--clickable html {
  font-size: 100%;
}
.cds--tile--clickable body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--tile--clickable code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--tile--clickable strong {
  font-weight: 600;
}
.cds--tile--clickable:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--tile--clickable:focus {
    outline-style: dotted;
  }
}
.cds--tile--clickable:hover .cds--tile__checkmark, .cds--tile--clickable:focus .cds--tile__checkmark {
  opacity: 1;
}

.cds--tile--expandable::-moz-focus-inner {
  border: 0;
}

.cds--tile--clickable:hover,
.cds--tile--clickable:active,
.cds--tile--clickable:visited,
.cds--tile--clickable:visited:hover {
  color: var(--cds-text-primary, #161616);
  text-decoration: none;
}

.cds--tile--clickable.cds--link--disabled,
.cds--tile--clickable:hover.cds--link--disabled {
  display: block;
  padding: 1rem;
  background-color: var(--cds-layer);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--tile--selectable {
  padding-right: 3rem;
  border: 1px solid transparent;
}

.cds--tile__checkmark {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 1rem;
  border: none;
  background: transparent;
  opacity: 0;
  -webkit-transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--tile__checkmark svg {
  border-radius: 50%;
  fill: var(--cds-icon-secondary, #525252);
}
.cds--tile__checkmark:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--tile__checkmark:focus {
    outline-style: dotted;
  }
}

.cds--tile__checkmark--persistent {
  opacity: 1;
}

.cds--tile__chevron {
  position: absolute;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cds--tile__chevron svg {
  fill: var(--cds-icon-primary, #161616);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--tile__chevron svg {
    -webkit-transition: none;
    transition: none;
  }
}
@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
  .cds--tile__chevron svg {
    fill: ButtonText;
  }
}

.cds--tile__chevron--interactive {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cds--tile__chevron--interactive *,
.cds--tile__chevron--interactive *::before,
.cds--tile__chevron--interactive *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--tile__chevron--interactive::-moz-focus-inner {
  border: 0;
}
.cds--tile__chevron--interactive:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
.cds--tile__chevron--interactive:hover {
  background-color: var(--cds-layer-hover);
  cursor: pointer;
}

.cds--tile--expandable {
  position: relative;
  overflow: hidden;
  width: 100%;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  -webkit-transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}
.cds--tile--expandable:hover {
  background: var(--cds-layer-hover);
}

.cds--tile--expandable.cds--tile--expandable--interactive {
  cursor: default;
  -webkit-transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--tile--expandable.cds--tile--expandable--interactive:hover {
  background-color: var(--cds-layer);
}
.cds--tile--expandable.cds--tile--expandable--interactive:focus {
  outline: none;
}

.cds--tile--expandable--interactive:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--tile--expandable--interactive:focus {
    outline-style: dotted;
  }
}

.cds--tile-content__above-the-fold {
  display: block;
}

.cds--tile-content__below-the-fold {
  display: block;
  opacity: 0;
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  visibility: hidden;
}

.cds--tile--is-expanded {
  overflow: visible;
  -webkit-transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--tile--is-expanded .cds--tile__chevron svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cds--tile--is-expanded .cds--tile-content__below-the-fold {
  opacity: 1;
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  visibility: inherit;
}
@media not all and (-webkit-min-device-pixel-ratio: 0), not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .cds--tile--is-expanded .cds--tile-content__below-the-fold {
      overflow-y: auto;
    }
  }
}

.cds--tile--is-selected {
  border: 1px solid var(--cds-layer-selected-inverse, #161616);
}

.cds--tile--is-selected .cds--tile__checkmark {
  opacity: 1;
}

.cds--tile--is-selected .cds--tile__checkmark svg {
  fill: var(--cds-icon-primary, #161616);
}
@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
  .cds--tile--is-selected .cds--tile__checkmark svg {
    fill: ButtonText;
  }
}

.cds--tile-content {
  width: 100%;
  height: 100%;
}

.cds--tile-input {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}

.cds--tile-input:focus + .cds--tile {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--tile-input:focus + .cds--tile {
    outline-style: dotted;
  }
}

.cds--tile--disabled.cds--tile--selectable {
  background-color: var(--cds-layer);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--tile--disabled.cds--tile--selectable.cds--tile--light {
  background-color: var(--cds-layer-02, #ffffff);
}

.cds--tile--disabled.cds--tile--is-selected {
  border-color: var(--cds-button-disabled, #c6c6c6);
}

.cds--tile--disabled .cds--tile__checkmark svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--tile__chevron svg,
  .cds--tile__checkmark svg,
  .cds--tile--is-selected .cds--tile__checkmark svg {
    fill: ButtonText;
  }
}

.cds--time-picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.cds--time-picker__select {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cds--time-picker__select:not(:last-of-type) {
  margin: 0 0.125rem;
}

.cds--time-picker__input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cds--time-picker .cds--select-input {
  width: auto;
  min-width: auto;
  padding-right: 3rem;
  margin: 0;
  line-height: 1;
}

.cds--time-picker__input-field {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-family: var(--cds-code-02-font-family, 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace);
  font-size: var(--cds-code-02-font-size, 0.875rem);
  font-weight: var(--cds-code-02-font-weight, 400);
  line-height: var(--cds-code-02-line-height, 1.42857);
  letter-spacing: var(--cds-code-02-letter-spacing, 0.32px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 4.875rem;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--time-picker__input-field::-webkit-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--time-picker__input-field::-moz-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--time-picker__input-field::-ms-input-placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}
.cds--time-picker__input-field::placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}

.cds--time-picker--light .cds--select-input {
  background-color: var(--cds-field-02, #ffffff);
}
.cds--time-picker--light .cds--select-input:hover {
  background-color: var(--cds-field-hover);
}
.cds--time-picker--light .cds--select-input:disabled, .cds--time-picker--light .cds--select-input:hover:disabled {
  border-bottom: 1px solid transparent;
  background-color: transparent;
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
}

.cds--time-picker--sm .cds--select-input,
.cds--time-picker--sm .cds--time-picker__input-field {
  height: 2rem;
  max-height: 2rem;
}

.cds--time-picker--lg .cds--select-input,
.cds--time-picker--lg .cds--time-picker__input-field {
  height: 3rem;
  max-height: 3rem;
}

.cds--time-picker--readonly .cds--time-picker__input-field {
  background-color: transparent;
}

.cds--time-picker--readonly .cds--select-input {
  background-color: transparent;
  cursor: default;
}

.cds--time-picker--readonly .cds--select-input + .cds--select__arrow {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--toggletip-label {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  margin-right: 0.5rem;
  color: var(--cds-text-secondary, #525252);
}

.cds--toggletip-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cds--toggletip-button *,
.cds--toggletip-button *::before,
.cds--toggletip-button *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--toggletip-button::-moz-focus-inner {
  border: 0;
}

.cds--toggletip-button svg {
  fill: var(--cds-icon-secondary, #525252);
}

.cds--toggletip-button:hover svg,
.cds--toggletip--open .cds--toggletip-button svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--toggletip-button:focus {
  outline: 1px solid var(--cds-focus, #620b5b);
}
@media screen and (prefers-contrast) {
  .cds--toggletip-button:focus {
    outline-style: dotted;
  }
}

.cds--toggletip {
  --cds-popover-offset: 0.8125rem;
}

.cds--toggletip-content {
  --cds-button-focus-color: var(--cds-focus-inverse, #ffffff);
  --cds-link-text-color: var(--cds-link-inverse, #78a9ff);
  --cds-link-hover-text-color: var(--cds-link-inverse, #78a9ff);
  --cds-link-focus-text-color: var(--cds-focus-inverse, #ffffff);
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  display: grid;
  max-width: 18rem;
  padding: 1rem;
  row-gap: 1rem;
}

.cds--toggletip-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.cds--toggle {
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cds--toggle__label-text {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  display: block;
  margin-bottom: 1rem;
  color: var(--cds-text-secondary, #525252);
}

.cds--toggle__button {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}
.cds--toggle__button:focus {
  outline: none;
}

.cds--toggle__appearance {
  display: inline-grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  cursor: pointer;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
}

.cds--toggle__switch {
  position: relative;
  width: 3rem;
  height: 1.5rem;
  background-color: var(--cds-toggle-off, #8d8d8d);
  border-radius: 0.75rem;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 1, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 1, 0.9);
}
.cds--toggle__switch::before {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  background-color: var(--cds-icon-on-color, #ffffff);
  border-radius: 50%;
  content: "";
  -webkit-transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
  transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
  transition: transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
  transition: transform 70ms cubic-bezier(0.2, 0, 1, 0.9), -webkit-transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--toggle__switch::before {
    -webkit-transition: none;
    transition: none;
  }
}

.cds--toggle__button:focus + .cds--toggle__label .cds--toggle__switch,
.cds--toggle__button:not(:disabled):active + .cds--toggle__label .cds--toggle__switch {
  -webkit-box-shadow: 0 0 0 1px var(--cds-focus-inset, #ffffff), 0 0 0 3px var(--cds-focus, #620b5b);
  box-shadow: 0 0 0 1px var(--cds-focus-inset, #ffffff), 0 0 0 3px var(--cds-focus, #620b5b);
}

.cds--toggle__switch--checked {
  background-color: var(--cds-support-success, #198038);
}
.cds--toggle__switch--checked::before {
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}

.cds--toggle__text {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  color: var(--cds-text-primary, #161616);
}

.cds--toggle__appearance--sm .cds--toggle__switch {
  width: 2rem;
  height: 1rem;
}
.cds--toggle__appearance--sm .cds--toggle__switch::before {
  width: 0.625rem;
  height: 0.625rem;
}

.cds--toggle__appearance--sm .cds--toggle__switch--checked::before {
  -webkit-transform: translateX(1rem);
  transform: translateX(1rem);
}

.cds--toggle__check {
  position: absolute;
  top: 0.375rem;
  right: 0.3125rem;
  width: 0.375rem;
  height: 0.3125rem;
  fill: var(--cds-support-success, #198038);
  visibility: hidden;
}

.cds--toggle__switch--checked .cds--toggle__check {
  visibility: visible;
}

.cds--toggle--disabled .cds--toggle__appearance {
  cursor: not-allowed;
}

.cds--toggle--disabled .cds--toggle__label-text,
.cds--toggle--disabled .cds--toggle__text {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--toggle--disabled .cds--toggle__switch {
  background-color: var(--cds-button-disabled, #c6c6c6);
}
.cds--toggle--disabled .cds--toggle__switch::before {
  background-color: var(--cds-icon-on-color-disabled, #8d8d8d);
}

.cds--toggle--disabled .cds--toggle__check {
  fill: var(--cds-button-disabled, #c6c6c6);
}

.cds--toggle--readonly .cds--toggle__appearance {
  cursor: default;
}

.cds--toggle--readonly .cds--toggle__switch {
  border: 1px solid var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
  background-color: transparent;
}
.cds--toggle--readonly .cds--toggle__switch::before {
  top: 0.125rem;
  left: 0.125rem;
  background-color: var(--cds-text-primary, #161616);
}

.cds--toggle--readonly .cds--toggle__check {
  top: 0.3125rem;
  right: 0.25rem;
  fill: var(--cds-background, #ffffff);
}

.cds--toggle--readonly .cds--toggle__text {
  cursor: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--toggle__switch,
  .cds--toggle__switch::before {
    outline: 1px solid transparent;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--toggle__button:focus + .cds--toggle__label .cds--toggle__switch,
  .cds--toggle__button:not(:disabled):active + .cds--toggle__label .cds--toggle__switch {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

.cds--tree {
  overflow: hidden;
}

.cds--tree-node {
  padding-left: 1rem;
  background-color: var(--cds-layer-01, #f4f4f4);
  color: var(--cds-text-secondary, #525252);
}
.cds--tree-node:focus {
  outline: none;
}

.cds--tree-node:focus > .cds--tree-node__label {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--tree-node:focus > .cds--tree-node__label {
    outline-style: dotted;
  }
}

.cds--tree-node--disabled:focus > .cds--tree-node__label {
  outline: none;
}

.cds--tree-node--disabled,
.cds--tree-node--disabled .cds--tree-node__label:hover,
.cds--tree-node--disabled .cds--tree-node__label:hover .cds--tree-node__label__details {
  background-color: var(--cds-field-01, #f4f4f4);
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--tree-node--disabled .cds--tree-parent-node__toggle-icon,
.cds--tree-node--disabled .cds--tree-node__icon,
.cds--tree-node--disabled .cds--tree-node__label:hover .cds--tree-parent-node__toggle-icon,
.cds--tree-node--disabled .cds--tree-node__label:hover .cds--tree-node__icon {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--tree-node--disabled,
.cds--tree-node--disabled .cds--tree-parent-node__toggle-icon:hover {
  cursor: not-allowed;
}

.cds--tree-node__label {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 2rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}
.cds--tree-node__label:hover {
  background-color: var(--cds-layer-hover-01, #e8e8e8);
  color: var(--cds-text-primary, #161616);
}

.cds--tree-node__label:hover .cds--tree-node__label__details {
  color: var(--cds-text-primary, #161616);
}

.cds--tree-node__label:hover .cds--tree-parent-node__toggle-icon,
.cds--tree-node__label:hover .cds--tree-node__icon {
  fill: var(--cds-icon-primary, #161616);
}

.cds--tree-leaf-node {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 2.5rem;
}

.cds--tree-leaf-node.cds--tree-node--with-icon {
  padding-left: 2rem;
}

.cds--tree-node__label__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cds--tree-node--with-icon .cds--tree-parent-node__toggle {
  margin-right: 0;
}

.cds--tree-parent-node__toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 0.0625rem 0;
  border: 0;
  margin-right: 0.5rem;
}
.cds--tree-parent-node__toggle:hover {
  cursor: pointer;
}
.cds--tree-parent-node__toggle:focus {
  outline: none;
}

.cds--tree-parent-node__toggle-icon {
  fill: var(--cds-icon-secondary, #525252);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--tree-parent-node__toggle-icon--expanded {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.cds--tree-node__icon {
  min-width: 1rem;
  min-height: 1rem;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-top: 0.0625rem;
  margin-right: 0.5rem;
  fill: var(--cds-icon-secondary, #525252);
}

.cds--tree-node--selected > .cds--tree-node__label {
  background-color: var(--cds-layer-selected-01, #e0e0e0);
  color: var(--cds-text-primary, #161616);
}
.cds--tree-node--selected > .cds--tree-node__label:hover {
  background-color: var(--cds-layer-selected-hover-01, #d1d1d1);
}

.cds--tree-node--selected > .cds--tree-node__label .cds--tree-parent-node__toggle-icon,
.cds--tree-node--selected > .cds--tree-node__label .cds--tree-node__icon {
  fill: var(--cds-icon-primary, #161616);
}

.cds--tree-node--active > .cds--tree-node__label {
  position: relative;
}
.cds--tree-node--active > .cds--tree-node__label::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.25rem;
  height: 100%;
  background-color: var(--cds-interactive, #620b5b);
  content: "";
}

.cds--tree--xs .cds--tree-node__label {
  min-height: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.cds--content {
  padding: 2rem;
  color: var(--cds-text-primary, #161616);
  will-change: margin-left;
}

.cds--header ~ .cds--content {
  margin-top: 3rem;
}

.cds--side-nav ~ .cds--content {
  margin-left: 3rem;
}

.cds--side-nav.cds--side-nav--expanded ~ .cds--content {
  margin-left: 16rem;
}

.cds--header {
  position: fixed;
  z-index: 8000;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--cds-border-subtle);
  background-color: var(--cds-background, #ffffff);
}

.cds--header__action {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 3rem;
  height: 3rem;
  border: 0.0625rem solid transparent;
  -webkit-transition: background-color 110ms, border-color 110ms;
  transition: background-color 110ms, border-color 110ms;
}
.cds--header__action *,
.cds--header__action *::before,
.cds--header__action *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--header__action::-moz-focus-inner {
  border: 0;
}
@media (max-width: 41.98rem) {
  .cds--header__action {
    min-width: 3rem;
  }
}

.cds--header__action > svg.cds--navigation-menu-panel-collapse-icon,
.cds--header__action--active > svg.cds--navigation-menu-panel-expand-icon {
  display: none;
}

.cds--header__action--active > svg.cds--navigation-menu-panel-collapse-icon {
  display: inline;
}

.cds--header__action:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
}

.cds--header__action--active {
  border-right: 1px solid var(--cds-border-subtle);
  border-bottom: 1px solid transparent;
  border-left: 1px solid var(--cds-border-subtle);
  background: var(--cds-layer);
}

.cds--header__action--active > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__action:focus {
  border-color: var(--cds-focus, #620b5b);
  outline: none;
}

.cds--header__action:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
}

.cds--header__action.cds--btn--icon-only {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cds--btn.cds--btn--icon-only.cds--header__action svg {
  fill: var(--cds-icon-secondary, #525252);
}

.cds--btn.cds--btn--icon-only.cds--header__action:hover svg,
.cds--btn.cds--btn--icon-only.cds--header__action:active svg,
.cds--btn.cds--btn--icon-only.cds--header__action--active svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-trigger > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-trigger:hover > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 66rem) {
  .cds--header__menu-toggle__hidden {
    display: none;
  }
}

a.cds--header__name {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 2rem 0 1rem;
  border: 0.125rem solid transparent;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  outline: none;
  text-decoration: none;
  -webkit-transition: border-color 110ms;
  transition: border-color 110ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 41.98rem) {
  a.cds--header__name {
    padding: 0 1rem;
  }
}

a.cds--header__name:focus {
  border-color: var(--cds-focus, #620b5b);
}

.cds--header__name--prefix {
  font-weight: 400;
}

a.cds--header__name,
a.cds--header__name:hover {
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-toggle:not(.cds--header__menu-toggle__hidden) ~ .cds--header__name {
  padding-left: 0.5rem;
}

.cds--header__nav {
  position: relative;
  display: none;
  height: 100%;
  padding-left: 1rem;
}
@media (min-width: 66rem) {
  .cds--header__nav {
    display: block;
  }
}
.cds--header__nav::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 0.0625rem;
  height: 1.5rem;
  background-color: var(--cds-border-subtle);
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cds--header__menu-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

a.cds--header__menu-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  border: 2px solid transparent;
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-secondary, #525252);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-decoration: none;
  -webkit-transition: background-color 110ms, border-color 110ms, color 110ms;
  transition: background-color 110ms, border-color 110ms, color 110ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a.cds--header__menu-item:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--header__action:active,
a.cds--header__menu-item:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
  color: var(--cds-text-primary, #161616);
}

a.cds--header__menu-item:focus {
  border-color: var(--cds-focus, #620b5b);
  outline: none;
}

a.cds--header__menu-item:hover > svg,
a.cds--header__menu-item:active > svg {
  fill: var(--cds-icon-primary, #161616);
}

a.cds--header__menu-item[aria-current=page],
.cds--header__menu-item--current {
  color: var(--cds-text-primary, #161616);
}

a.cds--header__menu-item[aria-current=page]::after,
.cds--header__menu-item--current::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -2px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid var(--cds-border-interactive, #620b5b);
  content: "";
}

a.cds--header__menu-item[aria-current=page]:focus::after,
.cds--header__menu-item--current:focus::after {
  border: 0;
}

.cds--header__submenu .cds--header__menu a.cds--header__menu-item[aria-current=page]::after,
.cds--header__submenu .cds--header__menu .cds--header__menu-item--current::after {
  bottom: 0;
  left: -2px;
  border-bottom: none;
  border-left: 3px solid var(--cds-border-interactive, #620b5b);
}
.cds--header__submenu .cds--header__menu a.cds--header__menu-item[aria-current=page]:focus::after,
.cds--header__submenu .cds--header__menu .cds--header__menu-item--current:focus::after {
  left: 0;
  border-left: 3px solid var(--cds-border-interactive, #620b5b);
}

a.cds--header__menu-item[aria-current=page]:focus,
a.cds--header__menu-item.cds--header__menu-item--current:focus {
  border: 2px solid var(--cds-focus, #620b5b);
}

.cds--header__submenu {
  position: relative;
}

.cds--header__menu-title[aria-haspopup=true] {
  position: relative;
}

.cds--header__menu-title[aria-expanded=true] {
  z-index: 8002;
  background-color: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
}

.cds--header__menu-title[aria-expanded=true] > .cds--header__menu-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cds--header__menu {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  position: absolute;
  z-index: 8001;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 12.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: var(--cds-layer);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item {
  background-color: var(--cds-layer);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item:hover {
  background-color: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item:active {
  background-color: var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu .cds--header__menu-item {
  height: 3rem;
}

.cds--header__menu-arrow {
  margin-left: 0.5rem;
  fill: var(--cds-icon-secondary, #525252);
  -webkit-transition: fill 110ms, -webkit-transform 110ms;
  transition: fill 110ms, -webkit-transform 110ms;
  transition: transform 110ms, fill 110ms;
  transition: transform 110ms, fill 110ms, -webkit-transform 110ms;
}

.cds--header__global {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cds--skip-to-content {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}

.cds--skip-to-content:focus {
  z-index: 9999;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  border: 4px solid var(--cds-focus, #620b5b);
  background-color: var(--cds-background, #ffffff);
  clip: auto;
  color: var(--cds-text-secondary, #525252);
  outline: none;
}

.cds--header-panel {
  position: fixed;
  z-index: 8000;
  top: 3rem;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 0;
  border: none;
  background-color: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
  -webkit-transition: width 110ms cubic-bezier(0.2, 0, 1, 0.9);
  transition: width 110ms cubic-bezier(0.2, 0, 1, 0.9);
  will-change: width;
}

.cds--header-panel--expanded {
  width: 16rem;
  border-right: 1px solid var(--cds-border-subtle);
  border-left: 1px solid var(--cds-border-subtle);
}

.cds--side-nav {
  position: fixed;
  z-index: 8000;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 3rem;
  max-width: 16rem;
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-secondary, #525252);
  -webkit-transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  will-change: width;
}

.cds--side-nav--ux {
  top: 3rem;
  width: 16rem;
}
@media (max-width: 65.98rem) {
  .cds--side-nav--ux {
    width: 0;
  }
}

.cds--side-nav--rail {
  width: 3rem;
}

.cds--side-nav--hidden {
  width: 0;
}

.cds--side-nav.cds--side-nav--rail:not(.cds--side-nav--fixed):hover,
.cds--side-nav--expanded {
  width: 16rem;
}

.cds--side-nav__overlay {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  opacity: 0;
  -webkit-transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
  transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
}

@media (max-width: 65.98rem) {
  .cds--side-nav__overlay-active {
    width: 100vw;
    height: 100vh;
    background-color: var(--cds-overlay, rgba(22, 22, 22, 0.5));
    opacity: 1;
    -webkit-transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
    transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
  }
}

.cds--header ~ .cds--side-nav {
  top: 3rem;
  height: calc(100% - 48px);
}

.cds--side-nav--fixed {
  width: 16rem;
}

.cds--side-nav--collapsed {
  width: 16rem;
  -webkit-transform: translateX(-16rem);
  transform: translateX(-16rem);
}

.cds--side-nav__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cds--side-nav__items {
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 1rem 0 0;
}
.cds--side-nav:hover .cds--side-nav__items, .cds--side-nav--fixed .cds--side-nav__items, .cds--side-nav--expanded .cds--side-nav__items {
  overflow-y: auto;
}

.cds--side-nav--ux .cds--side-nav__items {
  overflow-y: auto;
}

.cds--side-nav__item {
  overflow: hidden;
  width: auto;
  height: auto;
}

.cds--side-nav--ux .cds--side-nav__item {
  width: auto;
  height: auto;
}

.cds--side-nav__item:not(.cds--side-nav__item--active):hover .cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__submenu:hover,
.cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__link:hover,
.cds--side-nav__menu a.cds--side-nav__link:not(.cds--side-nav__link--current):not([aria-current=page]):hover,
.cds--side-nav a.cds--header__menu-item:hover,
.cds--side-nav .cds--header__menu-title[aria-expanded=true]:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__link:hover > span,
.cds--side-nav__item:not(.cds--side-nav__item--active) .cds--side-nav__menu-item > .cds--side-nav__link:hover > span {
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item--large {
  height: 3rem;
}

.cds--side-nav__divider {
  height: 1px;
  margin: 0.5rem 1rem;
  background-color: var(--cds-border-subtle);
}

.cds--side-nav__submenu {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  color: var(--cds-text-secondary, #525252);
  -webkit-transition: color 110ms, background-color 110ms, outline 110ms;
  transition: color 110ms, background-color 110ms, outline 110ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cds--side-nav__submenu *,
.cds--side-nav__submenu *::before,
.cds--side-nav__submenu *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--side-nav__submenu::-moz-focus-inner {
  border: 0;
}

.cds--side-nav__submenu:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__submenu:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--side-nav__submenu:focus {
    outline-style: dotted;
  }
}

.cds--side-nav__submenu-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.cds--side-nav__icon.cds--side-nav__submenu-chevron {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cds--side-nav__submenu-chevron > svg {
  width: 1rem;
  height: 1rem;
  -webkit-transition: -webkit-transform 110ms;
  transition: -webkit-transform 110ms;
  transition: transform 110ms;
  transition: transform 110ms, -webkit-transform 110ms;
}

.cds--side-nav__submenu[aria-expanded=true] .cds--side-nav__submenu-chevron > svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cds--side-nav__item--large .cds--side-nav__submenu {
  height: 3rem;
}

.cds--side-nav__item--active .cds--side-nav__submenu:hover {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item--active .cds--side-nav__submenu[aria-expanded=false] {
  position: relative;
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  color: var(--cds-text-primary, #161616);
}
.cds--side-nav__item--active .cds--side-nav__submenu[aria-expanded=false]::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
  background-color: var(--cds-border-interactive, #620b5b);
  content: "";
}

.cds--side-nav__item--active .cds--side-nav__submenu-title {
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
}

.cds--side-nav__item--active .cds--side-nav__icon > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--side-nav__menu {
  display: block;
  max-height: 0;
  visibility: hidden;
}

.cds--side-nav__submenu[aria-expanded=true] + .cds--side-nav__menu {
  max-height: 93.75rem;
  visibility: inherit;
}

.cds--side-nav__menu a.cds--side-nav__link {
  height: 2rem;
  min-height: 2rem;
  padding-left: 2rem;
  font-weight: 400;
}

.cds--side-nav__item.cds--side-nav__item--icon a.cds--side-nav__link {
  padding-left: 4.5rem;
}

.cds--side-nav__menu a.cds--side-nav__link--current,
.cds--side-nav__menu a.cds--side-nav__link[aria-current=page],
a.cds--side-nav__link--current {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
}
.cds--side-nav__menu a.cds--side-nav__link--current > span,
.cds--side-nav__menu a.cds--side-nav__link[aria-current=page] > span,
a.cds--side-nav__link--current > span {
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
}

a.cds--side-nav__link,
.cds--side-nav a.cds--header__menu-item,
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  text-decoration: none;
  -webkit-transition: color 110ms, background-color 110ms, outline 110ms;
  transition: color 110ms, background-color 110ms, outline 110ms;
}

.cds--side-nav__item--large a.cds--side-nav__link {
  height: 3rem;
}

a.cds--side-nav__link > .cds--side-nav__link-text,
.cds--side-nav a.cds--header__menu-item .cds--text-truncate-end {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--cds-text-secondary, #525252);
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a.cds--side-nav__link:focus,
.cds--side-nav a.cds--header__menu-item:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  a.cds--side-nav__link:focus,
  .cds--side-nav a.cds--header__menu-item:focus {
    outline-style: dotted;
  }
}

a.cds--side-nav__link[aria-current=page],
a.cds--side-nav__link--current {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  font-weight: 600;
}

a.cds--side-nav__link[aria-current=page] .cds--side-nav__link-text,
a.cds--side-nav__link--current .cds--side-nav__link-text {
  color: var(--cds-text-primary, #161616);
}

a.cds--side-nav__link[aria-current=page]::before,
a.cds--side-nav__link--current::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
  background-color: var(--cds-border-interactive, #620b5b);
  content: "";
}

.cds--side-nav__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cds--side-nav__icon:not(.cds--side-nav__submenu-chevron) {
  margin-right: 1.5rem;
}

.cds--side-nav__icon > svg {
  width: 1rem;
  height: 1rem;
  fill: var(--cds-icon-secondary, #525252);
}

.cds--side-nav__icon > svg.cds--side-nav-collapse-icon {
  display: none;
}

.cds--side-nav--expanded .cds--side-nav__icon > svg.cds--side-nav-expand-icon {
  display: none;
}

.cds--side-nav--expanded .cds--side-nav__icon > svg.cds--side-nav-collapse-icon {
  display: block;
}

.cds--side-nav--fixed a.cds--side-nav__link,
.cds--side-nav--fixed .cds--side-nav__submenu {
  padding-left: 1rem;
}

.cds--side-nav--fixed .cds--side-nav__item:not(.cds--side-nav__item--icon) .cds--side-nav__menu a.cds--side-nav__link {
  padding-left: 2rem;
}

@media (max-width: 65.98rem) {
  .cds--side-nav .cds--header__nav {
    display: block;
  }
}

.cds--side-nav__header-navigation {
  display: none;
}
@media (max-width: 65.98rem) {
  .cds--side-nav__header-navigation {
    position: relative;
    display: block;
    margin-bottom: 2rem;
  }
}

.cds--side-nav__header-divider::after {
  position: absolute;
  bottom: -1rem;
  left: 1rem;
  width: calc(100% - 32px);
  height: 0.0625rem;
  background: var(--cds-border-subtle);
  content: "";
}

.cds--side-nav a.cds--header__menu-item {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--cds-text-secondary, #525252);
  white-space: nowrap;
}
.cds--side-nav a.cds--header__menu-item[aria-expanded=true] {
  background-color: transparent;
}

.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  bottom: inherit;
  width: 100%;
  padding: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform: none;
  transform: none;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu li {
  width: 100%;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item {
  padding-left: 4.25rem;
  font-weight: 400;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav .cds--header__menu a.cds--header__menu-item {
  height: inherit;
}

.cds--side-nav a.cds--header__menu-item:hover .cds--header__menu-arrow,
.cds--side-nav a.cds--header__menu-item:focus .cds--header__menu-arrow,
.cds--side-nav .cds--header__menu-arrow {
  fill: var(--cds-icon-secondary, #525252);
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--side-nav__icon > svg,
  .cds--side-nav a.cds--header__menu-item:hover .cds--header__menu-arrow,
  .cds--side-nav a.cds--header__menu-item:focus .cds--header__menu-arrow,
  .cds--side-nav .cds--header__menu-arrow {
    fill: ButtonText;
  }
}

.cds--switcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--cds-text-secondary, #525252);
}

.cds--switcher__item {
  width: 100%;
  height: 2rem;
}

.cds--switcher__item:nth-child(1) {
  margin-top: 1rem;
}

.cds--switcher__item--divider {
  display: block;
  width: 14rem;
  height: 1px;
  border: none;
  margin: 0.5rem 1rem;
  background: var(--cds-border-subtle);
}

.cds--switcher__item-link {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  display: block;
  height: 2rem;
  padding: 0.375rem 1rem;
  color: var(--cds-text-secondary, #525252);
  text-decoration: none;
}
.cds--switcher__item-link:hover:not(.cds--switcher__item-link--selected) {
  background: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
}
.cds--switcher__item-link:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
.cds--switcher__item-link:active {
  background: var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

.cds--switcher__item-link--selected {
  background: var(--cds-layer-selected);
  color: var(--cds-text-primary, #161616);
}

.cds--content {
  padding: 2rem;
  color: var(--cds-text-primary, #161616);
  will-change: margin-left;
}

.cds--header ~ .cds--content {
  margin-top: 3rem;
}

.cds--side-nav ~ .cds--content {
  margin-left: 3rem;
}

.cds--side-nav.cds--side-nav--expanded ~ .cds--content {
  margin-left: 16rem;
}

.cds--header {
  position: fixed;
  z-index: 8000;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--cds-border-subtle);
  background-color: var(--cds-background, #ffffff);
}

.cds--header__action {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 3rem;
  height: 3rem;
  border: 0.0625rem solid transparent;
  -webkit-transition: background-color 110ms, border-color 110ms;
  transition: background-color 110ms, border-color 110ms;
}
.cds--header__action *,
.cds--header__action *::before,
.cds--header__action *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--header__action::-moz-focus-inner {
  border: 0;
}
@media (max-width: 41.98rem) {
  .cds--header__action {
    min-width: 3rem;
  }
}

.cds--header__action > svg.cds--navigation-menu-panel-collapse-icon,
.cds--header__action--active > svg.cds--navigation-menu-panel-expand-icon {
  display: none;
}

.cds--header__action--active > svg.cds--navigation-menu-panel-collapse-icon {
  display: inline;
}

.cds--header__action:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
}

.cds--header__action--active {
  border-right: 1px solid var(--cds-border-subtle);
  border-bottom: 1px solid transparent;
  border-left: 1px solid var(--cds-border-subtle);
  background: var(--cds-layer);
}

.cds--header__action--active > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__action:focus {
  border-color: var(--cds-focus, #620b5b);
  outline: none;
}

.cds--header__action:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
}

.cds--header__action.cds--btn--icon-only {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cds--btn.cds--btn--icon-only.cds--header__action svg {
  fill: var(--cds-icon-secondary, #525252);
}

.cds--btn.cds--btn--icon-only.cds--header__action:hover svg,
.cds--btn.cds--btn--icon-only.cds--header__action:active svg,
.cds--btn.cds--btn--icon-only.cds--header__action--active svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-trigger > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-trigger:hover > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 66rem) {
  .cds--header__menu-toggle__hidden {
    display: none;
  }
}

a.cds--header__name {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 2rem 0 1rem;
  border: 0.125rem solid transparent;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  outline: none;
  text-decoration: none;
  -webkit-transition: border-color 110ms;
  transition: border-color 110ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 41.98rem) {
  a.cds--header__name {
    padding: 0 1rem;
  }
}

a.cds--header__name:focus {
  border-color: var(--cds-focus, #620b5b);
}

.cds--header__name--prefix {
  font-weight: 400;
}

a.cds--header__name,
a.cds--header__name:hover {
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-toggle:not(.cds--header__menu-toggle__hidden) ~ .cds--header__name {
  padding-left: 0.5rem;
}

.cds--header__nav {
  position: relative;
  display: none;
  height: 100%;
  padding-left: 1rem;
}
@media (min-width: 66rem) {
  .cds--header__nav {
    display: block;
  }
}
.cds--header__nav::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 0.0625rem;
  height: 1.5rem;
  background-color: var(--cds-border-subtle);
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cds--header__menu-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

a.cds--header__menu-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  border: 2px solid transparent;
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-secondary, #525252);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-decoration: none;
  -webkit-transition: background-color 110ms, border-color 110ms, color 110ms;
  transition: background-color 110ms, border-color 110ms, color 110ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a.cds--header__menu-item:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--header__action:active,
a.cds--header__menu-item:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
  color: var(--cds-text-primary, #161616);
}

a.cds--header__menu-item:focus {
  border-color: var(--cds-focus, #620b5b);
  outline: none;
}

a.cds--header__menu-item:hover > svg,
a.cds--header__menu-item:active > svg {
  fill: var(--cds-icon-primary, #161616);
}

a.cds--header__menu-item[aria-current=page],
.cds--header__menu-item--current {
  color: var(--cds-text-primary, #161616);
}

a.cds--header__menu-item[aria-current=page]::after,
.cds--header__menu-item--current::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -2px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid var(--cds-border-interactive, #620b5b);
  content: "";
}

a.cds--header__menu-item[aria-current=page]:focus::after,
.cds--header__menu-item--current:focus::after {
  border: 0;
}

.cds--header__submenu .cds--header__menu a.cds--header__menu-item[aria-current=page]::after,
.cds--header__submenu .cds--header__menu .cds--header__menu-item--current::after {
  bottom: 0;
  left: -2px;
  border-bottom: none;
  border-left: 3px solid var(--cds-border-interactive, #620b5b);
}
.cds--header__submenu .cds--header__menu a.cds--header__menu-item[aria-current=page]:focus::after,
.cds--header__submenu .cds--header__menu .cds--header__menu-item--current:focus::after {
  left: 0;
  border-left: 3px solid var(--cds-border-interactive, #620b5b);
}

a.cds--header__menu-item[aria-current=page]:focus,
a.cds--header__menu-item.cds--header__menu-item--current:focus {
  border: 2px solid var(--cds-focus, #620b5b);
}

.cds--header__submenu {
  position: relative;
}

.cds--header__menu-title[aria-haspopup=true] {
  position: relative;
}

.cds--header__menu-title[aria-expanded=true] {
  z-index: 8002;
  background-color: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
}

.cds--header__menu-title[aria-expanded=true] > .cds--header__menu-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cds--header__menu {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  position: absolute;
  z-index: 8001;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 12.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: var(--cds-layer);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item {
  background-color: var(--cds-layer);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item:hover {
  background-color: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item:active {
  background-color: var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu .cds--header__menu-item {
  height: 3rem;
}

.cds--header__menu-arrow {
  margin-left: 0.5rem;
  fill: var(--cds-icon-secondary, #525252);
  -webkit-transition: fill 110ms, -webkit-transform 110ms;
  transition: fill 110ms, -webkit-transform 110ms;
  transition: transform 110ms, fill 110ms;
  transition: transform 110ms, fill 110ms, -webkit-transform 110ms;
}

.cds--header__global {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cds--skip-to-content {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}

.cds--skip-to-content:focus {
  z-index: 9999;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  border: 4px solid var(--cds-focus, #620b5b);
  background-color: var(--cds-background, #ffffff);
  clip: auto;
  color: var(--cds-text-secondary, #525252);
  outline: none;
}

.cds--header-panel {
  position: fixed;
  z-index: 8000;
  top: 3rem;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 0;
  border: none;
  background-color: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
  -webkit-transition: width 110ms cubic-bezier(0.2, 0, 1, 0.9);
  transition: width 110ms cubic-bezier(0.2, 0, 1, 0.9);
  will-change: width;
}

.cds--header-panel--expanded {
  width: 16rem;
  border-right: 1px solid var(--cds-border-subtle);
  border-left: 1px solid var(--cds-border-subtle);
}

.cds--side-nav {
  position: fixed;
  z-index: 8000;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 3rem;
  max-width: 16rem;
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-secondary, #525252);
  -webkit-transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  will-change: width;
}

.cds--side-nav--ux {
  top: 3rem;
  width: 16rem;
}
@media (max-width: 65.98rem) {
  .cds--side-nav--ux {
    width: 0;
  }
}

.cds--side-nav--rail {
  width: 3rem;
}

.cds--side-nav--hidden {
  width: 0;
}

.cds--side-nav.cds--side-nav--rail:not(.cds--side-nav--fixed):hover,
.cds--side-nav--expanded {
  width: 16rem;
}

.cds--side-nav__overlay {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  opacity: 0;
  -webkit-transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
  transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
}

@media (max-width: 65.98rem) {
  .cds--side-nav__overlay-active {
    width: 100vw;
    height: 100vh;
    background-color: var(--cds-overlay, rgba(22, 22, 22, 0.5));
    opacity: 1;
    -webkit-transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
    transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
  }
}

.cds--header ~ .cds--side-nav {
  top: 3rem;
  height: calc(100% - 48px);
}

.cds--side-nav--fixed {
  width: 16rem;
}

.cds--side-nav--collapsed {
  width: 16rem;
  -webkit-transform: translateX(-16rem);
  transform: translateX(-16rem);
}

.cds--side-nav__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cds--side-nav__items {
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 1rem 0 0;
}
.cds--side-nav:hover .cds--side-nav__items, .cds--side-nav--fixed .cds--side-nav__items, .cds--side-nav--expanded .cds--side-nav__items {
  overflow-y: auto;
}

.cds--side-nav--ux .cds--side-nav__items {
  overflow-y: auto;
}

.cds--side-nav__item {
  overflow: hidden;
  width: auto;
  height: auto;
}

.cds--side-nav--ux .cds--side-nav__item {
  width: auto;
  height: auto;
}

.cds--side-nav__item:not(.cds--side-nav__item--active):hover .cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__submenu:hover,
.cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__link:hover,
.cds--side-nav__menu a.cds--side-nav__link:not(.cds--side-nav__link--current):not([aria-current=page]):hover,
.cds--side-nav a.cds--header__menu-item:hover,
.cds--side-nav .cds--header__menu-title[aria-expanded=true]:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__link:hover > span,
.cds--side-nav__item:not(.cds--side-nav__item--active) .cds--side-nav__menu-item > .cds--side-nav__link:hover > span {
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item--large {
  height: 3rem;
}

.cds--side-nav__divider {
  height: 1px;
  margin: 0.5rem 1rem;
  background-color: var(--cds-border-subtle);
}

.cds--side-nav__submenu {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  color: var(--cds-text-secondary, #525252);
  -webkit-transition: color 110ms, background-color 110ms, outline 110ms;
  transition: color 110ms, background-color 110ms, outline 110ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cds--side-nav__submenu *,
.cds--side-nav__submenu *::before,
.cds--side-nav__submenu *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.cds--side-nav__submenu::-moz-focus-inner {
  border: 0;
}

.cds--side-nav__submenu:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__submenu:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--side-nav__submenu:focus {
    outline-style: dotted;
  }
}

.cds--side-nav__submenu-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.cds--side-nav__icon.cds--side-nav__submenu-chevron {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cds--side-nav__submenu-chevron > svg {
  width: 1rem;
  height: 1rem;
  -webkit-transition: -webkit-transform 110ms;
  transition: -webkit-transform 110ms;
  transition: transform 110ms;
  transition: transform 110ms, -webkit-transform 110ms;
}

.cds--side-nav__submenu[aria-expanded=true] .cds--side-nav__submenu-chevron > svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cds--side-nav__item--large .cds--side-nav__submenu {
  height: 3rem;
}

.cds--side-nav__item--active .cds--side-nav__submenu:hover {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item--active .cds--side-nav__submenu[aria-expanded=false] {
  position: relative;
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  color: var(--cds-text-primary, #161616);
}
.cds--side-nav__item--active .cds--side-nav__submenu[aria-expanded=false]::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
  background-color: var(--cds-border-interactive, #620b5b);
  content: "";
}

.cds--side-nav__item--active .cds--side-nav__submenu-title {
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
}

.cds--side-nav__item--active .cds--side-nav__icon > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--side-nav__menu {
  display: block;
  max-height: 0;
  visibility: hidden;
}

.cds--side-nav__submenu[aria-expanded=true] + .cds--side-nav__menu {
  max-height: 93.75rem;
  visibility: inherit;
}

.cds--side-nav__menu a.cds--side-nav__link {
  height: 2rem;
  min-height: 2rem;
  padding-left: 2rem;
  font-weight: 400;
}

.cds--side-nav__item.cds--side-nav__item--icon a.cds--side-nav__link {
  padding-left: 4.5rem;
}

.cds--side-nav__menu a.cds--side-nav__link--current,
.cds--side-nav__menu a.cds--side-nav__link[aria-current=page],
a.cds--side-nav__link--current {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
}
.cds--side-nav__menu a.cds--side-nav__link--current > span,
.cds--side-nav__menu a.cds--side-nav__link[aria-current=page] > span,
a.cds--side-nav__link--current > span {
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
}

a.cds--side-nav__link,
.cds--side-nav a.cds--header__menu-item,
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  text-decoration: none;
  -webkit-transition: color 110ms, background-color 110ms, outline 110ms;
  transition: color 110ms, background-color 110ms, outline 110ms;
}

.cds--side-nav__item--large a.cds--side-nav__link {
  height: 3rem;
}

a.cds--side-nav__link > .cds--side-nav__link-text,
.cds--side-nav a.cds--header__menu-item .cds--text-truncate-end {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--cds-text-secondary, #525252);
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a.cds--side-nav__link:focus,
.cds--side-nav a.cds--header__menu-item:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  a.cds--side-nav__link:focus,
  .cds--side-nav a.cds--header__menu-item:focus {
    outline-style: dotted;
  }
}

a.cds--side-nav__link[aria-current=page],
a.cds--side-nav__link--current {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  font-weight: 600;
}

a.cds--side-nav__link[aria-current=page] .cds--side-nav__link-text,
a.cds--side-nav__link--current .cds--side-nav__link-text {
  color: var(--cds-text-primary, #161616);
}

a.cds--side-nav__link[aria-current=page]::before,
a.cds--side-nav__link--current::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
  background-color: var(--cds-border-interactive, #620b5b);
  content: "";
}

.cds--side-nav__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cds--side-nav__icon:not(.cds--side-nav__submenu-chevron) {
  margin-right: 1.5rem;
}

.cds--side-nav__icon > svg {
  width: 1rem;
  height: 1rem;
  fill: var(--cds-icon-secondary, #525252);
}

.cds--side-nav__icon > svg.cds--side-nav-collapse-icon {
  display: none;
}

.cds--side-nav--expanded .cds--side-nav__icon > svg.cds--side-nav-expand-icon {
  display: none;
}

.cds--side-nav--expanded .cds--side-nav__icon > svg.cds--side-nav-collapse-icon {
  display: block;
}

.cds--side-nav--fixed a.cds--side-nav__link,
.cds--side-nav--fixed .cds--side-nav__submenu {
  padding-left: 1rem;
}

.cds--side-nav--fixed .cds--side-nav__item:not(.cds--side-nav__item--icon) .cds--side-nav__menu a.cds--side-nav__link {
  padding-left: 2rem;
}

@media (max-width: 65.98rem) {
  .cds--side-nav .cds--header__nav {
    display: block;
  }
}

.cds--side-nav__header-navigation {
  display: none;
}
@media (max-width: 65.98rem) {
  .cds--side-nav__header-navigation {
    position: relative;
    display: block;
    margin-bottom: 2rem;
  }
}

.cds--side-nav__header-divider::after {
  position: absolute;
  bottom: -1rem;
  left: 1rem;
  width: calc(100% - 32px);
  height: 0.0625rem;
  background: var(--cds-border-subtle);
  content: "";
}

.cds--side-nav a.cds--header__menu-item {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--cds-text-secondary, #525252);
  white-space: nowrap;
}
.cds--side-nav a.cds--header__menu-item[aria-expanded=true] {
  background-color: transparent;
}

.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  bottom: inherit;
  width: 100%;
  padding: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform: none;
  transform: none;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu li {
  width: 100%;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item {
  padding-left: 4.25rem;
  font-weight: 400;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav .cds--header__menu a.cds--header__menu-item {
  height: inherit;
}

.cds--side-nav a.cds--header__menu-item:hover .cds--header__menu-arrow,
.cds--side-nav a.cds--header__menu-item:focus .cds--header__menu-arrow,
.cds--side-nav .cds--header__menu-arrow {
  fill: var(--cds-icon-secondary, #525252);
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--side-nav__icon > svg,
  .cds--side-nav a.cds--header__menu-item:hover .cds--header__menu-arrow,
  .cds--side-nav a.cds--header__menu-item:focus .cds--header__menu-arrow,
  .cds--side-nav .cds--header__menu-arrow {
    fill: ButtonText;
  }
}

.cds--switcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--cds-text-secondary, #525252);
}

.cds--switcher__item {
  width: 100%;
  height: 2rem;
}

.cds--switcher__item:nth-child(1) {
  margin-top: 1rem;
}

.cds--switcher__item--divider {
  display: block;
  width: 14rem;
  height: 1px;
  border: none;
  margin: 0.5rem 1rem;
  background: var(--cds-border-subtle);
}

.cds--switcher__item-link {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  display: block;
  height: 2rem;
  padding: 0.375rem 1rem;
  color: var(--cds-text-secondary, #525252);
  text-decoration: none;
}
.cds--switcher__item-link:hover:not(.cds--switcher__item-link--selected) {
  background: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
}
.cds--switcher__item-link:focus {
  outline: 2px solid var(--cds-focus, #620b5b);
  outline-offset: -2px;
}
.cds--switcher__item-link:active {
  background: var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

.cds--switcher__item-link--selected {
  background: var(--cds-layer-selected);
  color: var(--cds-text-primary, #161616);
}
